import { Button, Col, Input, Popover, Row, Select, Space, Table } from 'antd';
import { PageHeader } from 'components/shared';
import React, { useEffect, useState } from 'react';
import { useStore } from 'store';
import queryString from 'querystring';
import { useHistory, useLocation } from 'react-router-dom';
import { getBill, getListKelasId, getListTahunAjaran } from 'utils/fetch';
import { FilterOutlined } from '@ant-design/icons';
import { InfoCard } from 'components/fragments/card';
import moment from 'moment';
import Text from 'antd/lib/typography/Text';
import { goToSearchQuery } from 'utils/helpers/common';

const RiwayatPerubahan = () => {
  const {
    handleBreadcrumb,
    handleSiderActive,
    handleListSchoolYear,
    handleListKelas,
    state: { activeSchoolYear, selectedUnit, listKelas, listTahunAjaran },
  } = useStore();
  const history = useHistory();
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [data, setData] = useState(null);
  const [filterPopover, setFilterPopover] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totalData, setTotalData] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState({
    student_name: null,
    student_nis: null,
    bill_name: null,
    bill_category: null,
    information: null,
    school_year: null,
  });
  const [selectedId, setSelectedId] = useState(null);
  const [otherData, setOtherData] = useState(null);
  const unitId = selectedUnit?._id;
  const schoolYearId = activeSchoolYear?._id;
  const {
    unit,
    limit = 10,
    page = 1,
    student_name = null,
    student_nis = null,
    bill_name = null,
    bill_category = null,
    information = null,
    school_year = null,
  } = queryString.parse(location.search.replace('?', ''));
  const filterQuery = {
    unit,
    limit,
    page,
    student_name,
    student_nis,
    bill_name,
    bill_category,
    information,
    school_year,
  };

  useEffect(() => {
    const newBreadcrumb = [
      {
        linkTo: '/tagihan/history',
        node: <span>Riwayat Perubahan Tagihan</span>,
      },
    ];
    handleBreadcrumb(newBreadcrumb);
    handleSiderActive(['3.4']);
  }, []);

  useEffect(() => {
    if (currentPage) {
      Object.keys(filterQuery).forEach((key) => {
        if (!filterQuery[key]) delete filterQuery[key];
      });
      history.push({
        search: queryString.stringify({
          ...filterQuery,
          page: currentPage,
        }),
      });
    }
  }, [currentPage]);

  useEffect(() => {
    fetchDataListKelas();
    // fetchDataTahunAjaran();
  }, []);

  useEffect(() => {
    if (selectedUnit) {
      fetchData();
    }

    return () => {};
  }, [
    page,
    limit,
    student_name,
    student_nis,
    bill_name,
    bill_category,
    information,
    school_year,
    selectedUnit,
  ]);

  useEffect(() => {
    setFilter({
      student_name,
      student_nis,
      bill_name,
      bill_category,
      information,
      school_year,
    });
  }, [
    student_name,
    student_nis,
    bill_name,
    bill_category,
    information,
    school_year,
  ]);

  const fetchDataTahunAjaran = () => {
    getListTahunAjaran()
      .then((res) => {
        console.log(res);
        handleListSchoolYear(res.schoolsYears);
      })
      .catch((err) => console.error(err));
  };

  const fetchDataListKelas = () => {
    getListKelasId(unitId, schoolYearId)
      .then((res) => {
        console.log(res.grades);
        handleListKelas(res.grades);
      })
      .catch((err) => console.error(err));
  };

  const fetchData = () => {
    let query = `limit=${limit}&page=${page - 1}`;

    Object.keys(filterQuery)
      .filter((fil) => filterQuery[fil] !== null)
      .forEach((key) => {
        if (key === 'student_nis') {
          query += `&student_nik=${filterQuery['student_nis']}`;
        } else if (key !== 'limit' && key !== 'page') {
          query += `&${key}=${filterQuery[key]}`;
        }
      });

    setLoading(true);

    getBill(unitId, 'history-logs', query)
      .then((res) => {
        const numbered = res.historyLogs.map((el, idx) => ({
          ...el,
          no: idx + 1,
        }));
        console.log(numbered);
        setData(numbered);
        setTotalData(res.totalData);
        setLoading(false);
        setOtherData({
          totalChangesAmount: res.totalChangesAmount,
          totalBillChanges: res.totalBillChanges,
          totalStudentChanges: res.totalStudentChanges,
        });
        // console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response, 'this is error fetch');
      });
  };

  const handleSubmitFilter = () => {
    Object.keys(filter).forEach((key) => {
      if (!filter[key]) delete filter[key];
    });

    history.push({
      pathname: location.pathname,
      search: queryString.stringify({
        unit,
        limit,
        page,
        ...filter,
      }),
    });
    setFilterPopover(false);
  };

  const filterMenu = (
    <div style={{ width: '30rem' }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Input
          type="text"
          name="student_name"
          onChange={(e) => {
            const newFilter = {
              ...filter,
              student_name: e.target.value,
            };
            setFilter(newFilter);
          }}
          value={filter?.student_name}
          placeholder="Nama Murid"
        />
        <Input
          type="text"
          name="student_nis"
          onChange={(e) => {
            const newFilter = {
              ...filter,
              student_nis: e.target.value,
            };
            setFilter(newFilter);
          }}
          value={filter?.student_nis}
          placeholder="Nomor NIS"
        />
        <Input
          type="text"
          name="bill_name"
          onChange={(e) => {
            const newFilter = {
              ...filter,
              bill_name: e.target.value,
            };
            setFilter(newFilter);
          }}
          value={filter?.bill_name}
          placeholder="Nama Tagihan"
        />
        <Select
          placeholder="Kategori Tagihan"
          style={{ width: '100%' }}
          onChange={(val) => {
            const newFilter = {
              ...filter,
              bill_category: val,
            };
            setFilter(newFilter);
          }}
          value={filter?.bill_category}
        >
          <Select.Option value={'SPP'}>SPP</Select.Option>
          <Select.Option value={'PPDB'}>PSB</Select.Option>
          <Select.Option value={'General'}>Tagihan Lainnya</Select.Option>
        </Select>
        <Input
          type="text"
          name="information"
          onChange={(e) => {
            const newFilter = {
              ...filter,
              information: e.target.value,
            };
            setFilter(newFilter);
          }}
          value={filter?.information}
          placeholder="Informasi"
        />
        <Select
          placeholder="Tahun Ajaran"
          style={{ width: '100%' }}
          onChange={(val) => {
            const newFilter = {
              ...filter,
              school_year: val,
            };
            setFilter(newFilter);
          }}
          value={filter?.school_year}
        >
          {listTahunAjaran?.map((year) => {
            return <Select.Option value={year._id}>{year.name}</Select.Option>;
          })}
        </Select>
        <footer
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            borderTop: '1px solid #d5d5d5',
            paddingTop: '0.5rem',
          }}
        >
          <Space>
            <Button
              onClick={() => {
                setFilterPopover(false);
                setFilter({
                  name: null,
                  status: null,
                  grade: null,
                });
                history.push({
                  pathname: location.pathname,
                  search: queryString.stringify({
                    unit: selectedUnit.name,
                    limit,
                    page,
                  }),
                });
              }}
            >
              Hapus Filter
            </Button>
            <Button type="primary" onClick={handleSubmitFilter}>
              Cari
            </Button>
          </Space>
        </footer>
      </Space>
    </div>
  );

  const tableColumns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: 80,
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Nama Tagihan',
      dataIndex: 'bill_name',
      key: 'bill_name',
      width: 250,
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Nama Murid',
      dataIndex: 'student',
      key: 'student',
      width: 250,
      render: (data) => <span>{data?.name}</span>,
    },
    {
      title: 'Informasi',
      dataIndex: 'information',
      key: 'information',
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Tgl Perubahan',
      dataIndex: 'change_date',
      key: 'change_date',
      width: 200,
      render: (data) => <span>{moment(data).format('DD/MM/YYYY')}</span>,
    },
    {
      title: 'Nominal Awal',
      dataIndex: 'amount_before',
      key: 'amount_before',
      width: 150,
      render: (data) => <span>Rp{Number(data || 0).toLocaleString()}</span>,
    },
    {
      title: 'Nominal Akhir',
      dataIndex: 'amount_after',
      key: 'amount_after',
      width: 150,
      render: (data) => <span>Rp{Number(data || 0).toLocaleString()}</span>,
    },
    {
      title: 'Selisih',
      dataIndex: 'amount_difference',
      key: 'amount_difference',
      width: 150,
      fixed: 'right',
      render: (data) => {
        const isBelowZero = data < 0 ? true : false;

        return (
          <Text type={isBelowZero ? 'danger' : 'success'}>
            {isBelowZero
              ? `-Rp${Number(Math.abs(data) || 0).toLocaleString()}`
              : `Rp${Number(Math.abs(data) || 0).toLocaleString()}`}
          </Text>
        );
      },
    },
  ];

  return (
    <>
      <PageHeader />
      <section style={{ width: 'auto', margin: '2rem 0' }}>
        <Popover
          content={filterMenu}
          visible={filterPopover}
          placement="bottomLeft"
          style={{ width: '30rem' }}
        >
          <Button
            onClick={() => setFilterPopover(!filterPopover)}
            icon={<FilterOutlined />}
          >
            Cari Berdasarkan
          </Button>
        </Popover>
      </section>
      <Row gutter={[24, 0]}>
        <Col span="8">
          <InfoCard
            title="Selisih Perubahan Nominal"
            content={`${otherData?.totalChangesAmount < 0 ? `-Rp` : 'Rp'}${(
              Math.abs(otherData?.totalChangesAmount) || 0
            ).toLocaleString()}`}
            sideColor="#FFA6A7"
          />
        </Col>
        <Col span="8">
          <InfoCard
            title="Jumlah Perubahan Tagihan"
            content={(otherData?.totalBillChanges || 0).toLocaleString()}
            sideColor="#FFA6A7"
          />
        </Col>
        <Col span="8">
          <InfoCard
            title="Jumlah Murid Perubahan"
            content={(otherData?.totalStudentChanges || 0).toLocaleString()}
            sideColor="#FFA6A7"
          />
        </Col>
      </Row>
      <section style={{ width: '100%', margin: '2rem 0' }}>
        <Table
          dataSource={data}
          columns={tableColumns}
          pagination={{
            position: ['bottomLeft'],
            total: totalData && totalData,
            onChange: (page) => {
              setCurrentPage(page);
            },
            current: currentPage,
            pageSize: limit,
            showSizeChanger: true,
            pageSizeOptions: ['10', '30', '50'],
            onShowSizeChange: (curr, pageSize) => {
              goToSearchQuery('limit', pageSize, filterQuery, history);
            },
          }}
          scroll={{ x: 1500 }}
          loading={loading}
        />
      </section>
    </>
  );
};

export default RiwayatPerubahan;
