import {
  SET_AUTHENTICATED,
  SET_BREADCRUMBS,
  SET_SIDER_ACTIVE,
  SET_HEADER_ACTIVE,
  SET_USER_DATA,
  SET_SELECTED_UNIT,
  SET_SCHOOL_YEAR,
  SET_LIST_KELAS,
  SET_LIST_SCHOOL_YEAR,
  SET_CALLING_CODES,
} from 'utils/action-types';

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_AUTHENTICATED:
      return { ...state, isAuthenticated: payload.auth };
    case SET_BREADCRUMBS:
      return { ...state, breadcrumbs: payload.data };
    case SET_HEADER_ACTIVE:
      return { ...state, headerActive: payload.data };
    case SET_SIDER_ACTIVE:
      return { ...state, siderActive: payload.data };
    case SET_USER_DATA:
      return { ...state, userData: payload.data };
    case SET_SELECTED_UNIT:
      return { ...state, selectedUnit: payload.data };
    case SET_SCHOOL_YEAR:
      return { ...state, activeSchoolYear: payload.data };
    case SET_LIST_SCHOOL_YEAR:
      return { ...state, listTahunAjaran: payload.data };
    case SET_LIST_KELAS:
      return { ...state, listKelas: payload.data };
    case SET_CALLING_CODES:
      return { ...state, callingCodes: payload.data };
    default:
      return state;
  }
};

export default reducer;
