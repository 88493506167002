import React, { useEffect, useState } from 'react';
import { Drawer, Form, Button, Input } from 'antd';
import { Footer } from './shared';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { InputWrapper } from '..';

const ItemTagihan = ({
  visible,
  onCancel,
  isEdit,
  value,
  onSubmit,
  loadingSubmit,
  ...rest
}) => {
  const [form] = Form.useForm();
  const [deletedItems, setDeletedItems] = useState([]);

  const handleDeletedItems = (item) => {
    setDeletedItems([...deletedItems, item]);
  };

  useEffect(() => {
    return () => {
      form.setFieldsValue({ items: [''] });
    };
  }, [visible]);

  useEffect(() => {
    if (isEdit) form.setFieldsValue({ items: value });
  }, [isEdit, value]);

  const EditForm = () => {
    return (
      <InputWrapper
        type="text"
        validateTrigger={['onChange', 'onBlur']}
        rules={[
          {
            required: true,
            whitespace: true,
            message: 'Nama item tagihan wajib diisi.',
          },
        ]}
        name="items"
        label="Name Item Tagihan"
      />
    );
  };

  const AddForm = () => {
    return (
      <Form.List name="items">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                required={false}
                key={field.key}
                label="Nama Item Tagihan"
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  {...field}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Nama item tagihan wajib diisi.',
                    },
                  ]}
                  noStyle
                  label="Name Item Tagihan"
                >
                  <Input
                    type="text"
                    placeholder="Nama item tagihan"
                    style={{ width: '90%' }}
                  />
                </Form.Item>
                {fields.length > 1 ? (
                  <Button
                    type="text"
                    onClick={() => {
                      handleDeletedItems(
                        form.getFieldValue('items')[field.name]?._id
                      );
                      remove(field.name);
                    }}
                    disabled={isEdit && value.lock_status}
                    icon={<DeleteOutlined style={{ fontSize: '20px' }} />}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="secondary"
                onClick={() => add()}
                style={{ width: '60%', marginTop: '2rem' }}
                icon={<PlusOutlined />}
              >
                Tambah Item Tagihan
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    );
  };

  return (
    <Drawer
      title={`${isEdit ? 'Edit' : 'Tambah'} Item Tagihan`}
      width="40rem"
      visible={visible}
      onClose={onCancel}
      footer={
        <Footer
          onCancel={onCancel}
          onSubmit={() => onSubmit(form.getFieldsValue())}
          loadingState={loadingSubmit}
        />
      }
      {...rest}
    >
      <Form
        form={form}
        name={isEdit ? '' : 'dynamic_form_item'}
        // onFinish={(val) => console.log(val)}
        initialValues={{
          items: isEdit ? value : [''],
        }}
        layout="vertical"
      >
        {isEdit ? <EditForm /> : <AddForm />}
      </Form>
    </Drawer>
  );
};

export default ItemTagihan;
