import { CardBase, CustomButton, Loader } from 'components/atoms';
import { Drawer, Modal, PageHeader } from 'components/shared';
import React, { useEffect, useState } from 'react';
import IC_PLUSR from 'assets/icons/plus-rounded.svg';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Empty,
  Menu,
  Row,
  Space,
  Spin,
  Progress,
  message,
  Popover,
} from 'antd';
import { useStore } from 'store';
import {
  downloadVADashboard,
  fetchTahunAjaran,
  getDashboardDataAllUnit,
  getDashboardDataUnit,
  getMainDashboardData,
  updateDashboardData,
} from 'utils/fetch';
import { useHistory } from 'react-router-dom';
import IC_PENDAPATAN from 'assets/icons/pendapatan.svg';
import { ProgressContainer, ProgressTitle } from './style';

import IC_ASAK from 'assets/icons/ASAK.svg';
import IC_TUNGGAKAN from 'assets/icons/tunggakan.svg';
import IC_DANA_MASUK from 'assets/icons/dana-masuk.svg';
import IC_REFUND from 'assets/icons/Refund.svg';
import IC_PEMBAYARAN from 'assets/icons/Pembayaran.svg';
import moment from 'moment';
import { DownloadOutlined, FileExclamationOutlined } from '@ant-design/icons';
import exportExcel from 'utils/helpers/xlsx';

export const CardDashboard = ({
  title,
  rincian = 0,
  amount = 0,
  debt = false,
  selectMonth,
  selectedMonth = '-',
  loading,
  icon = IC_PENDAPATAN,
  disableAction,
  fullHeight,
  noFooter,
  openModal = () => {},
}) => {
  const {
    state: { activeSchoolYear },
  } = useStore();

  const MenuBulan = ({ onClick }) => {
    const list = activeSchoolYear
      ? Array.from({ length: 12 }, (_, i) => {
          const date = new Date(activeSchoolYear.start_date);
          const next = new Date(date.setMonth(date.getMonth() + i));

          return {
            name: `${moment(next).format('MMMM YYYY')}`,
            value: next,
          };
        })
      : [];

    return (
      <Menu>
        {list.map((month) => (
          <Menu.Item key={month.value} onClick={() => onClick(month)}>
            {month.name}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  return (
    <Card
      style={{ height: fullHeight || noFooter ? '100%' : 'auto' }}
      bodyStyle={{ height: fullHeight ? '78%' : 'auto' }}
      actions={
        !noFooter && [
          <div
            style={{
              maxHeight: '4.7rem',
              margin: 0,
              cursor: 'pointer',
              textAlign: 'center',
            }}
            onClick={openModal}
          >
            Lihat Rincian ({rincian})
          </div>,
        ]
      }
    >
      <header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <img src={icon} />
        {selectMonth ? (
          // <Dropdown
          //   disabled={disableAction}
          //   overlay={<MenuBulan onClick={(v) => selectMonth(v)} />}
          // >
          <Button disabled={disableAction}>{selectedMonth}</Button>
        ) : // </Dropdown>
        null}
      </header>
      {!loading ? (
        <section style={{ marginTop: '2rem' }}>
          <p style={{ margin: 0 }}>{title}</p>
          <h2
            style={{ color: debt ? '#FF4D4F' : '#0b0b0b', fontWeight: '700' }}
          >
            Rp{(amount || 0).toLocaleString()}
          </h2>
        </section>
      ) : (
        <Loader text="Wait for details ..." />
      )}
    </Card>
  );
};

const DashboardKeseluruhan = () => {
  const {
    state: { activeSchoolYear, unitData, userData },
    handleBreadcrumb,
    handleSiderActive,
  } = useStore();
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [studentCondition, setStudentCondition] = useState(null);
  const [lastUpdated, setLastUpdated] = useState('');
  const [revenueFilter, setRevenueFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [asakMonth, setAsakMonth] = useState({
    name: '',
    value: '',
  });
  const [refundMonth, setRefundMonth] = useState({
    name: '',
    value: '',
  });
  const [listTahunAjaran, setListTahunAjaran] = useState([]);
  const [dashboardUnit, setDashboardUnit] = useState(userData?.unit || '');
  const [rincianModal, setRincianModal] = useState({
    state: false,
    table: '',
    data: null,
  });
  const [downloadModal, setDownloadModal] = useState({
    state: false,
    type: '',
  });
  const arrayUnit = Object.keys(unitData).map((key) => unitData[key]);
  const history = useHistory();

  useEffect(() => {
    fetchListTahun();
  }, []);

  useEffect(() => {
    if (userData.unit) {
      setDashboardUnit(userData.unit);
    }
  }, [userData]);

  useEffect(() => {
    if (activeSchoolYear?._id) {
      setRevenueFilter(activeSchoolYear);
      setCategoryFilter(activeSchoolYear);
      // const next = new Date(activeSchoolYear.start_date);
      const next = new Date();

      const date = {
        name: `${moment(next).format('MMMM YYYY')}`,
        value: next,
      };

      setAsakMonth(date);
      setRefundMonth(date);
    }
  }, [activeSchoolYear]);

  useEffect(() => {
    setStudentCondition(null);
    fetchData();
  }, [dashboardUnit]);

  const fetchData = () => {
    setStudentCondition(null);

    if (dashboardUnit?._id) {
      getDashboardDataUnit(dashboardUnit?._id)
        .then((res) => {
          console.log(res, 'this is student condition');
          setStudentCondition(res.result);
          setLastUpdated(res.lastUpdated);
        })
        .catch((err) => {
          setStudentCondition('error');
          message.error(err?.response?.data?.m);
          console.log(err.response, 'error fetch student condition');
        });
    } else {
      getDashboardDataAllUnit()
        .then((res) => {
          console.log(res, 'this is student condition');
          setStudentCondition(res.result);
          setLastUpdated(res.lastUpdated);
        })
        .catch((err) => {
          setStudentCondition('error');
          message.error(err?.response?.data?.m);
          console.log(err.response, 'error fetch student condition');
        });
    }
  };

  useEffect(() => {
    const newBreadcrumb = [
      {
        linkTo: '/dashboard/semua',
        node: (
          <div>
            <p style={{ margin: 0, lineHeight: 0.75 }}>Dashboard Keseluruhan</p>
            <span style={{ fontSize: '1.15rem', color: '#262626', margin: 0 }}>
              Update terakhir:{' '}
              {lastUpdated
                ? moment(lastUpdated).format('DD/MM/YYYY, hh:mm A')
                : '-'}
            </span>
          </div>
        ),
      },
    ];
    handleBreadcrumb(newBreadcrumb);
    handleSiderActive(['1']);
  }, [lastUpdated]);

  const fetchListTahun = () => {
    fetchTahunAjaran()
      .then((res) => {
        console.log(res);
        setListTahunAjaran(res.schoolYears);
      })
      .catch((err) => console.log(err, 'error list tahun'));
  };

  const handleUpdateData = () => {
    setLoadingUpdate(true);

    updateDashboardData()
      .then((res) => {
        console.log(res, 'berhasil');
        if (res.m) {
          message.success('Berhasil memperbaharui data.');
          fetchData();
        }
      })
      .catch((err) => {
        console.log(err, 'error update');
        message.error('Gagal menmperbaharui data.');
      })
      .finally(() => setLoadingUpdate(false));
  };

  const MenuTahun = ({ onClick, list = [{ name: '' }] }) => {
    return (
      <Menu
        style={{
          boxShadow:
            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        }}
      >
        {list.map((ta, idx) => {
          return (
            <Menu.Item
              key={idx}
              onClick={() => {
                console.log(ta, 'this is selected tahun ajaran');
                onClick(ta);
              }}
            >
              {ta.name}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  const MenuUnit = ({ onClick }) => {
    return (
      <Menu
        style={{
          boxShadow:
            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        }}
      >
        <Menu.Item key="all" onClick={() => onClick('')}>
          Semua Unit
        </Menu.Item>
        {arrayUnit.map((unit) => (
          <Menu.Item key={unit._id} onClick={() => onClick(unit)}>
            {unit.name}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const handleDownloadVADashboard = () => {
    if (dashboardUnit) {
      setLoadingSubmit(true);
      downloadVADashboard(dashboardUnit?._id)
        .then((values) => {
          exportExcel(
            values.endResults,
            `Laporan VA Tagihan ${dashboardUnit?.name}`
          );
        })
        .catch((err) => {
          console.log(err.response.data?.m, 'error download');
          message.error(
            'Gagal melakukan download data, silakan cek pilihan unit anda.'
          );
        })
        .finally(() => setLoadingSubmit(false));
    } else {
      message.error('Silakan pilih unit terlebih dahulu');
    }
  };

  const MenuDownload = () => {
    return (
      <Menu
        style={{
          boxShadow:
            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        }}
      >
        <Menu.Item key={'all'} onClick={downloadLaporan}>
          Laporan Keseluruhan
        </Menu.Item>

        <Menu.Item
          key={'all'}
          onClick={() => setDownloadModal({ state: true, type: 'all' })}
        >
          Laporan Estimasi Pendapatan
        </Menu.Item>

        <Menu.Item
          key={'va'}
          onClick={() => setDownloadModal({ state: true, type: 'rekap' })}
        >
          Rekapan Tagihan
        </Menu.Item>
      </Menu>
    );
  };

  const parsedResultTunggakan = (data = []) => {
    const parsed = [['No', 'Nama Murid', 'Tunggakan', 'Kelas', 'Unit']];

    data.forEach((dt, idx) => {
      const array = [
        idx + 1,
        dt.name,
        dt.total_debt,
        dt.grade_name,
        dt.unit_name,
      ];

      parsed.push(array);
    });

    return parsed;
  };
  const parsedResultDanaBelumMasuk = (data = []) => {
    const parsed = [
      [
        'No',
        'Nama Murid',
        'Total Dana Masuk',
        'Tunggakan',
        'Tanggal Masuk',
        'Kelas',
        'Unit',
      ],
    ];

    data.forEach((dt, idx) => {
      const array = [
        idx + 1,
        dt.name,
        dt.total_fund,
        dt.total_debt || 'Lunas',
        moment(dt.date).format('DD/MM/YYYY'),
        dt.grade_name,
        dt.unit_name,
      ];

      parsed.push(array);
    });

    return parsed;
  };

  const parsedResultTabunganBelumMasuk = (data = []) => {
    const parsed = [
      ['No', 'Nama Murid', 'Total Tabungan', 'Tunggakan', 'Kelas', 'Unit'],
    ];

    data.forEach((dt, idx) => {
      const array = [
        idx + 1,
        dt.name,
        dt.total_savings,
        dt.total_debt || 'Lunas',
        dt.grade_name,
        dt.unit_name,
      ];

      parsed.push(array);
    });

    return parsed;
  };

  const parsedResultPendapatanASAK = (data = []) => {
    const parsed = [
      ['No', 'Nama Murid', 'Total Dana ASAK', 'Tunggakan', 'Kelas', 'Unit'],
    ];

    data.forEach((dt, idx) => {
      const array = [
        idx + 1,
        dt.name,
        dt.total_asak,
        dt.total_debt,
        dt.grade_name,
        dt.unit_name,
      ];

      parsed.push(array);
    });

    return parsed;
  };

  const parsedResultRefund = (data = []) => {
    const parsed = [
      ['No', 'Nama Murid', 'Tagihan', 'Jumlah Refund', 'Kelas', 'Unit'],
    ];

    data.forEach((dt, idx) => {
      const array = [
        idx + 1,
        dt.name,
        dt.bill_name,
        dt.refund_amount,
        dt.grade_name,
        dt.unit_name,
      ];

      parsed.push(array);
    });

    return parsed;
  };
  const parsedResultMenunggak5Tagihan = (data = []) => {
    const parsed = [
      ['No', 'Nama Murid', 'Jml Tagihan', 'Tunggakan', 'Kelas', 'Unit'],
    ];

    data.forEach((dt, idx) => {
      const array = [
        idx + 1,
        dt.name,
        dt.total_bill,
        dt.total_debt,
        dt.grade_name,
        dt.unit_name,
      ];

      parsed.push(array);
    });

    return parsed;
  };
  const parsedResultKategori = (data = []) => {
    const parsed = [['No', 'Nama', 'Tunggakan']];

    data.forEach((dt, idx) => {
      const array = [idx + 1, dt.name, dt.remaining_amount];

      parsed.push(array);
    });

    return parsed;
  };

  const downloadLaporan = () => {
    const parsedTunggakan = parsedResultTunggakan(
      studentCondition?.student_debt_list
    );
    const parsedDanaBelumMasuk = parsedResultDanaBelumMasuk(
      studentCondition?.student_fund_list
    );
    const parsedTabunganBelumMasuk = parsedResultTabunganBelumMasuk(
      studentCondition?.student_savings_list
    );
    const parsedPendapatanASAK = parsedResultPendapatanASAK(
      studentCondition?.student_asak_list
    );
    const parsedRefund = parsedResultRefund(
      studentCondition?.student_refund_list
    );
    const parsedMenunggak5Tagihan = parsedResultMenunggak5Tagihan(
      studentCondition?.student_debt_above_5_list
    );
    const parsedKategori = parsedResultKategori(
      studentCondition?.categories_information
    );
    const parsedData = [
      {
        data: parsedTunggakan,
        sheetName: 'Tunggakan Murid',
      },
      {
        data: parsedDanaBelumMasuk,
        sheetName: 'Dana Belum Alokasi',
      },
      {
        data: parsedTabunganBelumMasuk,
        sheetName: 'Tabungan Belum Alokasi',
      },
      {
        data: parsedPendapatanASAK,
        sheetName: 'Pendapatan ASAK',
      },
      {
        data: parsedRefund,
        sheetName: 'Refund',
      },
      {
        data: parsedMenunggak5Tagihan,
        sheetName: 'Menunggak diatas 5 Tagihan',
      },
      {
        data: parsedKategori,
        sheetName: 'Kategori',
      },
    ];
    const fileName = dashboardUnit
      ? `Laporan Keseluruhan - ${dashboardUnit?.name}`
      : 'Laporan Keseluruhan';
    exportExcel(parsedData, fileName, true);
  };

  const isNormalUser = userData.unit !== undefined;

  return (
    <>
      {studentCondition && !loadingUpdate ? (
        <>
          <PageHeader>
            <Space>
              <Button
                icon={<FileExclamationOutlined />}
                onClick={handleUpdateData}
                loading={loadingUpdate}
              >
                Update Data
              </Button>
              <Dropdown
                disabled={studentCondition === 'error' || userData.type === 2}
                overlay={<MenuUnit onClick={(v) => setDashboardUnit(v)} />}
              >
                <Button>{dashboardUnit?.name || 'Semua Unit'}</Button>
              </Dropdown>
              <Dropdown overlay={MenuDownload}>
                <Button
                  icon={<DownloadOutlined />}
                  type="primary"
                  loading={loadingSubmit}
                  disabled={studentCondition === 'error'}
                >
                  Download Laporan
                </Button>
              </Dropdown>
            </Space>
          </PageHeader>
          {studentCondition === 'error' ? (
            <Empty description="No data avalaible" />
          ) : (
            <section>
              <Row gutter={[24, 24]}>
                <Col span="8">
                  <CardDashboard
                    fullHeight
                    title="Total Tunggakan Murid"
                    debt={true}
                    amount={studentCondition?.total_student_debt || 0}
                    rincian={studentCondition?.total_student_debt_count || 0}
                    loading={studentCondition ? false : true}
                    icon={IC_TUNGGAKAN}
                    openModal={() => {
                      console.log(studentCondition);
                      setRincianModal({
                        ...rincianModal,
                        state: true,
                        table: 'tunggakan',
                        data: studentCondition?.student_debt_list || [],
                      });
                    }}
                  />
                </Col>
                <Col span="8">
                  <CardDashboard
                    fullHeight
                    title="Total Dana Belum Masuk Alokasi"
                    amount={studentCondition?.total_fund || 0}
                    rincian={studentCondition?.total_fund_count || 0}
                    loading={studentCondition ? false : true}
                    icon={IC_DANA_MASUK}
                    openModal={() =>
                      setRincianModal({
                        ...rincianModal,
                        state: true,
                        table: 'dana-masuk',
                        data: studentCondition?.student_fund_list || [],
                      })
                    }
                  />
                </Col>
                <Col span="8">
                  <CardDashboard
                    fullHeight
                    title="Total Tabungan Belum Alokasi"
                    amount={studentCondition?.total_savings || 0}
                    rincian={studentCondition?.total_savings_count || 0}
                    loading={studentCondition ? false : true}
                    icon={IC_PEMBAYARAN}
                    openModal={() =>
                      setRincianModal({
                        ...rincianModal,
                        state: true,
                        table: 'tabungan',
                        data: studentCondition?.student_savings_list || [],
                      })
                    }
                  />
                </Col>
                <Col span="8">
                  <CardDashboard
                    title="Murid Menunggak di Atas 5 Tagihan"
                    amount={studentCondition?.total_debt_above_5 || 0}
                    rincian={studentCondition?.total_debt_above_5_count || 0}
                    loading={studentCondition ? false : true}
                    icon={IC_TUNGGAKAN}
                    debt={true}
                    openModal={() =>
                      setRincianModal({
                        state: true,
                        table: 'murid-menunggak',
                        data: studentCondition?.student_debt_above_5_list || [],
                      })
                    }
                  />
                </Col>
                <Col span="8">
                  <CardDashboard
                    fullHeight
                    title="Total Pendapatan ASAK"
                    amount={studentCondition?.total_asak || 0}
                    rincian={studentCondition?.total_asak_count || 0}
                    selectMonth={(v) => setAsakMonth(v)}
                    selectedMonth={asakMonth?.name || '-'}
                    loading={studentCondition ? false : true}
                    icon={IC_ASAK}
                    openModal={() =>
                      setRincianModal({
                        ...rincianModal,
                        state: true,
                        table: 'asak',
                        data: studentCondition?.student_asak_list || [],
                      })
                    }
                    disableAction={true}
                  />
                </Col>
                <Col span="8">
                  <CardDashboard
                    fullHeight
                    title="Total Refund"
                    amount={studentCondition?.total_refund || 0}
                    rincian={studentCondition?.student_refund_list_count || 0}
                    selectMonth={(v) => setRefundMonth(v)}
                    selectedMonth={refundMonth?.name || '-'}
                    loading={studentCondition ? false : true}
                    icon={IC_REFUND}
                    openModal={() =>
                      setRincianModal({
                        ...rincianModal,
                        state: true,
                        table: 'refund',
                        data: studentCondition?.student_refund_list || [],
                      })
                    }
                    disableAction={true}
                  />
                </Col>
                <Col span="24">
                  <Card>
                    <header
                      style={{
                        marginBottom: '1.25rem',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          fontWeight: '700',
                          fontSize: '1.75rem',
                        }}
                      >
                        Tunggakan Berdasarkan Kategori
                      </p>
                    </header>
                    {studentCondition ? (
                      studentCondition?.categories_information?.map((item) => {
                        const total =
                          studentCondition.categories_information.reduce(
                            (curr, acc) => curr + (acc.remaining_amount || 0),
                            0
                          );
                        const percentage =
                          (item.remaining_amount / total) * 100;

                        return (
                          <ProgressContainer>
                            <ProgressTitle
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <span>{item.name}</span>
                              {item?.remaining_amount ? (
                                <span
                                  style={{
                                    fontWeight: '600',
                                    fontSize: '14px',
                                    lineHeight: '22px',
                                    color: '#333333',
                                  }}
                                >
                                  Tunggakan : Rp
                                  {Number(
                                    item?.remaining_amount || 0
                                  ).toLocaleString()}
                                </span>
                              ) : (
                                <span
                                  style={{
                                    fontWeight: '600',
                                    fontSize: '14px',
                                    lineHeight: '22px',
                                    color: '#1A7C47',
                                  }}
                                >
                                  LUNAS
                                </span>
                              )}
                            </ProgressTitle>

                            <Progress
                              percent={Math.floor(percentage) || 0}
                              strokeColor="#F8A11E"
                              showInfo={false}
                            />
                          </ProgressContainer>
                        );
                      })
                    ) : (
                      <Loader text="Fetching category data ..." />
                    )}
                  </Card>
                </Col>
              </Row>
            </section>
          )}
        </>
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin />
        </div>
      )}

      <Modal.RincianDashboard
        visible={rincianModal.state}
        table={rincianModal.table}
        data={rincianModal.data}
        asakMonth={asakMonth}
        unitName={dashboardUnit.name}
        onClose={() =>
          setRincianModal({
            state: false,
            table: '',
            data: null,
          })
        }
      />

      {downloadModal.state && (
        <Modal.DownloadRekapanTagihan
          visible={downloadModal.state}
          onClose={() => setDownloadModal({ state: false, type: '' })}
          type={downloadModal.type}
        />
      )}
    </>
  );
};

export default DashboardKeseluruhan;
