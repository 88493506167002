import { Col, Row, Spin } from 'antd';
import { Card } from 'components/fragments';
import { Drawer, PageHeader } from 'components/shared';
import React, { useEffect, useState } from 'react';
import { useStore } from 'store';
import IC_PLUSR from 'assets/icons/plus-rounded.svg';
import { CustomButton, Loader } from 'components/atoms';
import { useHistory } from 'react-router-dom';
import {
  activateTahunAjaran,
  createTahunAjaran,
  fetchTahunAjaran,
} from 'utils/fetch';

const TahunAjaran = () => {
  const { handleBreadcrumb, handleSiderActive } = useStore();
  const history = useHistory();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [data, setData] = useState(null);

  const fetchData = () => {
    fetchTahunAjaran()
      .then((res) => {
        console.log(res);
        setData(res.schoolYears);
        setOpenDrawer(false);
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  useEffect(() => {
    const newBreadcrumb = [
      { linkTo: '/pengaturan/tahun-ajaran', node: <span>Tahun Ajaran</span> },
    ];
    handleBreadcrumb(newBreadcrumb);
    handleSiderActive(['1']);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = (values) => {
    createTahunAjaran(values)
      .then((res) => {
        console.log(res);
        fetchData();
      })
      .catch((err) => console.error(err));
  };

  const handleAktifasi = (id) => {
    console.log(id);
    activateTahunAjaran(id)
      .then((res) => {
        console.log(res);
        fetchData();
      })
      .catch((err) => console.error(err));
  };

  return (
    <>
      <PageHeader>
        <CustomButton.IconTextButton
          type="primary"
          icon={IC_PLUSR}
          onClick={() => setOpenDrawer(true)}
        >
          Tambah Tahun Ajaran
        </CustomButton.IconTextButton>
      </PageHeader>
      <Row gutter={[12, 18]} style={{ margin: '2rem 0' }}>
        {data ? (
          data.map((year) => {
            return (
              <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                <Card.TahunAjaran
                  fullHeight
                  handleAktifasi={handleAktifasi}
                  data={year}
                  onClick={() => {
                    if (year.status) {
                      history.push({
                        pathname: '/settings/tahun-ajaran/' + year._id,
                      });
                    } else {
                      return;
                    }
                  }}
                />
              </Col>
            );
          })
        ) : (
          <section
            style={{ height: '100%', width: '100%', marginTop: '15rem' }}
          >
            <Loader text="Memuat data tahun ajaran ..." />
          </section>
        )}
      </Row>

      <Drawer.TambahTahunAjaran
        visible={openDrawer}
        onCancel={() => setOpenDrawer(false)}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default TahunAjaran;
