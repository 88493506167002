import {
  FundOutlined,
  SnippetsOutlined,
  DiffOutlined,
  UserDeleteOutlined,
  ProfileOutlined,
  TeamOutlined,
  FileTextOutlined,
  ContainerOutlined,
  ReconciliationOutlined,
  WalletOutlined,
  SettingOutlined,
  CalendarOutlined,
  UserOutlined,
  ReloadOutlined,
  StarOutlined,
} from '@ant-design/icons';

export const MENU = {
  dashboard: [
    {
      key: '1',
      name: 'Keseluruhan',
      to: '/dashboard/semua',
      icon: FundOutlined,
    },
    {
      key: '2',
      name: 'Laporan Harian',
      to: '/dashboard/laporan-harian',
      icon: SnippetsOutlined,
    },
    {
      key: '5',
      name: 'Laporan Bulanan',
      to: '/dashboard/laporan-bulanan',
      icon: CalendarOutlined,
    },
    {
      key: '3',
      name: 'Rekapan Kategori',
      to: '',
      icon: DiffOutlined,
      childrens: [
        {
          key: '3.1',
          name: 'SPP',
          to: '/dashboard/rekap/spp',
        },
        {
          key: '3.2',
          name: 'PSB',
          to: '/dashboard/rekap/psb',
        },
        {
          key: '3.3',
          name: 'Lainnya',
          to: '/dashboard/rekap/other',
        },
      ],
    },
    {
      key: '4',
      name: 'Alumni Menunggak',
      to: '/dashboard/alumni-menunggak',
      icon: UserDeleteOutlined,
    },
  ],
  pengaturan: [
    {
      key: '1',
      name: 'Tahun Ajaran',
      to: '/settings/tahun-ajaran',
      icon: CalendarOutlined,
    },
    {
      key: '2',
      name: 'Pengguna',
      to: '/settings/pengguna',
      icon: UserOutlined,
    },
  ],
  main: [
    {
      key: '1',
      name: 'Daftar Kelas',
      to: '/daftar-kelas',
      icon: ProfileOutlined,
    },
    {
      key: '2',
      name: 'Daftar Murid',
      to: '/daftar-murid',
      icon: TeamOutlined,
    },
    {
      key: '3',
      name: 'Tagihan',
      to: '',
      icon: FileTextOutlined,
      childrens: [
        {
          key: '3.1',
          name: 'SPP',
          to: '/tagihan/spp',
        },
        {
          key: '3.2',
          name: 'PSB',
          to: '/tagihan/psb',
        },
        {
          key: '3.3',
          name: 'Lainnya',
          to: '/tagihan/other',
        },
        {
          key: '3.4',
          name: 'Riwayat Perubahan',
          to: '/tagihan/history',
        },
      ],
    },
    {
      key: '4',
      name: 'Dana Masuk',
      to: '/dana-masuk',
      icon: ContainerOutlined,
    },
    {
      key: '5',
      name: 'Pembayaran',
      to: '/pembayaran',
      icon: ReconciliationOutlined,
    },
    {
      key: '6',
      name: 'Tabungan',
      to: '/tabungan',
      icon: WalletOutlined,
    },
    {
      key: '7',
      name: 'Apresiasi',
      to: '/apresiasi-list',
      icon: StarOutlined,
    },
    {
      key: '8',
      name: 'List Refund',
      to: '/refund-list',
      icon: ReloadOutlined,
    },
    {
      key: '9',
      name: 'Pengaturan Unit',
      to: '',
      icon: SettingOutlined,
      childrens: [
        {
          key: '9.1',
          name: 'Tipe Tagihan',
          to: '/pengaturan-unit/tipe-tagihan',
        },
        {
          key: '9.2',
          name: 'Sistem Pembayaran',
          to: '/pengaturan-unit/sistem-pembayaran',
        },
      ],
    },
  ],
};
