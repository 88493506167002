import { CardBase, CustomButton, Loader } from 'components/atoms';
import { Drawer, PageHeader } from 'components/shared';
import React, { useEffect, useState } from 'react';
import IC_PLUSR from 'assets/icons/plus-rounded.svg';
import {
  Button,
  Col,
  Dropdown,
  Empty,
  Menu,
  Row,
  Space,
  Spin,
  Modal,
  message,
} from 'antd';
import { EmptyStateContainer } from '../style';
import {
  MoreOutlined,
  RetweetOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { useStore } from 'store';
import {
  editDetailKelas,
  getListKelas,
  tambahKelas,
  hapusKelas,
} from 'utils/fetch';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'querystring';

export const DaftarKelas = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDrawerEdit, setOpenDrawerEdit] = useState(false);
  const [listKelas, setListKelas] = useState([]);
  const [kelasSelectedEdit, setKelasSelectedEdit] = useState({
    name: '',
    id: '',
  });
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const {
    state: { activeSchoolYear, selectedUnit },
    handleBreadcrumb,
    handleSiderActive,
    handleListKelas,
  } = useStore();
  const unitId = selectedUnit?._id;
  const schoolYearId = activeSchoolYear?._id;
  const history = useHistory();

  useEffect(() => {
    const newBreadcrumb = [
      {
        linkTo: '/daftar-kelas',
        node: <span>Daftar Kelas</span>,
      },
    ];
    handleBreadcrumb(newBreadcrumb);
    handleSiderActive(['1']);
  }, []);

  useEffect(() => {
    if (selectedUnit && schoolYearId) {
      fetchData();
    }
  }, [selectedUnit, schoolYearId]);

  const handleEditKelas = (payload, id) => {
    setLoadingSubmit(true);
    editDetailKelas(selectedUnit?._id, schoolYearId, id, payload)
      .then((data) => {
        console.log(data);
        fetchData();
        setOpenDrawerEdit(false);
        setKelasSelectedEdit({
          name: '',
          id: '',
        });
        message.success('Berhasil mengubah data kelas');
      })
      .catch((err) => {
        console.log(err, 'this is error edit kelas');
        message.error(err?.data?.m | 'Gagal mengubah data kelas');
      })
      .finally(() => setLoadingSubmit(false));
  };

  const handleSubmitTambah = (payload) => {
    setLoadingSubmit(true);
    tambahKelas(unitId, schoolYearId, payload)
      .then((value) => {
        console.log(value);
        message.success('Berhasil menambah data kelas');
        fetchData();
        setOpenDrawer(false);
      })
      .catch((err) => {
        console.log(err);
        message.error(err?.data?.m || 'Gagal menambah data kelas');
      })
      .finally(() => setLoadingSubmit(false));
  };

  const handleDeleteKelas = (id) => {
    hapusKelas(unitId, schoolYearId, id)
      .then((res) => {
        console.log(res);
        message.success('Berhasil menghapus kelas');
        fetchData();
      })
      .catch((err) => {
        console.log(err);
        message.error('Gagal menghapus kelas');
      });
  };

  const EditMenu = ({ id, name, students }) => {
    const openEditMenu = () => {
      setOpenDrawerEdit(true);
      setKelasSelectedEdit({ id, name });
    };
    return (
      <Menu>
        <Menu.Item onClick={openEditMenu}>Edit</Menu.Item>
        {!students ? (
          <Menu.Item
            onClick={() => {
              Modal.confirm({
                icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
                onOk: () => handleDeleteKelas(id),
                okButtonProps: { type: 'primary', danger: true },
                centered: true,
                content: (
                  <div style={{ margin: 0 }}>
                    <h2 style={{ margin: 0 }}>Hapus Kelas</h2>
                    <p style={{ marginTop: '1rem', marginLeft: '4rem' }}>
                      Apakah Anda yakin ingin menghapus kelas {name}?
                    </p>
                  </div>
                ),
              });
            }}
            style={{ color: '#FF4D4F' }}
          >
            Hapus Kelas
          </Menu.Item>
        ) : null}
      </Menu>
    );
  };

  const fetchData = () => {
    setLoading(true);
    getListKelas(unitId, schoolYearId)
      .then((res) => {
        console.log(res.grades);
        setLoading(false);
        setListKelas(res.grades);
        handleListKelas(res.grades);
      })
      .catch((err) => console.log(err));
  };

  return (
    <main>
      <PageHeader>
        <Space>
          {listKelas.length ? (
            <Button
              onClick={() =>
                history.push({
                  pathname: '/daftar-kelas/assign-murid',
                  search: queryString.stringify({
                    unit: selectedUnit.name,
                    grade: listKelas[0]._id,
                  }),
                })
              }
              icon={<RetweetOutlined />}
            >
              Assign Murid ke Kelas
            </Button>
          ) : null}
          <CustomButton.IconTextButton
            icon={IC_PLUSR}
            type="primary"
            onClick={() => setOpenDrawer(true)}
          >
            Tambah Kelas
          </CustomButton.IconTextButton>
        </Space>
      </PageHeader>
      <section>
        {listKelas.length && !loading ? (
          <Row gutter={[18, 12]}>
            {listKelas.map((kelas) => {
              return (
                <Col span="8">
                  <CardBase>
                    <header
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginBottom: '1.25rem',
                      }}
                    >
                      <h3 style={{ margin: 0 }}>Kelas {kelas.name}</h3>
                      <Dropdown
                        overlay={
                          <EditMenu
                            id={kelas._id}
                            name={kelas.name}
                            students={kelas.students}
                          />
                        }
                        placement="bottomCenter"
                        overlayStyle={{
                          border: '1px solid #DEE4ED',
                        }}
                      >
                        <MoreOutlined style={{ cursor: 'pointer' }} />
                      </Dropdown>
                    </header>
                    <p>
                      <b>{kelas.students}</b> Murid
                    </p>
                  </CardBase>
                </Col>
              );
            })}
          </Row>
        ) : loading ? (
          <section
            style={{ marginTop: '15rem', width: '100%', height: '100%' }}
          >
            <Loader text="Memuat data ..." />
          </section>
        ) : (
          <EmptyStateContainer>
            <Empty description="Belum ada kelas pada tahun ajaran ini. Silakan tambah kelas terlebih dahulu" />
          </EmptyStateContainer>
        )}
      </section>

      <Drawer.TambahKelas
        unitId={unitId}
        visible={openDrawer}
        onCancel={() => setOpenDrawer(false)}
        handleSubmit={(values) => handleSubmitTambah(values)}
        loadingState={loadingSubmit}
      />

      <Drawer.EditKelas
        unitId={unitId}
        kelas={kelasSelectedEdit}
        visible={openDrawerEdit}
        onCancel={() => setOpenDrawerEdit('')}
        handleSubmit={(values, id) => handleEditKelas(values, id)}
        loadingState={loadingSubmit}
      />
    </main>
  );
};

export { default as AssignMurid } from './assign-murid';
