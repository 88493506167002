import React, { useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Input,
  message,
  Modal,
  Select,
  Space,
  Table,
  Upload,
} from 'antd';
import { useStore } from 'store';
import { DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import { parsingVABCA, toText } from 'utils/helpers/parse-va';
import moment from 'moment';
import { uploadVA } from 'utils/fetch';
import exportExcel from 'utils/helpers/xlsx';
import Text from 'antd/lib/typography/Text';

export const UploadBox = ({ handleChange, value }) => {
  return (
    <label
      htmlFor="input-va"
      style={{
        border: '1px dashed #bfbfbf',
        width: '100%',
        height: '132px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f5f5f5',
        cursor: 'pointer',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <InboxOutlined
          style={{ color: '#40A9FF', fontSize: '5rem' }}
          value={value}
        />

        <p style={{ fontWeight: 'bold' }}>
          Klik disini dan pilih file .txt data virtual account
        </p>
        <Input
          id="input-va"
          type="file"
          style={{ display: 'none' }}
          onChange={handleChange}
        />
      </div>
    </label>
  );
};

const RiwayatVA = ({
  visible,
  onClose,
  refetchData,
  unitId,
  data,
  ...rest
}) => {
  const [parsedData, setParsedData] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [selectedBank, setSelectedBank] = useState('BCA');
  const [failedData, setFailedData] = useState([]);
  const [date, setDate] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [error, setError] = useState({
    fileFormat: false,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!visible) {
      setParsedData([]);
      setFileData(null);
    }
  }, [visible]);

  const handleChangeFile = async (e) => {
    const file = e.target.files[0];
    const result = await toText(file);

    const parsed = parsingVABCA(result);

    setFileData(file);
    setParsedData(parsed.newParsing);
    setDate(parsed.tanggal);

    setTotalAmount(
      parsed.newParsing.reduce((current, acc) => current + acc.amount, 0)
    );

    // console.log(file, type, '==== THIS IS FILE ====');
    console.log(parsed, '==== THIS IS READER RESULT ====');
  };

  const parsedResult = (data = []) => {
    const parsed = [
      [
        'No',
        'Nama Murid',
        'Nomor VA',
        'Tanggal Pembayaran',
        'Nominal Pembayaran',
        'Catatan VA',
        'Hasil Integrasi',
      ],
    ];

    data.forEach((dt, idx) => {
      const array = [
        idx + 1,
        dt.student,
        dt.vaCode,
        moment(dt.date).format('DD/MM/YYYY'),
        `Rp${dt.amount.toLocaleString()}`,
        dt.notes,
        dt.result,
      ];

      parsed.push(array);
    });

    return parsed;
  };

  const handleSubmitVA = (data) => {
    setLoading(true);
    const formData = new FormData();

    formData.append('file', fileData);

    // Object.keys(data).forEach((key) => {
    //   formData.append(key, JSON.parse(data[key]));
    // });
    formData.append('totalAmount', Number(data.totalAmount));
    formData.append('date', date);
    formData.append('newIncomingFunds', JSON.stringify(data.newIncomingFunds));

    console.log(formData, data, 'this is payload');

    uploadVA(unitId, formData, { 'Content-Type': 'multipart/form-data' })
      .then((res) => {
        console.log(res, 'success');
        if (res?.reportStatus?.length) {
          setFailedData(res.reportStatus);
          const parsedExcel = parsedResult(res.reportStatus);
          console.log(parsedExcel, 'this is for excel');
          exportExcel(parsedExcel, `va-${moment().format('DD-MM-YYYY hh.mm')}`);
        }

        onClose();
        refetchData();
      })
      .catch((err) => {
        console.log(err, 'error export excel');
        console.log(err.response, 'error submit va');
        message.error(
          err?.response.data.m ||
            'Gagal import VA, silakan cek format file anda.'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const tableColumns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      render: (data, _, idx) => <span>{idx + 1}</span>,
    },
    {
      title: 'Nomor VA',
      dataIndex: 'va_number',
      key: 'va_number',
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Nama Murid',
      dataIndex: 'student',
      key: 'student',
      width: 250,
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Tanggal',
      dataIndex: 'date',
      key: 'date',
      width: 175,
      render: (data) => (
        <span>{data ? moment(data).format('DD/MM/YYYY HH:mm') : '-'}</span>
      ),
    },
    {
      title: 'Nominal',
      dataIndex: 'amount',
      key: 'amount',
      render: (data) => <span>{(data || 0)?.toLocaleString() || '-'}</span>,
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Result',
      dataIndex: 'result',
      key: 'result',
      render: (data) => <span>{data}</span>,
    },
  ];

  return (
    <Modal
      title="Riwayat Import VA"
      visible={visible}
      onCancel={onClose}
      {...rest}
      centered
      width="60vw"
      footer={
        <section
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <div>
            <Button onClick={onClose}>Tutup</Button>
          </div>
        </section>
      }
    >
      <section>
        <header>
          <Space>
            <span>
              Tanggal: <b>{moment(data?.date).format('DD/MM/YYYY')}</b>
            </span>
            <Divider type="vertical" />
            <span>
              Jml Transaksi: <b>{data?.student_list.length}</b>
            </span>
            <Divider type="vertical" />
            <span>
              Total: <b>Rp{data?.total_amount.toLocaleString()}</b>
            </span>
          </Space>
        </header>

        <section
          style={{
            marginTop: '2rem',
            maxHeight: '70vh',
            overflowY: 'auto',
          }}
        >
          <Table
            columns={tableColumns}
            dataSource={data?.student_list}
            pagination={{
              total: data?.student_list.length,
              position: ['bottomLeft'],
              simple: true,
            }}
            size="middle"
            scroll={{ x: 1200 }}
          />
        </section>
      </section>
    </Modal>
  );
};

export default RiwayatVA;
