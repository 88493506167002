import { Col, Divider, Row, Space, Tag } from 'antd';
import YellowCard from 'components/atoms/card-base/yellow-card';
import { themeColor } from 'lib/theme';
import { statusMurid, tipeMurid } from 'utils/helpers/common';

const StudentCard = ({ student }) => {
  return (
    <YellowCard>
      <Row>
        <Col span="12">
          <h2 style={{ color: themeColor.primary }}>{student?.name || '-'}</h2>
          <Space>
            <Tag color={statusMurid[student?.status || 0].color}>
              {statusMurid[student?.status || 0].status}
            </Tag>
            {/* {student?.type && <Tag color={tipeMurid[student?.type || 0].color}>
              {tipeMurid[student?.type || 0].text}
            </Tag>} */}
          </Space>
        </Col>
        <Col span="1">
          <Divider type="vertical" style={{ height: '100%' }} />
        </Col>
        <Col span="11">
          <Row gutter={[8, 8]}>
            <Col span="12">NIS</Col>
            <Col span="12">{student?.nik || '-'}</Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span="12">Kelas</Col>
            <Col span="12">{student?.grade?.name || 'Murid Tanpa Kelas'}</Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span="12">Nomor VA</Col>
            <Col span="12">{student?.virtual_account || '-'}</Col>
          </Row>
        </Col>
      </Row>
    </YellowCard>
  );
};

export default StudentCard;
