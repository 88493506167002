import { Col, Row, Tag } from 'antd';
import { Card } from 'components/fragments';
import { Drawer, PageHeader } from 'components/shared';
import React, { useEffect, useState } from 'react';
import { useStore } from 'store';
import IC_EDIT from 'assets/icons/Edit.svg';
import IC_CALENDAR from 'assets/icons/Calendar.svg';
import { CustomButton, Loader } from 'components/atoms';
import { fetchDetailTahunAjaran } from 'utils/fetch';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { UnitListContainer, UnitItemContainer } from 'pages/pengaturan/style';
import { themeColor } from 'lib/theme';

const DetailTahunAjaran = () => {
  const { handleBreadcrumb } = useStore();
  const urutan = ['tk', 'sd', 'smp', 'sma'];
  const [openDrawer, setOpenDrawer] = useState(false);
  const [tahunAjaran, setTahunAjaran] = useState(null);
  const [units, setUnits] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { id } = useParams();
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const start = tahunAjaran
    ? `${months[moment(tahunAjaran.start_date).month()]} ${moment(
        tahunAjaran.start_date
      ).year()}`
    : '';
  const end = tahunAjaran
    ? `${months[moment(tahunAjaran.end_date).month()]} ${moment(
        tahunAjaran.end_date
      ).year()}`
    : '';

  useEffect(() => {
    if (tahunAjaran) {
      const newBreadcrumb = [
        {
          linkTo: '/settings/tahun-ajaran',
          node: <span>Tahun Ajaran</span>,
        },
        {
          linkTo: `/settings/tahun-ajaran/${id}`,
          node: (
            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
              {tahunAjaran.name}
              {tahunAjaran.status === 1 && (
                <Tag color="blue" style={{ marginLeft: '1rem' }}>
                  Aktif
                </Tag>
              )}
            </span>
          ),
        },
      ];
      handleBreadcrumb(newBreadcrumb);
    }
  }, [tahunAjaran]);

  useEffect(() => {
    setLoading(true);
    fetchDetailTahunAjaran(id)
      .then((res) => {
        console.log(res);
        setLoading(false);
        setTahunAjaran(res.schoolYear);
        setUnits(res.units);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, []);

  return (
    <>
      <PageHeader>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span
            style={{
              marginRight: '1rem',
              color: '#333333',
              opacity: '0.5',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src={IC_CALENDAR}
              width={18}
              height={18}
              style={{ marginRight: '0.5rem' }}
            />
            {start} - {end}
          </span>

          <CustomButton.IconTextButton
            type="secondary"
            icon={IC_EDIT}
            onClick={() => setOpenDrawer(true)}
          >
            <span style={{ color: themeColor.primary }}>Edit Info</span>
          </CustomButton.IconTextButton>
        </div>
      </PageHeader>
      <UnitListContainer>
        {/* {JSON.stringify(units)} */}
        {units ? (
          units.map((data) => {
            return (
              <UnitItemContainer>
                <Card.DetailKelas
                  data={data}
                  title={`${data.name.toUpperCase()}`}
                />
              </UnitItemContainer>
            );
          })
        ) : (
          <Loader />
        )}
      </UnitListContainer>

      <Drawer.TambahTahunAjaran
        visible={openDrawer}
        onCancel={() => setOpenDrawer(false)}
        isEdit={true}
        value={{
          text: String(moment(tahunAjaran?.start_date).year()),
          value: tahunAjaran?.start_date,
        }}
        // value="2021"
      />
    </>
  );
};

export default DetailTahunAjaran;
