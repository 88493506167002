import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Form,
  Button,
  Space,
  message,
  Input,
  Select,
  List,
  Steps,
  DatePicker,
  Table,
  Radio,
  InputNumber,
  Alert,
  Drawer,
  Modal,
} from 'antd';
import moment from 'moment';

import {
  StyledContentWrapper,
  StyledPageContainer,
  StyledFixedFooter,
} from '../style';
import IC_BACK from 'assets/icons/chevron-left.svg';
import { useHistory } from 'react-router-dom';
import { useStore } from 'store';
import {
  deactivateSPPBill,
  getListKelasId,
  getMuridByGrade,
  getOutstandingBills,
  getUnPaidSPP,
  tambahPembayaran,
} from 'utils/fetch';
import { CardBase, Loader } from 'components/atoms';
import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { TambahTagihanSPP } from 'components/shared/drawer';

const EditTagihanSPP = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const {
    state: { listTahunAjaran, selectedUnit, activeSchoolYear },
  } = useStore();
  const [current, setCurrent] = useState(0);
  const [listMurid, setListMurid] = useState([]);
  const [listKelas, setListKelas] = useState([]);
  const [selectedKelas, setSelectedKelas] = useState('');
  const [studentByGrade, setStudentByGrade] = useState(null);
  const [filteredMurid, setFilteredMurid] = useState([]);
  const [sppUnpaidData, setSppUnpaidData] = useState([]);
  const [tahunAjaran, setTahunAjaran] = useState('');
  const [newPayments, setNewPayments] = useState([]);
  const [tanggalBayar, setTanggalBayar] = useState('');
  const [studentName, setStudentName] = useState('');
  const [hasNoGrades, setHasNoGrades] = useState([]);
  const [notActive, setNotActive] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [afterSubmit, setAfterSubmit] = useState({
    done: false,
    success: [],
    error: [],
  });
  const unitId = selectedUnit?._id;
  const schoolYearId = activeSchoolYear?._id;
  const studentId = listMurid.find((item) => item?.selected)?._id || '';
  // const studentId = 'kkk';

  const [drawer, setDrawer] = useState({
    visible: false,
    data: null,
  });

  useEffect(() => {
    if (tahunAjaran && unitId) {
      fetchData();
      fetchDataKelas();
    }
  }, [tahunAjaran, unitId]);

  useEffect(() => {
    if (schoolYearId) {
      setTahunAjaran(schoolYearId);
    }
  }, [schoolYearId]);

  useEffect(() => {
    if (current === 1) {
      setStudentName('');
      fetchUnpaidSPP();
    }
  }, [current]);

  useEffect(() => {
    if (studentByGrade) {
      let murid;
      if (selectedKelas !== 'Murid Tanpa Kelas' || selectedKelas !== 'all') {
        murid = studentByGrade
          .find((item) => item?._id == selectedKelas)
          ?.students.map((item) => {
            return {
              ...item,
              selected: false,
            };
          });
      }

      if (selectedKelas === 'all') {
        const allStudents = studentByGrade.map((el) => el?.students);
        const flattened = [...allStudents, hasNoGrades, notActive]
          .flatMap((x) => x)
          .filter((y) => y !== undefined);
        murid = flattened.map((item) => {
          return {
            ...item,
            selected: false,
          };
        });
      }

      if (selectedKelas === 'Murid Tanpa Kelas') {
        murid = hasNoGrades?.map((item) => {
          return {
            ...item,
            selected: false,
          };
        });
      }

      if (selectedKelas === 'graduated') {
        murid = notActive?.map((item) => {
          return {
            ...item,
            selected: false,
          };
        });
      }

      setListMurid(murid);
    }
  }, [selectedKelas, studentByGrade]);

  const fetchDataKelas = () => {
    getListKelasId(unitId, tahunAjaran)
      .then((res) => {
        console.log(res);
        setListKelas(res.grades);
      })
      .catch((err) => console.log(err.response));
  };

  const fetchData = () => {
    getMuridByGrade(unitId, tahunAjaran)
      .then((res) => {
        console.log(res, 'this is res pambayaran');
        setNotActive(res.studentsNotActive);
        setStudentByGrade(res.grades);
        setHasNoGrades(res.studentsNoGrades);
      })
      .catch((err) => {
        console.log(err.response, 'this is error fetch');
      });
  };

  const fetchUnpaidSPP = () => {
    getUnPaidSPP(unitId, studentId)
      .then((res) => {
        console.log(res, 'unpaid spp');
        setSppUnpaidData(res.value?.sppBills || []);
      })
      .catch((err) => console.log(err.response));
  };

  const deleteSPPBill = (id) => {
    Modal.confirm({
      title: 'Apakah anda yakin untuk menghapus SPP bulan ini?',
      content:
        'Perubahan nominal pembayaran akan mempengaruhi jumlah sisa dana masuk/tabungan.',
      onOk: () => {
        deactivateSPPBill(unitId, id)
          .then((res) => {
            console.log(res);
            message.success(res?.m || 'Success delete spp');
            fetchUnpaidSPP();
          })
          .catch((err) => {
            console.log(err);
            message.error(err?.response?.data?.m || 'Failed delete spp');
          });
      },
      okText: 'Hapus',
      okButtonProps: {
        danger: true,
      },
      centered: true,
    });
  };

  const submitPembayaran = (payload) => {
    setLoadingSubmit(true);
    tambahPembayaran(unitId, studentId, payload)
      .then((value) => {
        console.log(value);
        setAfterSubmit({
          ...value.conditions,
          done: true,
        });
        fetchUnpaidSPP();
        // message.success('Berhasil menambah pembayaran');
        // history.push(`/pembayaran?unit=${selectedUnit?.name}`);
      })
      .catch((err) => {
        console.log(err.response, 'error add pembayaran');
        message.error(err?.response?.data?.m || 'Gagal menambah pembayaran');
      })
      .finally(() => setLoadingSubmit(false));
  };

  useEffect(() => {
    if (listKelas || listKelas.length) {
      setSelectedKelas(listKelas[0]?._id);
    }
  }, [listKelas]);

  const tableColumns = [
    {
      title: 'Nama Tagihan',
      dataIndex: 'month',
      key: 'month',
      render: (data) => <span>SPP {moment(data).format('MMMM YYYY')}</span>,
    },
    {
      title: 'Tahun Ajaran',
      dataIndex: 'school_year',
      key: 'school_year',
      render: (data) => <span>{data?.name || '-'}</span>,
    },
    {
      title: 'Nominal',
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (data) => <span>Rp{(data || 0).toLocaleString()}</span>,
    },

    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      align: 'right',
      render: (data) => {
        return (
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => deleteSPPBill(data)}
          >
            Hapus
          </Button>
        );
      },
    },
  ];

  const InputNominal = () => (
    <>
      <section
        style={{
          margin: '1rem 0 2.4rem',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <h2>Daftar Tagihan SPP</h2>
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={() => setDrawer({ visible: true, data: null })}
        >
          Tambah Tagihan SPP
        </Button>
      </section>
      <Table
        columns={tableColumns}
        dataSource={sppUnpaidData}
        pagination={false}
      />
    </>
  );

  return (
    <StyledPageContainer>
      <StyledContentWrapper>
        <div
          onClick={() => history.goBack()}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '3rem',
            cursor: 'pointer',
          }}
        >
          <img
            src={IC_BACK}
            alt="back"
            style={{ marginRight: '0.75rem' }}
            width="18"
            height="18"
          />
          <span>Kembali</span>
        </div>
        <h2>Edit Tagihan SPP</h2>
        <section style={{ marginTop: '2rem', marginBottom: '3rem' }}>
          <Steps current={current}>
            <Steps.Step title="Pilih Murid" />
            <Steps.Step title="Edit Tagihan SPP" />
          </Steps>
        </section>

        {afterSubmit?.done && (
          <section>
            {afterSubmit?.success?.length && !afterSubmit?.error?.length ? (
              <Alert
                type="success"
                message={
                  <span style={{ fontWeight: 600 }}>Pembayaran Berhasil</span>
                }
                description={
                  <>
                    {afterSubmit.success.map((msg) => (
                      <p style={{ margin: 0 }}>{msg}</p>
                    ))}
                  </>
                }
                showIcon
                closable
                onClose={() =>
                  setAfterSubmit({
                    done: false,
                    success: [],
                    error: [],
                  })
                }
              />
            ) : null}
            {!afterSubmit?.success?.length && afterSubmit?.error?.length ? (
              <Alert
                type="error"
                message={
                  <span style={{ fontWeight: 600 }}>Pembayaran Gagal</span>
                }
                description={
                  <>
                    {afterSubmit.error.map((msg) => (
                      <p style={{ margin: 0 }}>{msg}</p>
                    ))}
                  </>
                }
                showIcon
                closable
                onClose={() =>
                  setAfterSubmit({
                    done: false,
                    success: [],
                    error: [],
                  })
                }
              />
            ) : null}
            {afterSubmit?.success?.length && afterSubmit?.error?.length ? (
              <Alert
                type="warning"
                message={
                  <span style={{ fontWeight: 600 }}>
                    Sebagian Pembayaran Berhasil
                  </span>
                }
                description={
                  <>
                    {afterSubmit.success.map((msg) => (
                      <p style={{ margin: 0 }}>{msg}</p>
                    ))}
                    {afterSubmit.error.map((msg) => (
                      <p style={{ margin: 0 }}>{msg}</p>
                    ))}
                  </>
                }
                showIcon
                closable
                onClose={() =>
                  setAfterSubmit({
                    done: false,
                    success: [],
                    error: [],
                  })
                }
              />
            ) : null}
          </section>
        )}

        <section style={{ marginTop: '2rem' }}>
          {current === 0 ? (
            <div style={{ marginTop: '2rem', paddingBottom: '4rem' }}>
              {studentByGrade ? (
                <Row gutter={[24, 14]}>
                  <Col span="9">
                    <CardBase
                      style={{
                        padding: 0,
                      }}
                      bodyStyle={{
                        padding: 0,
                        display: 'flex',
                        maxHeight: '32.575rem',
                      }}
                      title="Daftar Kelas"
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          overflowY: 'scroll',
                          flex: 1,
                        }}
                      >
                        <>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              padding: '8px 20px',
                              justifyContent: 'space-between',
                              backgroundColor:
                                selectedKelas === 'all' ? '#E6F0EB' : '',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setSelectedKelas('all');
                            }}
                          >
                            Pilih Semua Kelas
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              padding: '8px 20px',
                              justifyContent: 'space-between',
                              backgroundColor:
                                selectedKelas === 'graduated' ? '#E6F0EB' : '',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setSelectedKelas('graduated');
                            }}
                          >
                            Lulus
                            <span>{notActive?.length} Murid</span>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              padding: '8px 20px',
                              justifyContent: 'space-between',
                              backgroundColor:
                                selectedKelas === 'Murid Tanpa Kelas'
                                  ? '#E6F0EB'
                                  : '',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setSelectedKelas('Murid Tanpa Kelas');
                            }}
                          >
                            Tanpa Kelas
                            {/* </Checkbox> */}
                            <span>{hasNoGrades?.length} Murid</span>
                          </div>
                          {studentByGrade?.map((grade) => {
                            // const checked = selectedKelas?.some((el) => el === grade._id);
                            return (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  padding: '8px 20px',
                                  justifyContent: 'space-between',
                                  backgroundColor:
                                    grade?._id == selectedKelas
                                      ? '#E6F0EB'
                                      : '',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  setSelectedKelas(grade?._id);
                                }}
                              >
                                {grade.name}
                                <span>{grade.students?.length} Murid</span>
                              </div>
                            );
                          })}
                        </>
                      </div>
                    </CardBase>
                  </Col>
                  <Col span="15">
                    <CardBase
                      style={{
                        overflowY: 'auto',
                      }}
                      bodyStyle={{
                        padding: '16px 0',
                        display: 'flex',
                        maxHeight: '38.375rem',
                        flexDirection: 'column',
                      }}
                    >
                      <Input.Search
                        // suffix={<img src={SearchSVG} />}
                        placeholder="Cari nama murid"
                        onChange={(e) => setStudentName(e.target.value)}
                      />
                      {listMurid ? (
                        <section style={{ margin: '1rem 0' }}>
                          <List
                            dataSource={listMurid.filter((murid) =>
                              murid.name
                                .toLowerCase()
                                .trim()
                                .includes(studentName.toLowerCase().trim())
                            )}
                            renderItem={(item) => {
                              return (
                                <List.Item
                                  onClick={() => {
                                    setListMurid((prevState) => {
                                      return prevState.map((item2) => {
                                        if (item?._id == item2?._id) {
                                          return {
                                            ...item2,
                                            selected: true,
                                          };
                                        } else {
                                          return {
                                            ...item2,
                                            selected: false,
                                          };
                                        }
                                      });
                                    });
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      width: '100%',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <Radio
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                      checked={item?.selected == true}
                                      onChange={() => {
                                        setListMurid((prevState) => {
                                          return prevState.map((item2) => {
                                            if (item?._id == item2?._id) {
                                              return {
                                                ...item2,
                                                selected: true,
                                              };
                                            } else {
                                              return {
                                                ...item2,
                                                selected: false,
                                              };
                                            }
                                          });
                                        });
                                      }}
                                    >
                                      {item.name}
                                    </Radio>
                                  </div>
                                </List.Item>
                              );
                            }}
                          />
                        </section>
                      ) : (
                        'Tidak ada murid di kelas ini'
                      )}
                    </CardBase>
                  </Col>
                </Row>
              ) : (
                <Loader text="Fetch data murid..." />
              )}
            </div>
          ) : (
            <InputNominal />
          )}
        </section>

        <StyledFixedFooter>
          <Space>
            <Button
              onClick={() => {
                if (current === 0 || afterSubmit.done) {
                  history.goBack();
                } else {
                  setCurrent(current - 1);
                }
              }}
            >
              {afterSubmit.done ? 'Keluar' : 'Kembali'}
            </Button>
            {current === 0 ? (
              <Button
                disabled={!studentId}
                type="primary"
                onClick={() => setCurrent(current + 1)}
              >
                Berikutnya
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={() => {
                  const formData = form.getFieldsValue();
                  const payload = {
                    ...formData,
                    date: tanggalBayar,
                    newPayments: newPayments.filter(
                      (el) => el.total_amount > 0
                    ),
                  };
                  console.log(payload);
                  submitPembayaran(payload);
                }}
                loading={loadingSubmit}
                disabled={
                  !newPayments.filter((el) => el.total_amount > 0).length ||
                  !form.getFieldValue('payment_origin') ||
                  !tanggalBayar
                }
              >
                Simpan
              </Button>
            )}
          </Space>
        </StyledFixedFooter>
      </StyledContentWrapper>

      <TambahTagihanSPP
        visible={drawer.visible}
        onCancel={() => setDrawer({ visible: false, data: null })}
        value={{ unitId, studentId }}
        refetchData={fetchUnpaidSPP}
      />
    </StyledPageContainer>
  );
};

export default EditTagihanSPP;
