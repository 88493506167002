import './App.less';
import { StoreProvider } from 'store';
import Routes from 'routes';
import { Helmet } from 'react-helmet';
import { themeTitle } from 'lib/theme';

function App() {
  return (
    <StoreProvider>
      <Helmet>
        <meta name="description" content={themeTitle.description} />
        <title>{themeTitle.title}</title>
      </Helmet>
      <Routes />
    </StoreProvider>
  );
}

export default App;
