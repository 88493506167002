import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import CustomHeader from './header';
import CustomSider from './sider';
import { StyledContentLayout, StyledContentWrapper } from './style';
import { useLocation } from 'react-router-dom';
import { useStore } from 'store';
import queryString from 'querystring';
import { getListKelasId, getListTahunAjaran } from 'utils/fetch';

const { Content } = Layout;

const MainLayout = ({ children }) => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const {
    state: { unitData, activeSchoolYear },
    handleSelectedUnit,
    handleListKelas,
    handleListSchoolYear,
  } = useStore();
  const { unit = '', dashUnit = '' } = queryString.parse(
    location.search.replace('?', '')
  );
  const whitelisted = [
    'profile',
    'assign-murid',
    'tambah-murid',
    'ganti-kelulusan',
    'tambah-tagihan',
    'tambah-pembayaran',
    'tambah-dana-masuk',
    // kirim reminder
    'kirim-reminder',
    'edit',
  ];

  useEffect(() => {
    if (unit) {
      handleSelectedUnit({ ...unitData[unit], name: unit });
    } else if (dashUnit) {
      handleSelectedUnit({ ...unitData[dashUnit], name: dashUnit });
    } else {
      handleSelectedUnit(null);
    }
  }, [unit]);

  useEffect(() => {
    if (activeSchoolYear?._id && unit) {
      fetchDataListKelas(unitData[unit]?._id, activeSchoolYear?._id);
      fetchDataTahunAjaran();
    }
  }, [activeSchoolYear, unit]);

  const fetchDataTahunAjaran = () => {
    getListTahunAjaran()
      .then((res) => {
        console.log(res);
        handleListSchoolYear(res.schoolsYears);
      })
      .catch((err) => console.error(err));
  };

  const fetchDataListKelas = (unitId, schoolYearId) => {
    getListKelasId(unitId, schoolYearId)
      .then((res) => {
        console.log(res.grades);
        handleListKelas(res.grades);
      })
      .catch((err) => console.error(err));
  };

  return (
    <Layout>
      <CustomHeader />
      <StyledContentLayout>
        {whitelisted.findIndex((el) => location.pathname.includes(el)) < 0 && (
          <CustomSider collapsed={collapsed} setCollapsed={setCollapsed} />
        )}
        <StyledContentWrapper
          noMargin={
            !(whitelisted.findIndex((el) => location.pathname.includes(el)) < 0)
          }
          minimize={collapsed}
        >
          <Content>{children}</Content>
        </StyledContentWrapper>
      </StyledContentLayout>
    </Layout>
  );
};

export default MainLayout;
