import { EyeOutlined, FileDoneOutlined } from '@ant-design/icons';
import { Drawer } from 'components/shared';
import moment from 'moment';
import { useStore } from 'store';
import { fetchTahunAjaran, studentTabSPP } from 'utils/fetch';

const { Dropdown, Button, Table, Menu, Tag, Space } = require('antd');
const { useState, useEffect } = require('react');

const DetailMuridSPP = ({ studentId, unitId, refetchData }) => {
  const {
    state: { activeSchoolYear },
  } = useStore();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [listTahunAjaran, setListTahunAjaran] = useState([]);
  const [filterTahun, setFilterTahun] = useState(null);
  const [openDrawer, setOpenDrawer] = useState({
    state: false,
    id: '',
  });

  useEffect(() => {
    fetchListTahun();
  }, []);

  useEffect(() => {
    if (filterTahun) {
      fetchData();
    }
  }, [filterTahun]);

  useEffect(() => {
    if (activeSchoolYear?._id) {
      setFilterTahun(activeSchoolYear);
    }
  }, [activeSchoolYear]);

  const fetchData = () => {
    setLoading(true);
    studentTabSPP(studentId, filterTahun?._id)
      .then((value) => {
        console.log(value);
        setData(value.sppBills);
      })
      .catch((err) => {
        console.log(err.response);
      })
      .finally(() => setLoading(false));
  };

  const MenuTahun = ({ onClick, list = [{ name: '' }] }) => {
    return (
      <Menu>
        {list.map((ta, idx) => {
          return (
            <Menu.Item
              key={idx}
              onClick={() => {
                console.log(ta, 'this is selected tahun ajaran');
                onClick(ta);
              }}
            >
              {ta.name}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  const fetchListTahun = () => {
    fetchTahunAjaran()
      .then((res) => {
        console.log(res);
        setListTahunAjaran(res.schoolYears);
      })
      .catch((err) => console.log(err, 'error list tahun'));
  };

  const tableColumns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      render: (data, _, idx) => <span>{idx + 1}</span>,
    },
    {
      title: 'Bulan',
      dataIndex: 'month',
      key: 'month',
      render: (data) => (
        <span>{data ? moment(data).format('MMMM YYYY') : '-'}</span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'paid_amount',
      key: 'paid_amount',
      render: (data, record) => {
        if (!record.active_status) {
          return <Tag color="red">TIDAK AKTIF</Tag>;
        }

        const total = record.total_amount - (data || 0);
        const isLunas = total === 0;

        if (isLunas) {
          return <Tag color="green">LUNAS</Tag>;
        } else {
          return (
            <Tag color="red">-Rp{Number(total || 0).toLocaleString()}</Tag>
          );
        }
      },
    },
    {
      title: 'Tanggal Tagihan',
      dataIndex: 'month',
      key: 'bill_date',
      render: (data) => (
        <span>{data ? moment(data).format('DD/MM/YYYY') : '-'}</span>
      ),
    },
    {
      title: 'Tanggal Pembayaran',
      dataIndex: 'payment',
      key: 'payment',
      render: (data) => (
        <span>{data ? moment(data).format('DD/MM/YYYY') : '-'}</span>
      ),
    },
    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      align: 'right',
      render: (data, record) => (
        <Space>
          <Button
            icon={<FileDoneOutlined />}
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_API_URL_ALFIKRI}/bill/${unitId}/spp/${data}/download-receipt`,
                '_blank'
              );
            }}
          >
            Receipt
          </Button>
          <Button
            icon={<EyeOutlined />}
            onClick={() =>
              setOpenDrawer({
                state: true,
                id: record._id,
              })
            }
          >
            Lihat
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <article>
        <header style={{ marginBottom: '2rem' }}>
          <Dropdown
            overlay={
              <MenuTahun
                onClick={(v) => setFilterTahun(v)}
                list={listTahunAjaran}
              />
            }
          >
            <Button>{filterTahun?.name || '-'}</Button>
          </Dropdown>
        </header>

        <Table
          columns={tableColumns}
          dataSource={data}
          pagination={{
            position: ['bottomLeft'],
            simple: true,
            total: totalData && totalData,
            onChange: (page) => {
              setCurrentPage(page);
            },
            current: currentPage,
          }}
          // scroll={{ x: 1500 }}
          loading={loading}
        />
      </article>

      <Drawer.DetailTagihanSPP
        visible={openDrawer.state}
        onCancel={() => {
          setOpenDrawer({
            state: false,
            id: '',
          });
        }}
        value={{ id: openDrawer.id, unitId }}
        refetchData={refetchData}
      />
    </>
  );
};

export default DetailMuridSPP;
