import { Loader } from 'components/atoms';
import { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useStore } from 'store';
import {
  getActiveSchoolYear,
  getListKelasId,
  getListTahunAjaran,
  getUserData,
} from 'utils/fetch';
import { getUserToken } from 'utils/helpers/storage';
import { keys } from './theme';

export const GuestRoute = ({ component: Component, ...rest }) => {
  return <Route render={(props) => <Component {...props} />} {...rest} />;
};

export const logout = () => localStorage.removeItem(keys.token);

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const {
    handleUserData,
    handleListSchoolYear,
    handleSchoolYear,
    handleCallingCodes,
  } = useStore();
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    const token = getUserToken();
    if (token) {
      getUserData()
        .then((res) => {
          console.log(res.user, 'result decode token');
          handleUserData(res.user);

          return getListTahunAjaran();
        })
        .then((ta) => {
          console.log(ta, 'tahun ajaran decode token');

          handleListSchoolYear(ta.schoolYears);

          return getActiveSchoolYear();
        })
        .then((active) => {
          console.log(active, 'this is active school year');

          handleSchoolYear(active);

          console.log(window.location);

          return fetch(`${window.location.origin}/calling-codes.json`, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          });
        })
        .then((res) => res.json())
        .then((json) => {
          console.log(json, 'this is calling codes');
          handleCallingCodes(json);

          if (isMounted) {
            setIsAuthenticated(true);
          }
        })
        .catch((err) => {
          console.log(err, 'this is error decode token');
          localStorage.removeItem('dev-access-token');
          setIsAuthenticated(false);
        })
        .finally(() => {
          if (isMounted) {
            setLoading(false);
          }
        });
    } else {
      setIsAuthenticated(false);
      setLoading(false);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            width: '100%',
            height: '100vh',
            display: 'grid',
            placeContent: 'center',
          }}
        >
          <Loader text="Redirecting" />
        </div>
      ) : (
        <Route
          {...rest}
          render={(props) => {
            if (isAuthenticated) {
              return <Component {...props} />;
            } else {
              return <Redirect to="/signin" />;
            }
          }}
        />
      )}
    </>
  );
};
