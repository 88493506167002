import React, { useEffect, useState } from 'react';
import {
  Drawer,
  Form,
  Row,
  Col,
  Space,
  Button,
  Card,
  Divider,
  Tag,
  Table,
  InputNumber,
  DatePicker,
  Select,
  Alert,
  Modal,
  message,
  Menu,
  Dropdown,
} from 'antd';
import { Footer } from './shared';
import { InputWrapper, PageHeader } from '..';
import { InfoCard, StudentCard } from 'components/fragments/card';
import { CustomButton, Loader } from 'components/atoms';
import IC_EDIT from 'assets/icons/Edit.svg';
import {
  CalendarOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  FileDoneOutlined,
  FileOutlined,
  MenuOutlined,
  MoneyCollectOutlined,
  StarFilled,
} from '@ant-design/icons';
import YellowCard from 'components/atoms/card-base/yellow-card';
import { getDetailPembayaran, hapusPembayaran } from 'utils/fetch';
import { EditPaymentDate } from '../modal';
import moment from 'moment';

const DetailPembayaranLainnya = (props) => {
  const { visible, onCancel, value, refetchData, ...rest } = props;
  const [displayItem, setDisplayItem] = useState(0);
  const [detail, setDetail] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const { unitId = '', paymentId = '' } = value;

  useEffect(() => {
    if (visible && paymentId) {
      fetchData();
    } else {
      setDetail(null);
    }
  }, [visible, paymentId]);

  const fetchData = () => {
    getDetailPembayaran(unitId, 'general', paymentId)
      .then((res) => {
        console.log(res);
        setDetail(res);
      })
      .catch((err) => console.log(err.response, 'error fetch detail'));
  };

  const handleDelete = () => {
    Modal.confirm({
      title: `Apakah Anda yakin ingin menghapus pembayaran ${detail?.payment.name} oleh ${detail?.payment.student.name}?`,
      content: (
        <div>
          <p>
            Jika Anda menghapus pembayaran akan menambah tunggakan murid, dan
            dana alokasi akan dikembalikan ke dana masuk.
          </p>
        </div>
      ),
      okButtonProps: {
        danger: true,
      },
      okText: 'Hapus',
      cancelText: 'Batal',
      cancelButtonProps: {
        type: 'secondary',
      },
      onOk() {
        hapusPembayaran(unitId, paymentId, 'general', 'dana_masuk=false')
          .then((res) => {
            console.log(res);
            onCancel();
            refetchData();
          })
          .catch((err) => {
            console.log(err.response);
            message.err(err?.response?.data?.m || 'Gagal menghapus pembayaran');
          });
      },
      centered: true,
    });
  };

  const handleBackToFund = () => {
    Modal.confirm({
      title: `Apakah Anda yakin ingin mengembalikan pembayaran ${detail?.payment.name} oleh ${detail?.payment.student.name} ke Dana Masuk?`,
      content: (
        <div>
          <p>
            Jika anda setuju maka akan menambah tunggakan murid, dan dana akan
            dikembalikan ke dana masuk.
          </p>
        </div>
      ),
      okButtonProps: {
        danger: true,
      },
      okText: 'Ya',
      cancelText: 'Batal',
      cancelButtonProps: {
        type: 'secondary',
      },
      onOk() {
        hapusPembayaran(unitId, paymentId, 'general', 'dana_masuk=true')
          .then((res) => {
            console.log(res);
            onCancel();
            refetchData();
            message.success('Berhasil mengembalikan pembayaran ke Dana Masuk');
          })
          .catch((err) => {
            console.log(err.response);
            message.err(
              err?.response?.data?.m ||
                'Gagal mengembalikan pembayaran ke Dana Masuk'
            );
          });
      },
      centered: true,
    });
  };

  const tableColumnsItemTagihan = [
    {
      title: 'Item',
      dataIndex: 'master_bill_item',
      key: 'master_bill_item',
      render: (data) => <span>{data?.name || '-'}</span>,
    },
    {
      title: 'Sudah Bayar',
      dataIndex: 'paid_amount',
      key: 'paid_amount',
      render: (data) => <span>Rp{Number(data || 0).toLocaleString()}</span>,
    },
    {
      title: 'Refund',
      dataIndex: 'refund',
      key: 'refund',
      render: (data) => <span>Rp {Number(data || 0).toLocaleString()}</span>,
    },
    {
      title: 'Nominal Tagihan',
      dataIndex: 'total_amount',
      key: 'total_amount',
      align: 'right',
      render: (data) => <span>Rp{Number(data || 0).toLocaleString()}</span>,
    },
  ];

  const MenuHapus = (
    <Menu>
      <Menu.Item danger onClick={handleDelete}>
        Hapus Pembayaran
      </Menu.Item>
      <Menu.Item danger onClick={handleBackToFund}>
        Kembalikan Ke Dana Masuk
      </Menu.Item>
    </Menu>
  );

  const Detail = () => (
    <>
      <section style={{ marginBottom: '2rem' }}>
        <header
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '0.75rem',
          }}
        >
          <Space>
            <h2 style={{ margin: 0 }}>{detail?.payment?.name}</h2>
          </Space>
          <Space>
            <Button
              icon={<FileDoneOutlined />}
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_API_URL_ALFIKRI}/bill/${unitId}/general-bill/${detail?.generalBill._id}/download-receipt`,
                  '_blank'
                );
              }}
            >
              Receipt
            </Button>
            <Button
              icon={<CalendarOutlined />}
              onClick={() => setOpenModal(true)}
            >
              Edit Tanggal
            </Button>
            {detail?.payment?.payment_origin === 'Tabungan' ? (
              <Button
                icon={<DeleteOutlined />}
                danger
                onClick={handleDelete}
                // disabled={detail?.payment.payment_origin === 'Direct VA'}
              >
                Hapus Pembayaran
              </Button>
            ) : (
              <Dropdown overlay={MenuHapus}>
                <Button
                  icon={<MenuOutlined />}
                  // onClick={handleBackToFund}
                  // disabled={detail?.payment.payment_origin === 'Direct VA'}
                >
                  Menu Lainnya
                </Button>
              </Dropdown>
            )}
            {/* <CustomButton.IconTextButton
              type="secondary"
              icon={IC_EDIT}
              onClick={() => setDisplayItem(1)}
            >
              <span style={{ color: '#586BA4' }}>Edit</span>
            </CustomButton.IconTextButton> */}
          </Space>
        </header>
        <Space>
          <span>
            <CalendarOutlined style={{ color: '#1890FF' }} /> 28/03/2020
          </span>
          {/* <span>
            <FileOutlined style={{ color: '#1890FF' }} /> SPP2021FK2
          </span> */}
        </Space>
      </section>

      <StudentCard student={detail?.payment?.student} />

      <Row gutter={[18, 0]} style={{ marginTop: '2rem' }}>
        <Col span="12">
          <InfoCard
            title="Sudah Bayar"
            content={`Rp${(
              detail?.generalBill?.paid_amount || 0
            ).toLocaleString()}`}
            sideColor="green"
          />
        </Col>
        {detail?.payment?.refund > 0 && (
          <Col span="12">
            <InfoCard
              title="Refund"
              content={`Rp${(detail?.payment?.refund || 0).toLocaleString()}`}
              sideColor="red"
            />
          </Col>
        )}
      </Row>

      <section style={{ marginTop: '2rem' }}>
        <h3>Item Terbayar</h3>
        <Table
          columns={tableColumnsItemTagihan}
          dataSource={detail?.generalBillItems}
          pagination={false}
        />
      </section>
    </>
  );

  const drawerTitle =
    displayItem === 0
      ? 'Detail Tagihan Lainnya'
      : 'Edit Nominal Tagihan Lainnya';

  return (
    <Drawer
      title={drawerTitle}
      width={`50vw`}
      visible={visible}
      footer={
        displayItem > 0 ? (
          <Footer onCancel={() => setDisplayItem(0)} onSubmit={() => {}} />
        ) : null
      }
      onClose={onCancel}
      {...rest}
    >
      {detail ? <Detail /> : <Loader text="Fetching details ..." />}

      {openModal ? (
        <EditPaymentDate
          visible={openModal}
          onCancel={() => setOpenModal(false)}
          unitId={unitId}
          paymentId={paymentId}
          refetch={() => {
            refetchData();
            fetchData();
          }}
          defaultValue={moment(detail?.payment?.date)}
        />
      ) : null}
    </Drawer>
  );
};

export default DetailPembayaranLainnya;
