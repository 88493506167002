import {
  Button,
  Input,
  Popover,
  Select,
  Space,
  Table,
  Tag,
  Alert,
  message,
  Typography,
  Menu,
  Dropdown,
} from 'antd';
import { Drawer, Modal, PageHeader } from 'components/shared';
import React, { useEffect, useState } from 'react';
import { useStore } from 'store';
import IC_PLUSR from 'assets/icons/plus-rounded.svg';
import queryString from 'querystring';
import { CustomButton } from 'components/atoms';
import { useHistory, useLocation } from 'react-router-dom';
import {
  createNewUser,
  editUserData,
  getListKelasId,
  getListMurid,
  getListTahunAjaran,
  getNoEmailGuardians,
} from 'utils/fetch';
import {
  ExclamationCircleOutlined,
  EyeOutlined,
  FilterOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import { UpdateEmailWali } from 'components/shared/modal';
import { goToSearchQuery } from 'utils/helpers/common';

const { Link } = Typography;

export const DaftarMurid = () => {
  const {
    handleBreadcrumb,
    handleSiderActive,
    handleListSchoolYear,
    handleListKelas,
    state: { activeSchoolYear, selectedUnit, listKelas },
  } = useStore();
  const history = useHistory();
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [data, setData] = useState(null);
  const [isEdit, setIsEdit] = useState({
    state: false,
    data: null,
  });
  const [filterPopover, setFilterPopover] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totalData, setTotalData] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [listGuardiansNoEmail, setListGuardiansNoEmail] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [filter, setFilter] = useState({
    name: null,
    grade: null,
    status: null,
    nis: null,
    virtual_account: null,
  });
  const unitId = selectedUnit?._id;
  const schoolYearId = activeSchoolYear?._id;
  console.log('cek schollyearId', schoolYearId);
  const {
    unit,
    limit = 10,
    page,
    status,
    name,
    grade,
    nis,
    virtual_account,
  } = queryString.parse(location.search.replace('?', ''));
  const filterQuery = {
    unit,
    limit,
    page,
    name,
    grade,
    status,
    nis,
    virtual_account,
  };
  const [downloadLaporan, setDownloadLaporan] = useState({
    open: false,
    type: '',
  });

  useEffect(() => {
    const newBreadcrumb = [
      {
        linkTo: '/daftar-murid',
        node: <span>Daftar Murid</span>,
      },
    ];
    handleBreadcrumb(newBreadcrumb);
    handleSiderActive(['2']);
  }, []);

  useEffect(() => {
    if (currentPage) {
      Object.keys(filterQuery).forEach((key) => {
        if (!filterQuery[key]) delete filterQuery[key];
      });
      history.push({
        search: queryString.stringify({
          ...filterQuery,
          page: currentPage,
        }),
      });
    }
  }, [currentPage]);

  useEffect(() => {
    console.log('cek in');
    if (selectedUnit?._id && activeSchoolYear?._id) {
      console.log('masuk');
      setLoading(true);
      fetchDataListKelas();
      fetchDataTahunAjaran();
      fetchData();
      fetchNoEmailGuardian(selectedUnit?._id, activeSchoolYear?._id);
    }
  }, [selectedUnit?._id, activeSchoolYear?._id]);

  useEffect(() => {
    if (unitId && schoolYearId && !isNaN(page)) {
      fetchData();
    }

    return () => {};
  }, [page, limit, name, status, grade, nis, virtual_account]);

  useEffect(() => {
    setFilter({
      name,
      status,
      grade,
      nis,
      virtual_account,
    });
  }, [name, status, grade, nis, virtual_account]);

  const fetchDataTahunAjaran = () => {
    getListTahunAjaran()
      .then((res) => {
        setLoading(false);
        console.log(res);
        handleListSchoolYear(res.schoolsYears);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const fetchDataListKelas = () => {
    getListKelasId(unitId, schoolYearId)
      .then((res) => {
        setLoading(false);
        console.log(res.grades);
        handleListKelas(res.grades);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const fetchNoEmailGuardian = async (unitId, schoolYearId) => {
    try {
      setListGuardiansNoEmail([]);
      const value = await getNoEmailGuardians(unitId, schoolYearId);
      console.log(value, 'this is guardians email');
      setListGuardiansNoEmail(value?.listGuardians);
    } catch (err) {
      console.log(err);
      message.error(err?.data || 'Failed fetch no email guardian');
      setListGuardiansNoEmail([]);
    }
  };

  const fetchData = () => {
    if (!isNaN(page)) {
      let query = `limit=${limit}&page=${page - 1}`;

      if (name) query += `&name=${name}`;
      if (status) query += `&status=${status}`;
      if (grade) query += `&grade=${grade}`;
      if (nis) query += `&nik=${nis}`;
      if (virtual_account) query += `&virtual_account=${virtual_account}`;

      setLoading(true);

      getListMurid(unitId, schoolYearId, query)
        .then((res) => {
          setFilterPopover(false);
          const numbered = res.students.map((el, idx) => ({
            ...el,
            no: idx + 1,
          }));
          console.log(numbered);
          setData(numbered);
          setTotalData(res.totalRecord);
          setLoading(false);
        })
        .catch((err) => {
          setFilterPopover(false);
          setLoading(false);
          console.log(err.response, 'this is error fetch');
        });
    }
  };

  const handleSubmit = (data, id) => {
    setLoading(true);
    if (!id) {
      createNewUser(data)
        .then((res) => {
          fetchData();
          setOpenDrawer(false);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    } else {
      editUserData(data, id)
        .then((res) => {
          fetchData();
          setOpenDrawer(false);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    }
  };

  const handleSubmitFilter = () => {
    Object.keys(filter).forEach((key) => {
      if (!filter[key]) delete filter[key];
    });
    history.push({
      pathname: location.pathname,
      search: queryString.stringify({
        unit,
        limit,
        page,
        ...filter,
      }),
    });
    setFilterPopover(false);
  };

  const filterMenu = (
    <div style={{ width: '30rem' }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Input
          type="text"
          placeholder="Cari nama murid"
          value={filter?.name}
          onChange={(e) => {
            const newFilter = {
              ...filter,
              name: e.target.value,
            };
            setFilter(newFilter);
          }}
        />
        <Input
          type="text"
          placeholder="NIS Murid"
          value={filter?.nis}
          onChange={(e) => {
            const newFilter = {
              ...filter,
              nis: e.target.value,
            };
            setFilter(newFilter);
          }}
        />
        <Input
          type="text"
          placeholder="Virtual Account Murid"
          value={filter?.virtual_account}
          onChange={(e) => {
            const newFilter = {
              ...filter,
              virtual_account: e.target.value,
            };
            setFilter(newFilter);
          }}
        />
        <Select
          placeholder="Pilih kelas"
          style={{ width: '100%' }}
          onChange={(val) => {
            const newFilter = {
              ...filter,
              grade: val,
            };
            setFilter(newFilter);
          }}
          value={filter?.grade}
        >
          {[
            { _id: 'Murid Tanpa Kelas', name: 'Murid Tanpa Kelas' },
            ...listKelas,
          ].map((kelas) => {
            return (
              <Select.Option value={kelas._id}>{kelas.name}</Select.Option>
            );
          })}
        </Select>
        <Select
          placeholder="Status"
          style={{ width: '100%' }}
          onChange={(val) => {
            const newFilter = {
              ...filter,
              status: val,
            };
            setFilter(newFilter);
          }}
          value={filter?.status}
        >
          <Select.Option value={'Tidak Aktif'}>Tidak Aktif</Select.Option>
          <Select.Option value={'Aktif'}>Aktif</Select.Option>
          <Select.Option value={'Lulus'}>Lulus</Select.Option>
        </Select>
        <footer
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            borderTop: '1px solid #d5d5d5',
            paddingTop: '0.5rem',
          }}
        >
          <Space>
            <Button
              onClick={() => {
                setFilterPopover(false);
                setFilter({
                  name: null,
                  status: null,
                  grade: null,
                  nis: null,
                  virtual_account: null,
                });
                history.push({
                  pathname: location.pathname,
                  search: queryString.stringify({
                    unit: selectedUnit.name,
                    limit,
                    page,
                  }),
                });
              }}
            >
              Hapus Filter
            </Button>
            <Button type="primary" onClick={handleSubmitFilter}>
              Cari
            </Button>
          </Space>
        </footer>
      </Space>
    </div>
  );

  const tableColumns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Nama Murid',
      dataIndex: 'name',
      key: 'name',
      width: 250,
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Kelas',
      dataIndex: 'grade',
      key: 'grade',
      render: (data) => <span>{data ? data.name : 'Murid Tanpa Kelas'}</span>,
    },
    {
      title: 'NIS',
      dataIndex: 'nik',
      key: 'nik',
      width: 120,
      render: (data) => <span>{data || '-'}</span>,
    },
    {
      title: 'VA Number',
      dataIndex: 'virtual_account',
      key: 'virtual_account',
      width: 200,
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (data) => {
        let color;
        let text;

        switch (data) {
          case 1:
            color = 'blue';
            text = 'AKTIF';
            break;
          case 2:
            color = 'red';
            text = 'TIDAK AKTIF';
            break;
          case 3:
            color = 'green';
            text = 'LULUS';
            break;
          default:
            break;
        }

        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      align: 'center',
      render: (id) => (
        <Button
          onClick={() =>
            history.push({
              pathname: `${location.pathname}/detail/${id}`,
              search: queryString.stringify({
                unit: selectedUnit.name,
              }),
            })
          }
          icon={<EyeOutlined />}
        >
          Lihat
        </Button>
      ),
    },
  ];

  const MenuDropdown = () => {
    return (
      <Menu style={{ boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.12)' }}>
        <Menu.Item
          key="ganti-kelulusan"
          onClick={() => {
            history.push({
              pathname: '/daftar-murid/ganti-kelulusan',
              search: queryString.stringify({
                unit: selectedUnit.name,
              }),
            });
          }}
        >
          Ganti Status Kelulusan Murid
        </Menu.Item>
        <Menu.Item
          key="blast-reminder"
          onClick={() => {
            history.push({
              pathname: '/daftar-murid/kirim-reminder',
              search: queryString.stringify({
                unit: selectedUnit.name,
              }),
            });
          }}
        >
          Kirim Reminder
        </Menu.Item>
        <Menu.Item
          key="download-master-data"
          onClick={() => {
            setDownloadLaporan({
              open: true,
              type: 'download-master-data',
            });
          }}
        >
          Download Master Data
        </Menu.Item>
        <Menu.Item
          key="download-keseluruhan"
          onClick={() => {
            setDownloadLaporan({
              open: true,
              type: 'download-condition',
            });
          }}
        >
          Download Keseluruhan
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <>
      <PageHeader>
        <Space>
          <Dropdown overlay={<MenuDropdown />} placement="bottomRight">
            <Button icon={<MenuOutlined />}>Menu Lainnya</Button>
          </Dropdown>
          <CustomButton.IconTextButton
            type="primary"
            icon={IC_PLUSR}
            onClick={() =>
              history.push({
                pathname: '/daftar-murid/tambah-murid',
                search: queryString.stringify({
                  unit: selectedUnit.name,
                }),
              })
            }
          >
            Tambah Murid
          </CustomButton.IconTextButton>
        </Space>
      </PageHeader>
      <section style={{ width: 'auto', margin: '2rem 0' }}>
        <Popover
          content={filterMenu}
          visible={filterPopover}
          placement="bottomLeft"
          style={{ width: '30rem' }}
          trigger="click"
        >
          <Button
            onClick={() => setFilterPopover(!filterPopover)}
            icon={<FilterOutlined />}
          >
            Cari Berdasarkan
          </Button>
        </Popover>
      </section>

      {/* no email guardian update */}

      {listGuardiansNoEmail?.length ? (
        <Alert
          type="error"
          message={
            <div style={{ fontWeight: 'bold' }}>
              {listGuardiansNoEmail?.length
                ? listGuardiansNoEmail.length.toLocaleString('en')
                : 0}{' '}
              email wali murid belum terdaftar &nbsp;
              <Link
                style={{ color: '#1890FF', textDecoration: 'underline' }}
                onClick={() => setOpenModal(true)}
              >
                Lihat
              </Link>
            </div>
          }
          showIcon
          icon={<ExclamationCircleOutlined />}
        />
      ) : null}

      <section style={{ width: '100%', margin: '2rem 0' }}>
        <Table
          dataSource={data}
          columns={tableColumns}
          pagination={{
            position: ['bottomLeft'],
            total: totalData && totalData,
            onChange: (page) => {
              setCurrentPage(page);
            },
            current: currentPage,
            pageSize: limit,
            showSizeChanger: true,
            pageSizeOptions: ['10', '30', '50'],
            onShowSizeChange: (curr, pageSize) => {
              goToSearchQuery('limit', pageSize, filterQuery, history);
            },
          }}
          scroll={{ x: 1000 }}
          loading={loading}
        />
      </section>

      <Drawer.ManagePengguna
        visible={openDrawer}
        onCancel={() => {
          setOpenDrawer(false);
          setIsEdit({
            state: false,
            data: null,
          });
        }}
        isEdit={isEdit.state}
        values={isEdit.data}
        handleSubmit={handleSubmit}
      />

      <Modal.UpdateEmailWali
        visible={openModal}
        onClose={() => setOpenModal(false)}
        data={listGuardiansNoEmail}
        unitId={selectedUnit?._id}
        schoolYearId={activeSchoolYear?._id}
        refetchData={() => {
          fetchData();
          fetchNoEmailGuardian(selectedUnit?._id, activeSchoolYear?._id);
        }}
        unit={unit?.toUpperCase() || ''}
      />

      <Modal.DownloadLaporanMurid
        visible={downloadLaporan.open}
        type={downloadLaporan.type}
        onCancel={() => setDownloadLaporan({ open: false, type: '' })}
      />
    </>
  );
};

export { default as TambahMurid } from './tambah-murid';
export { default as GantiKelulusan } from './ganti-kelulusan';
export { default as DetailMurid } from './detail';
export { default as RiwayatKelas } from './riwayat-kelas';
export { default as KirimReminder } from './kirim-reminder';
