import React from 'react';
import { Modal, Button, Alert } from 'antd';

const AktifkanTahunAjaran = ({ visible, onCancel }) => {
  const Description = () => {
    return (
      <div>
        <p>Sebelum mengaktifkan tahun ajaran baru pastikan anda telah:</p>
      </div>
    );
  };

  return (
    <Modal centered closeIcon={''} visible={visible} onCancel={onCancel}>
      <Alert
        type="warning"
        message="Perhatian!"
        description={<Description />}
        showIcon
      />
    </Modal>
  );
};

export default AktifkanTahunAjaran;
