import React from 'react';
import { Card } from 'antd';

const YellowCard = ({ children, style={},...rest }) => {
  return (
    <Card
      style={{
        backgroundColor: '#FFF8E4',
        border: '1px solid #FFD698',
        width: '100%',
        ...style
      }}
      {...rest}
    >
      {children}
    </Card>
  );
};

export default YellowCard;
