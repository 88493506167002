import React, { useEffect, useState } from 'react';
import {
  Drawer,
  Form,
  Row,
  Col,
  Space,
  Button,
  Card,
  Divider,
  Tag,
  Table,
  InputNumber,
  DatePicker,
  Select,
  Alert,
  Modal,
  message,
  Dropdown,
  Menu,
  Input,
  Popover,
  Radio,
} from 'antd';
import { Footer } from './shared';
import { InputWrapper, PageHeader } from '..';
import { InfoCard, StudentCard } from 'components/fragments/card';
import { CustomButton, Loader } from 'components/atoms';
import IC_EDIT from 'assets/icons/Edit.svg';
import {
  CalendarOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FileDoneOutlined,
  FileOutlined,
  MenuFoldOutlined,
  PlusCircleOutlined,
  StarFilled,
} from '@ant-design/icons';
import YellowCard from 'components/atoms/card-base/yellow-card';
import moment from 'moment';
import {
  BASE_URL,
  cancelPPDB,
  createApresiasi,
  deleteApresiasi,
  deletePPDB,
  editNominal,
  editNominalEdited,
  getDetailPembayaran,
  getDetailTagihan,
  getListTahunAjaran,
  updateApresiasi,
} from 'utils/fetch';
import { useStore } from 'store';
import { themeColor } from 'lib/theme';

const DetailTagihanPPDB = (props) => {
  const [form] = Form.useForm();
  const { visible, onCancel, value, refetchData, apresiasiData, ...rest } =
    props;
  const {
    state: { listTahunAjaran },
    handleListSchoolYear,
  } = useStore();
  const [displayItem, setDisplayItem] = useState(0);
  const [listSchoolYear, setListTahunAjaran] = useState([]);
  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState(null);
  const [ppdbItems, setPpdbItems] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [installment, setInstallment] = useState([]);
  const [payments, setPayments] = useState([]);
  const [error, setError] = useState(null);
  const [cancelDate, setCancelDate] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [dateApresiasi, setDateApresiasi] = useState('');
  const [listApresiasi, setListApresiasi] = useState([]);
  const [editApresiasi, setEditApresiasi] = useState({
    state: false,
    data: null,
  });
  const [instalmentOver, setInstalmentOver] = useState(false);
  const { id = '', unitId = '' } = value;

  const ArrayTanggal = Array.from({ length: 28 }, (_, i) => i + 1);

  useEffect(() => {
    if (apresiasiData) {
      setDisplayItem(3);
      setEditApresiasi({ state: true, data: apresiasiData });
    }
  }, [apresiasiData]);

  useEffect(() => {
    if (!visible) setDisplayItem(0);
    else {
      fetchData();
      // fetchDataPembayaran();
      getListTahunAjaran().then((res) => {
        console.log(res);
        setListTahunAjaran(res.schoolsYears);
        handleListSchoolYear(res.schoolsYears);
      });
    }
  }, [visible]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 3000);
    }
  }, [error]);

  useEffect(() => {
    if (editApresiasi.state && editApresiasi.data) {
      form.setFieldsValue({
        total_amount: editApresiasi.data?.total_amount,
        notes: editApresiasi.data?.notes,
        date: moment(editApresiasi.data?.date),
      });
      setDateApresiasi(editApresiasi?.data?.date);
    } else {
      form.resetFields();
    }
  }, [editApresiasi]);

  useEffect(() => {
    if (displayItem === 1) {
      const formItems = ppdbItems?.map((item) => ({
        ppdb_bill_item: item._id,
        total_amount: item.total_amount,
        paid_amount: item.paid_amount,
        new_total_amount: item.total_amount,
      }));
      console.log(detail, 'THIS IS DETAIL');
      setTotalAmount(
        formItems.reduce((acc, curr) => acc + (curr.new_total_amount || 0), 0)
      );
      form.setFieldsValue({
        instalment_repition: detail.instalment_repition,
        instalment_date: detail.instalment_date,
        items: formItems,
        fix_payment: detail.fix_payment,
        instalments: installment?.map((ins) => ({
          ...ins,
          instalment_repition: ins.instalment_repition + 1,
          instalment_date: moment(ins.instalment_date),
        })),
        fix_payment: detail.fix_payment ? 1 : 0,
        school_year: detail?.school_year?._id,
      });
    } else if (displayItem === 2) {
      const formItems = ppdbItems?.map((item) => ({
        ppdb_bill_item_id: item._id,
        total_amount: item.total_amount,
        paid_amount: item.paid_amount,
        refund_amount: '',
      }));
      const ta =
        listTahunAjaran.find((el) => el._id === detail.school_year)?.name ||
        detail.school_year;
      form.setFieldsValue({
        school_year: ta,
        student: detail.student.name,
        items: formItems,
      });
    }

    return () => {
      form.resetFields();
      setCancelDate('');
    };
  }, [displayItem, detail]);

  const fetchData = () => {
    setLoading(true);

    getDetailTagihan(unitId, id, 'ppdb')
      .then((value) => {
        console.log(value, 'this is detail tagihan');
        setDetail(value.ppdbBill);
        setInstallment(value.ppdbInstalments);
        setPayments(value.payments);
        setPpdbItems(value.ppdbBillItems);
        setListApresiasi(value.appreciations);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, 'this is error');
        setLoading(false);
      });
  };

  const fetchDataPembayaran = () => {
    getDetailPembayaran(unitId, 'ppdb-instalment', id)
      .then((res) => {
        console.log(res, 'detail pembayaran');
        setPayments(res.payment);
      })
      .catch((err) => {
        message.error(
          err?.response?.data?.m || 'Gagal memuat detail pembayaran'
        );
        console.log(err.response);
      });
  };

  const submitDeletePpdb = () => {
    Modal.confirm({
      title: `Apakah anda yakin akan menghapus tagihan PSB ${detail.student.name}`,
      onOk: () => {
        deletePPDB(unitId, detail._id)
          .then((value) => {
            console.log(value);
            message.success('Berhasil menghapus tagihan PSB');
            refetchData();
            onCancel();
            fetchData();
          })
          .catch((err) => {
            message.error(err.response?.data?.m);
            console.log(err.response);
          });
      },
      okText: 'Hapus',
      cancelText: 'Batal',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
    });
  };

  const submitEditPpdb = (values) => {
    // setLoadingSubmit(true);
    const total = values.items.reduce((curr, acc) => {
      return curr + Number(acc.new_total_amount);
    }, 0);
    console.log(values.items, 'this is items');
    // update 4 februari
    const newItems = values.items.map((item) => ({
      ppdb_bill_item: item.ppdb_bill_item,
      new_total_amount: Number(item.new_total_amount || 0),
    }));

    // update 4 februari
    const payload = {
      ...values,
      total_amount: total,
      items: newItems,
      instalments: values.instalments?.map((ins, index) => ({
        total_amount: ins.total_amount,
        notes: ins?.notes || '-',
        instalment_date: moment(ins.instalment_date),
        instalment_repition: ins?.instalment_repition - 1 || index,
      })),
      fix_payment: values.fix_payment ? true : false,
      school_year: values?.school_year || detail?.school_year._id,
    };
    editNominalEdited(unitId, 'ppdb', detail._id, payload)
      .then((value) => {
        console.log(value);
        message.success('Berhasil memperbaharui data PPDB');
        fetchData();
        refetchData();
        setDisplayItem(0);
        // onCancel();
      })
      .catch((err) => {
        refetchData();
        console.log(err.response, 'error edit nominal');
        setError(err?.response?.data?.m);
        message.error(err?.response?.data?.m);
      })
      .finally(() => setLoadingSubmit(false));
  };

  const submitCancel = (payload) => {
    const newItems = payload?.items.filter((item) => item.refund_amount);
    const newPayload = { ...payload, items: newItems };
    console.log(newPayload);
    if (!payload?.notes) {
      setError({
        title: 'Gagal Refund',
        m: 'Alasan cancel tidak diisi',
      });
    } else {
      cancelPPDB(unitId, detail._id, newPayload)
        .then((res) => {
          console.log(res);
          refetchData();
          fetchData();
          message.success('Berhasil membatalkan tagihan PSB');
        })
        .catch((err) => {
          console.log(err.response, 'error cancel');
          setError(err.response.data);
        });
    }
  };

  const tableColumnsItemTagihan = [
    {
      title: 'Item',
      dataIndex: 'ppdb_item',
      key: 'ppdb_item',
      render: (data) => <span>{data?.master_bill_item.name}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'paid_amount',
      key: 'paid_amount',
      render: (data, record) => {
        const total = record.total_amount - (data || 0);
        const isLunas = total === 0;

        if (isLunas) {
          return <Tag color="green">LUNAS</Tag>;
        } else {
          return (
            <Tag color="red">-Rp{Number(total || 0).toLocaleString()}</Tag>
          );
        }
      },
    },
    {
      title: 'Nominal Tagihan',
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (data) => <span>Rp{Number(data || 0).toLocaleString()}</span>,
    },
  ];

  const tablePembayaran = [
    {
      title: 'Kode Pembayaran',
      dataIndex: 'name',
      key: 'name',
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Tanggal Pembayaran',
      dataIndex: 'date',
      key: 'date',
      render: (data) => (
        <span>{data ? new Date(data).toLocaleDateString('ID') : '-'}</span>
      ),
    },
    {
      title: 'Sumber',
      dataIndex: 'payment_origin',
      key: 'payment_origin',
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Nominal',
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (data) => <span>Rp{Number(data).toLocaleString()}</span>,
    },
  ];

  const tableInstalment = [
    {
      title: 'Cicilan ke-',
      dataIndex: 'instalment_repition',
      key: 'instalment_repition',
      render: (data) => <span>{Number(data) + 1}</span>,
    },
    {
      title: 'Tanggal Jatuh Tempo',
      dataIndex: 'instalment_date',
      key: 'instalment_date',
      render: (data) => {
        return <span>{new Date(data).toLocaleDateString('ID')}</span>;
      },
    },
    {
      title: 'Nominal Tagihan',
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (data) => <span>Rp{Number(data).toLocaleString()}</span>,
    },
    {
      title: 'Nominal Terbayar',
      dataIndex: 'paid_amount',
      key: 'paid_amount',
      render: (data) => <span>Rp{Number(data).toLocaleString()}</span>,
    },
    {
      title: 'Catatan',
      dataIndex: 'notes',
      key: 'notes',
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'paid_amount',
      key: 'paid_amount',
      render: (data, record) => {
        let color, text;

        if (Number(data) === 0) {
          color = 'red';
          text = 'BELUM BAYAR';
        } else if (data === record.total_amount) {
          color = 'green';
          text = 'LUNAS';
        } else {
          color = 'red';
          text = `-Rp${Math.abs(data - record.total_amount).toLocaleString()}`;
        }

        return <Tag color={color}>{text}</Tag>;
      },
    },
  ];

  const tableApresiasi = [
    {
      title: 'Tanggal Pemberian',
      dataIndex: 'date',
      key: 'date',
      render: (data) => (
        <span>{data ? new Date(data).toLocaleDateString('ID') : '-'}</span>
      ),
    },
    {
      title: 'Nominal',
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (data) => <span>Rp{Number(data).toLocaleString()}</span>,
    },
    {
      title: 'Catatan',
      dataIndex: 'notes',
      key: 'notes',
      render: (data) => <span>{data || '-'}</span>,
    },
    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      render: (data, record) => (
        <Space align="center" size="small">
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setDisplayItem(3);
              setEditApresiasi({ state: true, data: record });
            }}
          />
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => _handleDeleteApresiasi(data)}
          />
        </Space>
      ),
    },
  ];

  const MenuDropdown = () => {
    return (
      <Menu style={{ boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.12)' }}>
        <Menu.Item key="edit-tagihan" onClick={() => setDisplayItem(1)}>
          Edit
        </Menu.Item>
        <Menu.Item key="apresiasi-tagihan" onClick={() => setDisplayItem(3)}>
          Tambah Apresiasi
        </Menu.Item>
        <Menu.Item
          key="hapus-tagihan"
          danger
          onClick={submitDeletePpdb}
          disabled={detail?.paid_amount > 0 || detail?.paid_status || false}
        >
          Hapus Tagihan
        </Menu.Item>
        <Menu.Item
          key="cancel-tagihan"
          danger
          onClick={() => setDisplayItem(2)}
          disabled={detail?.paid_status || false}
        >
          Cancel Tagihan
        </Menu.Item>
      </Menu>
    );
  };

  const Detail = () => {
    const outstandingAmount = detail?.total_amount - detail?.paid_amount;
    return (
      <>
        <section style={{ marginBottom: '2rem' }}>
          <header
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '0.75rem',
            }}
          >
            <Space>
              <h2 style={{ margin: 0 }}>PPDB Murid Baru</h2>
            </Space>
            <Space>
              <Button
                icon={<FileDoneOutlined />}
                onClick={() => {
                  window.open(
                    BASE_URL(
                      `bill/${unitId}/ppdb/${detail?._id}/download-receipt`
                    ),
                    '_blank'
                  );
                }}
              >
                Receipt
              </Button>
              <Dropdown overlay={<MenuDropdown />} placement="bottomRight">
                <Button icon={<MenuFoldOutlined />}>Menu Lainnya</Button>
              </Dropdown>
            </Space>
          </header>
          <Space>
            <span>
              <CalendarOutlined style={{ color: '#1890FF' }} />{' '}
              {moment(detail?.instalment_start_month).format('DD/MM/YY')}
            </span>
            {/* <span>
            <FileOutlined style={{ color: '#1890FF' }} /> SPP2021FK2
          </span> */}
          </Space>
        </section>

        <StudentCard student={detail?.student} />

        <Row gutter={[18, 0]} style={{ marginTop: '2rem' }}>
          <Col span="12">
            <InfoCard
              title="Nominal Tagihan"
              content={`Rp${(detail?.total_amount || 0).toLocaleString()}`}
              sideColor="blue"
            />
          </Col>
          <Col span="12">
            <InfoCard
              title="Status Pembayaran"
              content={
                detail?.paid_status
                  ? 'LUNAS'
                  : detail?.paid_amount
                  ? `-Rp${(outstandingAmount || 0).toLocaleString()}`
                  : 'BELUM BAYAR'
              }
              sideColor={detail?.paid_status ? 'green' : 'red'}
              fontColor={detail?.paid_status ? 'green' : 'red'}
            />
          </Col>
        </Row>

        <section style={{ marginTop: '2rem' }}>
          <h3>Item Tagihan</h3>
          <Table
            columns={tableColumnsItemTagihan}
            dataSource={ppdbItems}
            pagination={false}
          />
        </section>

        {detail?.paid_amount > 0 ? (
          <section style={{ marginTop: '2rem' }}>
            <h3>Detail Pembayaran</h3>
            <Table
              columns={tablePembayaran}
              dataSource={payments}
              pagination={false}
            />
          </section>
        ) : null}

        <section style={{ marginTop: '2rem' }}>
          <h3>Cicilan ({installment.length}x)</h3>
          <Table
            columns={tableInstalment}
            dataSource={installment}
            pagination={false}
          />
        </section>

        <section style={{ marginTop: '2rem' }}>
          <h3>Apresiasi</h3>
          <Table
            columns={tableApresiasi}
            dataSource={listApresiasi}
            pagination={false}
          />
        </section>
      </>
    );
  };

  const _handleAddApresiasi = async (payload) => {
    try {
      const response = await createApresiasi(unitId, payload);
      if (response) {
        console.log(response);
        fetchData();
        setDisplayItem(0);
        form.resetFields();
      }
    } catch (err) {
      console.log(err, 'error add');
    }
  };
  const _handleEditApresiasi = async (id, payload) => {
    try {
      const response = await updateApresiasi(unitId, id, payload);
      if (response) {
        console.log(response);
        fetchData();
        setDisplayItem(0);
        setEditApresiasi({ state: false, data: null });
        form.resetFields();
      }
    } catch (err) {
      console.log(err, 'error add');
    }
  };

  const _handleDeleteApresiasi = async (id) => {
    try {
      const response = await deleteApresiasi(unitId, id);
      if (response) {
        fetchData();
      }
    } catch (err) {
      console.log(err, 'error delete');
    }
  };

  const Cancel = ({ form }) => (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={(v) => {
          const newItems = v.items.map((item) => ({
            ppdb_bill_item_id: item.ppdb_bill_item_id,
            refund_amount: item.refund_amount,
            // paid_amount: item.paid_amount,
          }));
          const totalRefund = v.items.reduce(
            (curr, acc) => curr + Number(acc.refund_amount),
            0
          );
          const payload = {
            items: newItems,
            date: cancelDate,
            total_refund: totalRefund,
            notes: v.notes,
            // paid_amount: detail.paid_amount,
          };
          submitCancel(payload);
        }}
      >
        <Row gutter={[18, 8]}>
          <Col span="12">
            <InputWrapper
              type="text"
              name="school_year"
              label="Tahun Ajaran"
              disabled
            />
          </Col>
          <Col span="12">
            <InputWrapper
              type="text"
              name="student"
              label="Pilih Murid"
              disabled
            />
          </Col>
        </Row>

        <Row gutter={[18, 8]} style={{ marginBottom: '1rem' }}>
          <Col span="12">
            <InfoCard
              sideColor="green"
              title="Nominal Tagihan Terbayar"
              content={`Rp${(detail?.paid_amount || 0).toLocaleString()}`}
            />
          </Col>
          <Col span="12">
            <InfoCard
              sideColor="blue"
              title="Total Refund"
              content={`Rp${(detail?.refund || 0).toLocaleString()}`}
            />
          </Col>
        </Row>

        <Form.List name="items">
          {(fields, { add, remove }) => {
            return fields.map((field, idx) => {
              return (
                <section>
                  <h3>{ppdbItems[idx].ppdb_item?.master_bill_item.name}</h3>
                  <Row gutter={[18, 8]}>
                    <Col span="8">
                      <InputWrapper
                        type="number"
                        prefix="Rp"
                        name="nominal"
                        label="Nominal Tagihan"
                        disabled
                        formItemProps={{
                          name: [field.name, 'total_amount'],
                        }}
                        formatter={(value) =>
                          `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
                      />
                    </Col>
                    <Col span="8">
                      <InputWrapper
                        type="number"
                        prefix="Rp"
                        formItemProps={{
                          name: [field.name, 'paid_amount'],
                        }}
                        label="Sudah Bayar"
                        disabled
                        formatter={(value) =>
                          `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
                      />
                    </Col>
                    <Col span="8">
                      <InputWrapper
                        type="number"
                        prefix="Rp"
                        placeholder="500.000"
                        formItemProps={{
                          name: [field.name, 'refund_amount'],
                        }}
                        label="Refund Nominal"
                        formatter={(value) =>
                          `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
                      />
                    </Col>
                  </Row>
                </section>
              );
            });
          }}
        </Form.List>
        <section>
          <h3>Refund</h3>
          <Row gutter={[18, 8]}>
            <Col span="12">
              <Form.Item label="Tanggal Refund" name="date">
                <DatePicker
                  onChange={(v, dateString) => {
                    setCancelDate(dateString);
                  }}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span="12">
              <InputWrapper type="text" name="notes" label="Alasan Cancel" />
            </Col>
          </Row>
        </section>
      </Form>
    </>
  );

  const drawerTitle = {
    0: 'Detail Tagihan Murid',
    1: 'Edit Nominal PSB',
    2: 'Cancel Tagihan PSB',
    3: 'Tambah Apresiasi',
  };

  return (
    <Drawer
      title={drawerTitle[displayItem]}
      width={displayItem > 0 ? '50vw' : '60vw'}
      visible={visible}
      footer={
        displayItem > 0 ? (
          <Footer
            onCancel={() => setDisplayItem(0)}
            onSubmit={form.submit}
            loadingState={loadingSubmit}
            submitDisabled={
              displayItem === 2 && !cancelDate && !form.getFieldValue('notes')
            }
          />
        ) : null
      }
      onClose={onCancel}
      {...rest}
    >
      {loading ? (
        <Loader text="Fetching details ..." />
      ) : (
        <section>
          {error ? (
            <Alert
              type="error"
              message={error.title}
              description={error.m}
              showIcon
              style={{ marginBottom: '2rem' }}
            />
          ) : null}
          {displayItem === 0 && <Detail />}
          {displayItem === 1 && (
            <>
              <Row gutter={[24, 12]} style={{ marginBottom: '1rem' }}>
                <Col span="12">
                  <YellowCard bodyStyle={{ padding: 10 }}>
                    <span>Nama Murid</span>
                    <h2
                      style={{
                        color: themeColor.primary,
                        margin: 0,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {detail?.student?.name}
                    </h2>
                  </YellowCard>
                </Col>
                <Col span="12">
                  <InfoCard
                    sideColor="blue"
                    title="Total"
                    content={`Rp${(totalAmount || 0).toLocaleString()}`}
                  />
                </Col>
              </Row>

              <Form
                form={form}
                layout="vertical"
                onFinish={(val) =>
                  val.instalments?.length
                    ? submitEditPpdb(val)
                    : message.error('Cicilan tidak boleh kosong')
                }
                onValuesChange={(current, values) => {
                  if (current.items) {
                    setTotalAmount(
                      values.items.reduce(
                        (acc, curr) => acc + (curr.new_total_amount || 0),
                        0
                      )
                    );
                  }

                  if (current.instalments) {
                    const instalmentAmount = values.instalments?.reduce(
                      (acc, curr) => acc + curr?.total_amount || 0,
                      0
                    );
                    const itemAmount = totalAmount;

                    if (instalmentAmount > itemAmount) {
                      message.error(
                        'Nominal cicilan tidak dapat melebihi nominal total tagihan PSB'
                      );
                      setInstalmentOver(true);
                    } else {
                      setInstalmentOver(false);
                    }
                    console.log(values.instalments, 'this is changed');
                  }
                }}
              >
                <Form.Item label="Tahun Ajaran" name="school_year">
                  <Select
                    placeholder="Pilih tahun ajaran"
                    style={{ width: '100%' }}
                    // onChange={(e) => setTahunAjaran(e)}
                  >
                    {listTahunAjaran?.map((year) => (
                      <Select.Option value={year._id}>
                        {year.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.List name="items">
                  {(fields, { add, remove }) => {
                    return fields.map((field, idx) => {
                      return (
                        <section>
                          <h3>
                            {ppdbItems[idx].ppdb_item?.master_bill_item.name}
                          </h3>
                          <Row gutter={[18, 8]}>
                            <Col span="8">
                              <InputWrapper
                                type="number"
                                prefix="Rp"
                                name="nominal"
                                label="Nominal Tagihan"
                                disabled
                                formItemProps={{
                                  name: [field.name, 'total_amount'],
                                }}
                                formatter={(value) =>
                                  `Rp ${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ','
                                  )
                                }
                                parser={(value) =>
                                  value.replace(/\Rp\s?|(,*)/g, '')
                                }
                              />
                            </Col>
                            <Col span="8">
                              <InputWrapper
                                type="number"
                                prefix="Rp"
                                formItemProps={{
                                  name: [field.name, 'paid_amount'],
                                }}
                                label="Sudah Bayar"
                                disabled
                                formatter={(value) =>
                                  `Rp ${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ','
                                  )
                                }
                                parser={(value) =>
                                  value.replace(/\Rp\s?|(,*)/g, '')
                                }
                              />
                            </Col>
                            <Col span="8">
                              <InputWrapper
                                type="number"
                                placeholder="Rp 500.000"
                                formatter={(value) =>
                                  `Rp ${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ','
                                  )
                                }
                                parser={(value) =>
                                  value.replace(/\Rp\s?|(,*)/g, '')
                                }
                                formItemProps={{
                                  name: [field.name, 'new_total_amount'],
                                }}
                                label="Ubah Nominal"
                              />
                            </Col>
                          </Row>
                        </section>
                      );
                    });
                  }}
                </Form.List>

                <section>
                  <h3>Cicilan</h3>{' '}
                  <section style={{ marginTop: '2rem' }}>
                    <Form.Item
                      label={
                        <span>
                          Tipe Nominal Tagihan &nbsp;{' '}
                          <Popover
                            trigger={['hover']}
                            content={
                              <div>
                                <p>
                                  Pilih "Fix Amount" jika nominal tagihan tidak
                                  dapat diubah ketika orang tua melakukan
                                  pembayaran.
                                </p>
                                <p>
                                  Pilih "Variable Amount" jika nominal tagihan
                                  dapat diubah ketika orang tua melakukan
                                  pembayaran.
                                </p>
                              </div>
                            }
                          >
                            <ExclamationCircleOutlined
                              style={{
                                margin: 0,
                                color: '#4EAFFF',
                                cursor: 'pointer',
                              }}
                            />
                          </Popover>
                        </span>
                      }
                      name="fix_payment"
                    >
                      <Radio.Group>
                        <Radio value={1}>Fix Amount</Radio>
                        <Radio value={0}>Variable Amount</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </section>
                  <Form.List name="instalments">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          {fields.map((field, idx) => {
                            return (
                              <section>
                                <h3>
                                  Cicilan Ke -{' '}
                                  {installment?.[field.name]
                                    ?.instalment_repition + 1 || idx + 1}
                                </h3>
                                <Row gutter={[8]}>
                                  <Col span="8">
                                    <InputWrapper
                                      type="number"
                                      prefix="Rp"
                                      name="nominal"
                                      label="Nominal Tagihan"
                                      formItemProps={{
                                        name: [field.name, 'total_amount'],
                                        rules: [
                                          {
                                            validator: (x, v) => {
                                              if (
                                                v >=
                                                (installment[field.name]
                                                  ?.paid_amount || 0)
                                              ) {
                                                return Promise.resolve();
                                              } else {
                                                return Promise.reject(
                                                  new Error(
                                                    'Nominal harus lebih besar daripada yang sudah dibayar'
                                                  )
                                                );
                                              }
                                            },
                                          },
                                        ],
                                      }}
                                      formatter={(value) =>
                                        `Rp ${value}`.replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ','
                                        )
                                      }
                                      parser={(value) =>
                                        value.replace(/\Rp\s?|(,*)/g, '')
                                      }
                                    />
                                  </Col>
                                  <Col span="8">
                                    <InputWrapper
                                      type="number"
                                      prefix="Rp"
                                      formItemProps={{
                                        name: [field.name, 'paid_amount'],
                                      }}
                                      label="Sudah Bayar"
                                      disabled
                                      formatter={(value) =>
                                        `Rp ${value}`.replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ','
                                        )
                                      }
                                      parser={(value) =>
                                        value.replace(/\Rp\s?|(,*)/g, '')
                                      }
                                    />
                                  </Col>
                                  <Col span="8">
                                    <Form.Item
                                      label={'Tanggal Jatuh Tempo'}
                                      name={[field.name, 'instalment_date']}
                                      style={{ width: '100%' }}
                                      rules={[
                                        {
                                          message: 'Tanggal tidak boleh kosong',
                                          required: true,
                                        },
                                      ]}
                                      required={true}
                                    >
                                      <DatePicker style={{ width: '100%' }} />
                                    </Form.Item>
                                  </Col>
                                  <Col span="22">
                                    <InputWrapper
                                      type="text"
                                      placeholder="Catatan"
                                      formItemProps={{
                                        name: [field.name, 'notes'],
                                      }}
                                      label="Catatan"
                                    />
                                  </Col>
                                  {(idx > installment?.length - 1 ||
                                    installment[idx]?.paid_amount === 0) &&
                                    idx !== 0 && (
                                      <Col span="2">
                                        <Form.Item label={<p hidden>Hapus</p>}>
                                          <Button
                                            type="text"
                                            onClick={() => {
                                              remove(field.name);
                                            }}
                                            icon={
                                              <DeleteOutlined
                                                style={{ fontSize: '20px' }}
                                              />
                                            }
                                          />
                                        </Form.Item>
                                      </Col>
                                    )}
                                </Row>
                              </section>
                            );
                          })}

                          <Button
                            icon={<PlusCircleOutlined />}
                            onClick={() => {
                              add();
                            }}
                          >
                            Tambah Cicilan
                          </Button>
                        </>
                      );
                    }}
                  </Form.List>
                </section>
              </Form>

              {/* <section style={{ marginTop: '2rem' }}>
        <Alert
          message="Perhatian!"
          description="Nominal cicilan berikutnya akan menjadi Rp3,700,000"
          type="info"
          showIcon
        />
      </section> */}
            </>
          )}
          {displayItem === 2 && <Cancel form={form} />}
        </section>
      )}
      {displayItem === 3 && (
        <>
          <YellowCard>
            <span>Nama Murid</span>
            <h2 style={{ color: themeColor.primary, margin: 0 }}>
              {detail?.student.name}
            </h2>
          </YellowCard>

          <section style={{ margin: '2rem 0' }}>
            <InfoCard
              sideColor="blue"
              title="Total"
              content={`Rp${(totalAmount || 0).toLocaleString()}`}
            />
          </section>
          <Form
            form={form}
            layout="vertical"
            onFinish={(v) => {
              const payload = {
                ...v,
                date: dateApresiasi,
              };
              if (editApresiasi.state) {
                _handleEditApresiasi(editApresiasi?.data?._id, payload);
              } else {
                _handleAddApresiasi({
                  ...payload,
                  student: detail?.student?._id,
                  bill_type: 'PPDB',
                  bill_id: detail?._id,
                  unit: unitId,
                });
              }
            }}
          >
            <Row gutter={[18, 8]}>
              <Col span="12">
                <InputWrapper
                  type="number"
                  prefix="Rp"
                  name="total_amount"
                  label="Nominal"
                  placeholder="Rp 500.000"
                  formatter={(value) =>
                    `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
                />
              </Col>
              <Col span="12">
                <Form.Item label="Tanggal Diberikan" name="date">
                  <DatePicker
                    style={{ width: '100%' }}
                    onChange={(_, dateString) => {
                      setDateApresiasi(dateString);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span="24">
                <Form.Item name="notes" label="Notes">
                  <Input.TextArea
                    rows={4}
                    placeholder="Masukan catatan"
                    onChange={(e) => {
                      const value = e.target.value;
                      console.log(value);
                      form.setFieldsValue({ notes: value });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </Drawer>
  );
};

export default DetailTagihanPPDB;
