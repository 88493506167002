import { StarFilled } from '@ant-design/icons';
import moment from 'moment';
import queryString from 'querystring';

export const statusMurid = {
  0: {
    status: 'Default',
    color: 'orange',
  },
  1: {
    status: 'AKTIF',
    color: 'blue',
  },
  2: {
    status: 'TIDAK AKTIF',
    color: 'red',
  },
  3: {
    status: 'LULUS',
    color: 'green',
  },
};

export const tipeMurid = {
  0: {
    text: 'Default',
    color: 'red',
  },
  1: {
    text: 'Murid Standard',
    color: 'blue',
  },
  2: {
    text: (
      <span>
        <StarFilled /> &nbsp;ASAK
      </span>
    ),
    color: 'orange',
  },
};

export const billType = {
  SPP: 'spp',
  'PPDB Instalment': 'ppdb-instalment',
  General: 'general',
  Tabungan: 'savings-allocation',
};

export const ArrayTanggal = (limit) =>
  Array.from({ length: limit }, (_, i) => i + 1);

export const monthDictionary = {
  1: 'Januari',
  2: 'Februari',
  3: 'Maret',
  4: 'April',
  5: 'Mei',
  6: 'Juni',
  7: 'Juli',
  8: 'Agustus',
  9: 'September',
  10: 'Oktober',
  11: 'November',
  12: 'Desember',
};

export const goToSearchQuery = (qKey, value, filterQuery, history) => {
  Object.keys(filterQuery).forEach((key) => {
    if (!filterQuery[key]) delete filterQuery[key];
  });
  history.push({
    search: queryString.stringify({
      ...filterQuery,
      [qKey]: value,
    }),
  });
};
