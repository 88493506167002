import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Form,
  Button,
  Space,
  message,
  Select,
  Steps,
  DatePicker,
  Radio,
  Empty,
  Popover,
  Input,
} from 'antd';
import moment from 'moment';
import {
  StyledContentWrapper,
  StyledPageContainer,
  StyledFixedFooter,
} from '../style';
import IC_BACK from 'assets/icons/chevron-left.svg';
import { useHistory } from 'react-router-dom';
import { InputWrapper } from 'components/shared';
import { useStore } from 'store';
import { getSppBillItems, submitCreateStudent } from 'utils/fetch';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { InfoCard } from 'components/fragments/card';
import { PhoneCodesDropdown } from 'components/form';

const FormDataMurid = ({
  setDob,
  gradeList,
  schoolYears,
  requiredFields,
  setRequiredFields,
}) => {
  return (
    <Row gutter={[24, 12]}>
      <Col span={12}>
        <Form.Item label="Jenis Kelamin" name="gender">
          <Select placeholder="Pilih jenis kelamin">
            <Select.Option value={1}>Laki - Laki</Select.Option>
            <Select.Option value={2}>Perempuan</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Tanggal Lahir" name="dob">
          <DatePicker
            style={{ width: '100%' }}
            format={'MM/DD/YYYY'}
            onChange={(val, dateString) => {
              setDob(dateString);
            }}
          />
        </Form.Item>
        <InputWrapper
          type="text"
          name="virtual_account"
          label="Virtual Account"
          placeholder="Masukan nomor virtual account"
          // formItemProps={{
          //   required: true,
          //   rules: [
          //     { required: true, message: 'Virtual account tidak boleh kosong' },
          //   ],
          //   validateTrigger: ['onBlur', 'onChange'],
          // }}
          onChange={(e) => {
            // setRequiredFields({
            //   ...requiredFields,
            //   virtual_account: e.target.value,
            // })
          }}
        />
        <Form.Item label="Jenis Murid" name="type">
          <Radio.Group>
            <Radio value={1}>Murid Standar</Radio>
            <Radio value={2}>ASAK</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Tahun Ajaran" name="school_year" required>
          <Select
            placeholder="Pilih Tahun Ajaran"
            // onSelect={(e) => console.log(e)}
            onChange={(e) =>
              setRequiredFields({
                ...requiredFields,
                school_year: e,
              })
            }
          >
            {schoolYears?.map((std) => {
              return <Select.Option value={std._id}>{std.name}</Select.Option>;
            })}
          </Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <InputWrapper
          type="text"
          name="name"
          label="Nama Murid"
          placeholder="Masukan nama murid"
          formItemProps={{
            required: true,
            rules: [{ required: true, message: 'Nama tidak boleh kosong' }],
            validateTrigger: ['onBlur', 'onChange'],
          }}
          onChange={(e) =>
            setRequiredFields({ ...requiredFields, name: e.target.value })
          }
        />
        <InputWrapper
          type="text"
          name="birth_location"
          label="Tempat Lahir"
          placeholder="Masukan tempat lahir"
          // formItemProps={{
          //   required: true,
          //   rules: [
          //     { required: true, message: 'Tempat lahir tidak boleh kosong' },
          //   ],
          //   validateTrigger: ['onBlur', 'onChange'],
          // }}
        />
        <InputWrapper
          type="text"
          name="nik"
          label="NIS"
          placeholder="Masukan nomor NIS"
        />
        <Form.Item label="Kelas" name="grade">
          <Select placeholder="Pilih kelas">
            {gradeList?.map((grade) => {
              return (
                <Select.Option value={grade._id}>{grade.name}</Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
};

const FormDataWali = ({ callingCodes, setPhoneCodes, phoneCodes }) => {
  return (
    <>
      <section>
        <h2>Wali 1</h2>
        <Row gutter={[24, 12]}>
          <Col span={12}>
            <InputWrapper
              type="text"
              name="name1"
              label="Nama Wali 1"
              placeholder="Masukan nama wali"
              // formItemProps={{
              //   required: true,
              //   rules: [
              //     { required: true, message: 'Nama wali tidak boleh kosong' },
              //   ],
              //   validateTrigger: ['onBlur', 'onChange'],
              // }}
            />
            <Form.Item
              label="Status"
              name="status1"
              // required
              // rules={[
              //   { required: true, message: 'Status wali tidak boleh kosong' },
              // ]}
              // validateTrigger={['onBlur', 'onChange']}
            >
              <Select placeholder="Pilih status wali">
                <Select.Option value={1}>Ibu</Select.Option>
                <Select.Option value={2}>Ayah</Select.Option>
                <Select.Option value={3}>Kakek</Select.Option>
                <Select.Option value={4}>Nenek</Select.Option>
                <Select.Option value={5}>Paman</Select.Option>
                <Select.Option value={6}>Bibi</Select.Option>
                <Select.Option value={7}>Lainnya</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Email Wali Murid 1" name="email1">
              <Input type="email" placeholder="Masukan alamat email wali" />
            </Form.Item>
          </Col>
        </Row>
      </section>

      <section>
        <h2>Wali 2</h2>
        <Row gutter={[24, 12]}>
          <Col span={12}>
            <InputWrapper
              type="text"
              name="name2"
              label="Nama Wali 2"
              placeholder="Masukan nama wali"
              // formItemProps={{
              //   required: true,
              //   rules: [
              //     { required: true, message: 'Nama wali tidak boleh kosong' },
              //   ],
              //   validateTrigger: ['onBlur', 'onChange'],
              // }}
            />
            <Form.Item
              label="Status"
              name="status2"
              required
              // rules={[
              //   { required: true, message: 'Status wali tidak boleh kosong' },
              // ]}
              // validateTrigger={['onBlur', 'onChange']}
            >
              <Select placeholder="Pilih status wali">
                <Select.Option value={1}>Ibu</Select.Option>
                <Select.Option value={2}>Ayah</Select.Option>
                <Select.Option value={3}>Kakek</Select.Option>
                <Select.Option value={4}>Nenek</Select.Option>
                <Select.Option value={5}>Paman</Select.Option>
                <Select.Option value={6}>Bibi</Select.Option>
                <Select.Option value={7}>Lainnya</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Email Wali Murid 2" name="email2">
              <Input type="email" placeholder="Masukan alamat email wali" />
            </Form.Item>
          </Col>
        </Row>
      </section>
    </>
  );
};

const FormDataSPP = () => {
  return (
    <>
      <h2>Detail Nominal Spp</h2>
      <InputWrapper
        type="number"
        prefix="Rp"
        name="spp_amount"
        label="Total SPP"
        placeholder="Rp 500.000"
        formatter={(value) =>
          `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
        parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
        style={{ width: '35%' }}
      />
    </>
  );
};

const TambahMurid = () => {
  const [currentForm, setCurrentForm] = useState(0);
  const history = useHistory();
  const [form] = Form.useForm();
  const {
    state: {
      activeSchoolYear,
      selectedUnit,
      listTahunAjaran,
      listKelas,
      callingCodes,
    },
    handleUserData,
  } = useStore();
  const [dob, setDob] = useState('');
  const [studentData, setStudentData] = useState(null);
  const [guardianData, setGuardianData] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [sppItems, setSppItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [phoneCodes, setPhoneCodes] = useState({
    wali1: '62',
    wali2: '62',
  });
  const [requiredFields, setRequiredFields] = useState({
    name: '',
    school_year: '',
  });
  const unitId = selectedUnit?._id;
  const schoolYearId = activeSchoolYear?._id;

  useEffect(() => {
    if (selectedUnit && activeSchoolYear && currentForm === 2) {
      fetchSppBillItems(selectedUnit?._id, activeSchoolYear?._id);
    }
  }, [selectedUnit, activeSchoolYear, currentForm]);

  useEffect(() => {
    if (sppItems) {
      const newItems = sppItems.map((item) => ({
        master_bill_item: item.master_bill_item._id,
      }));
      form.setFieldsValue({
        items: newItems,
      });
    }
  }, [sppItems]);

  const handleSubmit = (values) => {
    // console.log(values);
    setLoadingSubmit(true);
    submitCreateStudent(values, unitId, schoolYearId)
      .then((res) => {
        console.log(res);
        message.success('Berhasil menambahkan data murid');
        history.push(`/daftar-murid?unit=${selectedUnit?.name.toLowerCase()}`);
      })
      .catch((err) => {
        console.log(err.response);
        message.error(err?.response?.data?.m || 'Gagal menambahkan murid baru');
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  };

  const fetchSppBillItems = async (unitId, schoolYearId) => {
    try {
      const { sppItems } = await getSppBillItems(unitId, schoolYearId);
      setSppItems(sppItems);
      console.log(sppItems);
    } catch (err) {
      console.log(err?.response);
      message.error(err?.response?.data?.m || 'Failed get spp bill items');
    }
  };

  const nextDisabled = () => {
    const formValue = form.getFieldValue();
    if (formValue.name) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <StyledPageContainer>
      <StyledContentWrapper>
        <div
          onClick={() => history.goBack()}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '3.5rem',
            cursor: 'pointer',
          }}
        >
          <img
            src={IC_BACK}
            alt="back"
            style={{ marginRight: '0.75rem' }}
            width="18"
            height="18"
          />
          <span>Kembali</span>
        </div>
        <h2>Tambah Murid</h2>
        <Steps style={{ margin: '2rem 0' }} current={currentForm}>
          <Steps.Step title={'Data Murid'} />
          <Steps.Step title={'Data Wali Murid'} />
          <Steps.Step title={'Data SPP'} />
        </Steps>
        <div style={{ marginTop: '2rem' }}>
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              type: 1,
              sppStatus: -1,
            }}
            onFinish={(val) => {
              const payload = {
                ...studentData,
                guardians: guardianData,
                ...val,
                spp_amount: val.items?.reduce(
                  (acc, curr) => acc + (curr.amount || 0),
                  0
                ),
                spp_items: val.items?.map((item) => ({
                  ...item,
                  amount: String(item.amount || 0),
                })),
                sppFromBegining: val.sppFromBegining === 0 ? false : true,
                sppStatus: val.sppStatus,
                school_year: studentData.school_year,
              };

              delete payload['items'];
              console.log(payload);
              const keys = Object.keys(payload);
              keys.forEach((key) => {
                if (!payload[key] && key !== 'sppStatus') {
                  delete payload[key];
                }
              });
              handleSubmit(payload);
            }}
            onValuesChange={(changedValue, value) => {
              if (changedValue?.items) {
                setTotal(
                  value?.items.reduce((acc, curr) => {
                    return acc + Number(curr?.amount || 0);
                  }, 0)
                );
              }
            }}
          >
            {currentForm === 0 && (
              <FormDataMurid
                setDob={setDob}
                schoolYears={listTahunAjaran}
                gradeList={listKelas}
                requiredFields={requiredFields}
                setRequiredFields={setRequiredFields}
              />
            )}
            {currentForm === 1 && (
              <FormDataWali
                setPhoneCodes={setPhoneCodes}
                phoneCodes={phoneCodes}
                callingCodes={callingCodes}
              />
            )}
            {currentForm === 2 && (
              <>
                <h2>Detail Nominal Spp</h2>
                {sppItems?.length ? (
                  <>
                    <Form.List name="items">
                      {(fields) => {
                        return fields.map((field, idx) => {
                          return (
                            <Row
                              gutter={[8, 0]}
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <Col span="8">
                                <p>{sppItems[idx]?.master_bill_item.name}</p>
                              </Col>
                              <Col span="16">
                                <InputWrapper
                                  type="number"
                                  prefix="Rp"
                                  formItemProps={{
                                    name: [field.name, 'amount'],
                                  }}
                                  placeholder="Rp 500.000"
                                  formatter={(value) =>
                                    `Rp ${value}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ','
                                    )
                                  }
                                  parser={(value) =>
                                    value.replace(/\Rp\s?|(,*)/g, '')
                                  }
                                />
                              </Col>
                            </Row>
                          );
                        });
                      }}
                    </Form.List>

                    {/* UPDATE SPP */}

                    <section style={{ marginTop: '2rem' }}>
                      <Form.Item
                        label={
                          <span>
                            Buat Tagihan SPP &nbsp;{' '}
                            {/* <Popover
                              trigger={['hover']}
                              content={
                                <div>
                                  <p>
                                    Pilih "Ya" jika ingin membuat tagihan dari
                                    awal tahun ajaran.
                                  </p>
                                  <p>
                                    Pilih "Tidak" jika ingin membuat SPP dari
                                    bulan Juli sampai akhir tahun ajaran.
                                  </p>
                                </div>
                              }
                            >
                              <ExclamationCircleOutlined
                                style={{
                                  margin: 0,
                                  color: '#4EAFFF',
                                  cursor: 'pointer',
                                }}
                              />
                            </Popover> */}
                          </span>
                        }
                        name="sppStatus"
                      >
                        <Radio.Group>
                          <Radio value={0}>Buat Dari Awal Tahun Ajaran</Radio>
                          <Radio value={1}>
                            Buat Dari Bulan {moment().format('MMMM')}
                          </Radio>
                          <Radio value={-1}>Tidak Sama Sekali</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </section>

                    <section style={{ width: '50%', marginTop: '2rem' }}>
                      <InfoCard
                        sideColor="green"
                        title="Total"
                        content={`Rp${(total || 0).toLocaleString()}`}
                      />
                    </section>
                  </>
                ) : (
                  <Empty description="Tidak ada data item tagihan pada tahun ajaran ini, silakan cek pengaturan tipe tagihan." />
                )}
              </>
            )}
          </Form>
        </div>

        <StyledFixedFooter>
          <Space>
            <Button
              loading={loadingSubmit}
              onClick={() => {
                if (currentForm > 0) {
                  setCurrentForm(currentForm - 1);
                } else {
                  history.goBack();
                }
              }}
            >
              Kembali
            </Button>
            {currentForm === 2 && (
              <Button
                type="primary"
                onClick={() => form.submit()}
                loading={loadingSubmit}
              >
                Simpan
              </Button>
            )}
            {currentForm !== 2 && (
              <Button
                type="primary"
                onClick={() => {
                  setCurrentForm(currentForm + 1);
                  const formValue = form.getFieldsValue();
                  if (currentForm === 0) {
                    // const dob = moment(formValue.dob).date();
                    setStudentData({ ...formValue, dob });
                  } else if (currentForm === 1) {
                    const guardians = [
                      {
                        index: 1,
                        name: formValue.name1 || '',
                        email: formValue.email1,
                        status: formValue.status1 || 1,
                      },
                      {
                        index: 2,
                        name: formValue.name2 || '',
                        email: formValue.email2,
                        status: formValue.status2 || 2,
                      },
                    ];

                    const filteredGuardians = guardians.map((el) => {
                      const keys = Object.keys(el);

                      keys.forEach((key) => {
                        if (!el[key] || el[key] === undefined) {
                          delete el[key];
                        }
                      });

                      return el;
                    });

                    // console.log(filteredGuardians);
                    setGuardianData(filteredGuardians);
                  }
                }}
                disabled={Object.keys(requiredFields).some(
                  (key) => !requiredFields[key]
                )}
              >
                Berikutnya
              </Button>
            )}
          </Space>
        </StyledFixedFooter>
      </StyledContentWrapper>
    </StyledPageContainer>
  );
};

export default TambahMurid;
