import { DownloadOutlined, FileExclamationOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Menu,
  message,
  Progress,
  Row,
  Space,
  Popover,
  Spin,
} from 'antd';
import { Modal, PageHeader } from 'components/shared';
import React, { useEffect, useState } from 'react';
import { useStore } from 'store';
import {
  fetchTahunAjaran,
  getRekapAllUnit,
  getRekapUnit,
  getSPPReport,
  updateRekap,
} from 'utils/fetch';
import { CardDashboard } from './keseluruhan';
import { ProgressContainer, ProgressTitle } from './style';

import IC_TUNGGAKAN from 'assets/icons/tunggakan.svg';
import IC_DANA_MASUK from 'assets/icons/dana-masuk.svg';
import IC_REFUND from 'assets/icons/Refund.svg';
import moment from 'moment';
import exportExcel from 'utils/helpers/xlsx';

const RekapSPP = () => {
  const {
    handleBreadcrumb,
    handleSiderActive,
    state: { unitData, activeSchoolYear, userData },
  } = useStore();
  const [sppReport, setSppReport] = useState(null);
  const [modal, setModal] = useState({
    state: false,
    data: null,
    table: '',
  });
  const [selectedUnit, setSelectedUnit] = useState(userData?.unit || '');
  const [selectedTahun, setSelectedTahun] = useState(null);
  const [listTahunAjaran, setListTahunAjaran] = useState([]);
  const [mustPaidMonth, setMustPaidMonth] = useState({
    name: 'Januari 2021',
    value: '1-1-2021',
  });
  const [paidThisMonth, setPaidThisMonth] = useState({
    name: 'Februari 2021',
    value: '1-2-2021',
  });
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [lastUpdated, setLastUpdated] = useState('');
  const [pembayaranBulanIni, setPembayaranBulanIni] = useState(null);
  const arrayUnit = Object.keys(unitData).map((key) => unitData[key]);

  useEffect(() => {
    fetchListTahun();
  }, []);

  useEffect(() => {
    if (userData?.unit) {
      setSelectedUnit(userData.unit);
    }
  }, [userData]);

  useEffect(() => {
    if (activeSchoolYear?._id) {
      setSelectedTahun(activeSchoolYear);
      const next = new Date(activeSchoolYear.start_date);

      const date = {
        name: `${moment(next).format('MMMM YYYY')}`,
        value: next,
      };
      setPaidThisMonth(date);
      setMustPaidMonth(date);
    }
  }, [activeSchoolYear]);

  useEffect(() => {
    const newBreadcrumb = [
      {
        linkTo: '/dashboard/rekap/spp',
        node: (
          <div>
            <p style={{ margin: 0, lineHeight: 0.75 }}>Dashboard SPP</p>
            <span style={{ fontSize: '1.15rem', color: '#262626', margin: 0 }}>
              Update terakhir:{' '}
              {lastUpdated
                ? moment(lastUpdated).format('DD/MM/YYYY, hh:mm A')
                : '-'}
            </span>
          </div>
        ),
      },
    ];
    handleBreadcrumb(newBreadcrumb);
    handleSiderActive(['3.1']);
  }, [lastUpdated]);

  useEffect(() => {
    if (selectedTahun) {
      if (selectedUnit) {
        fetchDataUnit();
      } else {
        fetchDataAllUnit();
      }
    }
  }, [selectedTahun, selectedUnit]);

  // useEffect(() => {
  //   const query = `unitId=${selectedUnit?._id || ''}&schoolYearId=${
  //     selectedTahun?._id || ''
  //   }&month=${paidThisMonth?.value || ''}`;

  //   getSPPReport(query, 'monthly-installment')
  //     .then((val) => {
  //       console.log(val);
  //       setPembayaranBulanIni(val);
  //     })
  //     .catch((err) => console.log(err, 'error monthly instalment'));
  // }, [paidThisMonth]);

  const fetchDataAllUnit = () => {
    const query = selectedTahun
      ? `school_year=${selectedTahun?._id || ''}`
      : '';

    setLoading(true);
    getRekapAllUnit('spp-bill-dashboard', query)
      .then((res) => {
        console.log(res.result, 'this is result');
        setSppReport(res.result);
        setLastUpdated(res.lastUpdated);
      })
      .catch((err) => {
        console.log(err.response);
        message.error(err?.response?.data?.m);
      })
      .finally(() => setLoading(false));
  };

  const fetchDataUnit = () => {
    const query = selectedTahun
      ? `school_year=${selectedTahun?._id || ''}`
      : '';

    setLoading(true);
    getRekapUnit('spp-bill-dashboard', selectedUnit?._id, query)
      .then((res) => {
        setSppReport(res.result);
        setLastUpdated(res.lastUpdated);
      })
      .catch((err) => {
        console.log(err.response);
        message.error(err?.response?.data?.m);
      })
      .finally(() => setLoading(false));
  };

  const handleUpdateData = () => {
    setLoadingUpdate(true);
    updateRekap('spp-bill-dashboard')
      .then((res) => {
        if (res) {
          message.success('Berhasil memperbaharui data rekap SPP');

          if (selectedUnit) {
            fetchDataUnit();
          } else {
            fetchDataAllUnit();
          }
        }
      })
      .catch((err) => {
        console.log(err.response);
        message.error('Gagal memperbaharui data rekap SPP');
      })
      .finally(() => setLoadingUpdate(false));
  };

  const fetchListTahun = () => {
    fetchTahunAjaran()
      .then((res) => {
        console.log(res);
        setListTahunAjaran(res.schoolYears);
      })
      .catch((err) => console.log(err, 'error list tahun'));
  };

  const MenuUnit = ({ onClick }) => {
    return (
      <Menu
        style={{
          boxShadow:
            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        }}
      >
        <Menu.Item key={'all-unit'} onClick={() => onClick('')}>
          Semua Unit
        </Menu.Item>
        {arrayUnit.map((unit) => (
          <Menu.Item key={unit._id} onClick={() => onClick(unit)}>
            {unit.name}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const MenuTahun = ({ onClick, list = [{ name: '' }] }) => {
    return (
      <Menu
        style={{
          boxShadow:
            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        }}
      >
        {list.map((ta, idx) => {
          return (
            <Menu.Item key={idx} onClick={() => onClick(ta)}>
              {ta.name}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  const parsedResultPembayaran = (data = []) => {
    const parsed = [
      [
        'No',
        'Nama Murid',
        'NIS',
        'Nominal',
        'Nama Tagihan',
        'Kelas',
        'Unit',
        'Tanggal Pembayaran',
      ],
    ];

    data.forEach((dt, idx) => {
      const array = [
        idx + 1,
        dt.student_name,
        dt.student_nik,
        dt.amount,
        dt.bill_name,
        dt.student?.grade?.name || 'Murid Tanpa Kelas',
        dt.unit_name,
        moment(dt?.payment_date).format('DD/MM/YYYY'),
      ];

      parsed.push(array);
    });

    return parsed;
  };
  const parsedResultTunggakan = (data = []) => {
    const parsed = [
      [
        'No',
        'Nama Murid',
        'NIS',
        'Tunggakan',
        'Jumlah Tunggakan',
        'Kelas',
        'Unit',
        'Tanggal Pembayaran',
      ],
    ];

    data.forEach((dt, idx) => {
      const array = [
        idx + 1,
        dt.student_name,
        dt?.student_nik || '-',
        dt?.total_debt || 0,
        dt?.total_bill_count || 0,
        dt.grade_name,
        dt.unit_name,
        dt.payment_date ? moment(dt?.payment_date) : '-',
      ];

      parsed.push(array);
    });

    return parsed;
  };

  const parsedResultKategori = (data = []) => {
    const parsed = [['No', 'Nama', 'Belum Bayar', 'Sudah Bayar', 'Total']];

    data.forEach((dt, idx) => {
      const array = [
        idx + 1,
        dt.item_name,
        dt.remaining_amount,
        dt.paid_amount,
        dt.total_amount,
      ];

      parsed.push(array);
    });

    return parsed;
  };

  const downloadLaporan = () => {
    const parsedPembayaran = parsedResultPembayaran(
      sppReport?.payment_monthly?.payment_list
    );
    const parsedTunggakan = parsedResultTunggakan(sppReport?.outstanding_list);
    const parsedKategori = parsedResultKategori(sppReport?.item_list);
    const parsedData = [
      {
        data: parsedPembayaran,
        sheetName: 'Total Pembayaran Bulan Ini',
      },
      {
        data: parsedTunggakan,
        sheetName: 'Total Tunggakan',
      },
      {
        data: parsedKategori,
        sheetName: 'Kategori',
      },
    ];
    exportExcel(parsedData, 'Laporan Rekap SPP', true);
  };

  return (
    <>
      {loading || loadingUpdate ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin />
        </div>
      ) : (
        <>
          <PageHeader>
            <Space>
              <Button
                icon={<FileExclamationOutlined />}
                onClick={handleUpdateData}
                loading={loadingUpdate}
              >
                Update Data
              </Button>

              <Dropdown
                overlay={
                  <MenuTahun
                    onClick={(v) => {
                      setSelectedTahun(v);
                      console.log(v);
                    }}
                    list={listTahunAjaran}
                  />
                }
              >
                <Button>{selectedTahun?.name}</Button>
              </Dropdown>

              <Dropdown
                disabled={userData?.type === 2}
                overlay={<MenuUnit onClick={(v) => setSelectedUnit(v)} />}
              >
                <Button>{selectedUnit?.name || 'Semua Unit'}</Button>
              </Dropdown>
              <Button
                icon={<DownloadOutlined />}
                type="primary"
                onClick={downloadLaporan}
              >
                Download Laporan
              </Button>
            </Space>
          </PageHeader>
          <section>
            <Card
              style={{
                backgroundColor: '#EDF7FF',
                border: '1px solid #A7D7FF',
              }}
            >
              <Row gutter={[4, 12]}>
                <Col span="6">
                  <span>Total Harus Bayar</span>
                  <h2>Rp{(sppReport?.total_amount || 0).toLocaleString()}</h2>
                </Col>
                <Col span="1">
                  <Divider
                    type="vertical"
                    orientation="center"
                    style={{ height: '100%' }}
                  />
                </Col>
                <Col span="6">
                  <span>Total Sudah Bayar</span>
                  <h2 style={{ color: '#1A7C47' }}>
                    Rp{(sppReport?.total_paid_amount || 0).toLocaleString()}
                  </h2>
                </Col>
                <Col span="1">
                  <Divider
                    type="vertical"
                    orientation="center"
                    style={{ height: '100%' }}
                  />
                </Col>
                <Col span="9">
                  <span>Total Sisa Bayar</span>
                  <h2 style={{ color: '#FF4D4F' }}>
                    Rp
                    {(sppReport?.total_remaining_amount || 0).toLocaleString()}
                  </h2>
                </Col>
              </Row>
              <span style={{ textTransform: 'uppercase' }}>
                TERHITUNG SEJAK{' '}
                {selectedTahun &&
                  `${moment(selectedTahun?.start_date).format(
                    'MMMM YYYY'
                  )} - ${moment(selectedTahun?.end_date).format('MMMM YYYY')}`}
              </span>
            </Card>
          </section>
          <section style={{ margin: '2rem 0' }}>
            <Row gutter={[12, 8]}>
              {/* <Col span="8">
                <CardDashboard
                  title="Total Harus Bayar Bulan Ini"
                  selectMonth={(v) => setMustPaidMonth(v)}
                  selectedMonth={mustPaidMonth?.name}
                  icon={IC_DANA_MASUK}
                  amount={sppReport?.total_pay_this_month || 0}
                  disableAction={true}
                  noFooter
                />
              </Col> */}
              <Col span="8">
                <CardDashboard
                  title="Total Pembayaran Bulan Ini"
                  selectMonth={(v) => setPaidThisMonth(v)}
                  selectedMonth={
                    sppReport?.payment_monthly?.total_payment_month
                  }
                  amount={sppReport?.payment_monthly?.total_payment_amount}
                  rincian={sppReport?.payment_monthly?.total_payment_count}
                  icon={IC_REFUND}
                  openModal={() =>
                    setModal({
                      state: true,
                      data: {
                        month: sppReport?.payment_monthly?.total_payment_month,
                        type: 'spp-bill-dashboard',
                        list: sppReport?.payment_monthly?.payment_list || [],
                      },
                      table: 'monthly-recap',
                      title: 'Total Pembayaran Bulan Ini',
                    })
                  }
                  disableAction={true}
                />
              </Col>
              <Col span="8">
                <CardDashboard
                  title="Total Tunggakan"
                  debt={true}
                  amount={sppReport?.outstanding_amount || 0}
                  rincian={sppReport?.outstanding_bills_count || 0}
                  icon={IC_TUNGGAKAN}
                  openModal={() =>
                    setModal({
                      state: true,
                      data: sppReport?.outstanding_list || [],
                      table: 'rekap-tunggakan',
                      title: 'Total Tunggakan SPP',
                    })
                  }
                />
              </Col>
            </Row>
          </section>

          <Card>
            <header
              style={{
                marginBottom: '1.25rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p style={{ margin: 0, fontWeight: '700', fontSize: '1.75rem' }}>
                Berdasarkan Item SPP
              </p>
            </header>
            {sppReport?.item_list.map((item) => {
              const percentage = (item.paid_amount / item.total_amount) * 100;
              return (
                <ProgressContainer>
                  <ProgressTitle
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span>{item.item_name}</span>
                    {item?.remaining_amount ? (
                      <span
                        style={{
                          fontWeight: '600',
                          fontSize: '14px',
                          lineHeight: '22px',
                          color: '#333333',
                        }}
                      >
                        Harus Bayar : Rp
                        {Number(item?.total_amount || 0).toLocaleString()}
                      </span>
                    ) : (
                      <span
                        style={{
                          fontWeight: '600',
                          fontSize: '14px',
                          lineHeight: '22px',
                          color: '#1A7C47',
                        }}
                      >
                        Lunas : Rp
                        {Number(item?.total_amount || 0).toLocaleString()}
                      </span>
                    )}
                  </ProgressTitle>
                  <Popover
                    placement="top"
                    content={
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          gap: '28px',
                          minWidth: '45rem',
                        }}
                      >
                        <div style={{ flex: 1, flexDirection: 'column' }}>
                          <div style={{ fontSize: '14px' }}>Sudah Bayar</div>
                          <div
                            style={{
                              color: '#1A7C47',
                              fontWeight: 600,
                              fontSize: '24px',
                              lineHeight: '36px',
                            }}
                          >
                            Rp {(item?.paid_amount || 0).toLocaleString()}
                          </div>
                        </div>
                        <div
                          style={{ width: '2px', backgroundColor: '#D9D9D9' }}
                        />
                        <div style={{ flex: 1, flexDirection: 'column' }}>
                          <div style={{ fontSize: '14px' }}>Belum Bayar</div>
                          <div
                            style={{
                              color: '#FF4D4F',
                              fontWeight: 600,
                              fontSize: '24px',
                              lineHeight: '36px',
                            }}
                          >
                            Rp {(item?.remaining_amount || 0).toLocaleString()}
                          </div>
                        </div>
                      </div>
                    }
                  >
                    <Progress
                      percent={percentage}
                      strokeColor="#66A785"
                      showInfo={false}
                    />
                  </Popover>
                </ProgressContainer>
              );
            }) || <p>Tidak ada data</p>}
          </Card>
        </>
      )}

      <Modal.RincianDashboard
        visible={modal.state}
        data={modal.data}
        onClose={() =>
          setModal({ state: false, data: null, table: '', title: '' })
        }
        table={modal.table}
        type="spp"
        modalTitle={modal.title || ''}
      />
    </>
  );
};

export default RekapSPP;
