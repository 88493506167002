import React, { useEffect, useState } from 'react';
import {
  Drawer,
  Form,
  Row,
  Col,
  Space,
  Button,
  Card,
  Divider,
  Tag,
  Table,
  InputNumber,
  DatePicker,
  Select,
  Alert,
  Input,
  message,
  Modal,
} from 'antd';
import { Footer } from './shared';
import { InputWrapper, PageHeader } from '..';
import moment from 'moment';
import { InfoCard } from 'components/fragments/card';
import { Loader } from 'components/atoms';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import YellowCard from 'components/atoms/card-base/yellow-card';
import {
  allocate,
  allocationHistory,
  editIncomingFunds,
  getDetailDanaMasuk,
  getOutstandingBills,
  hapusIncomingFund,
  hapusPembayaran,
  refundDanaMasuk,
} from 'utils/fetch';
import { billType, statusMurid, tipeMurid } from 'utils/helpers/common';
import truncateText from 'utils/helpers/truncate-text';
import { themeColor } from 'lib/theme';

const DetailDanaMasuk = (props) => {
  const [form] = Form.useForm();
  const { visible, onCancel, value, content, refetchData, ...rest } = props;
  const [displayItem, setDisplayItem] = useState(0);
  const [isEdit, setIsEdit] = useState({
    state: false,
    data: null,
  });
  const [billsAmount, setBillsAmount] = useState(0);
  const [outstandingBills, setOutstandingBills] = useState([]);
  const [funds, setFunds] = useState([]);
  const [allocation, setAllocation] = useState([]);
  const [student, setStudent] = useState(null);
  const [dateFunds, setDateFunds] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { id = '', unitId = '' } = value;
  const [disableButton, setDisableButton] = useState('');
  const [studentSavings, setStudentSavings] = useState(0);
  const [refundNotes, setRefundNotes] = useState('');
  const [refundAmount, setRefundAmount] = useState(0);
  const [refundDate, setRefundDate] = useState('');
  const [afterSubmit, setAfterSubmit] = useState({
    done: false,
    success: [],
    error: [],
  });

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setDisplayItem(0);
      setIsEdit({
        state: false,
        data: null,
      });
      setAfterSubmit({
        done: false,
        success: [],
        error: [],
      });
    }
  }, [visible]);

  useEffect(() => {
    if (id && visible && content === 1) {
      fetchAllocationHistory();
    }
  }, [visible, content, id]);

  useEffect(() => {
    if (id) {
      fetchDetail();
      fetchOutstandingBills();
    }
  }, [id]);

  const fetchAllocationHistory = () => {
    allocationHistory(unitId, 'fund', id.id)
      .then((value) => {
        console.log(value);
        setAllocation(value.payments);
      })
      .catch((err) => {
        console.log(err.response, 'error allocation history');
      });
  };

  const fetchDetail = () => {
    setLoading(true);
    getDetailDanaMasuk(unitId, id.id)
      .then((value) => {
        console.log(value, 'detail dana mashoook');
        if (value) {
          setStudent(value.student);
          setFunds(value.incomingFunds);
        }
      })
      .catch((err) => {
        console.log(err, 'this is error');
      })
      .finally(() => setLoading(false));
  };

  const fetchOutstandingBills = () => {
    getOutstandingBills(id.id)
      .then((res) => {
        setOutstandingBills(res.bills);
        console.log(res, 'outstanding bills');
        const allocationObj = res.bills.map((bill) => ({
          _id: bill._id,
          allocation: 0,
          remaining: bill.remaining,
          type: bill.type,
          date: moment(),
        }));
        console.log(allocationObj);
        form.setFieldsValue({
          bills: allocationObj,
          savings: res.student?.savings,
          alokasiTabungan: 0, // update 2 september
          allocate_savings_date: moment(),
        });
        setBillsAmount(res.billsAmount);
        setStudentSavings(res.student?.savings);
      })
      .catch((err) => console.log(err, 'error fetch outstanding bills'));
  };

  useEffect(() => {
    if (isEdit.state) {
      setDateFunds(new Date(isEdit.data.date).toLocaleDateString());
      form.setFieldsValue({
        amount: isEdit?.data?.amount,
        date: moment(isEdit?.data?.date),
        notes: isEdit?.data?.notes,
      });
    }
  }, [isEdit]);

  const handleSubmitEditIncomingFunds = (values) => {
    console.log(values);
    editIncomingFunds(unitId, id.id, isEdit.data._id, values)
      .then((res) => {
        console.log(res);
        fetchDetail();
        refetchData();
        setIsEdit({
          state: false,
          data: null,
        });
      })
      .catch((err) => console.log(err.response));
  };

  const handleDeleteIncomingFund = (unitId, studentId, id) => {
    Modal.confirm({
      title: `Apakah anda yakin akan menghapus rincian dana masuk?`,
      onOk: () => {
        hapusIncomingFund(unitId, studentId, id)
          .then((res) => {
            console.log(res, 'success');
            fetchDetail();
            refetchData();
            message.success('Berhasil menghapus incoming funds');
          })
          .catch((err) => {
            console.log(err.response);
            message.error(err?.response?.data?.m || 'Gagal menghapus');
          });
      },
      okText: 'Hapus',
      cancelText: 'Batal',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
    });
  };

  const handleDeleteHistoryFund = (paymentId, type) => {
    Modal.confirm({
      title: `Apakah anda yakin akan menghapus riwayat dana masuk?`,
      onOk: () => {
        hapusPembayaran(unitId, paymentId, billType[type])
          .then((res) => {
            console.log(res, 'success');
            fetchDetail();
            refetchData();
            fetchAllocationHistory();
            message.success('Berhasil menghapus riwayat alokasi');
          })
          .catch((err) => {
            console.log(err.response);
            message.error(err?.response?.data?.m || 'Gagal menghapus');
          });
      },
      okText: 'Hapus',
      cancelText: 'Batal',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
    });
  };

  const tableColumnRiwayat = [
    {
      title: 'Tanggal Alokasi',
      dataIndex: 'date',
      key: 'date',
      render: (data) => <span>{moment(data).format('DD MMM YYYY')}</span>,
    },
    {
      title: 'Nama Tagihan',
      dataIndex: 'name',
      key: 'name',
      render: (data) => {
        return <span>{data ? data : '-'}</span>;
      },
    },
    {
      title: 'Nominal',
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (data) => <span>Rp {(data || 0).toLocaleString()}</span>,
    },
    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      align: 'center',
      render: (id, record) => (
        <Space>
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() => {
              console.log(record);
              handleDeleteHistoryFund(id, record.bill_type);
            }}
          >
            Hapus
          </Button>
        </Space>
      ),
    },
  ];

  const tableColumnRincian = [
    {
      title: 'Tanggal Masuk Tabungan',
      dataIndex: 'date',
      key: 'date',
      render: (data) => <span>{moment(data).format('DD MMM YYYY')}</span>,
    },
    {
      title: 'Catatan',
      dataIndex: 'notes',
      key: 'notes',
      render: (data) => <span>{data || '-'}</span>,
    },
    {
      title: 'Nominal',
      dataIndex: 'amount',
      key: 'amount',
      render: (data) => <span>Rp {(data || 0).toLocaleString()}</span>,
    },
    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      align: 'center',
      render: (id, record) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() =>
              setIsEdit({
                state: true,
                data: record,
              })
            }
          >
            Edit
          </Button>
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() =>
              handleDeleteIncomingFund(record.unit, record.student, id)
            }
          >
            Hapus
          </Button>
        </Space>
      ),
    },
  ];

  const InfoMurid = () => (
    <>
      <YellowCard>
        <Row>
          <Col span="12">
            <h2 style={{ color: themeColor.primary }}>{student?.name || ''}</h2>
            <Space>
              <Tag color={statusMurid[student?.status || 0].color}>
                {statusMurid[student?.status || 0].status}
              </Tag>
              {/* <Tag color={tipeMurid[student?.type || 0].color}>
                {tipeMurid[student?.type || 0].text}
              </Tag> */}
            </Space>
          </Col>
          <Col span="1">
            <Divider type="vertical" style={{ height: '100%' }} />
          </Col>
          <Col span="11">
            <Row gutter={[8, 8]}>
              <Col span="12">NIS</Col>
              <Col span="12">{student?.nik || '-'}</Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span="12">Kelas</Col>
              <Col span="12">{student?.grade?.name || 'Murid Tanpa Kelas'}</Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span="12">Nomor VA</Col>
              <Col span="12">{student?.virtual_account || '-'}</Col>
            </Row>
          </Col>
        </Row>
      </YellowCard>

      {content < 2 ? null : (
        <Row gutter={[18, 0]} style={{ marginTop: '2rem' }}>
          <Col span="12">
            <InfoCard
              title="Total Dana Masuk Belum Alokasi"
              content={
                student?.funds
                  ? `Rp${(student?.funds || 0).toLocaleString()}`
                  : '-'
              }
              sideColor="green"
            />
          </Col>
          <Col span="12">
            <InfoCard
              title="Total Tunggakan"
              content={
                billsAmount ? `-Rp${(billsAmount || 0).toLocaleString()}` : '-'
              }
              fontColor={billsAmount ? 'red' : ''}
              sideColor="red"
            />
          </Col>
        </Row>
      )}
    </>
  );

  const Detail = () => (
    <>
      <InfoMurid />
      <section style={{ marginTop: '2rem' }}>
        <h3>{content === 1 ? 'Riwayat Alokasi' : 'Rincian Dana Masuk'}</h3>
        <Row
          gutter={[18, 0]}
          style={{ marginTop: '2rem', marginBottom: '1rem' }}
        >
          <Col span="12">
            <InfoCard
              title="Total Dana Masuk Belum Alokasi"
              content={
                student?.funds
                  ? `Rp${(student?.funds || 0).toLocaleString()}`
                  : '-'
              }
              sideColor="green"
            />
          </Col>
          <Col span="12">
            <InfoCard
              title="Total Tunggakan"
              content={
                student?.studentDebt
                  ? `-Rp${(student?.studentDebt || 0).toLocaleString()}`
                  : '-'
              }
              fontColor={student?.studentDebt ? 'red' : ''}
              sideColor="red"
              // fontColor={detail?.paid_status ? '' : 'red'}
            />
          </Col>
        </Row>
        <Table
          columns={content === 1 ? tableColumnRiwayat : tableColumnRincian}
          dataSource={content === 1 ? allocation : funds}
          pagination={{
            position: ['bottomLeft'],
            simple: true,
            // total: totalData && totalData,
            // onChange: (page) => {
            //   setCurrentPage(page);
            // },
            // current: currentPage,
          }}
          scroll={{ x: 500 }}
        />
      </section>
    </>
  );

  const submitAlokasi = (payload) => {
    console.log(payload, 'this is allocation payload');
    setLoadingSubmit(true);
    allocate(unitId, 'fund', student._id, payload)
      .then(({ value }) => {
        console.log(value, 'success alokasi');
        setAfterSubmit({
          ...value.conditions,
          done: true,
        });
        // onCancel();
        // window.location.reload();
        message.success('Berhasil melakukan alokasi dana masuk');
        refetchData();
        fetchDetail();
        fetchOutstandingBills();
        // update 2 september
        form.setFieldsValue({
          alokasiTabungan: 0,
        });
      })
      .catch((err) => {
        console.log(err.response, 'error alokasi');
        message.error(err?.response?.data?.m || 'Gagal alokasi dana masuk');
      })
      .finally(() => setLoadingSubmit(false));
  };

  const Alokasi = () => (
    <>
      <InfoMurid />
      <Form
        layout="vertical"
        form={form}
        onFinish={(v) => {
          const bills = v.bills?.map((bill) => ({
            _id: bill._id,
            type: bill.type,
            allocation: Number(bill.allocation),
            date: bill.date,
          }));
          const total = bills?.reduce(
            (curr, acc) => curr + Number(acc.allocation),
            0
          );

          const filtered = bills.filter((el) => el.allocation > 0);

          const payload = {
            bills: filtered,
            // savings: student?.savings || 0,
            savings: v?.alokasiTabungan || 0,
            allocate_savings_date: moment(v?.allocate_savings_date).format(
              'MM/DD/YYYY'
            ),
            total_allocation: total + (v?.alokasiTabungan || 0),
          };

          if (payload.total_allocation > 0) {
            submitAlokasi(payload);
          } else {
            message.error('Silakan isi nominal alokasi terlebih dahulu');
          }
          // console.log(payload);
        }}
      >
        <section style={{ marginTop: '2rem' }}>
          <h3>Alokasi ke Tabungan</h3>
          <Row gutter={[18, 8]}>
            <Col span="8">
              <InputWrapper
                type="number"
                prefix="Rp"
                name="savings"
                label="Total Tabungan"
                disabled
                formatter={(value) =>
                  `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
              />
            </Col>
            <Col span="8">
              <Form.Item label="Tanggal Alokasi" name={'allocate_savings_date'}>
                <DatePicker
                  // defaultValue={moment()}
                  format="DD/MM/YYYY"
                  style={{ width: '100%' }}
                  onChange={(_, dateString) => {
                    console.log(dateString);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span="8">
              <InputWrapper
                type="number"
                name="alokasiTabungan"
                prefix="Rp"
                label="Nominal Alokasi"
                placeholder="Rp 500.000"
                formatter={(value) =>
                  `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
              />
            </Col>
          </Row>
        </section>
        <Form.List name="bills">
          {(fields, { add, remove }) => {
            return fields.map((field, idx) => {
              return (
                <section style={{ marginTop: '2rem' }}>
                  <h3>Alokasi ke {outstandingBills[idx]?.name || '-'}</h3>
                  <Row gutter={[18, 8]}>
                    {console.log(student?.funds)}
                    <Col span="8">
                      <InputWrapper
                        type="number"
                        prefix="Rp"
                        formItemProps={{
                          name: [field.name, 'remaining'],
                        }}
                        label="Total Tagihan"
                        disabled
                        formatter={(value) =>
                          `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
                      />
                    </Col>
                    <Col span="8">
                      <Form.Item
                        label="Tanggal Alokasi"
                        name={[field.name, 'date']}
                      >
                        <DatePicker
                          // defaultValue={moment()}
                          format="DD/MM/YYYY"
                          style={{ width: '100%' }}
                          onChange={(_, dateString) => {
                            const fieldValue =
                              form.getFieldValue('bills')[field.key];
                            console.log(dateString, fieldValue);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span="8">
                      <InputWrapper
                        type="number"
                        prefix="Rp"
                        formItemProps={{
                          name: [field.name, 'allocation'],
                        }}
                        label="Nominal Alokasi"
                        placeholder="Rp 500.000"
                        formatter={(value) =>
                          `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
                      />
                    </Col>
                  </Row>
                </section>
              );
            });
          }}
        </Form.List>
      </Form>
    </>
  );

  const submitRefund = (payload) => {
    setLoadingSubmit(true);
    refundDanaMasuk(unitId, payload)
      .then((res) => {
        console.log(res);
        onCancel();
        refetchData();
        message.success('Berhasil melakukan refund dana masuk');
      })
      .catch((err) => {
        console.log(err.response, 'error refund');
        message.error(
          err?.response?.data?.m || 'Gagal melakukan refund dana masuk'
        );
      })
      .finally(() => setLoadingSubmit(false));
  };

  let drawerTitle = '';

  switch (content) {
    case 0:
      drawerTitle = 'Detail Dana Masuk';
      break;
    case 1:
      drawerTitle = 'Riwayat Alokasi Dana Masuk';
      break;
    case 2:
      drawerTitle = 'Alokasi Dana Masuk';
      break;
    case 3:
      drawerTitle = 'Refund Dana Masuk';
      break;
    default:
      drawerTitle = '';
  }

  const disableRefund = !!refundNotes && !!refundAmount ? false : true;

  return (
    <Drawer
      title={isEdit.state ? 'Edit Dana Masuk' : drawerTitle}
      width={content === 3 ? `40rem` : '70rem'}
      visible={visible}
      footer={
        isEdit.state || content > 1 ? (
          <Footer
            onCancel={() => {
              if (isEdit) {
                setIsEdit(false);
              } else {
                onCancel();
              }
            }}
            onSubmit={() => form.submit()}
            submitDisabled={
              content === 3 ? disableRefund : isEdit.state && disableButton
            }
            loadingState={loadingSubmit}
          />
        ) : null
      }
      onClose={onCancel}
      {...rest}
    >
      {!loading ? (
        <>
          {afterSubmit?.done && (
            <section style={{ marginBottom: '2rem' }}>
              {afterSubmit?.success?.length && !afterSubmit?.error?.length && (
                <Alert
                  type="success"
                  message={
                    <span style={{ fontWeight: 600 }}>
                      Berhasil Alokasi Dana Masuk
                    </span>
                  }
                  description={
                    <>
                      {afterSubmit.success.map((msg) => (
                        <p style={{ margin: 0 }}>{msg}</p>
                      ))}
                    </>
                  }
                  showIcon
                  closable
                  onClose={() =>
                    setAfterSubmit({
                      done: false,
                      success: [],
                      error: [],
                    })
                  }
                />
              )}
              {!afterSubmit?.success?.length && afterSubmit?.error?.length && (
                <Alert
                  type="error"
                  message={
                    <span style={{ fontWeight: 600 }}>
                      Gagal Alokasi Dana Masuk
                    </span>
                  }
                  description={
                    <>
                      {afterSubmit.error.map((msg) => (
                        <p style={{ margin: 0 }}>{msg}</p>
                      ))}
                    </>
                  }
                  showIcon
                  closable
                  onClose={() =>
                    setAfterSubmit({
                      done: false,
                      success: [],
                      error: [],
                    })
                  }
                />
              )}
              {afterSubmit?.success?.length && afterSubmit?.error?.length ? (
                <Alert
                  type="warning"
                  message={
                    <span style={{ fontWeight: 600 }}>
                      Berhasil Alokasi Sebagian Dana Masuk
                    </span>
                  }
                  description={
                    <>
                      {afterSubmit.success.map((msg) => (
                        <p style={{ margin: 0 }}>{msg}</p>
                      ))}
                      {afterSubmit.error.map((msg) => (
                        <p style={{ margin: 0 }}>{msg}</p>
                      ))}
                    </>
                  }
                  showIcon
                  closable
                  onClose={() =>
                    setAfterSubmit({
                      done: false,
                      success: [],
                      error: [],
                    })
                  }
                />
              ) : null}
            </section>
          )}

          {isEdit.state && (
            <>
              <Row gutter={[24, 12]} style={{ marginTop: '2rem' }}>
                <Col span="12">
                  <YellowCard bodyStyle={{ padding: 10 }}>
                    <span>Nama Murid</span>
                    <h2 style={{ color: themeColor.primary, margin: 0 }}>
                      {truncateText(student?.name, 27)}
                    </h2>
                  </YellowCard>
                </Col>
                <Col span="12">
                  <InfoCard
                    sideColor="blue"
                    title="Total Dana Masuk"
                    content={
                      student?.funds
                        ? `Rp${(student?.funds || 0).toLocaleString()}`
                        : '-'
                    }
                  />
                </Col>
              </Row>

              <Form
                layout="vertical"
                form={form}
                onFinish={(v) =>
                  handleSubmitEditIncomingFunds({ ...v, date: dateFunds })
                }
                onValuesChange={(changedValue, value) => {
                  if (value?.date && value?.amount) {
                    setDisableButton(false);
                  } else {
                    setDisableButton(true);
                  }
                  // if (value?.amount && value?.date) {
                  //   setDisabledButton(false);
                  // } else {
                  //   setDisabledButton(true);
                  // }
                }}
              >
                <section style={{ marginTop: '2rem' }}>
                  <Row gutter={[8, 12]}>
                    <Col span="12">
                      <Form.Item label="Tanggal Dana Masuk" name="date">
                        <DatePicker
                          style={{ width: '100%' }}
                          onChange={(_, date) => setDateFunds(date)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span="12">
                      <InputWrapper
                        label="Nominal Dana Masuk"
                        type="number"
                        prefix="Rp"
                        name="amount"
                        placeholder="Masukan nominal"
                        formatter={(value) =>
                          `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
                      />
                    </Col>
                  </Row>
                  <Form.Item label="Catatan" name="notes">
                    <Input.TextArea
                      style={{ width: '100%' }}
                      rows={3}
                      placeholder="Tulis catatan"
                    />
                  </Form.Item>
                </section>
              </Form>

              {/* <section style={{ marginTop: '2rem' }}>
        <Alert
          message="Perhatian!"
          description="Nominal cicilan berikutnya akan menjadi Rp3,700,000"
          type="info"
          showIcon
        />
      </section> */}
            </>
          )}
          {!isEdit.state && content < 2 ? (
            <Detail />
          ) : !isEdit.state && content === 2 ? (
            <Alokasi />
          ) : !isEdit.state ? (
            <>
              <YellowCard>
                <span>Nama Murid</span>
                <h2 style={{ color: themeColor.primary, margin: 0 }}>
                  {student?.name || '-'}
                </h2>
              </YellowCard>

              <section style={{ margin: '2rem 0' }}>
                <InfoCard
                  sideColor="blue"
                  title="Dana Masuk Tersedia"
                  content={`Rp${(student?.funds || 0).toLocaleString()}`}
                />
              </section>

              <Form
                form={form}
                layout="vertical"
                onFinish={(v) => {
                  const payload = {
                    total_amount: Number(v.total_amount),
                    date: new Date().toLocaleDateString(),
                    refund_origin: 'Dana Masuk',
                    notes: v.notes,
                    unit: unitId,
                    student: student?._id,
                    date: refundDate,
                    // refund_origin_id: student?._id,
                  };

                  // console.log(payload, 'refund payload');
                  submitRefund(payload);
                }}
              >
                <InputWrapper
                  type="number"
                  prefix="Rp"
                  name="total_amount"
                  label="Nominal Refund"
                  placeholder="Rp 500.000"
                  formatter={(value) =>
                    `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
                  onChange={(e) => {
                    // const value = e.target.value;
                    setRefundAmount(e);
                    console.log(e);
                  }}
                />
                <Form.Item label="Tanggal Refund" name="date">
                  <DatePicker
                    style={{ width: '100%' }}
                    onChange={(_, dateString) => {
                      setRefundDate(dateString);
                    }}
                  />
                </Form.Item>
                <Form.Item name="notes" label="Notes">
                  <Input.TextArea
                    rows={4}
                    placeholder="Masukan alasan refund"
                    onChange={(e) => {
                      const value = e.target.value;
                      setRefundNotes(value);
                    }}
                  />
                </Form.Item>
              </Form>
            </>
          ) : null}
        </>
      ) : (
        <Loader text="Fetching details ..." />
      )}
    </Drawer>
  );
};

export default DetailDanaMasuk;
