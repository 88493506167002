import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Input,
  Menu,
  Popover,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from 'antd';
import { Modal, PageHeader } from 'components/shared';
import React, { useEffect, useState } from 'react';
import { useStore } from 'store';
import queryString from 'querystring';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getListKelasId,
  getListMurid,
  getListTahunAjaran,
  getPayment,
} from 'utils/fetch';
import {
  CalendarOutlined,
  DownloadOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { InfoCard } from 'components/fragments/card';
import moment from 'moment';
import exportExcel from 'utils/helpers/xlsx';

const RiwayatVA = () => {
  const {
    handleBreadcrumb,
    handleSiderActive,
    state: { selectedUnit },
  } = useStore();
  const [openModal, setOpenModal] = useState({
    state: false,
    data: null,
  });
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalData, setTotalData] = useState(1);
  const [otherData, setOtherData] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const unitId = selectedUnit?._id;

  useEffect(() => {
    if (selectedUnit) {
      const newBreadcrumb = [
        {
          linkTo: `/dana-masuk?unit=${selectedUnit?.name.toLowerCase()}`,
          node: <span>Daftar Dana Masuk</span>,
        },
        {
          linkTo: '/dana-masuk/riwayat-va',
          node: <span>Riwayat VA</span>,
        },
      ];
      handleBreadcrumb(newBreadcrumb);
      handleSiderActive(['4']);
      console.log(selectedUnit, 'this is unit');
    }
  }, [selectedUnit]);

  useEffect(() => {
    if (selectedUnit) {
      fetchData();
    }

    return () => {};
  }, [selectedMonth, selectedUnit]);

  const fetchData = () => {
    setLoading(true);

    let query = `page=0&limit=50`;

    if (selectedMonth)
      query += `&date=${moment(selectedMonth).format('MM/DD/YYYY')}`;

    getPayment(unitId, 'history-upload-va', query)
      .then((res) => {
        setLoading(false);
        // console.log(numbered);
        setData(res.historyList);
        setTotalData(res.totalData);
        console.log(res);
        setOtherData({
          totalTransactions: res.totalTransactions,
          totalAmount: res.totalAmount,
        });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response, 'this is error fetch');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const parsedData = (data = []) => {
    const parsed = [['No', 'Tanggal', 'Total Nominal']];

    data.forEach((dt, idx) => {
      const list = [
        idx + 1,
        moment(dt.date).format('DD/MM/YYYY HH:mm'),
        dt.total_amount,
      ];

      parsed.push(list);
    });

    return parsed;
  };

  const parsedByDate = (data) => {
    const parsed = [
      ['No', 'Nomor VA', 'Nama Murid', 'Tanggal', 'Nominal', 'Notes', 'Result'],
    ];

    data?.forEach((dt) => {
      dt?.student_list?.map((std, idx) => {
        parsed.push([
          idx + 1,
          std?.va_number || '-',
          std?.student || '-',
          moment(std?.date).format('DD/MM/YYYY'),
          std?.amount,
          std?.notes,
          std?.result,
        ]);
      });
    });

    return parsed;
  };

  const downloadLaporan = () => {
    const newParsedData = parsedData(data);
    const byDateParsed = parsedByDate(data);

    const multipleData = [
      {
        data: byDateParsed,
        sheetName: 'Riwayat VA Keseluruhan',
      },
      {
        data: newParsedData,
        sheetName: 'Ringkasan VA Keseluruhan',
      },
    ];

    exportExcel(
      multipleData,
      `Laporan VA ${moment(selectedMonth).format('MMMM YYYY')}`,
      true
    );
  };

  const tableColumns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: 80,
      render: (data, _, idx) => <span>{idx + 1}</span>,
    },
    {
      title: 'Tanggal',
      dataIndex: 'date',
      key: 'date',
      width: 200,
      render: (data) => (
        <span>{data ? moment(data).format('DD MMMM YYYY') : '-'}</span>
      ),
    },
    {
      title: 'Total Nominal',
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (data) => <span>{`Rp${(data || 0).toLocaleString()}`}</span>,
    },
    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      align: 'center',
      fixed: 'right',
      width: 150,
      render: (id, record) => {
        // const url = URL.createObjectURL(record.file_url);

        return (
          <Space>
            <Button
              icon={<EyeOutlined />}
              onClick={() => {
                setOpenModal({
                  state: true,
                  data: record,
                });
                console.log(record);
              }}
            >
              Lihat
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <PageHeader>
        <Space>
          <DatePicker
            defaultValue={moment()}
            picker="month"
            onChange={(_, dateString) => {
              console.log(dateString);
              setSelectedMonth(dateString);
            }}
            format={'MMMM YYYY'}
            placeholder="Pilih Bulan"
            id="month-picker"
          />
          <Button icon={<DownloadOutlined />} onClick={() => downloadLaporan()}>
            Download Laporan
          </Button>
        </Space>
      </PageHeader>

      <Row gutter={[24, 0]}>
        <Col span="8">
          <InfoCard
            title="Jumlah Transaksi"
            content={otherData?.totalTransactions || 0}
            sideColor="red"
          />
        </Col>
        <Col span="8">
          <InfoCard
            title="Total Nominal"
            content={`Rp${(otherData?.totalAmount || 0).toLocaleString()}`}
            sideColor="red"
          />
        </Col>
      </Row>

      <section style={{ width: '100%', margin: '2rem 0' }}>
        <Table
          dataSource={data}
          columns={tableColumns}
          pagination={false}
          loading={loading}
        />
      </section>

      {openModal.state && (
        <Modal.RiwayatVA
          data={openModal.data}
          visible={openModal.state}
          onClose={() => {
            setOpenModal({
              state: false,
              data: null,
            });
          }}
        />
      )}
    </>
  );
};

export default RiwayatVA;
