import {
  Button,
  Dropdown,
  Menu,
  message,
  Space,
  Table,
  Empty,
  Tabs,
} from 'antd';
import { Modal, PageHeader } from 'components/shared';
import React, { useEffect, useMemo, useState } from 'react';
import { useStore } from 'store';
import {
  getListTahunAjaran,
  getLaporanHarianAllUnit,
  getLaporanHarianUnit,
  updateLaporanHarian,
  downloadLaporanDashboard,
  getLaporanHarianByBillType,
} from 'utils/fetch';
import { DownloadOutlined, FileExclamationOutlined } from '@ant-design/icons';
import moment from 'moment';
import exportExcel from 'utils/helpers/xlsx';
import { Loader } from 'components/atoms';
import { themeColor } from 'lib/theme';

const TableRow = (props) => {
  const { columns, data, index, ...rest } = props;

  return (
    <section
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 1.6rem',
      }}
    >
      {columns.map((col, idx) => {
        const isLast = idx === columns.length - 1;

        return (
          <div
            key={col.key}
            style={{
              width: '200px',
              textAlign: isLast ? 'right' : 'left',
              borderBottom: '1px solid #f0f0f0',
              padding: '2rem 0',
            }}
          >
            {col.render(data[col.dataIndex], col, index)}
          </div>
        );
      })}
    </section>
  );
};

const LaporanHarian = () => {
  const {
    handleBreadcrumb,
    handleSiderActive,
    handleListSchoolYear,
    state: { activeSchoolYear, unitData, userData },
  } = useStore();
  const [selectedMonth, setSelectedMonth] = useState({
    name: '',
    value: '',
  });
  const [selectedUnit, setSelectedUnit] = useState(userData?.unit || '');
  const [data, setData] = useState(null);
  const [lastUpdated, setLastUpdated] = useState('');
  const [otherData, setOtherData] = useState(null);
  const [otherVaData, setOtherVaData] = useState(null);
  const [otherTotalData, setOtherTotalData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [modalDetail, setModalDetail] = useState({ state: false, query: null });

  const [currentTab, setCurrentTab] = useState('all');
  const [allData, setAllData] = useState(null);
  const [dynamicData, setDynamicData] = useState([]);
  const [otherDynamicData, setOtherDynamicData] = useState([]);
  const [dynamicColumn, setDynamicColumn] = useState([]);
  const [loadingDynamic, setLoadingDynamic] = useState(false);

  const schoolYearId = activeSchoolYear?._id;
  const arrayUnit = useMemo(
    () => Object.keys(unitData).map((key) => unitData[key]),
    [unitData]
  );

  const listBulan = useMemo(() => {
    if (activeSchoolYear) {
      return Array.from({ length: 12 }, (_, i) => {
        const date = new Date(activeSchoolYear.start_date);
        const next = new Date(date.setMonth(date.getMonth() + i));

        return {
          name: `${moment(next).format('MMMM YYYY')}`,
          value: next,
        };
      });
    } else {
      return [];
    }
  }, [activeSchoolYear]);

  useEffect(() => {
    if (activeSchoolYear && listBulan.length && !selectedMonth.name) {
      setSelectedMonth(listBulan[0]);
    }
  }, [activeSchoolYear, listBulan, selectedMonth]);

  useEffect(() => {
    if (userData?.unit) {
      setSelectedUnit(userData.unit);
    }
  }, [userData]);

  useEffect(() => {
    const newBreadcrumb = [
      {
        linkTo: '/dashboard/laporan-harian',
        node: (
          <div>
            <p style={{ margin: 0, lineHeight: 0.75 }}>
              Dashboard Laporan Harian
            </p>
            <span style={{ fontSize: '1.15rem', color: '#262626', margin: 0 }}>
              Update terakhir:{' '}
              {lastUpdated
                ? moment(lastUpdated).format('DD/MM/YYYY, hh:mm A')
                : '-'}
            </span>
          </div>
        ),
      },
    ];
    handleBreadcrumb(newBreadcrumb);
    handleSiderActive(['2']);
  }, [lastUpdated]);

  useEffect(() => {
    fetchDataTahunAjaran();
    const next = new Date();

    const date = {
      name: `${moment(next).format('MMMM YYYY')}`,
      value: next,
    };

    setSelectedMonth(date);
  }, []);

  useEffect(() => {
    if (allData && currentTab !== 'all') {
      const parsedData = allData?.[currentTab];
      const tableHeader = parsedData[0];
      const tableData = parsedData.slice(1);
      const data = tableData?.map((dt, idx) => {
        const headerData = tableHeader?.reduce((acc, curr, index) => {
          acc[curr] = dt[index];

          return acc;
        }, {});
        return headerData;
      });
      const columns = tableHeader?.map((header) => ({
        title: header,
        dataIndex: header,
        key: header,
        width: `calc(100% / ${tableHeader.length})`,
        render: (data) =>
          header === 'Tanggal' ? (
            <span>{data}</span>
          ) : (
            <span>Rp{Number(data || 0).toLocaleString()}</span>
          ),
      }));
      console.log({ currentTab, data, columns }, 'parsed data and columns');
      setDynamicColumn(columns);
      setDynamicData(data);
      setOtherDynamicData(data[data.length - 1]);
    }
    console.log({ currentTab, allData });
  }, [allData, currentTab]);

  useEffect(() => {
    if (selectedMonth?.value) {
      _fetchDynamicData({
        month: moment(selectedMonth.value).set('date', 1).format('MM-DD-YYYY'),
        unitId: selectedUnit?._id || null,
      });
    }
  }, [selectedMonth, selectedUnit]);

  const _fetchDynamicData = async (query) => {
    try {
      console.log({ query });
      setAllData(null);
      setLoadingDynamic(true);
      const response = await getLaporanHarianByBillType(query);
      console.log({ response });
      setAllData(response);
    } catch (err) {
      message.error(err.m);
    } finally {
      setLoadingDynamic(false);
    }
  };

  const fetchDataAllUnit = (query) => {
    setLoading(true);
    getLaporanHarianAllUnit(query)
      .then((res) => {
        console.log(res);
        setData(res.dailyReport);
        setOtherData({
          title: 'TOTAL',
          TK: 0,
          SD: 0,
          SMP: 0,
          ...res.units,
          total: res.totalAmount,
        });
        setOtherVaData({
          title: 'TOTAL',
          TK: 0,
          SD: 0,
          SMP: 0,
          ...res.vaUnits,
          total: res.totalVAAmount,
        });
        setOtherTotalData({
          title: 'Grand Total',
          TK: 0,
          SD: 0,
          SMP: 0,
          ...res.grandTotalPerUnit,
          total: res.grandTotal,
        });
        setLastUpdated(res.lastUpdated);
      })
      .catch((err) => {
        console.log(err.response);
        setData(null);
        message.error(err?.response?.data?.m);
      })
      .finally(() => setLoading(false));
  };

  const fetchDataUnit = (id, query) => {
    setLoading(true);
    getLaporanHarianUnit(id, query)
      .then((res) => {
        console.log(res);
        setData(res.dailyReport);
        setOtherData({
          title: 'TOTAL',
          ...res.billsType,
          total: res.totalAmount,
        });

        const emptyBills = Object.keys(res?.billsType)?.reduce((curr, acc) => {
          return { ...curr, [acc]: '' };
        }, {});

        setOtherVaData({
          title: 'TOTAL',
          ...emptyBills,
          total: res.totalVAAmount,
        });
        setOtherTotalData({
          title: 'Grand Total',
          ...emptyBills,
          total: res.grandTotal,
        });
        setLastUpdated(res.lastUpdated);
      })
      .catch((err) => {
        console.log(err.response);
        setData(null);
        message.error(err?.response?.data?.m);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (selectedMonth?.value) {
      console.log({ selectedUnit, selectedMonth });
      const query = [
        `month=${moment(selectedMonth.value).format('MM-DD-YYYY')}`,
        selectedUnit ? `unitId=${selectedUnit?._id}` : '',
      ].join('&');
      setData(null);
      setOtherData(null);
      if (!selectedUnit) {
        fetchDataAllUnit(query);
      } else {
        fetchDataUnit(selectedUnit._id, query);
      }
    }
  }, [selectedUnit, selectedMonth]);

  const fetchDataTahunAjaran = () => {
    getListTahunAjaran()
      .then((res) => {
        console.log(res);
        handleListSchoolYear(res.schoolsYears);
      })
      .catch((err) => console.error(err));
  };

  const handleUpdateData = () => {
    setLoadingUpdate(true);
    updateLaporanHarian()
      .then((res) => {
        if (res) {
          message.success('Berhasil memperbaharui data laporan harian.');
          if (selectedMonth?.value) {
            const query = `month=${moment(selectedMonth.value).format(
              'MM-DD-YYYY'
            )}`;
            if (!selectedUnit) {
              fetchDataAllUnit(query);
            } else {
              fetchDataUnit(selectedUnit._id, query);
            }
          }
        }
      })
      .catch((err) => {
        console.log(err.response);
        message.error(err.response.data.m);
      })
      .finally(() => setLoadingUpdate(false));
  };

  const onBodyTableClick = (vm, unit = null, date = null, isMonth = false) => {
    setModalDetail({
      state: true,
      query: {
        type: vm.dataIndex,
        date: isMonth ? null : date,
        unit,
        month: isMonth ? date : null,
      },
    });
  };

  const tableColumns = [
    {
      title: 'Tanggal',
      dataIndex: 'date',
      key: 'date',
      width: 'calc(100% / 6)',
      render: (data, record, idx) => (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const date = moment(selectedMonth.value)
              .set('date', idx + 1)
              .format('MM-DD-YYYY');

            onBodyTableClick({}, null, date);
          }}
        >
          {data}
        </span>
      ),
    },
    {
      title: 'TK',
      dataIndex: 'TK',
      key: 'tk',
      width: 'calc(100% / 6)',
      onHeaderClick(vm) {
        const unit = arrayUnit.find((el) => el.name === vm.dataIndex)._id;
        const month = moment(selectedMonth.value)
          .set('date', 1)
          .format('MM-DD-YYYY');
        onBodyTableClick({}, unit, month, true);
      },
      render: (data, record, idx) => (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const unit = arrayUnit.find(
              (el) => el.name === record.dataIndex
            )._id;
            const date = moment(selectedMonth.value)
              .set('date', idx + 1)
              .format('MM-DD-YYYY');

            onBodyTableClick({}, unit, date);
          }}
        >
          Rp{Number(data || 0).toLocaleString()}
        </span>
      ),
    },
    {
      title: 'SD',
      dataIndex: 'SD',
      key: 'sd',
      width: 'calc(100% / 6)',
      onHeaderClick(vm) {
        const unit = arrayUnit.find((el) => el.name === vm.dataIndex)._id;
        const month = moment(selectedMonth.value)
          .set('date', 1)
          .format('MM-DD-YYYY');
        onBodyTableClick({}, unit, month, true);
      },
      render: (data, record, idx) => (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const unit = arrayUnit.find(
              (el) => el.name === record.dataIndex
            )._id;
            const date = moment(selectedMonth.value)
              .set('date', idx + 1)
              .format('MM-DD-YYYY');

            onBodyTableClick({}, unit, date);
          }}
        >
          Rp{Number(data || 0).toLocaleString()}
        </span>
      ),
    },
    {
      title: 'SMP',
      dataIndex: 'SMP',
      key: 'smp',
      width: 'calc(100% / 6)',
      onHeaderClick(vm) {
        const unit = arrayUnit.find((el) => el.name === vm.dataIndex)._id;
        const month = moment(selectedMonth.value)
          .set('date', 1)
          .format('MM-DD-YYYY');
        onBodyTableClick({}, unit, month, true);
      },
      render: (data, record, idx) => (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const unit = arrayUnit.find(
              (el) => el.name === record.dataIndex
            )._id;
            const date = moment(selectedMonth.value)
              .set('date', idx + 1)
              .format('MM-DD-YYYY');

            onBodyTableClick({}, unit, date);
          }}
        >
          Rp{Number(data || 0).toLocaleString()}
        </span>
      ),
    },
    {
      title: 'SMA',
      dataIndex: 'SMA',
      key: 'sma',
      width: 'calc(100% / 6)',
      onHeaderClick(vm) {
        const unit = arrayUnit.find((el) => el.name === vm.dataIndex)._id;
        const month = moment(selectedMonth.value)
          .set('date', 1)
          .format('MM-DD-YYYY');
        onBodyTableClick({}, unit, month, true);
      },
      render: (data, record, idx) => (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const unit = arrayUnit.find(
              (el) => el.name === record.dataIndex
            )._id;
            const date = moment(selectedMonth.value)
              .set('date', idx + 1)
              .format('MM-DD-YYYY');

            onBodyTableClick({}, unit, date);
          }}
        >
          Rp{Number(data || 0).toLocaleString()}
        </span>
      ),
    },
    {
      title: 'Total Pendapatan',
      dataIndex: 'total',
      key: 'total',
      align: 'right',
      width: 'calc(100% / 6)',
      render: (data) => <span>Rp{Number(data || 0).toLocaleString()}</span>,
    },
  ];

  const tableColumnsUnit = [
    {
      title: 'Tanggal',
      dataIndex: 'date',
      key: 'date',
      width: 'calc(100% / 6)',
      render: (data, record, idx) => (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const unit = selectedUnit?._id;
            const date = moment(selectedMonth.value)
              .set('date', idx + 1)
              .format('MM-DD-YYYY');

            onBodyTableClick({}, unit, date);
          }}
        >
          {data}
        </span>
      ),
    },
    {
      title: 'SPP',
      dataIndex: 'SPP',
      key: 'spp',
      width: 'calc(100% / 6)',
      onHeaderClick(vm) {
        onBodyTableClick(vm);
      },
      render: (data, record, idx) => (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const unit = selectedUnit?._id;
            const date = moment(selectedMonth.value)
              .set('date', idx + 1)
              .format('MM-DD-YYYY');

            onBodyTableClick(record, unit, date);
          }}
        >
          Rp{Number(data || 0).toLocaleString()}
        </span>
      ),
    },
    {
      title: 'PSB',
      dataIndex: 'PPDB Instalment',
      key: 'ppdb',
      width: 'calc(100% / 6)',
      onHeaderClick(vm) {
        onBodyTableClick(vm);
      },
      render: (data, record, idx) => (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const unit = selectedUnit?._id;
            const date = moment(selectedMonth.value)
              .set('date', idx + 1)
              .format('MM-DD-YYYY');

            onBodyTableClick(record, unit, date);
          }}
        >
          Rp{Number(data || 0).toLocaleString()}
        </span>
      ),
    },
    {
      title: 'Tagihan Lainnya',
      dataIndex: 'General',
      key: 'general',
      width: 'calc(100% / 6)',
      onHeaderClick(vm) {
        onBodyTableClick(vm);
      },
      render: (data, record, idx) => (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const unit = selectedUnit?._id;
            const date = moment(selectedMonth.value)
              .set('date', idx + 1)
              .format('MM-DD-YYYY');

            onBodyTableClick(record, unit, date);
          }}
        >
          Rp{Number(data || 0).toLocaleString()}
        </span>
      ),
    },
    {
      title: 'Tabungan',
      dataIndex: 'Tabungan',
      key: 'savings',
      width: 'calc(100% / 6)',
      onHeaderClick(vm) {
        onBodyTableClick(vm);
      },
      render: (data, record, idx) => (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const unit = selectedUnit?._id;
            const date = moment(selectedMonth.value)
              .set('date', idx + 1)
              .format('MM-DD-YYYY');

            onBodyTableClick(record, unit, date);
          }}
        >
          Rp{Number(data || 0).toLocaleString()}
        </span>
      ),
    },
    {
      title: 'Total Pendapatan',
      dataIndex: 'total',
      key: 'total',
      align: 'right',
      width: 'calc(100% / 6)',
      render: (data) => <span>Rp{Number(data || 0).toLocaleString()}</span>,
    },
  ];

  const MenuBulan = ({ onClick }) => {
    return (
      <Menu
        style={{
          boxShadow:
            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          height: '25rem',
          overflowY: 'scroll',
          paddingRight: '1rem',
        }}
      >
        {listBulan.map((month) => (
          <Menu.Item key={month.value} onClick={() => onClick(month)}>
            {month.name}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const MenuUnit = ({ onClick }) => {
    return (
      <Menu
        style={{
          boxShadow:
            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        }}
      >
        <Menu.Item key="all" onClick={() => onClick('')}>
          Semua Unit
        </Menu.Item>
        {arrayUnit.map((unit) => (
          <Menu.Item key={unit._id} onClick={() => onClick(unit)}>
            {unit.name}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const keys = otherData ? Object.keys(otherData) : [];
  const keysVA = otherVaData ? Object.keys(otherVaData) : [];
  const keysTotal = otherTotalData ? Object.keys(otherTotalData) : [];

  const parsedResult = (data = [], total) => {
    const parsed = [['Tanggal', 'TK', 'SD', 'SMP', 'Total Pendapatan']];

    data.forEach((dt, idx) => {
      const array = [
        dt.date,
        dt.TK || 0,
        dt.SD || 0,
        dt.SMP || 0,
        dt.SMA || 0,
        dt.total,
      ];

      parsed.push(array);
    });

    // console.log(otherData);
    const footer = [];

    keys.forEach((key) => {
      footer.push(otherData[key]);
    });

    return [...parsed, footer];
  };

  const parsedResultUnit = (data = []) => {
    const parsed = [
      [
        'Tanggal',
        'SPP',
        'PSB',
        'Tagihan Lainnya',
        'Tabungan',
        'Total Pendapatan',
      ],
    ];

    data.forEach((dt, idx) => {
      const array = [
        dt.date,
        dt.SPP,
        dt['PPDB Instalment'],
        dt.General,
        dt.Tabungan,
        dt.total,
      ];

      parsed.push(array);
    });

    const footer = [];

    keys.forEach((key) => {
      footer.push(otherData[key]);
    });

    return [...parsed, footer];
  };

  const parsedDataPayments = (data = []) => {
    const parsed = [
      [
        'No',
        'Tanggal',
        'Nama',
        'NIS',
        'Kelas',
        'Nama Tagihan',
        'Total Nominal',
        'Item Tagihan',
      ],
    ];

    data.forEach((dt, idx) => {
      const itemList = dt.paid_items
        ?.map((item) => {
          if (item.amount > 0) {
            return `${item.typeName}: ${item.amount.toLocaleString()}`;
          }
        })
        .filter(Boolean)
        ?.join('; ');

      const list = [
        idx + 1,
        moment(dt.date).format('DD/MM/YYYY'),
        dt.student?.name,
        dt.student?.nik || '-',
        dt.student?.grade?.name || '-',
        dt.name,
        dt.total_amount,
        itemList || '-',
      ];

      parsed.push(list);
    });

    return parsed;
  };

  const downloadLaporan = async () => {
    try {
      setLoadingDownload(true);
      const parsedData = selectedUnit?._id
        ? parsedResultUnit(data)
        : parsedResult(data);

      let query = ``;

      if (selectedUnit) query += `unitId=${selectedUnit?._id}&`;
      if (selectedMonth)
        query += `month=${moment(selectedMonth.value).format('MM-DD-YYYY')}`;

      const { sppBillsReport, ppdbBillsReport, generalBillsReport, payments } =
        await downloadLaporanDashboard('daily-report', query);

      const multipleData = [
        { data: parsedData, sheetName: 'Laporan Harian Keseluruhan' },
        { data: sppBillsReport, sheetName: 'SPP' },
        { data: ppdbBillsReport, sheetName: 'PSB' },
        { data: generalBillsReport, sheetName: 'Tagihan Lainnya' },
        { data: parsedDataPayments(payments), sheetName: 'Pembayaran' },
      ];

      console.log(multipleData);

      exportExcel(multipleData, 'Laporan Harian', true);
      setLoadingDownload(false);
    } catch (err) {
      setLoadingDownload(false);
      console.log(err);
      message.error(err?.data?.m || 'Gagal download laporan harian');
    }
  };

  return (
    <>
      <PageHeader>
        <Space>
          <Button
            icon={<FileExclamationOutlined />}
            onClick={handleUpdateData}
            loading={loadingUpdate}
          >
            Update Data
          </Button>
          <Dropdown
            disabled={userData.type === 2}
            overlay={<MenuUnit onClick={(v) => setSelectedUnit(v)} />}
          >
            <Button>{selectedUnit?.name || 'Semua Unit'}</Button>
          </Dropdown>
          <Dropdown
            overlay={<MenuBulan onClick={(v) => setSelectedMonth(v)} />}
          >
            <Button>{selectedMonth?.name || '-'}</Button>
          </Dropdown>
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={downloadLaporan}
            loading={loadingDownload}
          >
            Download Laporan
          </Button>
        </Space>
      </PageHeader>
      <Tabs
        defaultActiveKey={currentTab}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
        onChange={(e) => {
          setCurrentTab(e);
        }}
      >
        <Tabs.TabPane tab="Keseluruhan" key="all">
          <div style={{ width: '100%', margin: '2rem 0', overflowX: 'scroll' }}>
            <div style={{ width: '100%', minWidth: '100rem' }}>
              <div
                style={{
                  padding: '1.6rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  color: '#000000D9',
                  backgroundColor: '#FAFAFA',
                }}
              >
                {(
                  ((selectedUnit ? tableColumnsUnit : tableColumns)?.length &&
                    (selectedUnit ? tableColumnsUnit : tableColumns)) ||
                  []
                ).map((item, idx) => {
                  // const isLast = idx === keys.length - 1;
                  return (
                    <div
                      style={{
                        cursor: item?.onHeaderClick ? 'pointer' : '',
                        fontWeight: 'bold',
                        // width: isLast ? '300px' : 100,
                        width: '200px',
                        // paddingRight: idx < 1 || idx === 6 ? '0' : '1.6rem',
                        // paddingLeft: idx === 4 || idx === 5 ? '1.6rem' : '0',
                        textAlign:
                          (selectedUnit
                            ? tableColumnsUnit?.length
                            : tableColumns?.length) -
                            1 ==
                          idx
                            ? 'right'
                            : 'left',
                      }}
                      onClick={() =>
                        item?.onHeaderClick ? item?.onHeaderClick(item) : null
                      }
                    >
                      {item?.title}
                    </div>
                  );
                })}
              </div>
              <div
                style={{ width: '100%', margin: '2rem 0', overflowY: 'scroll' }}
              >
                <div
                  style={{ height: 'calc(100vh - 350px)', minHeight: '20rem' }}
                >
                  {/* <Table
                dataSource={data}
                columns={selectedUnit ? tableColumnsUnit : tableColumns}
                pagination={false}
                loading={loading}
                showHeader={false}
              /> */}
                  {!loading ? (
                    data ? (
                      data?.map((dt, i) => {
                        return (
                          <TableRow
                            columns={
                              selectedUnit ? tableColumnsUnit : tableColumns
                            }
                            data={dt}
                            index={i}
                          />
                        );
                      })
                    ) : (
                      <Empty />
                    )
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
              <div
                style={{
                  padding: '1.4rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {keys.map((key, idx) => {
                  const isLast = idx === keys.length - 1;
                  return (
                    <div
                      style={{
                        color: themeColor.success,
                        fontWeight: 'bold',
                        // width: isLast ? '300px' : 100,
                        width: `200px`,
                        textAlign: isLast ? 'right' : 'left',
                      }}
                    >
                      {idx < 1
                        ? 'Total'
                        : //  otherData[key]
                          `Rp${Number(otherData[key]).toLocaleString()}`}
                    </div>
                  );
                })}
              </div>
              <div
                style={{
                  padding: '1.6rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {keysVA.map((key, idx) => {
                  const isLast = idx === keysVA.length - 1;
                  console.log(otherVaData[key]);
                  return (
                    <div
                      style={{
                        color: themeColor.success,
                        fontWeight: 'bold',
                        // width: isLast ? '300px' : 100,
                        width: `200px`,
                        textAlign: isLast ? 'right' : 'left',
                      }}
                    >
                      {idx < 1
                        ? 'Total VA'
                        : //  otherData[key]
                        otherVaData[key]
                        ? `Rp${Number(otherVaData[key]).toLocaleString()}`
                        : ''}
                    </div>
                  );
                })}
              </div>
              <div
                style={{
                  padding: '1.6rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {keysTotal.map((key, idx) => {
                  const isLast = idx === keysTotal.length - 1;
                  return (
                    <div
                      style={{
                        color: themeColor.success,
                        fontWeight: 'bold',
                        // width: isLast ? '300px' : 100,
                        width: `200px`,
                        textAlign: isLast ? 'right' : 'left',
                      }}
                    >
                      {idx < 1
                        ? 'Grand Total'
                        : //  otherData[key]
                        otherTotalData[key]
                        ? `Rp${Number(otherTotalData[key]).toLocaleString()}`
                        : ''}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="SPP" key="sppBillsReport">
          <div style={{ width: '100%', margin: '2rem 0', overflowX: 'scroll' }}>
            <div style={{ width: '100%', minWidth: '100rem' }}>
              <div
                style={{
                  padding: '1.6rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  color: '#000000D9',
                  backgroundColor: '#FAFAFA',
                }}
              >
                {dynamicColumn?.map((item, idx) => {
                  // const isLast = idx === keys.length - 1;
                  return (
                    <div
                      style={{
                        cursor: item?.onHeaderClick ? 'pointer' : '',
                        fontWeight: 'bold',
                        // width: isLast ? '300px' : 100,
                        width: '200px',
                        // paddingRight: idx < 1 || idx === 6 ? '0' : '1.6rem',
                        // paddingLeft: idx === 4 || idx === 5 ? '1.6rem' : '0',
                        textAlign:
                          (selectedUnit
                            ? tableColumnsUnit?.length
                            : tableColumns?.length) -
                            1 ==
                          idx
                            ? 'right'
                            : 'left',
                      }}
                      onClick={() =>
                        item?.onHeaderClick ? item?.onHeaderClick(item) : null
                      }
                    >
                      {item?.title}
                    </div>
                  );
                })}
              </div>
              <div
                style={{ width: '100%', margin: '2rem 0', overflowY: 'scroll' }}
              >
                <div
                  style={{ height: 'calc(100vh - 350px)', minHeight: '20rem' }}
                >
                  {/* <Table
                dataSource={data}
                columns={selectedUnit ? tableColumnsUnit : tableColumns}
                pagination={false}
                loading={loading}
                showHeader={false}
              /> */}
                  {!loadingDynamic ? (
                    dynamicData.length ? (
                      dynamicData?.map((dt, i) => {
                        return (
                          <TableRow
                            columns={dynamicColumn}
                            data={dt}
                            index={i}
                          />
                        );
                      })
                    ) : (
                      <Empty />
                    )
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="PPDB" key="ppdbBillsReport">
          <div style={{ width: '100%', margin: '2rem 0', overflowX: 'scroll' }}>
            <div style={{ width: '100%', minWidth: '100rem' }}>
              <div
                style={{
                  padding: '1.6rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  color: '#000000D9',
                  backgroundColor: '#FAFAFA',
                }}
              >
                {dynamicColumn?.map((item, idx) => {
                  // const isLast = idx === keys.length - 1;
                  return (
                    <div
                      style={{
                        cursor: item?.onHeaderClick ? 'pointer' : '',
                        fontWeight: 'bold',
                        // width: isLast ? '300px' : 100,
                        width: '200px',
                        // paddingRight: idx < 1 || idx === 6 ? '0' : '1.6rem',
                        // paddingLeft: idx === 4 || idx === 5 ? '1.6rem' : '0',
                        textAlign:
                          (selectedUnit
                            ? tableColumnsUnit?.length
                            : tableColumns?.length) -
                            1 ==
                          idx
                            ? 'right'
                            : 'left',
                      }}
                      onClick={() =>
                        item?.onHeaderClick ? item?.onHeaderClick(item) : null
                      }
                    >
                      {item?.title}
                    </div>
                  );
                })}
              </div>
              <div
                style={{ width: '100%', margin: '2rem 0', overflowY: 'scroll' }}
              >
                <div
                  style={{ height: 'calc(100vh - 350px)', minHeight: '20rem' }}
                >
                  {/* <Table
                dataSource={data}
                columns={selectedUnit ? tableColumnsUnit : tableColumns}
                pagination={false}
                loading={loading}
                showHeader={false}
              /> */}
                  {!loadingDynamic ? (
                    dynamicData.length ? (
                      dynamicData?.map((dt, i) => {
                        return (
                          <TableRow
                            columns={dynamicColumn}
                            data={dt}
                            index={i}
                          />
                        );
                      })
                    ) : (
                      <Empty />
                    )
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Tagihan Lainnya" key="generalBillsReport">
          <div style={{ width: '100%', margin: '2rem 0', overflowX: 'scroll' }}>
            <div style={{ width: '100%', minWidth: '100rem' }}>
              <div
                style={{
                  padding: '1.6rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  color: '#000000D9',
                  backgroundColor: '#FAFAFA',
                }}
              >
                {dynamicColumn?.map((item, idx) => {
                  // const isLast = idx === keys.length - 1;
                  return (
                    <div
                      style={{
                        cursor: item?.onHeaderClick ? 'pointer' : '',
                        fontWeight: 'bold',
                        // width: isLast ? '300px' : 100,
                        width: '200px',
                        // paddingRight: idx < 1 || idx === 6 ? '0' : '1.6rem',
                        // paddingLeft: idx === 4 || idx === 5 ? '1.6rem' : '0',
                        textAlign:
                          (selectedUnit
                            ? tableColumnsUnit?.length
                            : tableColumns?.length) -
                            1 ==
                          idx
                            ? 'right'
                            : 'left',
                      }}
                      onClick={() =>
                        item?.onHeaderClick ? item?.onHeaderClick(item) : null
                      }
                    >
                      {item?.title}
                    </div>
                  );
                })}
              </div>
              <div
                style={{ width: '100%', margin: '2rem 0', overflowY: 'scroll' }}
              >
                <div
                  style={{ height: 'calc(100vh - 350px)', minHeight: '20rem' }}
                >
                  {/* <Table
                dataSource={data}
                columns={selectedUnit ? tableColumnsUnit : tableColumns}
                pagination={false}
                loading={loading}
                showHeader={false}
              /> */}
                  {!loadingDynamic ? (
                    dynamicData?.length ? (
                      dynamicData?.map((dt, i) => {
                        return (
                          <TableRow
                            columns={dynamicColumn}
                            data={dt}
                            index={i}
                          />
                        );
                      })
                    ) : (
                      <Empty />
                    )
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Tabs.TabPane>
      </Tabs>

      <Modal.DetailPembayaranLaporanHarian
        visible={modalDetail.state}
        onClose={() => setModalDetail({ state: false, query: null })}
        query={modalDetail.query}
      />
    </>
  );
};

export default LaporanHarian;
