import React, { useEffect, useState } from 'react';
import { Drawer, Button, Form, Input, Checkbox, Space, message } from 'antd';
import { Footer } from './shared';
import { InputWrapper } from '..';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { getListKelasSebelumnya } from 'utils/fetch';

const TambahKelas = (props) => {
  const [form] = Form.useForm();
  const [previousGrades, setPreviousGrades] = useState(null);
  const [filled, setFilled] = useState([]);
  const {
    visible,
    onCancel,
    value,
    isEdit,
    handleSubmit,
    unitId,
    loadingState,
    ...rest
  } = props;

  useEffect(() => {
    if (isEdit) form.setFieldsValue({ kode: value });
  }, [isEdit]);

  useEffect(() => {
    console.log(filled);
  }, [filled]);

  useEffect(() => {
    if (!visible) form.resetFields();
  }, [visible]);

  const handleChecked = (e) => {
    console.log(e.target.checked);
    if (e.target.checked === true) {
      if (!previousGrades) {
        getListKelasSebelumnya(unitId)
          .then((res) => {
            console.log(res, 'this is kelas sebelumnya');
            form.setFieldsValue({ grades: res.previousGrades });
            if (res.previousGrades.length === 0) {
              setFilled(['']);
              setPreviousGrades(['']);
            } else {
              setPreviousGrades(res.previousGrades);
              setFilled(res.previousGrades);
            }
          })
          .catch((err) => {
            console.log(err);
            message.error('Gagal memuat kelas sebelumnya');
          });
      }

      form.setFieldsValue({ grades: previousGrades });
      setFilled(previousGrades);
    } else {
      form.resetFields();
      setPreviousGrades(null);
    }
  };

  return (
    <Drawer
      title={`${isEdit ? 'Edit' : 'Tambah'} Kelas`}
      width="40rem"
      visible={visible}
      footer={
        <Footer
          onCancel={() => {
            form.setFieldsValue({ grades: [''] });
            onCancel();
          }}
          onSubmit={form.submit}
          submitDisabled={!filled?.length || !filled[0]?.length}
          loadingState={loadingState}
        />
      }
      onClose={() => {
        form.setFieldsValue({ grades: [''] });
        onCancel();
      }}
      {...rest}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          grades: isEdit ? value : [''],
        }}
        onFinish={(val) => handleSubmit(val)}
      >
        <Space style={{ marginBottom: '1.5rem' }}>
          <Checkbox onChange={handleChecked}>
            Gunakan daftar kelas sebelumnya
          </Checkbox>
        </Space>
        <Form.List name="grades">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  required={false}
                  key={field.key}
                  label={`Nama Kelas ${field.key + 1}`}
                  style={{ width: '100%', marginBottom: 0 }}
                >
                  <Input.Group
                    style={{
                      display: 'flex',
                      width: '100%',
                    }}
                  >
                    <InputWrapper
                      type="text"
                      placeholder="Ketik nama kelas"
                      formItemProps={{
                        name: field.name,
                        style: { width: '100%' },
                      }}
                      style={{ width: '97%' }}
                      onChange={(e) => {
                        const value = e.target.value;
                        const duplicate = [...filled];
                        duplicate[index] = value;
                        console.log(value, index);
                        setFilled(duplicate);
                      }}
                    />
                    {fields.length > 1 ? (
                      <Button
                        type="text"
                        onClick={() => {
                          remove(field.name);
                          const removed = filled.splice(index, 1);
                          setFilled(removed);
                        }}
                        icon={<DeleteOutlined style={{ fontSize: '20px' }} />}
                      />
                    ) : null}
                  </Input.Group>
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="icon"
                  onClick={() => add()}
                  style={{ width: '60%' }}
                  icon={<PlusOutlined />}
                >
                  Tambah Kelas
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Drawer>
  );
};

export default TambahKelas;
