import React from 'react';
import { InputWrapper } from 'components/shared';

const ForgotPasswordForm = () => {
  return (
    <>
      <InputWrapper
        type="email"
        placeholder="contoh@email.com"
        name="email_forgot"
        label="Email Address"
      />
    </>
  );
};

export default ForgotPasswordForm;
