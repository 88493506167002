import React from 'react';
import { InputWrapper } from 'components/shared';

const SignInForm = () => {
  return (
    <>
      <InputWrapper
        type="email"
        placeholder="contoh@email.com"
        name="email"
        label="Email Address"
      />

      <InputWrapper
        type="password"
        placeholder="Ketik password Anda di sini"
        name="password"
        label="Password"
      />
    </>
  );
};

export default SignInForm;
