import React, { useState } from 'react';
import { Modal } from 'antd';
import {
  DashboardASAK,
  DashboardDanaMasuk,
  DashboardMuridMenunggak,
  DashboardTabungan,
  DashboardTunggakan,
  DashboardRefund,
  DashboardPPDBTunggakan,
  DashboardMonthlyRecap,
  DashboardMonthlyRecapPBDB,
  DashboardMonthlyRecapGeneral,
} from 'components/fragments/table';
import { useStore } from 'store';
import moment from 'moment';

const RincianDashboard = ({
  visible,
  onClose,
  table,
  data,
  type,
  asakMonth,
  unitName,
  modalTitle,
  ...rest
}) => {
  const {
    state: { activeSchoolYear },
  } = useStore();

  let title = '';

  switch (table) {
    case 'tunggakan':
      title = 'Tunggakan Murid';
      break;
    case 'dana-masuk':
      title = 'Dana Masuk Belum Alokasi';
      break;
    case 'murid-menunggak':
      title = 'Daftar Murid Menunggak Di Atas 5 Tagihan';
      break;
    case 'refund':
      title = 'Daftar Refund';
      break;
    case 'asak':
      title = `Murid ASAK Tahun Ajaran ${activeSchoolYear?.name || '-'}`;
      break;
    case 'tabungan':
      title = 'Total Tabungan Belum Alokasi';
      break;
    default:
      title = modalTitle || '';
      break;
  }

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onClose}
      {...rest}
      centered
      cancelText="Batal"
      width="70vw"
    >
      <section style={{ margin: '1rem 0' }}>
        {table === 'tunggakan' && (
          <DashboardTunggakan unitName={unitName} data={data} />
        )}
        {table === 'dana-masuk' && (
          <DashboardDanaMasuk unitName={unitName} data={data} />
        )}
        {table === 'asak' && (
          <DashboardASAK
            asakMonth={asakMonth}
            unitName={unitName}
            data={data}
          />
        )}
        {table === 'tabungan' && (
          <DashboardTabungan unitName={unitName} data={data} />
        )}
        {table === 'murid-menunggak' && (
          <DashboardMuridMenunggak unitName={unitName} data={data} />
        )}
        {table === 'refund' && (
          <DashboardRefund unitName={unitName} data={data} />
        )}
        {table === 'rekap-tunggakan' && (
          <DashboardPPDBTunggakan data={data} type={type} />
        )}
        {table === 'monthly-recap' && (
          <DashboardMonthlyRecap
            month={data.month}
            type={data.type}
            data={data.list}
          />
        )}
        {table === 'monthly-recap-pbdb' && (
          <DashboardMonthlyRecapPBDB
            month={data.month}
            type={data.type}
            data={data.list}
          />
        )}
        {table === 'monthly-recap-general' && (
          <DashboardMonthlyRecapGeneral data={data} />
        )}
      </section>
    </Modal>
  );
};

export default RincianDashboard;
