import React, { useEffect } from 'react';
import { Row, Col, Tag } from 'antd';
import { CardBase } from 'components/atoms';
import { StyledDetailKelasData } from './style';

const DetailKelas = (props) => {
  const { data, title, ...rest } = props;

  useEffect(() => {
    console.log(data, 'data kelas');
  }, [data]);

  return (
    <CardBase title={title} {...rest}>
      {data.grades.length ? (
        <p style={{ fontSize: '1.25rem', color: '#333333', opacity: '0.5' }}>
          {data.grades.length} Kelas
        </p>
      ) : (
        ''
      )}
      {data.grades.length
        ? data.grades[0].map((el) => {
          return (
            <StyledDetailKelasData>
              <p>{el.name}</p>
              <p>{el.students} Murid</p>
            </StyledDetailKelasData>
          );
        })
        : 'Tidak ada kelas di unit ini.'}
    </CardBase>
  );
};

export default DetailKelas;
