import { DownOutlined, EyeOutlined } from '@ant-design/icons';
import { Table, Space, Input, Dropdown, Button, Menu } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useStore } from 'store';
import queryString from 'querystring';
import { rincianMonthlyPayment } from 'utils/fetch';

const DashboardMonthlyRecapGeneral = ({ month, type, data }) => {
  const {
    state: { unitData },
  } = useStore();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  // const [data, setData] = useState([]);
  const [copiedData, setCopiedData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [nameSearch, setNameSearch] = useState('');
  const [limit, setLimit] = useState(10);
  const arrayUnit = Object.keys(unitData).map((key) => unitData[key]);

  useEffect(() => {
    // fetchData();
  }, [selectedUnit]);

  useEffect(() => {
    if (data) {
      setCopiedData(data);
    }
  }, [data]);

  useEffect(() => {
    if (nameSearch) {
      const filtered = data.filter((item) =>
        item.student_name.toLowerCase().includes(nameSearch.toLowerCase())
      );

      setCopiedData(filtered);
    } else {
      setCopiedData(data);
    }
  }, [nameSearch]);

  useEffect(() => {
    return () => setCurrentPage(1);
  }, []);

  const fetchData = () => {
    // let query = `unitId=${selectedUnit?._id || ''}&month=${
    //   month || '1-1-2021'
    // }`;
    // setLoading(true);
    // rincianMonthlyPayment(type, query)
    //   .then((res) => {
    //     console.log(res);
    //     setData(res.thisMonthPayments);
    //     setTotalData(res.thisMonthPayments.length);
    //   })
    //   .catch((err) => console.log(err.response, 'error fetch'))
    //   .finally(() => setLoading(false));
  };

  const tableColumns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: 80,
      render: (data, record, idx) => <span>{idx + 1}</span>,
    },
    {
      title: 'Nama Murid',
      dataIndex: 'student_name',
      key: 'student_name',
      width: 300,
      render: (data) => <span>{data || '-'}</span>,
    },
    {
      title: 'Nama Tagihan',
      dataIndex: 'bill_name',
      key: 'bill_name',
      width: 300,
      render: (data) => <span>{data || '-'}</span>,
    },
    {
      title: 'Nominal',
      dataIndex: 'amount',
      key: 'amount',
      render: (data, record) => {
        // const tunggakan = data - record.paid_amount;
        // const isLunas = tunggakan === 0;
        return <span>{`Rp${(data || 0).toLocaleString()}`}</span>;
      },
    },
    {
      title: 'Kelas',
      dataIndex: 'student',
      key: 'grade',
      render: (data) => <span>{data?.grade?.name || 'Murid Tanpa Kelas'}</span>,
    },
    {
      title: 'Unit',
      dataIndex: 'unit_name',
      key: 'unit_name',
      width: 100,
      render: (data) => {
        return <span>{data || '-'}</span>;
      },
    },
    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      fixed: 'right',
      width: '120',
      render: (data, record) => (
        <Button
          icon={<EyeOutlined />}
          onClick={() => {
            const unit = record?.unit_name || 'tk';
            history.push({
              pathname: `/daftar-murid/detail/${data}`,
              search: queryString.stringify({
                unit: unit,
                tabs: 'tunggakan',
              }),
            });
          }}
        >
          Lihat
        </Button>
      ),
    },
  ];

  const MenuUnit = ({ onClick }) => {
    return (
      <Menu>
        <Menu.Item key="all" onClick={() => onClick('')}>
          Semua Unit
        </Menu.Item>
        {arrayUnit.map((unit) => (
          <Menu.Item key={unit._id} onClick={() => onClick(unit)}>
            {unit.name}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  return (
    <article>
      <section
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '2rem',
        }}
      >
        <h3>Total Murid: {(copiedData?.length || 0).toLocaleString()}</h3>
        <Space>
          <Input.Search
            placeholder="Cari nama murid"
            value={nameSearch}
            onChange={(e) => setNameSearch(e.target.value)}
          />

          {/* <Dropdown overlay={<MenuUnit onClick={(v) => setSelectedUnit(v)} />}> */}
          <Button
            // icon={<DownOutlined />}
            disabled
          >
            {selectedUnit?.name || 'Semua Unit'}
          </Button>
          {/* </Dropdown> */}
        </Space>
      </section>
      <Table
        columns={tableColumns}
        dataSource={copiedData}
        pagination={{
          position: ['bottomLeft'],
          total: totalData && totalData,
          onChange: (page) => {
            setCurrentPage(page);
          },
          current: currentPage,
          pageSize: limit,
          showSizeChanger: true,
          pageSizeOptions: ['10', '30', '50'],
          onShowSizeChange: (curr, pageSize) => {
            setLimit(pageSize);
          },
        }}
        scroll={{ x: 1200, y: 350 }}
        loading={loading}
      />
    </article>
  );
};

export default DashboardMonthlyRecapGeneral;
