export const themeColor = {
  primary: '#3F4195',
  success: '#4D9970',
};

export const themeTitle = {
  title: 'Sistem Tagihan - Sekolah Islam Fitrah Al Fikri',
  description: 'Sekolah Islam Fitrah Al Fikri Sistem Tagihan',
  headline: 'Sistem Tagihan - Sekolah Islam Fitrah Al Fikri',
};

export const keys = {
  token: 'alfikri-access-token',
};
