import {
  Button,
  Dropdown,
  Empty,
  Menu,
  message,
  Space,
  Table,
  Tabs,
} from 'antd';
import { Modal, PageHeader } from 'components/shared';
import React, { useEffect, useMemo, useState } from 'react';
import { useStore } from 'store';
import {
  getListTahunAjaran,
  getLaporanBulananAllUnit,
  getLaporanBulananUnit,
  updateLaporanBulanan,
  downloadLaporanDashboard,
  getLaporanBulananByBillType,
} from 'utils/fetch';
import { DownloadOutlined, FileExclamationOutlined } from '@ant-design/icons';
import moment from 'moment';
import exportExcel from 'utils/helpers/xlsx';
import { Loader } from 'components/atoms';
import { themeColor } from 'lib/theme';

const TableRow = (props) => {
  const { columns, data, ...rest } = props;

  return (
    <section
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 1.6rem',
      }}
    >
      {columns.map((col, idx) => {
        const isLast = idx === columns.length - 1;

        return (
          <div
            key={col.key}
            style={{
              width: '200px',
              textAlign: isLast ? 'right' : 'left',
              borderBottom: '1px solid #f0f0f0',
              padding: '2rem 0',
            }}
          >
            {col.render(data[col.dataIndex], col, data)}
          </div>
        );
      })}
    </section>
  );
};

const LaporanBulanan = () => {
  const {
    handleBreadcrumb,
    handleSiderActive,
    handleListSchoolYear,
    state: { activeSchoolYear, unitData, listTahunAjaran, userData },
  } = useStore();
  const [selectedYear, setSelectedYear] = useState({
    name: '',
    _id: '',
  });
  const [selectedUnit, setSelectedUnit] = useState(userData?.unit || '');
  const [data, setData] = useState(null);
  const [lastUpdated, setLastUpdated] = useState('');
  const [otherData, setOtherData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [modalDetail, setModalDetail] = useState({ state: false, query: null });
  const schoolYearId = activeSchoolYear?._id;
  const arrayUnit = useMemo(
    () => Object.keys(unitData).map((key) => unitData[key]),
    [unitData]
  );

  const [currentTab, setCurrentTab] = useState('all');
  const [allData, setAllData] = useState(null);
  const [dynamicData, setDynamicData] = useState([]);
  const [otherDynamicData, setOtherDynamicData] = useState([]);
  const [dynamicColumn, setDynamicColumn] = useState([]);
  const [loadingDynamic, setLoadingDynamic] = useState(false);

  const listBulan = activeSchoolYear
    ? Array.from({ length: 12 }, (_, i) => {
        const date = new Date(activeSchoolYear.start_date);
        const next = new Date(date.setMonth(date.getMonth() + i));

        return {
          name: `${moment(next).format('MMMM YYYY')}`,
          value: next,
        };
      })
    : [];

  useEffect(() => {
    if (userData?.unit) {
      setSelectedUnit(userData.unit);
    }
  }, [userData]);

  useEffect(() => {
    const newBreadcrumb = [
      {
        linkTo: '/dashboard/laporan-bulanan',
        node: (
          <div>
            <p style={{ margin: 0, lineHeight: 0.75 }}>
              Dashboard Laporan Bulanan
            </p>
            <span style={{ fontSize: '1.15rem', color: '#262626', margin: 0 }}>
              Update terakhir:{' '}
              {lastUpdated
                ? moment(lastUpdated).format('DD/MM/YYYY, hh:mm A')
                : '-'}
            </span>
          </div>
        ),
      },
    ];
    handleBreadcrumb(newBreadcrumb);
    handleSiderActive(['5']);
  }, [lastUpdated]);

  useEffect(() => {
    fetchDataTahunAjaran();
  }, []);

  useEffect(() => {
    if (activeSchoolYear) {
      setSelectedYear({
        name: activeSchoolYear.name,
        _id: activeSchoolYear._id,
      });
    }
  }, [activeSchoolYear]);

  useEffect(() => {
    if (allData && currentTab !== 'all') {
      const parsedData = allData?.[currentTab];
      const tableHeader = parsedData[0];
      const tableData = parsedData.slice(1);
      const data = tableData?.map((dt, idx) => {
        const headerData = tableHeader?.reduce((acc, curr, index) => {
          acc[curr] = dt[index];

          return acc;
        }, {});
        return headerData;
      });
      const columns = tableHeader?.map((header) => ({
        title: header,
        dataIndex: header,
        key: header,
        width: `calc(100% / ${tableHeader.length})`,
        render: (data) =>
          header === 'Tanggal' ? (
            <span>{data}</span>
          ) : (
            <span>Rp{Number(data || 0).toLocaleString()}</span>
          ),
      }));
      console.log({ currentTab, data, columns }, 'parsed data and columns');
      setDynamicColumn(columns);
      setDynamicData(data);
      setOtherDynamicData(data[data.length - 1]);
    }
    console.log({ currentTab, allData });
  }, [allData, currentTab]);

  useEffect(() => {
    if (selectedYear._id && currentTab !== 'all') {
      const dictionary = {
        generalBillsReport: 'General',
        ppdbBillsReport: 'PPDB Instalment',
        sppBillsReport: 'SPP',
      };

      _fetchDynamicData({
        schoolYearId: selectedYear._id,
        unitId: selectedUnit?._id || null,
        type: dictionary[currentTab],
      });
    }
  }, [selectedYear, selectedUnit, currentTab]);

  const _fetchDynamicData = async (query) => {
    try {
      console.log({ query });
      setAllData(null);
      setLoadingDynamic(true);
      const response = await getLaporanBulananByBillType(query);
      console.log({ response });
      setAllData(response);
    } catch (err) {
      message.error(err.m);
    } finally {
      setLoadingDynamic(false);
    }
  };

  const fetchDataAllUnit = (query) => {
    setLoading(true);
    getLaporanBulananAllUnit(query)
      .then((res) => {
        console.log(res);
        setData(res.monthlyReport);
        setOtherData({
          title: 'TOTAL',
          'Play Group': 0,
          TK: 0,
          SD: 0,
          SMP: 0,
          SMA: 0,
          ...res.units,
          total: res.totalAmount,
        });
        setLastUpdated(res.lastUpdated);
      })
      .catch((err) => {
        console.log(err.response);
        setData(null);
        message.error(err.response.data.m);
      })
      .finally(() => setLoading(false));
  };

  const fetchDataUnit = (id, query) => {
    setLoading(true);
    getLaporanBulananUnit(id, query)
      .then((res) => {
        console.log(res);
        setData(res.dailyReport);
        setOtherData({
          title: 'TOTAL',
          ...res.billsType,
          total: res.totalAmount,
        });
        setLastUpdated(res.lastUpdated);
      })
      .catch((err) => {
        console.log(err.response);
        setData(null);
        message.error(err.response.data.m);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (selectedYear?._id) {
      const query = `school_year=${selectedYear._id}`;
      setData(null);
      setOtherData(null);
      if (!selectedUnit) {
        fetchDataAllUnit(query);
      } else {
        fetchDataUnit(selectedUnit._id, query);
      }
    }
  }, [selectedUnit, selectedYear]);

  const fetchDataTahunAjaran = () => {
    getListTahunAjaran()
      .then((res) => {
        console.log(res);
        handleListSchoolYear(res.schoolsYears);
      })
      .catch((err) => console.error(err));
  };

  const handleUpdateData = () => {
    setLoadingUpdate(true);
    updateLaporanBulanan()
      .then((res) => {
        if (res) {
          message.success('Berhasil memperbaharui data laporan harian.');
          if (selectedYear?._id) {
            const query = `school_year=${selectedYear._id}`;
            if (!selectedUnit) {
              fetchDataAllUnit(query);
            } else {
              fetchDataUnit(selectedUnit._id, query);
            }
          }
        }
      })
      .catch((err) => {
        console.log(err.response);
        message.error(err.response.data.m);
      })
      .finally(() => setLoadingUpdate(false));
  };

  const onBodyTableClick = (
    vm,
    unit = null,
    month = null,
    schoolYearId = ''
  ) => {
    setModalDetail({
      state: true,
      query: {
        type: vm.dataIndex,
        month,
        unit,
        schoolYearId: schoolYearId,
      },
    });
  };

  const tableColumns = [
    {
      title: 'Bulan',
      dataIndex: 'month',
      key: 'month',
      width: 'calc(100% / 7)',
      render: (data, record, idx) => (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            onBodyTableClick({}, null, data, selectedYear?._id);
          }}
        >
          {data}
        </span>
      ),
    },
    {
      title: 'TK',
      dataIndex: 'TK',
      key: 'tk',
      width: 'calc(100% / 7)',
      onHeaderClick(vm) {
        const unit = arrayUnit.find((el) => el.name === vm.dataIndex)._id;
        onBodyTableClick({}, unit, null, selectedYear._id);
      },
      render: (data, record, all) => (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const unit = arrayUnit.find(
              (el) => el.name === record.dataIndex
            )._id;
            onBodyTableClick({}, unit, all.month, null);
          }}
        >
          Rp{Number(data || 0).toLocaleString()}
        </span>
      ),
    },
    {
      title: 'SD',
      dataIndex: 'SD',
      key: 'sd',
      width: 'calc(100% / 7)',
      onHeaderClick(vm) {
        const unit = arrayUnit.find((el) => el.name === vm.dataIndex)._id;
        onBodyTableClick({}, unit, null, selectedYear._id);
      },
      render: (data, record, all) => (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const unit = arrayUnit.find(
              (el) => el.name === record.dataIndex
            )._id;
            onBodyTableClick({}, unit, all.month, null);
          }}
        >
          Rp{Number(data || 0).toLocaleString()}
        </span>
      ),
    },
    {
      title: 'SMP',
      dataIndex: 'SMP',
      key: 'smp',
      width: 'calc(100% / 7)',
      onHeaderClick(vm) {
        const unit = arrayUnit.find((el) => el.name === vm.dataIndex)._id;
        onBodyTableClick({}, unit, null, selectedYear._id);
      },
      render: (data, record, all) => (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const unit = arrayUnit.find(
              (el) => el.name === record.dataIndex
            )._id;
            onBodyTableClick({}, unit, all.month, null);
          }}
        >
          Rp{Number(data || 0).toLocaleString()}
        </span>
      ),
    },
    {
      title: 'SMA',
      dataIndex: 'SMA',
      key: 'sma',
      width: 'calc(100% / 7)',
      onHeaderClick(vm) {
        const unit = arrayUnit.find((el) => el.name === vm.dataIndex)._id;
        onBodyTableClick({}, unit, null, selectedYear._id);
      },
      render: (data, record, all) => (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const unit = arrayUnit.find(
              (el) => el.name === record.dataIndex
            )._id;
            onBodyTableClick({}, unit, all.month, null);
          }}
        >
          Rp{Number(data || 0).toLocaleString()}
        </span>
      ),
    },
    {
      title: 'Total Pendapatan',
      dataIndex: 'total',
      key: 'total',
      align: 'right',
      width: 'calc(100% / 7)',
      render: (data) => <span>Rp{Number(data || 0).toLocaleString()}</span>,
    },
  ];

  const tableColumnsUnit = [
    {
      title: 'Bulan',
      dataIndex: 'month',
      key: 'month',
      width: 'calc(100% / 6)',
      render: (data, record, idx) => (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            onBodyTableClick({}, selectedUnit._id, data, selectedYear?._id);
          }}
        >
          {data}
        </span>
      ),
    },
    {
      title: 'SPP',
      dataIndex: 'SPP',
      key: 'spp',
      width: 'calc(100% / 6)',
      onHeaderClick(vm) {
        const unit = selectedUnit._id;
        onBodyTableClick(vm, unit, null, selectedYear._id);
      },
      render: (data, record, all) => (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const unit = selectedUnit._id;
            onBodyTableClick(record, unit, all.month, selectedYear?._id);
          }}
        >
          Rp{Number(data || 0).toLocaleString()}
        </span>
      ),
    },
    {
      title: 'PSB',
      dataIndex: 'PPDB Instalment',
      key: 'ppdb',
      width: 'calc(100% / 6)',
      onHeaderClick(vm) {
        const unit = selectedUnit._id;
        onBodyTableClick(vm, unit, null, selectedYear._id);
      },
      render: (data, record, all) => (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const unit = selectedUnit._id;
            onBodyTableClick(record, unit, all.month, selectedYear?._id);
          }}
        >
          Rp{Number(data || 0).toLocaleString()}
        </span>
      ),
    },
    {
      title: 'Tagihan Lainnya',
      dataIndex: 'General',
      key: 'general',
      width: 'calc(100% / 6)',
      onHeaderClick(vm) {
        const unit = selectedUnit._id;
        onBodyTableClick(vm, unit, null, selectedYear._id);
      },
      render: (data, record, all) => (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const unit = selectedUnit._id;
            onBodyTableClick(record, unit, all.month, selectedYear?._id);
          }}
        >
          Rp{Number(data || 0).toLocaleString()}
        </span>
      ),
    },
    {
      title: 'Tabungan',
      dataIndex: 'Tabungan',
      key: 'savings',
      width: 'calc(100% / 6)',
      onHeaderClick(vm) {
        const unit = selectedUnit._id;
        onBodyTableClick(vm, unit, null, selectedYear._id);
      },
      render: (data, record, all) => (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const unit = selectedUnit._id;
            onBodyTableClick(record, unit, all.month, selectedYear?._id);
          }}
        >
          Rp{Number(data || 0).toLocaleString()}
        </span>
      ),
    },
    {
      title: 'Total Pendapatan',
      dataIndex: 'total',
      key: 'total',
      align: 'right',
      width: 'calc(100% / 6)',
      render: (data) => <span>Rp{Number(data || 0).toLocaleString()}</span>,
    },
  ];

  const MenuTA = ({ onClick }) => {
    return (
      <Menu
        style={{
          boxShadow:
            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          maxHeight: '25rem',
          overflowY: 'scroll',
          paddingRight: '1rem',
        }}
      >
        {listTahunAjaran.map((year) => (
          <Menu.Item key={year._id} onClick={() => onClick(year)}>
            {year.name}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const MenuUnit = ({ onClick }) => {
    return (
      <Menu
        style={{
          boxShadow:
            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        }}
      >
        <Menu.Item key="all" onClick={() => onClick('')}>
          Semua Unit
        </Menu.Item>
        {arrayUnit.map((unit) => (
          <Menu.Item key={unit._id} onClick={() => onClick(unit)}>
            {unit.name}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const keys = otherData ? Object.keys(otherData) : [];

  const parsedResult = (data = [], total) => {
    const parsed = [
      ['Tanggal', 'Play Group', 'TK', 'SD', 'SMP', 'SMA', 'Total Pendapatan'],
    ];

    data.forEach((dt, idx) => {
      const array = [
        dt.month,
        dt['Play Group'] || 0,
        dt.TK || 0,
        dt.SD || 0,
        dt.SMP || 0,
        dt.SMA || 0,
        dt.total || 0,
      ];

      parsed.push(array);
    });

    // console.log(otherData);
    const footer = [];

    keys.forEach((key) => {
      footer.push(otherData[key]);
    });

    return [...parsed, footer];
  };

  const parsedResultUnit = (data = []) => {
    const parsed = [
      [
        'Tanggal',
        'SPP',
        'PSB',
        'Tagihan Lainnya',
        'Tabungan',
        'Total Pendapatan',
      ],
    ];

    data.forEach((dt, idx) => {
      const array = [
        dt.month,
        dt.SPP || 0,
        dt['PPDB Instalment'] || 0,
        dt.General || 0,
        dt.Tabungan || 0,
        dt.total || 0,
      ];

      parsed.push(array);
    });

    const footer = [];

    keys.forEach((key) => {
      footer.push(otherData[key]);
    });

    return [...parsed, footer];
  };

  const downloadLaporan = async () => {
    try {
      setLoadingDownload(true);
      const parsedData = selectedUnit
        ? parsedResultUnit(data)
        : parsedResult(data);

      let query = ``;

      if (selectedUnit) query += `unitId=${selectedUnit._id}&`;
      if (selectedYear?._id) query += `schoolYearId=${selectedYear?._id}`;

      const { sppBillsReport, ppdbBillsReport, generalBillsReport } =
        await downloadLaporanDashboard('monthly-report', query);

      const multipleData = [
        { data: parsedData, sheetName: 'Laporan Bulanan Keseluruhan' },
        { data: sppBillsReport, sheetName: 'SPP' },
        { data: ppdbBillsReport, sheetName: 'PSB' },
        { data: generalBillsReport, sheetName: 'Tagihan Lainnya' },
      ];

      console.log(multipleData);

      exportExcel(multipleData, 'Laporan Bulanan', true);
      setLoadingDownload(false);
    } catch (err) {
      setLoadingDownload(false);
      console.log(err);
      message.error(err?.data?.m || 'Gagal download laporan bulanan');
    }
  };

  return (
    <>
      <PageHeader>
        <Space>
          <Button
            icon={<FileExclamationOutlined />}
            onClick={handleUpdateData}
            loading={loadingUpdate}
          >
            Update Data
          </Button>
          <Dropdown
            disabled={userData.type === 2}
            overlay={<MenuUnit onClick={(v) => setSelectedUnit(v)} />}
          >
            <Button>{selectedUnit?.name || 'Semua Unit'}</Button>
          </Dropdown>
          <Dropdown
            overlay={
              <MenuTA
                onClick={(v) =>
                  setSelectedYear({
                    name: v.name,
                    _id: v._id,
                  })
                }
              />
            }
          >
            <Button>{selectedYear?.name || '-'}</Button>
          </Dropdown>
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={downloadLaporan}
            loading={loadingDownload}
          >
            Download Laporan
          </Button>
        </Space>
      </PageHeader>
      <Tabs
        defaultActiveKey={currentTab}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
        onChange={(e) => {
          setCurrentTab(e);
        }}
      >
        <Tabs.TabPane tab="Keseluruhan" key="all">
          <div style={{ width: '100%', margin: '2rem 0', overflowX: 'scroll' }}>
            <div style={{ width: '100%', minWidth: '100rem' }}>
              <div
                style={{
                  padding: '1.6rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  color: '#000000D9',
                  backgroundColor: '#FAFAFA',
                }}
              >
                {(
                  ((selectedUnit ? tableColumnsUnit : tableColumns)?.length &&
                    (selectedUnit ? tableColumnsUnit : tableColumns)) ||
                  []
                ).map((item, idx) => {
                  // const isLast = idx === keys.length - 1;
                  return (
                    <div
                      style={{
                        cursor: item?.onHeaderClick ? 'pointer' : '',
                        fontWeight: 'bold',
                        // width: isLast ? '300px' : 100,
                        width: '200px',
                        // paddingRight: idx < 1 || idx === 6 ? '0' : '1.6rem',
                        // paddingLeft: idx === 4 || idx === 5 ? '1.6rem' : '0',
                        textAlign:
                          (selectedUnit
                            ? tableColumnsUnit?.length
                            : tableColumns?.length) -
                            1 ==
                          idx
                            ? 'right'
                            : 'left',
                      }}
                      onClick={() =>
                        item?.onHeaderClick ? item?.onHeaderClick(item) : null
                      }
                    >
                      {item?.title}
                    </div>
                  );
                })}
              </div>
              <div
                style={{ width: '100%', margin: '2rem 0', overflowY: 'scroll' }}
              >
                <div
                  style={{ height: 'calc(100vh - 350px)', minHeight: '20rem' }}
                >
                  {/* <Table
                dataSource={data}
                columns={selectedUnit ? tableColumnsUnit : tableColumns}
                pagination={false}
                loading={loading}
                showHeader={false}
              /> */}
                  {!loading ? (
                    data ? (
                      data?.map((dt, i) => {
                        return (
                          <TableRow
                            columns={
                              selectedUnit ? tableColumnsUnit : tableColumns
                            }
                            data={dt}
                            index={i}
                          />
                        );
                      })
                    ) : (
                      <Empty />
                    )
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
              <div
                style={{
                  padding: '1.4rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {keys.map((key, idx) => {
                  const isLast = idx === keys.length - 1;
                  return (
                    <div
                      style={{
                        color: themeColor.success,
                        fontWeight: 'bold',
                        // width: isLast ? '300px' : 100,
                        width: `200px`,
                        textAlign: isLast ? 'right' : 'left',
                      }}
                    >
                      {idx < 1
                        ? 'Total'
                        : //  otherData[key]
                          `Rp${Number(otherData[key]).toLocaleString()}`}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="SPP" key="sppBillsReport">
          <div style={{ width: '100%', margin: '2rem 0', overflowX: 'scroll' }}>
            <div style={{ width: '100%', minWidth: '100rem' }}>
              <div
                style={{
                  padding: '1.6rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  color: '#000000D9',
                  backgroundColor: '#FAFAFA',
                }}
              >
                {dynamicColumn?.map((item, idx) => {
                  // const isLast = idx === keys.length - 1;
                  return (
                    <div
                      style={{
                        cursor: item?.onHeaderClick ? 'pointer' : '',
                        fontWeight: 'bold',
                        // width: isLast ? '300px' : 100,
                        width: '200px',
                        // paddingRight: idx < 1 || idx === 6 ? '0' : '1.6rem',
                        // paddingLeft: idx === 4 || idx === 5 ? '1.6rem' : '0',
                        textAlign:
                          (selectedUnit
                            ? tableColumnsUnit?.length
                            : tableColumns?.length) -
                            1 ==
                          idx
                            ? 'right'
                            : 'left',
                      }}
                      onClick={() =>
                        item?.onHeaderClick ? item?.onHeaderClick(item) : null
                      }
                    >
                      {item?.title}
                    </div>
                  );
                })}
              </div>
              <div
                style={{ width: '100%', margin: '2rem 0', overflowY: 'scroll' }}
              >
                <div
                  style={{ height: 'calc(100vh - 350px)', minHeight: '20rem' }}
                >
                  {/* <Table
                dataSource={data}
                columns={selectedUnit ? tableColumnsUnit : tableColumns}
                pagination={false}
                loading={loading}
                showHeader={false}
              /> */}
                  {!loadingDynamic ? (
                    dynamicData.length ? (
                      dynamicData?.map((dt, i) => {
                        return (
                          <TableRow
                            columns={dynamicColumn}
                            data={dt}
                            index={i}
                          />
                        );
                      })
                    ) : (
                      <Empty />
                    )
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="PPDB" key="ppdbBillsReport">
          <div style={{ width: '100%', margin: '2rem 0', overflowX: 'scroll' }}>
            <div style={{ width: '100%', minWidth: '100rem' }}>
              <div
                style={{
                  padding: '1.6rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  color: '#000000D9',
                  backgroundColor: '#FAFAFA',
                }}
              >
                {dynamicColumn?.map((item, idx) => {
                  // const isLast = idx === keys.length - 1;
                  return (
                    <div
                      style={{
                        cursor: item?.onHeaderClick ? 'pointer' : '',
                        fontWeight: 'bold',
                        // width: isLast ? '300px' : 100,
                        width: '200px',
                        // paddingRight: idx < 1 || idx === 6 ? '0' : '1.6rem',
                        // paddingLeft: idx === 4 || idx === 5 ? '1.6rem' : '0',
                        textAlign:
                          (selectedUnit
                            ? tableColumnsUnit?.length
                            : tableColumns?.length) -
                            1 ==
                          idx
                            ? 'right'
                            : 'left',
                      }}
                      onClick={() =>
                        item?.onHeaderClick ? item?.onHeaderClick(item) : null
                      }
                    >
                      {item?.title}
                    </div>
                  );
                })}
              </div>
              <div
                style={{ width: '100%', margin: '2rem 0', overflowY: 'scroll' }}
              >
                <div
                  style={{ height: 'calc(100vh - 350px)', minHeight: '20rem' }}
                >
                  {/* <Table
                dataSource={data}
                columns={selectedUnit ? tableColumnsUnit : tableColumns}
                pagination={false}
                loading={loading}
                showHeader={false}
              /> */}
                  {!loadingDynamic ? (
                    dynamicData.length ? (
                      dynamicData?.map((dt, i) => {
                        return (
                          <TableRow
                            columns={dynamicColumn}
                            data={dt}
                            index={i}
                          />
                        );
                      })
                    ) : (
                      <Empty />
                    )
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Tagihan Lainnya" key="generalBillsReport">
          <div style={{ width: '100%', margin: '2rem 0', overflowX: 'scroll' }}>
            <div style={{ width: '100%', minWidth: '100rem' }}>
              <div
                style={{
                  padding: '1.6rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  color: '#000000D9',
                  backgroundColor: '#FAFAFA',
                }}
              >
                {dynamicColumn?.map((item, idx) => {
                  // const isLast = idx === keys.length - 1;
                  return (
                    <div
                      style={{
                        cursor: item?.onHeaderClick ? 'pointer' : '',
                        fontWeight: 'bold',
                        // width: isLast ? '300px' : 100,
                        width: '200px',
                        // paddingRight: idx < 1 || idx === 6 ? '0' : '1.6rem',
                        // paddingLeft: idx === 4 || idx === 5 ? '1.6rem' : '0',
                        textAlign:
                          (selectedUnit
                            ? tableColumnsUnit?.length
                            : tableColumns?.length) -
                            1 ==
                          idx
                            ? 'right'
                            : 'left',
                      }}
                      onClick={() =>
                        item?.onHeaderClick ? item?.onHeaderClick(item) : null
                      }
                    >
                      {item?.title}
                    </div>
                  );
                })}
              </div>
              <div
                style={{ width: '100%', margin: '2rem 0', overflowY: 'scroll' }}
              >
                <div
                  style={{ height: 'calc(100vh - 350px)', minHeight: '20rem' }}
                >
                  {/* <Table
                dataSource={data}
                columns={selectedUnit ? tableColumnsUnit : tableColumns}
                pagination={false}
                loading={loading}
                showHeader={false}
              /> */}
                  {!loadingDynamic ? (
                    dynamicData?.length ? (
                      dynamicData?.map((dt, i) => {
                        return (
                          <TableRow
                            columns={dynamicColumn}
                            data={dt}
                            index={i}
                          />
                        );
                      })
                    ) : (
                      <Empty />
                    )
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Tabs.TabPane>
      </Tabs>

      <Modal.DetailPembayaranLaporaBulanan
        visible={modalDetail.state}
        onClose={() => setModalDetail({ state: false, query: null })}
        query={modalDetail.query}
      />
    </>
  );
};

export default LaporanBulanan;
