import { Button, Space } from 'antd';

export const Footer = ({
  onCancel,
  onSubmit,
  toBeLocked,
  onlyClose,
  loadingState = false,
  CustomOk = '',
  submitDisabled=false,
}) => {
  return (
    <footer style={{ textAlign: 'right' }}>
      <Space>
        <Button type={onlyClose ? 'primary' : 'secondary'} onClick={onCancel}>
          {onlyClose ? 'Tutup' : 'Batal'}
        </Button>
        {!onlyClose && (
          <Button
            onClick={onSubmit}
            type="primary"
            htmlType="submit"
            loading={loadingState}
            disabled={submitDisabled}
          >
            {toBeLocked ? 'Lock Pengaturan' : CustomOk ? CustomOk : 'Simpan'}
          </Button>
        )}
      </Space>
    </footer>
  );
};
