import styled from 'styled-components';

export const UnitListContainer =  styled.div`
  margin: 2rem 0;
  height: 78vh;
  overflow-x: auto;
  white-space: nowrap;
`

export const UnitItemContainer =  styled.div`
  width: 30rem;
  display: inline-block;
  margin-right: 1.2rem;
  vertical-align: top;
`;