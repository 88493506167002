import React, { useEffect, useState } from 'react';
import { Drawer, Button, Form } from 'antd';
import { Footer } from './shared';
import { InputWrapper } from '..';

const VirtualAccount = (props) => {
  const [form] = Form.useForm();
  const {
    visible,
    onCancel,
    value,
    isEdit,
    handleSubmit,
    loadingState,
    ...rest
  } = props;

  useEffect(() => {
    if (isEdit) form.setFieldsValue({ va_code: value });
  }, [isEdit]);

  return (
    <Drawer
      title={`${isEdit ? 'Edit' : 'Tambah'} Virtual Account`}
      width="40rem"
      visible={visible}
      footer={
        <Footer
          onCancel={() => {
            form.setFieldsValue({ va_code: '' });
            onCancel();
          }}
          onSubmit={form.submit}
          loadingState={loadingState}
        />
      }
      onClose={() => {
        form.setFieldsValue({ va_code: '' });
        onCancel();
      }}
      {...rest}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          va_code: isEdit ? value : '',
        }}
        onFinish={(val) => handleSubmit(val)}
      >
        <InputWrapper
          type="text"
          placeholder="contoh: 76690"
          name="va_code"
          label="Kode Bank Virtual Account"
        />
      </Form>
    </Drawer>
  );
};

export default VirtualAccount;
