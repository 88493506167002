import {
  Button,
  Col,
  Input,
  Popover,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from 'antd';
import { Card } from 'components/fragments';
import { Drawer, InputWrapper, Modal, PageHeader } from 'components/shared';
import React, { useEffect, useState } from 'react';
import { useStore } from 'store';
import IC_PLUSR from 'assets/icons/plus-rounded.svg';
import queryString from 'querystring';
import IC_EDIT from 'assets/icons/Edit.svg';
import { CustomButton } from 'components/atoms';
import { useHistory, useLocation } from 'react-router-dom';
import {
  createNewUser,
  editUserData,
  fetchUserList,
  getListKelasId,
  getListMurid,
  getListTahunAjaran,
  getBill,
  generateExcelTagihan,
} from 'utils/fetch';
import {
  AccountBookOutlined,
  DownloadOutlined,
  EyeOutlined,
  FilterOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { InfoCard } from 'components/fragments/card';
import moment from 'moment';
import exportExcel from 'utils/helpers/xlsx';
import { goToSearchQuery } from 'utils/helpers/common';

const SampleData = [
  {
    no: 1,
    name: 'Test',
    grade: {
      name: 'TK A1',
    },
    spp_month: 'Agustus 2021',
    amount: 200000,
    totalDebt: 0,
    _id: 'asdjkhasjkd8675agsjha',
  },
];

const TagihanPPDB = () => {
  const {
    handleBreadcrumb,
    handleSiderActive,
    handleListSchoolYear,
    handleListKelas,
    state: { activeSchoolYear, selectedUnit, listKelas, listTahunAjaran },
  } = useStore();
  const history = useHistory();
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState(null);
  const [selectedId, setSelectedId] = useState('');
  const [filterPopover, setFilterPopover] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totalData, setTotalData] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [otherData, setOtherData] = useState(null);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [filter, setFilter] = useState({
    name: null,
    grade: null,
    status: null,
    nis: null,
    school_year: null,
  });
  const unitId = selectedUnit?._id;
  const schoolYearId = activeSchoolYear?._id;
  const {
    unit,
    limit = 10,
    page = 1,
    status,
    name,
    grade,
    nis,
    school_year,
  } = queryString.parse(location.search.replace('?', ''));
  const filterQuery = {
    unit,
    limit,
    page,
    name,
    grade,
    status,
    nis,
    school_year,
  };

  useEffect(() => {
    const newBreadcrumb = [
      {
        linkTo: '/tagihan/psb',
        node: <span>Daftar Tagihan PSB</span>,
      },
    ];
    handleBreadcrumb(newBreadcrumb);
    handleSiderActive(['3.2']);
  }, []);

  useEffect(() => {
    if (currentPage) {
      Object.keys(filterQuery).forEach((key) => {
        if (!filterQuery[key]) delete filterQuery[key];
      });
      history.push({
        search: queryString.stringify({
          ...filterQuery,
          page: currentPage,
        }),
      });
    }
  }, [currentPage]);

  useEffect(() => {
    if (unitId && schoolYearId) {
      fetchDataListKelas();
      // fetchDataTahunAjaran();
    }
  }, [unitId, schoolYearId]);

  useEffect(() => {
    if (selectedUnit) {
      fetchData();
    }

    return () => {};
  }, [page, limit, status, name, grade, nis, school_year, selectedUnit]);

  useEffect(() => {
    setFilter({
      status,
      name,
      grade,
      nis,
      school_year,
    });
  }, [status, name, grade, nis, school_year]);

  const fetchDataTahunAjaran = () => {
    getListTahunAjaran()
      .then((res) => {
        console.log(res);
        handleListSchoolYear(res.schoolsYears);
      })
      .catch((err) => console.error(err));
  };

  const fetchDataListKelas = () => {
    getListKelasId(unitId, schoolYearId)
      .then((res) => {
        console.log(res.grades);
        handleListKelas(res.grades);
      })
      .catch((err) => console.error(err));
  };

  const fetchData = () => {
    let query = `limit=${limit}&page=${page - 1}`;

    if (name) query += `&student_name=${name}`;
    if (status) query += `&student_status=${status}`;
    if (grade) query += `&student_grade=${grade}`;
    if (nis) query += `&student_nik=${nis}`;
    if (school_year) query += `&school_year=${school_year}`;

    setLoading(true);

    getBill(unitId, 'ppdb', query)
      .then((res) => {
        const numbered = res.ppdbBills.map((el, idx) => ({
          ...el,
          no: idx + 1,
        }));
        console.log(res);
        setData(numbered);
        setTotalData(res.totalData);
        setLoading(false);
        setOtherData({
          outstandingBill: res.outstandingBill,
          outstandingStudent: res.outstandingStudent,
          totalOutstanding: res.totalOutstanding,
        });
        setFilterPopover(false);
        // console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        setFilterPopover(false);
        console.log(err.response, 'this is error fetch');
      });
  };

  const handleSubmitFilter = () => {
    Object.keys(filter).forEach((key) => {
      if (!filter[key]) delete filter[key];
    });
    history.push({
      pathname: location.pathname,
      search: queryString.stringify({
        unit,
        limit,
        page,
        ...filter,
      }),
    });
  };

  const filterMenu = (
    <div style={{ width: '30rem' }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Input
          type="text"
          name="name"
          onChange={(e) => {
            const newFilter = {
              ...filter,
              name: e.target.value,
            };
            setFilter(newFilter);
          }}
          value={filter?.name}
          placeholder="Nama Murid"
        />
        <Input
          type="text"
          name="nis"
          onChange={(e) => {
            const newFilter = {
              ...filter,
              nis: e.target.value,
            };
            setFilter(newFilter);
          }}
          value={filter?.nis}
          placeholder="Nomor NIS"
        />
        <Select
          placeholder="Tahun Ajaran"
          style={{ width: '100%' }}
          onChange={(val) => {
            const newFilter = {
              ...filter,
              school_year: val,
            };
            setFilter(newFilter);
          }}
          value={filter?.school_year}
        >
          {listTahunAjaran?.map((year) => {
            return <Select.Option value={year._id}>{year.name}</Select.Option>;
          })}
        </Select>
        <Select
          placeholder="Kelas"
          style={{ width: '100%' }}
          onChange={(val) => {
            const newFilter = {
              ...filter,
              grade: val,
            };
            setFilter(newFilter);
          }}
          value={filter?.grade}
        >
          {[
            { _id: 'Murid Tanpa Kelas', name: 'Murid Tanpa Kelas' },
            ...listKelas,
          ].map((kelas) => {
            return (
              <Select.Option value={kelas._id}>{kelas.name}</Select.Option>
            );
          })}
        </Select>
        <Select
          placeholder="Status Murid"
          style={{ width: '100%' }}
          onChange={(val) => {
            const newFilter = {
              ...filter,
              status: val,
            };
            setFilter(newFilter);
          }}
          value={filter?.status}
        >
          <Select.Option value={'2'}>Tidak Aktif</Select.Option>
          <Select.Option value={'1'}>Aktif</Select.Option>
          <Select.Option value={'3'}>Lulus</Select.Option>
        </Select>
        <footer
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            borderTop: '1px solid #d5d5d5',
            paddingTop: '0.5rem',
          }}
        >
          <Space>
            <Button
              onClick={() => {
                setFilterPopover(false);
                setFilter({
                  name: null,
                  status: null,
                  grade: null,
                  nis: null,
                  school_year: null,
                });
                history.push({
                  pathname: location.pathname,
                  search: queryString.stringify({
                    unit: selectedUnit.name,
                    limit,
                    page,
                  }),
                });
              }}
            >
              Hapus Filter
            </Button>
            <Button type="primary" onClick={handleSubmitFilter}>
              Cari
            </Button>
          </Space>
        </footer>
      </Space>
    </div>
  );

  const tableColumns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Nama Murid',
      dataIndex: 'student',
      key: 'student',
      width: 300,
      render: (data, record) => {
        return (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() =>
              window.open(
                `/daftar-murid/detail/${data?._id}?unit=${selectedUnit?.name}`,
                '_blank'
              )
            }
          >
            {data?.name || '-'}
          </span>
        );
      },
    },
    {
      title: 'Kelas',
      dataIndex: 'student',
      key: 'student_grade',
      render: (data) => <span>{data?.grade?.name || 'Murid Tanpa Kelas'}</span>,
    },
    {
      title: 'Tahun Ajaran',
      dataIndex: 'school_year',
      key: 'school_year',
      width: 160,
      render: (data) => <span>{data?.name}</span>,
    },
    {
      title: 'Nominal Tagihan',
      dataIndex: 'total_amount',
      key: 'total_amount',
      width: 200,
      render: (data) => <span>Rp{Number(data || 0).toLocaleString()}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'paid_amount',
      key: 'paid_amount',
      render: (data, record) => {
        if (!record.active_status) {
          return <Tag color="red">TIDAK AKTIF</Tag>;
        } else if (record.canceled_status) {
          return <Tag color="red">CANCEL</Tag>;
        }

        const total = record.total_amount - (data || 0);
        const isLunas = total === 0;

        if (isLunas) {
          return <Tag color="green">LUNAS</Tag>;
        } else {
          return (
            <Tag color="red">-Rp{Number(total || 0).toLocaleString()}</Tag>
          );
        }
      },
    },
    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      align: 'center',
      render: (id) => (
        <Button
          onClick={() => {
            setOpenDrawer(true);
            setSelectedId(id);
          }}
          icon={<EyeOutlined />}
        >
          Lihat
        </Button>
      ),
    },
  ];

  const generateParsed = (data) => {
    const parsed = [
      [
        'No',
        'Nama Murid',
        'NIS',
        'Kelas',
        'Tahun Ajaran',
        'Uang Gedung',
        'Terbayar',
        'Uang Pengembangan',
        'Terbayar',
        'Kegiatan',
        'Terbayar',
      ],
    ];

    data.forEach((dt, idx) => {
      const array = [
        idx + 1,
        dt.student.name,
        dt.student?.nik || '-',
        dt.student.grade?.name || 'Murid Tanpa Kelas',
        dt.school_year.name,
      ];

      dt.ppdbBillItems?.forEach((item, idx) => {
        array.push(item.total_amount);
        array.push(item.paid_amount);
      });

      const final = [
        ...array,
        dt.total_amount,
        dt.paid_amount,
        dt.total_amount - dt.paid_amount,
      ];

      parsed.push(final);
    });

    parsed.splice(0, 1, [
      ...parsed[0],
      'Nominal Tagihan',
      'Sudah Bayar',
      'Sisa',
    ]);

    return [
      [
        'No',
        'Nama Murid',
        'NIS',
        'Kelas',
        'Tahun Ajaran',
        'Item Tagihan',
        '',
        '',
        '',
        '',
        '',
        'Nominal Tagihan',
        'Sudah Bayar',
        'Sisa',
      ],
      ...parsed,
    ];
  };

  const handleDownload = () => {
    let query = [];

    if (name) query.push(`student_name=${name}`);
    if (status) query.push(`student_status=${status}`);
    if (grade) query.push(`student_grade=${grade}`);
    if (nis) query.push(`student_nik=${nis}`);
    if (school_year) query.push(`&school_year=${school_year}`);

    const strQuery = query.join('&');

    setLoadingDownload(true);

    generateExcelTagihan(unitId, 'ppdb', strQuery)
      .then((res) => {
        const { excelGenerate } = res;
        // console.log(excelGenerate);
        if (res) {
          const toBeDownloaded = Object.keys(excelGenerate).map((dt) => {
            const parsed = generateParsed(excelGenerate[dt].dataList);

            return { data: parsed, sheetName: dt.replace('/', '-') };
          });

          console.log(toBeDownloaded);

          const merge = [
            { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },
            { s: { r: 0, c: 1 }, e: { r: 1, c: 1 } },
            { s: { r: 0, c: 2 }, e: { r: 1, c: 2 } },
            { s: { r: 0, c: 3 }, e: { r: 1, c: 3 } },
            { s: { r: 0, c: 4 }, e: { r: 1, c: 4 } },
            { s: { r: 0, c: 11 }, e: { r: 1, c: 11 } },
            { s: { r: 0, c: 12 }, e: { r: 1, c: 12 } },
            { s: { r: 0, c: 13 }, e: { r: 1, c: 13 } },
            { s: { c: 5, r: 0 }, e: { c: 10, r: 0 } },
            // { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } },
          ];

          exportExcel(toBeDownloaded, 'Laporan Tagihan PPDB', true, merge);
        }
      })
      .catch((err) => {
        console.log(err, 'error response');
      })
      .finally(() => setLoadingDownload(false));
  };

  return (
    <>
      <PageHeader>
        <Space>
          <Button
            type=""
            icon={<DownloadOutlined />}
            loading={loadingDownload}
            onClick={handleDownload}
          >
            Download Laporan
          </Button>
          <CustomButton.IconTextButton
            type="primary"
            icon={IC_PLUSR}
            onClick={() =>
              history.push({
                pathname: '/tagihan/psb/tambah-tagihan',
                search: queryString.stringify({
                  unit: selectedUnit.name,
                }),
              })
            }
          >
            Buat Tagihan
          </CustomButton.IconTextButton>
        </Space>
      </PageHeader>
      <section style={{ width: 'auto', margin: '2rem 0' }}>
        <Popover
          content={filterMenu}
          visible={filterPopover}
          placement="bottomLeft"
          style={{ width: '30rem' }}
        >
          <Button
            onClick={() => setFilterPopover(!filterPopover)}
            icon={<FilterOutlined />}
          >
            Cari Berdasarkan
          </Button>
        </Popover>
      </section>

      <Row gutter={[24, 0]}>
        <Col span="8">
          <InfoCard
            title="Jumlah Murid Menunggak"
            content={otherData?.outstandingStudent || 0}
            sideColor="#FFA6A7"
          />
        </Col>
        <Col span="8">
          <InfoCard
            title="Jumlah Tagihan Belum Lunas"
            content={otherData?.outstandingBill || 0}
            sideColor="#FFA6A7"
          />
        </Col>
        <Col span="8">
          <InfoCard
            title="Total Tunggakan"
            content={`Rp${
              (otherData?.totalOutstanding || 0).toLocaleString() || 0
            }`}
            sideColor="#FFA6A7"
          />
        </Col>
      </Row>

      <section style={{ width: '100%', margin: '2rem 0' }}>
        <Table
          dataSource={data ? data : SampleData}
          columns={tableColumns}
          pagination={{
            position: ['bottomLeft'],
            total: totalData && totalData,
            onChange: (page) => {
              setCurrentPage(page);
            },
            current: currentPage,
            pageSize: limit,
            showSizeChanger: true,
            pageSizeOptions: ['10', '30', '50'],
            onShowSizeChange: (curr, pageSize) => {
              goToSearchQuery('limit', pageSize, filterQuery, history);
            },
          }}
          scroll={{ x: 1000 }}
          loading={loading}
        />
      </section>

      <Drawer.DetailTagihanPPDB
        visible={openDrawer}
        onCancel={() => {
          setOpenDrawer(false);
        }}
        value={{ id: selectedId, unitId: unitId }}
        refetchData={fetchData}
      />

      <Modal.ImportVA visible={openModal} onClose={() => setOpenModal(false)} />
    </>
  );
};

export default TagihanPPDB;
