import React, { useEffect, useState } from 'react';
import { Button, Input, message, Modal, Space, Table } from 'antd';
import { useStore } from 'store';
import moment from 'moment';
import { getLaporanHarianDetailPembayaran } from 'utils/fetch';

const DetailPembayaranLaporanHarian = ({
  visible,
  onClose,
  query,
  ...rest
}) => {
  const {
    state: { activeSchoolYear },
  } = useStore();

  const [data, setData] = useState([]);
  const [copiedData, setCopiedData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [nameSearch, setNameSearch] = useState('');

  let title = 'Detail Pembayaran';

  useEffect(() => {
    if (query) {
      _fetchDetailData(query);
    }
  }, [query]);

  const _fetchDetailData = async (query) => {
    try {
      setLoading(true);
      const response = await getLaporanHarianDetailPembayaran(query);

      console.log({ response }, 'detail pemabayaran laporan harian');
      setData(response.value.payments);
      setTotalData(response.value.payments?.length);
    } catch (err) {
      console.log({ err });
      message.error('Error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (nameSearch) {
      const filtered = data.filter((item) => {
        const paidItems = item?.paid_items
          .map(
            (el) => `${el.typeName}: Rp${Number(el.amount).toLocaleString()}`
          )
          .join('; ');
        return (
          item?.student?.name
            .toLowerCase()
            .trim()
            .includes(nameSearch.toLowerCase().trim()) ||
          item?.student?.grade?.name
            .toLowerCase()
            .trim()
            .includes(nameSearch.toLowerCase().trim()) ||
          item?.name
            .toLowerCase()
            .trim()
            .includes(nameSearch.toLowerCase().trim()) ||
          item?.student.virtual_account
            .toLowerCase()
            .trim()
            .includes(nameSearch.toLowerCase().trim()) ||
          paidItems
            ?.toLowerCase()
            .trim()
            .includes(nameSearch.toLowerCase().trim())
        );
      });

      setCopiedData(filtered);
    } else {
      setCopiedData(data);
    }
  }, [nameSearch, data]);

  const tableColumns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: 80,
      render: (data, _, idx) => <span>{idx + 1}</span>,
    },
    {
      title: 'Tanggal',
      dataIndex: 'date',
      key: 'date',
      width: 150,
      render: (data) => <span>{moment(data).format('DD/MM/YYYY')}</span>,
    },
    {
      title: 'Nama Murid',
      dataIndex: 'student',
      key: 'name',
      width: 300,
      render: (data) => <span>{data?.name}</span>,
    },
    {
      title: 'Virtual Account',
      dataIndex: 'student',
      key: 'virtual_account',
      width: 100,
      render: (data) => <span>{data?.virtual_account || '-'}</span>,
    },
    {
      title: 'Kelas',
      dataIndex: 'student',
      key: 'name',
      width: 200,
      render: (data) => <span>{data?.grade?.name || 'Murid Tanpa Kelas'}</span>,
    },
    {
      title: 'Nama Tagihan',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Total Nominal',
      dataIndex: 'total_amount',
      key: 'total_amount',
      width: 200,
      render: (data) => <span>{`Rp${(data || 0).toLocaleString()}`}</span>,
    },
    {
      title: 'Item Tagihan',
      dataIndex: 'paid_items',
      key: 'paid_items',
      width: 200,
      render: (data) => (
        <span>
          {data
            .map(
              (el) => `${el.typeName}: Rp${Number(el.amount).toLocaleString()}`
            )
            .join('; ') || '-'}
        </span>
      ),
    },
  ];

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onClose}
      {...rest}
      centered
      cancelText="Batal"
      width="70vw"
    >
      <section style={{ margin: '1rem 0' }}>
        <section
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '2rem',
          }}
        >
          <Space>
            <Input.Search
              placeholder="Cari nama murid"
              value={nameSearch}
              onChange={(e) => setNameSearch(e.target.value)}
            />
          </Space>
        </section>

        <Table
          columns={tableColumns}
          dataSource={copiedData}
          pagination={{
            position: ['bottomLeft'],
            total: totalData && totalData,
            onChange: (page) => {
              setCurrentPage(page);
            },
            current: currentPage,
            pageSize: limit,
            showSizeChanger: true,
            pageSizeOptions: ['10', '30', '50'],
            onShowSizeChange: (curr, pageSize) => {
              setLimit(pageSize);
            },
          }}
          scroll={{ x: 1200, y: 350 }}
          loading={loading}
        />
      </section>
    </Modal>
  );
};

export default DetailPembayaranLaporanHarian;
