import React, { useEffect, useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { StyledHeader } from './style';
import IC_USER from 'assets/icons/user-icon.svg';
import IC_CDOWN from 'assets/icons/chevron-down.svg';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { logout } from 'lib/authentication';
import queryString from 'querystring';
import { getActiveSchoolYear } from 'utils/fetch';
import { useStore } from 'store';

const CustomHeader = () => {
  const location = useLocation();
  const history = useHistory();
  const {
    state: { activeSchoolYear, userData },
    handleSchoolYear,
  } = useStore();
  const { unit = '' } = queryString.parse(location.search.replace('?', ''));
  const [current, setCurrent] = useState(['dashboard']);

  useEffect(() => {
    const isDashboard = location.pathname.includes('dashboard');
    if (isDashboard) setCurrent(['dashboard']);
    if (unit === 'tk' && !isDashboard) setCurrent(['tk']);
    if (unit === 'sd' && !isDashboard) setCurrent(['sd']);
    if (unit === 'smp' && !isDashboard) setCurrent(['smp']);
    if (unit === 'sma' && !isDashboard) setCurrent(['sma']);
    if (location.pathname.includes('settings')) setCurrent(['pengaturan']);
  }, [location, unit]);

  useEffect(() => {
    const isDashboard = location.pathname.includes('dashboard');
    if (userData.type === 2 && !isDashboard) {
      history.push({
        search: queryString.stringify({
          unit: userData.unit?.name.toLowerCase(),
        }),
      });
    }
  }, [userData]);

  useEffect(() => {
    if (!activeSchoolYear) {
      getActiveSchoolYear()
        .then((data) => {
          console.log(data);
          handleSchoolYear(data);
        })
        .catch((err) => console.log(err));
    }
  }, [activeSchoolYear]);

  const dropdownMenu = (
    <Menu
      style={{
        padding: '2rem 0',
        width: '20rem',
      }}
    >
      <Menu.Item
        style={{
          width: '100%',
        }}
      >
        <Link to="/profile">Profil Saya</Link>
      </Menu.Item>
      <Menu.Item
        style={{
          width: '100%',
        }}
        onClick={() => {
          logout();
          window.location.reload();
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <StyledHeader>
      <Menu
        theme="light"
        mode="horizontal"
        defaultSelectedKeys={['dashboard']}
        selectedKeys={current}
        style={{ border: 'none' }}
      >
        <Menu.Item key="dashboard">
          <Link
            to={{
              pathname: '/dashboard/semua',
            }}
          >
            Dashboard
          </Link>
        </Menu.Item>
        {(userData?.unit?.name.toLowerCase() === 'tk' ||
          userData?.type === 1) && (
          <Menu.Item key="tk">
            <Link
              to={{
                pathname: '/daftar-kelas',
                search: queryString.stringify({
                  unit: 'tk',
                }),
              }}
            >
              TK
            </Link>
          </Menu.Item>
        )}
        {(userData?.unit?.name.toLowerCase() === 'sd' ||
          userData?.type === 1) && (
          <Menu.Item key="sd">
            <Link
              to={{
                pathname: '/daftar-kelas',
                search: queryString.stringify({
                  unit: 'sd',
                }),
              }}
            >
              SD
            </Link>
          </Menu.Item>
        )}
        {(userData?.unit?.name.toLowerCase() === 'smp' ||
          userData?.type === 1) && (
          <Menu.Item key="smp">
            <Link
              to={{
                pathname: '/daftar-kelas',
                search: queryString.stringify({
                  unit: 'smp',
                }),
              }}
            >
              SMP
            </Link>
          </Menu.Item>
        )}
        {(userData?.unit?.name.toLowerCase() === 'sma' ||
          userData?.type === 1) && (
          <Menu.Item key="sma">
            <Link
              to={{
                pathname: '/daftar-kelas',
                search: queryString.stringify({
                  unit: 'sma',
                }),
              }}
            >
              SMA
            </Link>
          </Menu.Item>
        )}
        {userData.type === 1 && (
          <Menu.Item key="pengaturan">
            <Link to="/settings/tahun-ajaran">Pengaturan</Link>
          </Menu.Item>
        )}
      </Menu>
      <section
        style={{
          padding: '0 2rem',
          display: 'flex',
        }}
      >
        <h4>Tahun Ajaran {activeSchoolYear?.name}</h4>
        <Dropdown
          placement="bottomRight"
          overlay={dropdownMenu}
          trigger={['click']}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '2rem',
              cursor: 'pointer',
            }}
          >
            <img
              src={IC_USER}
              alt="user"
              width={30}
              height={30}
              style={{ marginRight: '1.25rem' }}
            />
            <img src={IC_CDOWN} alt="down" />
          </div>
        </Dropdown>
      </section>
    </StyledHeader>
  );
};

export default CustomHeader;
