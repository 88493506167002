import {
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  Popover,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from 'antd';
import { Card } from 'components/fragments';
import { Drawer, InputWrapper, PageHeader } from 'components/shared';
import React, { useEffect, useState } from 'react';
import { useStore } from 'store';
import IC_PLUSR from 'assets/icons/plus-rounded.svg';
import queryString from 'querystring';
import IC_EDIT from 'assets/icons/Edit.svg';
import { CustomButton } from 'components/atoms';
import { useHistory, useLocation } from 'react-router-dom';
import {
  createNewUser,
  editUserData,
  fetchUserList,
  getListKelasId,
  getListMurid,
  getListTahunAjaran,
  getPayment,
} from 'utils/fetch';
import {
  AccountBookOutlined,
  DownloadOutlined,
  EyeOutlined,
  FilterOutlined,
  InfoCircleOutlined,
  RetweetOutlined,
} from '@ant-design/icons';
import { InfoCard } from 'components/fragments/card';
import { goToSearchQuery } from 'utils/helpers/common';

const Tabungan = () => {
  const {
    handleBreadcrumb,
    handleSiderActive,
    handleListSchoolYear,
    handleListKelas,
    state: { activeSchoolYear, selectedUnit, listKelas, listTahunAjaran },
  } = useStore();
  const history = useHistory();
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = useState({
    state: false,
    content: 0,
    id: '',
  });
  const [data, setData] = useState(null);
  const [isEdit, setIsEdit] = useState({
    state: false,
    data: null,
  });
  const [detailData, setDetailData] = useState(null);
  const [filterPopover, setFilterPopover] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totalData, setTotalData] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [otherData, setOtherData] = useState(null);
  const [filter, setFilter] = useState({
    name: null,
    grade: null,
    status: null,
  });
  const unitId = selectedUnit?._id;
  const schoolYearId = activeSchoolYear?._id;
  const {
    unit,
    limit = 10,
    page,
    status = null,
    name = '',
    grade = null,
    nis = '',
  } = queryString.parse(location.search.replace('?', ''));
  const filterQuery = { unit, limit, page, name, grade, status, nis };

  useEffect(() => {
    const newBreadcrumb = [
      {
        linkTo: '/tabungan',
        node: <span>Daftar Tabungan</span>,
      },
    ];
    handleBreadcrumb(newBreadcrumb);
    handleSiderActive(['6']);
  }, []);

  useEffect(() => {
    if (currentPage) {
      Object.keys(filterQuery).forEach((key) => {
        if (!filterQuery[key]) delete filterQuery[key];
      });
      history.push({
        search: queryString.stringify({
          ...filterQuery,
          page: currentPage,
        }),
      });
    }
  }, [currentPage]);

  useEffect(() => {
    if (unitId && schoolYearId) {
      fetchDataListKelas();
      fetchDataTahunAjaran();
    }
  }, [unitId, schoolYearId]);

  useEffect(() => {
    if (selectedUnit) {
      fetchData();
    }

    return () => {};
  }, [page, limit, name, status, grade, nis, selectedUnit]);

  useEffect(() => {
    setFilter({
      name,
      status,
      grade,
      nis,
    });
  }, [name, status, grade, nis]);

  const fetchDataTahunAjaran = () => {
    getListTahunAjaran()
      .then((res) => {
        console.log(res);
        handleListSchoolYear(res.schoolsYears);
      })
      .catch((err) => console.error(err));
  };

  const fetchDataListKelas = () => {
    getListKelasId(unitId, schoolYearId)
      .then((res) => {
        console.log(res.grades);
        handleListKelas(res.grades);
      })
      .catch((err) => console.error(err));
  };

  const fetchData = () => {
    let query = `limit=${limit}&page=${page - 1}`;

    if (name) query += `&name=${name}`;
    if (status) query += `&status=${status}`;
    if (grade) query += `&grade=${grade}`;
    if (nis) query += `&nik=${nis}`;

    setLoading(true);

    getPayment(unitId, 'savings', query)
      .then((res) => {
        const numbered = res.students.map((el, idx) => ({
          ...el,
          no: idx + 1,
        }));
        console.log(res);
        setData(numbered);
        setTotalData(res.totalData);
        setOtherData({
          belumAlokasi: res.notYetAllocateCount,
          totalAmount: res.notYetAllocateAmount,
        });
        setLoading(false);
        setFilterPopover(false);
      })
      .catch((err) => {
        setLoading(false);
        setFilterPopover(false);
        console.log(err.response, 'this is error fetch');
      })
      .finally(() => setLoading(false));
  };

  const handleSubmitFilter = () => {
    Object.keys(filter).forEach((key) => {
      if (!filter[key]) delete filter[key];
    });
    history.push({
      pathname: location.pathname,
      search: queryString.stringify({
        unit,
        limit,
        page,
        ...filter,
      }),
    });
    setFilterPopover(false);
  };

  const filterMenu = (
    <div style={{ width: '30rem' }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Input
          type="text"
          name="name"
          placeholder="Nama Murid"
          value={filter?.name}
          onChange={(e) => {
            const newFilter = {
              ...filter,
              name: e.target.value,
            };
            setFilter(newFilter);
          }}
        />
        <Input
          type="text"
          name="nis"
          placeholder="Nomor NIS"
          value={filter?.nis}
          onChange={(e) => {
            const newFilter = {
              ...filter,
              nis: e.target.value,
            };
            setFilter(newFilter);
          }}
        />
        <Select
          placeholder="Kelas"
          style={{ width: '100%' }}
          value={filter?.grade}
          onChange={(val) => {
            const newFilter = {
              ...filter,
              grade: val,
            };
            setFilter(newFilter);
          }}
        >
          {[
            { _id: 'Murid Tanpa Kelas', name: 'Murid Tanpa Kelas' },
            ...listKelas,
          ].map((kelas) => {
            return (
              <Select.Option value={kelas._id}>{kelas.name}</Select.Option>
            );
          })}
        </Select>
        <Select
          value={filter?.status}
          placeholder="Status Murid"
          style={{ width: '100%' }}
          onChange={(val) => {
            const newFilter = {
              ...filter,
              status: val,
            };
            setFilter(newFilter);
          }}
        >
          {[
            {
              id: 'Aktif',
              text: 'Aktif',
            },
            {
              id: 'Tidak Aktif',
              text: 'Tidak Aktif',
            },
            {
              id: 'Lulus',
              text: 'Lulus',
            },
          ].map((status) => {
            return (
              <Select.Option value={status.id}>{status.text}</Select.Option>
            );
          })}
        </Select>
        <footer
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            borderTop: '1px solid #d5d5d5',
            paddingTop: '0.5rem',
          }}
        >
          <Space>
            <Button
              onClick={() => {
                setFilterPopover(false);
                setFilter({
                  name: null,
                  status: null,
                  grade: null,
                  nis: null,
                });
                history.push({
                  pathname: location.pathname,
                  search: queryString.stringify({
                    unit: selectedUnit.name,
                    limit,
                    page,
                  }),
                });
              }}
            >
              Hapus Filter
            </Button>
            <Button type="primary" onClick={handleSubmitFilter}>
              Cari
            </Button>
          </Space>
        </footer>
      </Space>
    </div>
  );

  const LihatMenu = (id) => (
    <Menu
      style={{
        boxShadow:
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      }}
    >
      <Menu.Item
        onClick={() => {
          setOpenDrawer({
            state: true,
            content: 0,
            id,
          });
        }}
      >
        Rincian Tabungan Masuk
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setOpenDrawer({
            state: true,
            content: 1,
            id,
          });
        }}
      >
        Riwayat Alokasi Tabungan
      </Menu.Item>
    </Menu>
  );

  const AlokasiMenu = (id) => (
    <Menu
      style={{
        boxShadow:
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      }}
    >
      <Menu.Item
        onClick={() => {
          setOpenDrawer({
            state: true,
            content: 2,
            id,
          });
        }}
      >
        Alokasi Tabungan
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setOpenDrawer({
            state: true,
            content: 3,
            id,
          });
        }}
      >
        Refund Tabungan
      </Menu.Item>
    </Menu>
  );

  const tableColumns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Nama Murid',
      dataIndex: 'name',
      key: 'name',
      width: 250,
      render: (data, record) => {
        return (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() =>
              window.open(
                `/daftar-murid/detail/${record._id}?unit=${selectedUnit?.name}`,
                '_blank'
              )
            }
          >
            {data || '-'}
          </span>
        );
      },
    },
    {
      title: 'Kelas',
      dataIndex: 'grade',
      key: 'grade',
      render: (data) => <span>{data ? data.name : 'Murid Tanpa Kelas'}</span>,
    },
    {
      title: 'Total Tabungan',
      dataIndex: 'savings',
      key: 'savings',
      width: 200,
      render: (data) => <span>Rp{Number(data || 0).toLocaleString()}</span>,
    },
    {
      title: 'Total Tunggakan',
      dataIndex: 'totalDebt',
      key: 'totalDebt',
      render: (data) => {
        return <span>{`Rp${Number(data || 0).toLocaleString()}`}</span>;
      },
    },
    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      align: 'center',
      render: (id) => (
        <Space>
          <Dropdown overlay={<LihatMenu id={id} />} placement="bottomRight">
            <Button icon={<EyeOutlined />}>Lihat</Button>
          </Dropdown>

          <Dropdown overlay={<AlokasiMenu id={id} />} placement="bottomRight">
            <Button icon={<RetweetOutlined />}>Alokasi/Refund</Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <>
      <PageHeader>
        <Space>
          {/* <CustomButton.IconTextButton
            type="primary"
            icon={IC_PLUSR}
            onClick={() =>
              history.push({
                pathname: '/tagihan/other/tambah-tagihan',
                search: queryString.stringify({
                  unit: selectedUnit.name,
                }),
              })
            }
          >
            Tambah Tabungan
          </CustomButton.IconTextButton> */}
        </Space>
      </PageHeader>
      <section style={{ width: 'auto', margin: '2rem 0' }}>
        <Popover
          content={filterMenu}
          visible={filterPopover}
          placement="bottomLeft"
          style={{ width: '30rem' }}
        >
          <Button
            onClick={() => setFilterPopover(!filterPopover)}
            icon={<FilterOutlined />}
          >
            Cari Berdasarkan
          </Button>
        </Popover>
      </section>

      <Row gutter={[24, 0]}>
        <Col span="8">
          <InfoCard
            title="Jumlah Murid Memiliki Tabungan"
            content={otherData?.belumAlokasi || 0}
            sideColor="green"
          />
        </Col>
        <Col span="8">
          <InfoCard
            title="Jumlah Tabungan Murid"
            content={`Rp${(otherData?.totalAmount || 0).toLocaleString()}`}
            sideColor="blue"
          />
        </Col>
      </Row>

      <section style={{ width: '100%', margin: '2rem 0' }}>
        <Table
          dataSource={data}
          columns={tableColumns}
          pagination={{
            position: ['bottomLeft'],
            total: totalData && totalData,
            onChange: (page) => {
              setCurrentPage(page);
            },
            current: currentPage,
            pageSize: limit,
            showSizeChanger: true,
            pageSizeOptions: ['10', '30', '50'],
            onShowSizeChange: (curr, pageSize) => {
              goToSearchQuery('limit', pageSize, filterQuery, history);
            },
          }}
          scroll={{ x: 1000 }}
          loading={loading}
        />
      </section>

      <Drawer.DetailTabungan
        visible={openDrawer.state}
        onCancel={() => {
          setOpenDrawer({
            content: 0,
            id: '',
            state: false,
          });
        }}
        value={{ studentId: openDrawer.id, unitId: unitId }}
        content={openDrawer.content}
        refetchData={fetchData}
      />
    </>
  );
};

export default Tabungan;
