import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, Col, DatePicker, message, Modal, Row, Select } from 'antd';
import { useStore } from 'store';
import { parsingVABCA, toText } from 'utils/helpers/parse-va';
import moment from 'moment';
import { downloadVADashboard } from 'utils/fetch';
import exportExcel from 'utils/helpers/xlsx';
import { setLocalStorage } from 'utils/helpers/storage';

const FilterDashboardKeseluruhan = ({
  visible,
  onClose,
  refetchData,
  unitId,
  onSelectedFilter,
  units,
  cutOffDate,
  dashboardUnit,
  ...rest
}) => {
  const {
    state: { unitData, userData },
  } = useStore();
  const [parsedData, setParsedData] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [unit, setUnit] = useState(units || []);
  const [cutOff, setCutOff] = useState(cutOffDate || null);
  const [loading, setLoading] = useState(false);
  const arrayUnit = Object.keys(unitData).map((key) => unitData[key]);

  useEffect(() => {
    if (!visible) {
      setParsedData([]);
      setFileData(null);
    }
  }, [visible]);

  const disabled = cutOff && unit.length ? false : true;

  const unitsCheckbox = useMemo(() => {
    return arrayUnit.length
      ? arrayUnit.map((item) => ({ label: item.name, value: item._id }))
      : [];
  }, [arrayUnit]);

  return (
    <Modal
      title="Filter Dashboard Keseluruhan"
      visible={visible}
      onCancel={onClose}
      centered
      okText="Download"
      cancelText="Batal"
      width="40vw"
      okButtonProps={{
        loading: loading,
        disabled: disabled,
      }}
      cancelButtonProps={{ loading: loading }}
      onOk={() => {
        onSelectedFilter({ units: unit, cutOffDate: cutOff });
      }}
      {...rest}
    >
      <Row gutter={[24, 12]}>
        <Col span="24">
          <label
            htmlFor="unit-selection"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <p style={{ marginBottom: '0.25rem' }}>Pilih Unit</p>
            {/* <Select
              placeholder="Pilih Unit"
              value={unit}
              onChange={(val) => setUnit(val)}
              id="unit-selection"
              allowClear
              disabled={userData.type === 2}
            >
              {arrayUnit?.map((unit) => {
                return (
                  <Select.Option value={unit._id}>{unit.name}</Select.Option>
                );
              })}
            </Select> */}
            <Checkbox.Group
              //   options={unitsCheckbox}
              defaultValue={unit}
              onChange={(v) => {
                console.log(v);
                setUnit(v);
              }}
            >
              <Row gutter={[8, 12]}>
                {unitsCheckbox?.map((unit) => {
                  return (
                    <Col span={8}>
                      <Checkbox
                        disabled={!dashboardUnit?.includes(unit.value)}
                        value={unit.value}
                      >
                        {unit.label}
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </label>
        </Col>
        <Col span="24">
          <label
            htmlFor="unit-selection"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            Pilih Tanggal Terakhir Cut Off
            <DatePicker
              format={'MM-DD-YYYY'}
              onChange={(_, dateString) => {
                setCutOff(dateString);
              }}
              placeholder="Pilih tanggal"
              value={moment(cutOff)}
            />
          </label>
        </Col>
      </Row>
    </Modal>
  );
};

export default FilterDashboardKeseluruhan;
