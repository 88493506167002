import React from 'react';
import { Button } from 'antd';
import { IconWrapper, StyledIconButtonContent } from './style';

const IconTextButton = ({ children, icon, onClick, ...rest }) => {
  return (
    <Button onClick={onClick} {...rest}>
      <StyledIconButtonContent
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <IconWrapper src={icon} alt="plus" width={18} height={18} />
        <span>{children}</span>
      </StyledIconButtonContent>
    </Button>
  );
};

export default IconTextButton;
