import React, { useEffect, useState } from 'react';
import { Card, Col, List, Modal, Row } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { CardBase } from 'components/atoms';
import { themeColor } from 'lib/theme';

const KonfirmasiKelulusan = ({
  visible,
  onClose,
  handleSubmit,
  selectedStudent,
  loadingSubmit,
  data,
  ...rest
}) => {
  const onDebt = selectedStudent.filter((el) => el.totalDebt > 0);
  const [flattenedStudent, setFlattenedStudent] = useState([]);
  const [selectedKelas, setSelectedKelas] = useState('');

  useEffect(() => {
    if (visible && data) {
      console.log(data);
      setSelectedKelas(data[0]?._id);
    }
  }, [visible, data]);

  useEffect(() => {
    if (data && selectedKelas) {
      let murid;
      murid = data.find((item) => item?._id == selectedKelas);

      console.log(murid, 'this is murid');

      setFlattenedStudent(murid.students);
    }
  }, [selectedKelas, data]);

  return (
    <Modal
      title="Review Penggantian Status Murid"
      visible={visible}
      onCancel={onClose}
      okText="Luluskan Murid"
      onOk={handleSubmit}
      width="80rem"
      okButtonProps={{ loading: loadingSubmit }}
      cancelButtonProps={{ loading: loadingSubmit }}
    >
      <header>
        <p>Mohon periksa kembali daftar murid yang ingin Anda luluskan</p>
        {onDebt.length ? (
          <span style={{ color: '#FF4D4F' }}>
            <InfoCircleOutlined /> {onDebt.length} murid masih mempunyai
            tunggakan dan tidak dapat diluluskan
          </span>
        ) : null}
      </header>
      <section
        style={{ marginTop: '2rem', overflowY: 'auto', maxHeight: '40rem' }}
      >
        <Row gutter={[18, 0]}>
          <Col span="8">
            <CardBase
              style={{
                padding: 0,
              }}
              bodyStyle={{
                padding: '2rem 0',
                maxHeight: '32.575rem',
              }}
            >
              <h3 style={{ margin: '0 1.5rem 1.5rem' }}>Daftar Kelas</h3>
              {data?.map((grade) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '8px 20px',
                      justifyContent: 'space-between',
                      backgroundColor:
                        grade?._id == selectedKelas ? '#E6F0EB' : '',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setSelectedKelas(grade?._id);
                    }}
                  >
                    {grade.name}
                    <span style={{ color: themeColor.primary }}>
                      {grade.students?.length}/{grade?.total}
                    </span>
                  </div>
                );
              })}
            </CardBase>
          </Col>
          <Col span="16">
            <Card>
              <h3>Total Murid Terpilih: {selectedStudent.length}</h3>
              <List
                dataSource={flattenedStudent}
                renderItem={(item) => {
                  return (
                    <List.Item>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <span>{item.name}</span>
                        {item.totalDebt ? (
                          <span style={{ color: '#FF4D4F' }}>
                            -Rp{(item.totalDebt || 0).toLocaleString()}
                          </span>
                        ) : (
                          <span style={{ color: themeColor.primary }}>
                            LUNAS
                          </span>
                        )}
                      </div>
                    </List.Item>
                  );
                }}
              />
            </Card>
          </Col>
        </Row>
      </section>
    </Modal>
  );
};

export default KonfirmasiKelulusan;
