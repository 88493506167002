import { Route, Switch, Redirect } from 'react-router-dom';
import {
  PageDetailTahunAjaran,
  PageTahunAjaran,
  PagePengguna,
} from 'pages/pengaturan';
import MainLayout from 'components/layout';
import { EditProfile, Profile } from 'pages/profile';
import { SistemPembayaran, TipeTagihan } from 'pages/pengaturan-unit';
import { DaftarKelas, AssignMurid } from 'pages/daftar-kelas';
import {
  DaftarMurid,
  DetailMurid,
  GantiKelulusan,
  KirimReminder,
  RiwayatKelas,
  TambahMurid,
} from 'pages/daftar-murid';
import {
  EditTagihanSPP,
  RiwayatPerubahan,
  TagihanLainnya,
  TagihanPPDB,
  TagihanSPP,
  TambahLainnya,
  TambahPPDB,
} from 'pages/tagihan';
import Tabungan from 'pages/tabungan';
import { DanaMasuk, RiwayatVA, TambahDanaMasuk } from 'pages/dana-masuk';
import { Pembayaran, TambahPembayaran } from 'pages/pembayaran';
import {
  DashboardAlumniMenunggak,
  DashboardKeseluruhan,
  DashboardLaporanBulanan,
  DashboardLaporanHarian,
  DashboardRekapLainnya,
  DashboardRekapPPDB,
  DashboardRekapSPP,
} from 'pages/dashboard';
import { RefundList } from 'pages/refund-list';
import { getUserToken } from 'utils/helpers/storage';
import { useStore } from 'store';
import { ListApresiasi } from 'pages/list-apresiasi';

const MainRoutes = () => {
  const {
    state: { userData },
  } = useStore();

  return (
    <Switch>
      {/* Dashboard */}

      <Route
        exact
        path="/"
        render={() => {
          const token = getUserToken('dev-access-token') || null;

          if (token) {
            return <Redirect to="/dashboard/semua" />;
          } else {
            return <Redirect to="/signin" />;
          }
        }}
      />

      <MainLayout>
        {/* Daftar Kelas */}
        <Route exact path="/daftar-kelas" component={DaftarKelas} />
        <Route
          exact
          path="/daftar-kelas/assign-murid"
          component={AssignMurid}
        />
        {/* Daftar Murid */}
        <Route exact path="/daftar-murid" component={DaftarMurid} />
        <Route
          exact
          path="/daftar-murid/tambah-murid"
          component={TambahMurid}
        />
        <Route exact path="/daftar-murid/detail/:id" component={DetailMurid} />
        <Route
          exact
          path="/daftar-murid/detail/:id/riwayat-kelas"
          component={RiwayatKelas}
        />
        <Route
          exact
          path="/daftar-murid/ganti-kelulusan"
          component={GantiKelulusan}
        />
        {/* Kirim Reminder */}
        <Route
          exact
          path="/daftar-murid/kirim-reminder"
          component={KirimReminder}
        />

        {/* Tagihan */}
        <Route exact path="/tagihan/spp" component={TagihanSPP} />
        <Route exact path="/tagihan/spp/edit" component={EditTagihanSPP} />
        <Route exact path="/tagihan/psb" component={TagihanPPDB} />
        <Route exact path="/tagihan/other" component={TagihanLainnya} />
        <Route exact path="/tagihan/history" component={RiwayatPerubahan} />
        <Route
          exact
          path="/tagihan/psb/tambah-tagihan"
          component={TambahPPDB}
        />
        <Route
          exact
          path="/tagihan/other/tambah-tagihan"
          component={TambahLainnya}
        />
        {/* Tabungan */}
        <Route exact path="/tabungan" component={Tabungan} />
        {/* Dana Masuk */}
        <Route exact path="/dana-masuk" component={DanaMasuk} />
        <Route
          exact
          path="/dana-masuk/tambah-dana-masuk"
          component={TambahDanaMasuk}
        />
        <Route exact path="/dana-masuk/riwayat-va" component={RiwayatVA} />
        {/* Pembayaran */}
        <Route exact path="/pembayaran" component={Pembayaran} />
        <Route
          exact
          path="/pembayaran/tambah-pembayaran"
          component={TambahPembayaran}
        />
        {/* Refund List */}
        <Route exact path="/refund-list" component={RefundList} />
        {/* List Apresiasi */}
        <Route exact path="/apresiasi-list" component={ListApresiasi} />
        {/* Pengaturan Unit */}
        <Route
          exact
          path="/pengaturan-unit/tipe-tagihan"
          component={TipeTagihan}
        />
        <Route
          exact
          path="/pengaturan-unit/sistem-pembayaran"
          component={SistemPembayaran}
        />
        {/* Pengaturan */}
        <Route
          exact
          path="/settings/tahun-ajaran"
          render={(props) => {
            if (userData.type === 1) {
              return <PageTahunAjaran {...props} />;
            } else {
              return <Redirect to="/" />;
            }
          }}
        />
        <Route
          exact
          path="/settings/tahun-ajaran/:id"
          render={(props) => {
            if (userData.type === 1) {
              return <PageDetailTahunAjaran {...props} />;
            } else {
              return <Redirect to="/" />;
            }
          }}
        />
        <Route
          exact
          path="/settings/pengguna"
          render={(props) => {
            if (userData.type === 1) {
              return <PagePengguna {...props} />;
            } else {
              return <Redirect to="/" />;
            }
          }}
        />

        {/* Profile */}
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/profile/edit" component={EditProfile} />

        {/* Dashboard */}

        <Route exact path="/dashboard/semua" component={DashboardKeseluruhan} />
        <Route
          exact
          path="/dashboard/laporan-harian"
          component={DashboardLaporanHarian}
        />
        <Route
          exact
          path="/dashboard/laporan-bulanan"
          component={DashboardLaporanBulanan}
        />
        <Route
          exact
          path="/dashboard/alumni-menunggak"
          component={DashboardAlumniMenunggak}
        />
        <Route
          exact
          path="/dashboard/rekap/spp"
          component={DashboardRekapSPP}
        />
        <Route
          exact
          path="/dashboard/rekap/psb"
          component={DashboardRekapPPDB}
        />
        <Route
          exact
          path="/dashboard/rekap/other"
          component={DashboardRekapLainnya}
        />
      </MainLayout>
    </Switch>
  );
};

export default MainRoutes;
