import React, { useEffect, useState } from 'react';
import { Drawer, Button, Form, Input, Checkbox, Space } from 'antd';
import { Footer } from './shared';
import { InputWrapper } from '..';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { getListKelasSebelumnya } from 'utils/fetch';

const EditKelas = (props) => {
  const [form] = Form.useForm();
  const {
    visible,
    onCancel,
    value,
    isEdit,
    handleSubmit,
    unitId,
    kelas,
    loadingState,
    ...rest
  } = props;
  const [name, setName] = useState('');

  useEffect(() => {
    if (!visible) form.resetFields();
    form.setFieldsValue({
      name: kelas.name,
    });
    setName(kelas.name);

    console.log(kelas, 'this is detail kelas');
  }, [visible]);

  return (
    <Drawer
      title="Edit Kelas"
      width="40rem"
      visible={visible}
      footer={
        <Footer
          onCancel={() => {
            onCancel();
          }}
          onSubmit={form.submit}
          loadingState={loadingState}
          submitDisabled={!name}
        />
      }
      onClose={() => {
        onCancel();
      }}
      {...rest}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(val) => handleSubmit(val, kelas.id)}
      >
        <InputWrapper
          type="text"
          name="name"
          label="Kelas"
          placeholder="Masukan nama kelas"
          onChange={(e) => setName(e.target.value)}
        />
      </Form>
    </Drawer>
  );
};

export default EditKelas;
