import { CustomBreadcrumb } from 'components/fragments';
import React from 'react';
import { StyledHeader } from './style';

const PageHeader = ({ title, children }) => {
  return (
    <StyledHeader>
      {title ? <h2>{title}</h2> : <CustomBreadcrumb />}
      {children || ''}
    </StyledHeader>
  );
};

export default PageHeader;
