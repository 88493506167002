import moment from 'moment';

let fileType;
let name;

export const parsingVABCA = (file) => {
  let forbidden = [
    'LAPORAN TRANSAKSI VIA E-BANKING & COUNTER',
    'UNTUK PERUSAHAAN KERJASAMA',
    'CABANG          :',
    'NAMA PERUSAHAAN :',
    '======================',
    'SUB-COMP',
    'NAMA PELANGGAN         NILAI TRANSAKSI',
    'SUB TOTAL TRANSAKSI',
    'SUB TOTAL NILAI TRANSAKSI',
    'TOTAL TRANSAKSI        IDR',
    'TOTAL NILAI TRANSAKSI',
  ];
  let data = file.split('\n').filter((each) => {
    let check = forbidden.find((f) => each.match(new RegExp(f, 'i')) !== null);
    if (!check && each.trim().length > 0) {
      return each;
    }
  });

  const tanggalFile = file.split('\n')[1].split(' ')[79];

  let newParsing = [];
  for (let i = 0; i < data.length; i++) {
    let va = '';
    let nominal = '';
    let date = '';
    let student = '';
    let notes1 = '';
    let notes2 = '';
    for (let j = 0; j < data[i].length; j++) {
      if (j > 7 && j <= 26) {
        va += data[i][j];
      } else if (j > 27 && j <= 44) {
        student += data[i][j];
      } else if (j > 50 && j <= 70) {
        nominal += data[i][j];
      } else if (j > 72 && j <= 80) {
        date += j === 75 || j === 78 ? '/' : data[i][j];
      } else if (j > 99 && j <= 114) {
        notes1 += data[i][j];
      } else if (j > 116 && j <= 130) {
        notes2 += data[i][j];
      }
    }
    date = date.split('/');

    let row = {
      vaCode: va.trim(),
      date: moment(`${date[1]}/${date[0]}/${date[2]}`).format('MM-DD-YYYY'),
      // date: `${date[1]}-${date[0]}-${date[2]}`,
      //   paymentType: 'VA BCA',
      amount: nominal.trim().split('.')[0].split(',').join(''),
      studentName: student.trim(),
      notes: `${notes1} ${notes2}`,
    };
    let check = true;
    Object.keys(row).forEach((prop) => {
      if (!row[prop]) {
        check = false;
      }
      //   !row[prop] ? (check = false) : null;
    });

    if (check) {
      newParsing.push({ ...row, amount: Number(row.amount) });
    }

    // check ? newParsing.push({ ...row, amount: Number(row.amount) }) : null;
  }

  const splitedDate = tanggalFile.split('/');

  return {
    newParsing,
    tanggal: `${splitedDate[1]}-${splitedDate[0]}-${splitedDate[2]}`,
  };
};

export const uploadData = (file) => {
  fileType = file.type;
  name = file.name;
  var reader = new FileReader();
  reader.onload = this.onload;
  reader.onerror = this.error;
  if (this.fileType === 'text/plain') {
    reader.readAsText(file);
  } else {
    reader.readAsBinaryString(file);
  }
};

export const toText = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (file.type === 'text/plain') {
      reader.readAsText(file);
    } else {
      reader.readAsBinaryString(file);
    }
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};
