import styled from 'styled-components';
import { Layout, Menu } from 'antd';

const { Sider, Header } = Layout;

export const StyledContentLayout = styled(Layout)`
  background-color: #ffffff;
  min-height: calc(100vh - 7.3rem);
`;

export const StyledContentWrapper = styled(Layout)`
  background-color: #ffffff;
  padding: 2.5rem;
  margin: 0;
  margin-left: ${(props) =>
    (props.noMargin && '0') || (props.minimize && '8rem') || '25rem'};
`;

export const StyledMenu = styled(Menu)`
  height: 100%;
  padding: 2rem 1rem 0;
  border-right: 1px solid #dee4ed;
  overflow-y: scroll;
`;

export const StyledMenuItem = styled(Menu.Item)`
  margin: 1rem 0;
`;

export const StyledSider = styled(Sider)`
  height: calc(100vh - 64px);
  background-color: #ffffff;
  position: fixed;
  left: 0;
  top: 6.55rem;
  z-index: 500;
  ::-webkit-scrollbar {
    width: 0px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #3a3a3a;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #646464;
  }
`;

export const StyledHeader = styled(Header)`
  background-color: #ffffff;
  padding: 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dee4ed;
  position: sticky;
  top: 0;
  z-index: 500;
`;
