import React, { useEffect, useState } from 'react';
import {
  Drawer,
  Button,
  Form,
  Row,
  Col,
  Space,
  DatePicker,
  Select,
  Radio,
  Input,
} from 'antd';
import { Footer } from './shared';
import { InputWrapper } from '..';
import { useStore } from 'store';
import { editDetailMurid } from 'utils/fetch';
import { Loader } from 'components/atoms';
import moment from 'moment';
import { PhoneCodesDropdown } from 'components/form';

const FormDataMurid = ({ listKelas, listTahunAjaran, setDob }) => {
  return (
    <>
      <InputWrapper
        type="text"
        name="name"
        label="Nama Murid"
        placeholder="Masukan nama murid"
      />
      <Form.Item label="Masuk Tahun Ajaran" name="school_year">
        <Select placeholder="Pilih tahun ajaran">
          {listTahunAjaran?.map((ta) => (
            <Select.Option value={ta._id}>{ta.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <InputWrapper
        type="text"
        name="nik"
        label="NIS"
        placeholder="Masukan nomor NIS"
      />
      <InputWrapper
        type="text"
        name="virtual_account"
        label="Virtual Account"
        placeholder="Masukan nomor virtual account"
      />
      <Form.Item label="Kelas" name="grade">
        <Select placeholder="Pilih kelas">
          {listKelas?.map((kelas) => (
            <Select.Option value={kelas._id}>{kelas.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Jenis Kelamin" name="gender">
        <Select placeholder="Pilih jenis kelamin">
          <Select.Option value={1}>Laki - Laki</Select.Option>
          <Select.Option value={2}>Perempuan</Select.Option>
        </Select>
      </Form.Item>
      <InputWrapper
        type="text"
        name="birth_location"
        label="Tempat Lahir"
        placeholder="Masukan tempat lahir"
      />
      <Form.Item label="Tanggal Lahir" name="dob">
        <DatePicker
          style={{ width: '100%' }}
          format="MM/DD/YYYY"
          onChange={(v, dateString) => setDob(dateString)}
        />
      </Form.Item>
      <Form.Item label="Jenis Murid" name="type">
        <Radio.Group>
          <Radio value={1}>Murid Standar</Radio>
          <Radio value={2}>ASAK</Radio>
        </Radio.Group>
      </Form.Item>
    </>
  );
};

const FormDataWali = ({ setPhoneCodes, phoneCodes }) => {
  return (
    <>
      <section>
        <h2>Wali 1</h2>
        <InputWrapper
          type="text"
          name="name1"
          label="Nama Wali 1"
          placeholder="Masukan nama wali"
        />
        <Form.Item label="Status" name="status1">
          <Select placeholder="Pilih status wali">
            <Select.Option value={1}>Ibu</Select.Option>
            <Select.Option value={2}>Ayah</Select.Option>
            <Select.Option value={3}>Kakek</Select.Option>
            <Select.Option value={4}>Nenek</Select.Option>
            <Select.Option value={5}>Paman</Select.Option>
            <Select.Option value={6}>Bibi</Select.Option>
            <Select.Option value={7}>Lainnya</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="Email Wali Murid 1" name="email1">
          <Input type="email" placeholder="Masukan alamat email wali" />
        </Form.Item>
      </section>

      <section>
        <h2>Wali 2</h2>
        <InputWrapper
          type="text"
          name="name2"
          label="Nama Wali 2"
          placeholder="Masukan nama wali"
        />
        <Form.Item label="Status" name="status2">
          <Select placeholder="Pilih status wali">
            <Select.Option value={1}>Ibu</Select.Option>
            <Select.Option value={2}>Ayah</Select.Option>
            <Select.Option value={3}>Kakek</Select.Option>
            <Select.Option value={4}>Nenek</Select.Option>
            <Select.Option value={5}>Paman</Select.Option>
            <Select.Option value={6}>Bibi</Select.Option>
            <Select.Option value={7}>Lainnya</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="Email Wali Murid 2" name="email2">
          <Input type="email" placeholder="Masukan alamat email wali" />
        </Form.Item>
      </section>
    </>
  );
};

const FormDataSPP = ({ amount, items }) => {
  return '';
};

const FormStatus = () => {
  return (
    <Form.Item label="Status" name="status">
      <Select placeholder="Pilih status">
        <Select.Option value={1}>Aktif</Select.Option>
        <Select.Option value={2}>Tidak Aktif</Select.Option>
        <Select.Option value={3}>Lulus</Select.Option>
      </Select>
    </Form.Item>
  );
};

const EditDetailMurid = (props) => {
  const [form] = Form.useForm();
  const {
    visible,
    onCancel,
    value,
    isEdit,
    handleSubmit,
    type,
    dataMurid,
    dataWali,
    loadingState,
    ...rest
  } = props;
  const [name1, setName1] = useState('');
  const {
    state: { listKelas, listTahunAjaran, callingCodes },
  } = useStore();
  const [dob, setDob] = useState('');
  const [phoneCodes, setPhoneCodes] = useState({
    wali1: '62',
    wali2: '62',
  });
  const [totalSpp, setTotalSpp] = useState([]);

  useEffect(() => {
    console.log(form.getFieldsValue(), 'field value');
    console.log({ listKelas, listTahunAjaran }, 'list');
  }, []);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setDob('');
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      if (type === 1) {
        form.setFieldsValue({
          name: dataMurid?.name,
          school_year: dataMurid?.school_year?._id,
          grade: dataMurid?.grade?._id,
          nik: dataMurid?.nik || '',
          virtual_account: dataMurid?.virtual_account,
          gender: dataMurid?.gender,
          birth_location: dataMurid?.birth_location || '',
          dob: dataMurid?.dob ? moment(dataMurid?.dob) : '',
          type: dataMurid?.type,
        });
        setDob(dataMurid?.dob);
      } else if (type === 2) {
        form.setFieldsValue({
          name1: dataWali[0]?.name,
          email1: dataWali[0]?.email,
          status1: dataWali[0]?.status,
          name2: dataWali[1]?.name,
          email2: dataWali[1]?.email,
          status2: dataWali[1]?.status,
        });
        setPhoneCodes({
          wali1: dataWali[0]?.phone_code,
          wali2: dataWali[1]?.phone_code,
        });
      } else if (type === 3) {
        console.log(dataMurid, 'this is data edit murid');
        form?.setFieldsValue({
          spp_amount: dataMurid?.spp_amount,
          items: dataMurid?.spp_items,
        });
        const totalSpp = dataMurid?.spp_items.map((el) =>
          Number(el.amount || 0)
        );

        setTotalSpp(totalSpp);
      } else {
        form?.setFieldsValue({ status: dataMurid?.status });
      }
    }
  }, [type, visible]);

  return (
    <Drawer
      title={
        type === 1
          ? 'Edit Info Murid'
          : type === 2
          ? 'Edit Wali Murid'
          : type === 3
          ? 'Nominal SPP'
          : 'Edit Status Murid'
      }
      width="40rem"
      visible={visible}
      footer={
        <Footer
          onCancel={() => {
            form.resetFields();
            onCancel();
          }}
          onSubmit={form.submit}
          loadingState={loadingState}
        />
      }
      onClose={() => {
        form.resetFields();
        onCancel();
      }}
      {...rest}
    >
      {listKelas && listTahunAjaran && dataMurid ? (
        <Form
          form={form}
          layout="vertical"
          onFinish={(val) => {
            if (type === 2) {
              const guardians = [
                {
                  _id: dataWali[0]?._id,
                  name: val.name1,
                  email: val.email1,
                  status: val.status1,
                },
                {
                  _id: dataWali[1]?._id,
                  name: val.name2,
                  email: val.email2,
                  status: val.status2,
                },
              ];

              handleSubmit({ guardians }, 'guardian');
            } else if (type === 1) {
              const payload = { ...val, dob };
              handleSubmit(payload, 'info');
            } else if (type === 3) {
              handleSubmit(
                {
                  spp_items: val.items.map((item) => ({
                    master_bill_item: item.master_bill_item,
                    amount: String(item.amount),
                  })),
                  spp_amount: val.items.reduce(
                    (acc, curr) => acc + curr.amount,
                    0
                  ),
                },
                'spp'
              );
              // console.log(val);
            } else {
              handleSubmit(val, 'status');
            }
          }}
        >
          {type === 1 && (
            <FormDataMurid
              listKelas={listKelas}
              listTahunAjaran={listTahunAjaran}
              setDob={setDob}
            />
          )}
          {type === 2 && (
            <FormDataWali
              phoneCodes={phoneCodes}
              setPhoneCodes={setPhoneCodes}
            />
          )}
          {type === 3 && (
            // <FormDataSPP
            //   amount={dataMurid?.spp_amount}
            //   items={dataMurid?.spp_items}
            // />
            <>
              <Form.List name="items">
                {(fields) => {
                  return fields.map((field, idx) => {
                    return (
                      <Row
                        gutter={[8, 0]}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Col span="24">
                          <InputWrapper
                            label={dataMurid?.spp_items[idx]?.name || 'No Name'}
                            type="number"
                            prefix="Rp"
                            formItemProps={{
                              name: [field.name, 'amount'],
                            }}
                            placeholder="Rp 500.000"
                            formatter={(value) =>
                              `Rp ${value}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ','
                              )
                            }
                            parser={(value) =>
                              value.replace(/\Rp\s?|(,*)/g, '')
                            }
                            onChange={(v) => {
                              const duplicate = [...totalSpp];
                              duplicate[idx] = v;
                              setTotalSpp(duplicate);
                            }}
                          />
                        </Col>
                      </Row>
                    );
                  });
                }}
              </Form.List>
              <section
                style={{
                  border: '1px solid #bfbfbf',
                  width: '100%',
                  padding: '1rem',
                }}
              >
                <article
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      marginRight: '2rem',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        height: '7rem',
                        backgroundColor: '#A3DEF8',
                        width: '1rem',
                        borderRadius: '5rem',
                        marginRight: '1rem',
                      }}
                    />
                    <div>
                      <span style={{ color: '#595959', fontSize: '1.15rem' }}>
                        SPP Total
                      </span>
                      <h2>
                        Rp
                        {totalSpp
                          .reduce((acc, curr) => acc + Number(curr), 0)
                          .toLocaleString()}
                      </h2>
                    </div>
                  </div>
                </article>
              </section>
            </>
          )}
          {type === 4 && <FormStatus />}
        </Form>
      ) : (
        <Loader text="" />
      )}
    </Drawer>
  );
};

export default EditDetailMurid;
