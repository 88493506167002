import { keys } from 'lib/theme';

export const setUserToken = (value) => {
  localStorage.setItem(keys.token, value);
};

export const getUserToken = () => {
  const serialize = localStorage.getItem(keys.token);
  return serialize || '';
};
