import { DownOutlined, EyeOutlined } from '@ant-design/icons';
import { Table, Space, Input, Dropdown, Button, Menu } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useStore } from 'store';
import { detailRincianDashboard } from 'utils/fetch';
import queryString from 'querystring';

const DashboardRefund = ({ data, unitName }) => {
  const {
    state: { unitData, activeSchoolYear },
  } = useStore();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  // const [data, setData] = useState([]);
  const [copiedData, setCopiedData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [nameSearch, setNameSearch] = useState('');
  const [limit, setLimit] = useState(10);
  const arrayUnit = Object.keys(unitData).map((key) => unitData[key]);

  const listBulan = activeSchoolYear
    ? Array.from({ length: 10 }, (_, i) => {
        const date = new Date(activeSchoolYear.start_date);
        const next = new Date(date.setMonth(date.getMonth() + i));

        return {
          name: `${moment(next).format('MMMM YYYY')}`,
          value: next,
        };
      })
    : [];

  // useEffect(() => {
  //   fetchData();
  // }, [selectedUnit]);

  useEffect(() => {
    if (data) {
      setCopiedData(data);
      setTotalData(data.length);
    }
  }, [data]);

  useEffect(() => {
    if (nameSearch) {
      const filtered = data.filter((item) =>
        item.name.toLowerCase().trim().includes(nameSearch.toLowerCase().trim())
      );

      setCopiedData(filtered);
    } else {
      setCopiedData(data);
    }
  }, [nameSearch]);

  useEffect(() => {
    return () => setCurrentPage(1);
  }, []);

  // const fetchData = () => {
  //   let query = `unitId=${selectedUnit?._id || ''}&month=${
  //     selectedMonth?.value || ''
  //   }`;

  //   setLoading(true);

  //   detailRincianDashboard('student-asak-list', query)
  //     .then((res) => {
  //       console.log(res);
  //       setData(res.refundList);
  //       setTotalData(res.totalData);
  //     })
  //     .catch((err) => console.log(err.response, 'error fetch'))
  //     .finally(() => setLoading(false));
  // };

  const tableColumns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: 80,
      render: (data, _, idx) => <span>{idx + 1}</span>,
    },
    {
      title: 'Nama Murid',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Tagihan',
      dataIndex: 'bill_name',
      key: 'bill_name',
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Jumlah Refund',
      dataIndex: 'refund_amount',
      key: 'refund_amount',
      render: (data) => <span>Rp {(data || 0).toLocaleString()}</span>,
    },
    {
      title: 'Kelas',
      dataIndex: 'grade_name',
      key: 'grade_name',
      render: (data) => <span>{data || '-'}</span>,
    },
    {
      title: 'Unit',
      dataIndex: 'unit_name',
      key: 'unit_name',
      render: (data) => <span>{data || '-'}</span>,
    },
    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      fixed: 'right',
      render: (data, record) => (
        <Button
          icon={<EyeOutlined />}
          onClick={() => {
            history.push({
              pathname: `/daftar-murid/detail/${data}`,
              search: queryString.stringify({
                unit: record.unit?.name.toLowerCase() || '',
                tabs: 'refund',
              }),
            });
          }}
        >
          Lihat
        </Button>
      ),
    },
  ];

  const MenuBulan = ({ onClick }) => {
    return (
      <Menu>
        {listBulan.map((month) => (
          <Menu.Item key={month.value} onClick={() => onClick(month)}>
            {month.name}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const MenuUnit = ({ onClick }) => {
    return (
      <Menu>
        <Menu.Item key="all" onClick={() => onClick('')}>
          Semua Unit
        </Menu.Item>
        {arrayUnit.map((unit) => (
          <Menu.Item key={unit._id} onClick={() => onClick(unit)}>
            {unit.name}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  return (
    <article>
      <section
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '2rem',
        }}
      >
        <h3>Total Murid: {totalData}</h3>
        <Space>
          <Input.Search
            placeholder="Cari nama murid"
            value={nameSearch}
            onChange={(e) => setNameSearch(e.target.value)}
          />

          <Dropdown
            disabled
            overlay={<MenuUnit onClick={(v) => setSelectedUnit(v)} />}
          >
            <Button disabled icon={<DownOutlined />}>
              {unitName || 'Semua Unit'}
            </Button>
          </Dropdown>
          {/* <Dropdown
            disabled
            overlay={<MenuBulan onClick={(v) => setSelectedMonth(v)} />}
          >
            <Button disabled>{selectedMonth?.name || '-'}</Button>
          </Dropdown> */}
        </Space>
      </section>
      <Table
        columns={tableColumns}
        dataSource={copiedData}
        pagination={{
          position: ['bottomLeft'],
          total: totalData && totalData,
          onChange: (page) => {
            setCurrentPage(page);
          },
          current: currentPage,
          pageSize: limit,
          showSizeChanger: true,
          pageSizeOptions: ['10', '30', '50'],
          onShowSizeChange: (curr, pageSize) => {
            setLimit(pageSize);
          },
        }}
        scroll={{ x: 1200, y: 350 }}
        // scroll={{ x: 1500 }}
        loading={loading}
      />
    </article>
  );
};

export default DashboardRefund;
