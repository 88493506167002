import React, { useEffect, useState } from 'react';
import {
  Drawer,
  Form,
  Row,
  Col,
  Space,
  Select,
  Alert,
  DatePicker,
  message,
} from 'antd';
import moment from 'moment';

import { Footer } from './shared';
import { InputWrapper } from '..';
import {
  addSppBill,
  getDetailPembayaran,
  getUnbillMonth,
  refundPembayaran,
} from 'utils/fetch';
import { Loader } from 'components/atoms';
import { useStore } from 'store';
import { monthDictionary } from 'utils/helpers/common';

const TambahTagihanSPP = (props) => {
  const [form] = Form.useForm();
  const { visible, onCancel, value, refetchData, ...rest } = props;
  const [detail, setDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [notes, setNotes] = useState('');
  const [date, setDate] = useState('');
  const [tahunAjaran, setTahunAjaran] = useState('');
  const [billMonths, setBillMonths] = useState([]);
  const [sppItems, setSppItems] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');
  const { unitId = '', studentId = '', type = '' } = value;
  const {
    state: { listTahunAjaran },
  } = useStore();

  useEffect(() => {
    if (studentId && tahunAjaran && unitId) {
      fetchData(tahunAjaran);
    } else {
      setDetail(null);
      form.resetFields();
    }
  }, [unitId, studentId, tahunAjaran]);

  useEffect(() => {
    if (detail) {
      form.setFieldsValue({ paid_amount: detail?.payment?.total_amount });
    }
  }, [detail]);

  const fetchData = (schoolYearId) => {
    setLoading(true);
    getUnbillMonth(unitId, studentId, schoolYearId)
      .then((res) => {
        console.log(res, 'unbill month');
        // setDetail(res);
        setBillMonths(res.value?.months);
        const items = res.value?.items?.map((item) => ({
          master_bill_item: item?.master_bill_item?._id,
          name: item?.master_bill_item?.name,
          total_amount: 0,
        }));
        setSppItems(items);
        form.setFieldsValue({ items: items });
      })
      .catch((err) => console.log(err.response, 'error fetch detail'))
      .finally(() => setLoading(false));
  };

  const handleSubmit = (payload) => {
    setLoadingSubmit(true);
    addSppBill(unitId, studentId, payload)
      .then((res) => {
        console.log(res);
        onCancel();
        // window.location.reload();
        refetchData();
        message.success('Berhasil menambahkan tagihan spp');
      })
      .catch((err) => {
        console.log(err, 'error refund pembayaran');
        message.error(err?.data?.m || 'Gagal menambahkan tagihan spp');
      })
      .finally(() => setLoadingSubmit(false));
  };

  const drawerTitle = 'Tambah Tagihan SPP';

  return (
    <Drawer
      title={drawerTitle}
      width={`50vw`}
      visible={visible}
      footer={
        <Footer
          onCancel={() => onCancel()}
          onSubmit={form.submit}
          loadingState={loadingSubmit}
          submitDisabled={!selectedMonth && !tahunAjaran}
        />
      }
      onClose={onCancel}
      {...rest}
    >
      <>
        <h2>Tahun Ajaran</h2>
        <Row gutter={[18, 0]}>
          <Col span="12">
            <Select
              value={tahunAjaran}
              placeholder="Pilih tahun ajaran"
              style={{ width: '100%' }}
              onChange={(e) => setTahunAjaran(e)}
              allowClear
              onClear={() => setTahunAjaran('')}
            >
              {listTahunAjaran?.map((year) => (
                <Select.Option value={year._id}>{year.name}</Select.Option>
              ))}
            </Select>
          </Col>
          <Col span="12">
            <Select
              placeholder="Pilih bulan tagihan"
              style={{ width: '100%' }}
              onChange={(e) => setSelectedMonth(e)}
              value={selectedMonth}
              allowClear
              onClear={() => setSelectedMonth('')}
            >
              {billMonths?.map((month) => (
                <Select.Option value={month}>
                  {moment(month, 'MM-DD-YYYY').format('MMMM YYYY')}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Form
          form={form}
          layout="vertical"
          onFinish={(val) => {
            const payload = {
              school_year: tahunAjaran,
              unit: unitId,
              student: studentId,
              month: selectedMonth,
              total_amount: val.items?.reduce(
                (curr, acc) => curr + (acc?.total_amount || 0),
                0
              ),
              items: val.items?.map((item) => ({
                master_bill_item: item.master_bill_item,
                total_amount: item.total_amount,
              })),
            };
            console.log(payload);
            handleSubmit(payload);
          }}
          initialValues={{
            items: [],
          }}
        >
          <section style={{ marginTop: '2rem' }}>
            <h2>Item Tagihan</h2>
            <Form.List name="items">
              {(fields, { add, remove }) => {
                return fields.map((field, idx) => {
                  return (
                    <section>
                      <Row gutter={[18, 8]}>
                        <Col span="12">
                          <InputWrapper
                            type="text"
                            name="name"
                            placeholder="Item tagihan"
                            label="Item Tagihan"
                            disabled
                            formItemProps={{
                              name: [field.name, 'name'],
                            }}
                          />
                        </Col>
                        <Col span="12">
                          <InputWrapper
                            type="number"
                            prefix="Rp"
                            name="total_amount"
                            label="Nominal Tagihan"
                            formItemProps={{
                              name: [field.name, 'total_amount'],
                            }}
                            formatter={(value) =>
                              `Rp ${value}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ','
                              )
                            }
                            parser={(value) =>
                              value.replace(/\Rp\s?|(,*)/g, '')
                            }
                          />
                        </Col>
                      </Row>
                    </section>
                  );
                });
              }}
            </Form.List>
          </section>
        </Form>
      </>
    </Drawer>
  );
};

export default TambahTagihanSPP;
