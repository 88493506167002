import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { Drawer } from 'components/shared';
import { themeColor } from 'lib/theme';
import moment from 'moment';
import { useStore } from 'store';
import { fetchTahunAjaran, studentTab } from 'utils/fetch';
import exportExcel from 'utils/helpers/xlsx';

const { Dropdown, Button, Table, Menu, Tag } = require('antd');
const { useState, useEffect } = require('react');

const DetailMuridMutasi = ({ studentId, unitId }) => {
  const {
    state: { activeSchoolYear },
  } = useStore();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [listTahunAjaran, setListTahunAjaran] = useState([]);
  const [openDrawer, setOpenDrawer] = useState({
    state: false,
    id: '',
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    studentTab(studentId, 'mutasi')
      .then((value) => {
        console.log(value, 'mutasi instalments');
        setData(value.allData);
      })
      .catch((err) => {
        console.log(err.response);
      })
      .finally(() => setLoading(false));
  };

  const tableColumns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: 80,
      render: (data, _, idx) => <span>{idx + 1}</span>,
    },
    {
      title: 'Tanggal',
      dataIndex: 'date',
      key: 'date',
      render: (data) => (
        <span>{data ? moment(data).format('DD/MM/YYYY') : '-'}</span>
      ),
    },
    {
      title: 'Deskripsi',
      dataIndex: 'description',
      key: 'description',
      width: 200,
      ellipsis: true,
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Nominal',
      dataIndex: 'amount',
      key: 'amount',
      width: 200,
      render: (data) => (
        <span style={{ color: data < 0 ? '#FF4D4F' : themeColor.success }}>
          {`${
            data < 0
              ? `-Rp${Number(Math.abs(data) || 0).toLocaleString()}`
              : `Rp${Number(data || 0).toLocaleString()}`
          }`}
        </span>
      ),
    },
    {
      title: 'Tagihan',
      dataIndex: 'balance',
      key: 'balance',
      width: 200,
      render: (data) => (
        <span>{`${
          data < 0
            ? `-Rp${Number(Math.abs(data) || 0).toLocaleString()}`
            : `Rp${Number(data || 0).toLocaleString()}`
        }`}</span>
      ),
    },
    {
      title: 'Dana Masuk',
      dataIndex: 'fund',
      key: 'fund',
      render: (data) => <span>Rp{Number(data || 0).toLocaleString()}</span>,
    },
    {
      title: 'Tabungan',
      dataIndex: 'tabungan',
      key: 'tabungan',
      render: (data) => <span>Rp{Number(data || 0).toLocaleString()}</span>,
    },
  ];

  const parsedMutasi = (data = []) => {
    const parsed = [
      [
        'No',
        'Tanggal',
        'Deskripsi',
        'Nominal',
        'Tagihan',
        'Dana Masuk',
        'Tabungan',
      ],
    ];

    data.forEach((dt, idx) => {
      const amount = dt?.amount < 0 ? Math.abs(dt.amount) : dt.amount;
      const balance = dt?.balance < 0 ? Math.abs(dt.balance) : dt.balance;

      const row = [
        idx + 1,
        moment(dt?.date).format('DD/MM/YYYY'),
        dt?.description,
        dt?.amount < 0
          ? `-Rp${amount.toLocaleString()}`
          : `Rp${amount.toLocaleString()}`,
        dt?.balance < 0
          ? `-Rp${balance.toLocaleString()}`
          : `Rp${balance.toLocaleString()}`,
        `Rp${dt?.fund.toLocaleString() || 0}`,
        `Rp${dt?.tabungan.toLocaleString() || 0}`,
      ];

      parsed.push(row);
    });

    return parsed;
  };

  const handleDownloadMutasi = () => {
    const parsedData = parsedMutasi(data);

    exportExcel(parsedData, `Laporan Mutasi ${moment().format('DD MM YYYY')}`);
  };

  return (
    <>
      <article>
        <section style={{ marginBottom: '1.5rem' }}>
          <Button icon={<DownloadOutlined />} onClick={handleDownloadMutasi}>
            Download Mutasi
          </Button>
        </section>
        <Table
          columns={tableColumns}
          dataSource={data}
          pagination={{
            position: ['bottomLeft'],
            simple: true,
            total: totalData && totalData,
            onChange: (page) => {
              setCurrentPage(page);
            },
            current: currentPage,
          }}
          // scroll={{ x: 1500 }}
          loading={loading}
        />
      </article>
    </>
  );
};

export default DetailMuridMutasi;
