import { Button, Input, Select, Space, Table, Tag } from 'antd';
import { Card } from 'components/fragments';
import { Drawer, PageHeader } from 'components/shared';
import React, { useEffect, useState } from 'react';
import { useStore } from 'store';
import queryString from 'querystring';
import { CustomButton, Loader } from 'components/atoms';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  createNewUser,
  editUserData,
  fetchUserList,
  getListMurid,
  getStudentHistory,
} from 'utils/fetch';
import { StarFilled } from '@ant-design/icons';

const DummyData = [
  {
    _id: '601fc9307114c33c702f6d40',
    grade: {
      _id: '601fc9307114c33c702f6cf9',
      name: '1A',
    },
    school_year: {
      _id: '601fc9307114c33c702f6cf3',
      status: 1,
      name: '2020/2021',
      start_date: '2020-06-30T17:00:00.000Z',
      end_date: '2021-06-30T16:59:59.999Z',
    },
    student: '601fc9307114c33c702f6d04',
    spp_amount: 100,
    student_type: 1,
    createdAt: '2021-02-07T11:04:16.546Z',
    updatedAt: '2021-02-07T11:04:16.546Z',
    __v: 0,
  },
];

const RiwayatKelas = () => {
  const {
    handleBreadcrumb,
    handleSiderActive,
    state: { activeSchoolYear, selectedUnit },
  } = useStore();
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const unitId = selectedUnit?._id;
  const schoolYearId = activeSchoolYear?._id;
  const { id } = useParams();
  const { unit } = queryString.parse(location.search.replace('?', ''));

  useEffect(() => {
    const newBreadcrumb = [
      {
        linkTo: `/daftar-murid?unit=${selectedUnit?.name.toLowerCase()}`,
        node: <span>Daftar Murid</span>,
      },
      {
        linkTo: `/daftar-murid/detail/${id}?unit=${unit}`,
        node: <span>Detail Murid</span>,
      },
      {
        linkTo: `/daftar-murid/detail/riwayat-kelas?unit=${unit}`,
        node: <span>Riwayat Kelas</span>,
      },
    ];
    handleSiderActive(['2']);
    handleBreadcrumb(newBreadcrumb);
  }, []);

  useEffect(() => {
    if (id && unitId && activeSchoolYear?._id) {
      fetchData();
    }
  }, [id, activeSchoolYear, unitId]);

  const fetchData = () => {
    setLoading(true);
    getStudentHistory(unitId, activeSchoolYear?._id, id)
      .then((res) => {
        console.log(res, 'riwayat kelas');
        setData(res.gradeHistories);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response, 'this is error fetch');
      });
  };

  const tableColumns = [
    {
      title: 'Tahun Ajaran',
      dataIndex: 'school_year',
      key: 'school_year',
      render: (data) => <span>{data?.name || '-'}</span>,
    },
    {
      title: 'Kelas',
      dataIndex: 'grade',
      key: 'grade',
      render: (data) => <span>{data?.name || '-'}</span>,
    },
    {
      title: 'Nominal SPP',
      dataIndex: 'spp_amount',
      key: 'spp_amount',
      render: (data) => <span>{`Rp${(data || 0).toLocaleString()}`}</span>,
    },
    {
      title: 'Jenis Murid',
      dataIndex: 'student_type',
      key: 'student_type',
      render: (data) => {
        if (data === 1) return <span>Standar</span>;
        return (
          <Tag color="orange">
            <StarFilled /> &nbsp;ASAK
          </Tag>
        );
      },
    },
  ];

  return (
    <>
      <PageHeader />
      <section style={{ width: '100%', margin: '2rem 0' }}>
        {!loading ? (
          <Table
            dataSource={data}
            columns={tableColumns}
            // scroll={{ x: 1000 }}
            pagination={false}
          />
        ) : (
          <Loader />
        )}
      </section>
    </>
  );
};

export default RiwayatKelas;
