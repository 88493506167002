import { EyeOutlined, RetweetOutlined } from '@ant-design/icons';
import { Drawer } from 'components/shared';
import moment from 'moment';
import { useStore } from 'store';
import { fetchTahunAjaran, studentTab } from 'utils/fetch';

const { Dropdown, Button, Table, Menu, Tag, Space } = require('antd');
const { useState, useEffect } = require('react');

const DetailMuridRefund = ({ studentId, unitId }) => {
  const {
    state: { activeSchoolYear },
  } = useStore();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [listTahunAjaran, setListTahunAjaran] = useState([]);
  const [openDrawer, setOpenDrawer] = useState({
    type: '',
    id: '',
  });
  const [refundDrawer, setRefundDrawer] = useState({
    type: '',
    id: '',
    state: false,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    studentTab(studentId, 'payment')
      .then((value) => {
        console.log(value, 'payment instalments');
        setData(value.payments);
      })
      .catch((err) => {
        console.log(err.response);
      })
      .finally(() => setLoading(false));
  };

  const tableColumns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      render: (data, _, idx) => <span>{idx + 1}</span>,
    },
    {
      title: 'Nama Tagihan',
      dataIndex: 'name',
      key: 'name',
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Tanggal Bayar',
      dataIndex: 'date',
      key: 'date',
      render: (data) => (
        <span>{data ? moment(data).format('DD/MM/YYYY') : '-'}</span>
      ),
    },
    {
      title: 'Nominal',
      dataIndex: 'total_amount',
      key: 'total_amount',
      width: 200,
      render: (data) => <span>Rp{Number(data || 0).toLocaleString()}</span>,
    },
    {
      title: 'Sumber Dana',
      dataIndex: 'payment_origin',
      key: 'payment_origin',
      render: (data) => <span>{data}</span>,
    },
    {
      title: '',
      dataIndex: 'bill_type',
      key: 'bill_type',
      align: 'center',
      fixed: 'right',
      width: 250,
      render: (type, record) => {
        return (
          <Space>
            <Button
              icon={<EyeOutlined />}
              onClick={() =>
                setOpenDrawer({
                  type,
                  id: record._id,
                })
              }
            >
              Lihat
            </Button>
            <Button
              icon={<RetweetOutlined />}
              onClick={() =>
                setRefundDrawer({
                  type,
                  id: record._id,
                  state: true,
                })
              }
            >
              Refund
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <article>
        <Table
          columns={tableColumns}
          dataSource={data}
          pagination={{
            position: ['bottomLeft'],
            simple: true,
            total: totalData && totalData,
            onChange: (page) => {
              setCurrentPage(page);
            },
            current: currentPage,
          }}
          // scroll={{ x: 1500 }}
          loading={loading}
        />
      </article>

      <Drawer.DetailPembayaranSPP
        visible={openDrawer.type === 'SPP'}
        onCancel={() => {
          setOpenDrawer({
            type: '',
            id: '',
          });
        }}
        value={{ paymentId: openDrawer.id, unitId: unitId }}
        refetchData={fetchData}
      />

      <Drawer.DetailPembayaranPPDB
        visible={
          openDrawer.type == 'PPDB' || openDrawer.type == 'PPDB Instalment'
        }
        onCancel={() => {
          setOpenDrawer({
            type: '',
            id: '',
          });
        }}
        value={{ paymentId: openDrawer.id, unitId: unitId }}
        refetchData={fetchData}
      />

      <Drawer.DetailPembayaranLainnya
        visible={openDrawer.type === 'General'}
        onCancel={() => {
          setOpenDrawer({
            type: '',
            id: '',
          });
        }}
        value={{ paymentId: openDrawer.id, unitId: unitId }}
        refetchData={fetchData}
      />

      <Drawer.RefundPembayaran
        visible={refundDrawer.state}
        onCancel={() => {
          setRefundDrawer({
            type: '',
            id: '',
            state: false,
          });
        }}
        value={{
          paymentId: refundDrawer.id,
          unitId: unitId,
          type: refundDrawer.type,
        }}
        refetchData={fetchData}
      />
    </>
  );
};

export default DetailMuridRefund;
