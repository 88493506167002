import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Input,
  Menu,
  message,
  Popover,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from 'antd';
import { Card } from 'components/fragments';
import { Drawer, InputWrapper, PageHeader } from 'components/shared';
import React, { useEffect, useState } from 'react';
import { useStore } from 'store';
import IC_PLUSR from 'assets/icons/plus-rounded.svg';
import queryString from 'querystring';
import IC_EDIT from 'assets/icons/Edit.svg';
import { CustomButton } from 'components/atoms';
import { useHistory, useLocation } from 'react-router-dom';
import {
  createNewUser,
  downloadDataPembayaran,
  editUserData,
  fetchUserList,
  getListKelasId,
  getListMurid,
  getListTahunAjaran,
  getPayment,
} from 'utils/fetch';
import {
  AccountBookOutlined,
  DownloadOutlined,
  EyeOutlined,
  FilterOutlined,
  InfoCircleOutlined,
  RetweetOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { InfoCard } from 'components/fragments/card';
import moment from 'moment';
import exportExcel from 'utils/helpers/xlsx';
import { goToSearchQuery } from 'utils/helpers/common';

export const Pembayaran = () => {
  const {
    handleBreadcrumb,
    handleSiderActive,
    handleListSchoolYear,
    handleListKelas,
    state: { activeSchoolYear, selectedUnit, listKelas, listTahunAjaran },
  } = useStore();
  const history = useHistory();
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = useState({
    type: '',
    id: '',
  });
  const [refundDrawer, setRefundDrawer] = useState({
    type: '',
    id: '',
    state: false,
  });
  const [data, setData] = useState(null);
  const [isEdit, setIsEdit] = useState({
    state: false,
    data: null,
  });
  const [filterPopover, setFilterPopover] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totalData, setTotalData] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [filter, setFilter] = useState({
    name: null,
    grade: null,
    start_date: null,
    end_date: null,
    nis: null,
    bill_type: null,
    bill_name: null,
  });
  const unitId = selectedUnit?._id;
  const schoolYearId = activeSchoolYear?._id;
  const {
    unit,
    limit = 10,
    page = 1,
    start_date,
    end_date,
    name,
    grade,
    nis,
    bill_type,
    bill_name,
  } = queryString.parse(location.search.replace('?', ''));
  const filterQuery = {
    unit,
    limit,
    page,
    name,
    grade,
    start_date,
    end_date,
    nis,
    bill_type,
    bill_name,
  };

  useEffect(() => {
    const newBreadcrumb = [
      {
        linkTo: '/pembayaran',
        node: <span>Daftar Pembayaran</span>,
      },
    ];
    handleBreadcrumb(newBreadcrumb);
    handleSiderActive(['5']);
  }, []);

  useEffect(() => {
    if (currentPage) {
      Object.keys(filterQuery).forEach((key) => {
        if (!filterQuery[key]) delete filterQuery[key];
      });
      history.push({
        search: queryString.stringify({
          ...filterQuery,
          page: currentPage,
        }),
      });
    }
  }, [currentPage]);

  useEffect(() => {
    if (unitId && schoolYearId) {
      fetchDataListKelas();
      fetchDataTahunAjaran();
    }
  }, [unitId, schoolYearId]);

  useEffect(() => {
    if (selectedUnit) {
      fetchData();
    }

    return () => {};
  }, [
    page,
    limit,
    name,
    start_date,
    end_date,
    grade,
    nis,
    selectedUnit,
    bill_type,
    bill_name,
  ]);

  useEffect(() => {
    setFilter({
      name,
      start_date,
      end_date,
      grade,
      nis,
      bill_type,
      bill_name,
    });
  }, [name, start_date, end_date, grade, nis, bill_type, bill_name]);

  const fetchDataTahunAjaran = () => {
    getListTahunAjaran()
      .then((res) => {
        console.log(res);
        handleListSchoolYear(res.schoolsYears);
      })
      .catch((err) => console.error(err));
  };

  const fetchDataListKelas = () => {
    getListKelasId(unitId, schoolYearId)
      .then((res) => {
        console.log(res.grades);
        handleListKelas(res.grades);
      })
      .catch((err) => console.error(err));
  };

  const fetchData = () => {
    let query = `limit=${limit}&page=${page - 1}`;

    if (name) query += `&student_name=${name}`;
    if (end_date) query += `&end_date=${end_date}`;
    if (start_date) query += `&start_date=${start_date}`;
    if (grade) query += `&grade=${grade}`;
    if (nis) query += `&student_nik=${nis}`;
    if (bill_type) query += `&bill_type=${bill_type}`;
    if (bill_name) query += `&bill_name=${bill_name}`;

    console.log(query, 'this is query ke backend');

    setLoading(true);

    getPayment(unitId, 'payment', query)
      .then((res) => {
        const numbered = res.payments.map((el, idx) => ({
          ...el,
          no: idx + 1,
        }));
        console.log(res, 'this is payment data');
        // console.log(res);
        setData(numbered);
        setTotalData(res.totalData);
        // setOtherData({
        //   belumAlokasi: res.notYetAllocateCount,
        //   totalAmount: res.notYetAllocateAmount,
        // });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err, 'this is error fetch');
      });
  };

  const handleSubmitFilter = () => {
    Object.keys(filter).forEach((key) => {
      if (!filter[key]) delete filter[key];
    });
    history.push({
      pathname: location.pathname,
      search: queryString.stringify({
        unit,
        limit,
        page,
        ...filter,
      }),
    });
    setFilterPopover(false);
  };

  const filterMenu = (
    <div style={{ width: '30rem' }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Input
          type="text"
          name="name"
          value={filter.name}
          placeholder="Nama Murid"
          onChange={(e) => {
            const newFilter = {
              ...filter,
              name: e.target.value,
            };
            setFilter(newFilter);
          }}
        />
        <Input
          type="text"
          name="nis"
          value={filter.nis}
          placeholder="Nomor NIS"
          onChange={(e) => {
            const newFilter = {
              ...filter,
              nis: e.target.value,
            };
            setFilter(newFilter);
          }}
        />
        <Select
          placeholder="Kelas"
          style={{ width: '100%' }}
          value={filter.grade}
          onChange={(val) => {
            const newFilter = {
              ...filter,
              grade: val,
            };
            console.log(newFilter);
            setFilter(newFilter);
          }}
        >
          {[
            { _id: 'Murid Tanpa Kelas', name: 'Murid Tanpa Kelas' },
            ...listKelas,
          ].map((kelas) => {
            return (
              <Select.Option value={kelas._id}>{kelas.name}</Select.Option>
            );
          })}
        </Select>
        <Select
          placeholder="Tipe Tagihan"
          style={{ width: '100%' }}
          value={filter.bill_type}
          onChange={(val) => {
            const newFilter = {
              ...filter,
              bill_type: val,
            };
            console.log(newFilter);
            setFilter(newFilter);
          }}
        >
          {[
            { key: 'PPDB Instalment', name: 'PSB' },
            { key: 'SPP', name: 'SPP' },
            { key: 'General', name: 'Tagihan Lainnya' },
          ].map((kelas) => {
            return (
              <Select.Option value={kelas.key}>{kelas.name}</Select.Option>
            );
          })}
        </Select>
        <Input
          type="text"
          name="bill_name"
          value={filter.bill_name}
          placeholder="Nama Tagihan"
          onChange={(e) => {
            const newFilter = {
              ...filter,
              bill_name: e.target.value,
            };
            setFilter(newFilter);
          }}
        />
        <DatePicker.RangePicker
          // placeholder="Tanggal Bayar"
          style={{ width: '100%' }}
          onChange={(e, dateString) => {
            const newFilter = {
              ...filter,
              start_date: dateString[0],
              end_date: dateString[1],
            };
            setFilter(newFilter);
            console.log(dateString, 'this is result from range picker');
          }}
        />
        <footer
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            borderTop: '1px solid #d5d5d5',
            paddingTop: '0.5rem',
          }}
        >
          <Space>
            <Button
              onClick={() => {
                setFilterPopover(false);
                setFilter({
                  name: null,
                  date: null,
                  grade: null,
                  nis: null,
                  bill_type: null,
                  bill_name: null,
                });
                setCurrentPage(1);
                history.push({
                  pathname: location.pathname,
                  search: queryString.stringify({
                    unit: selectedUnit.name,
                    limit,
                    page,
                  }),
                });
              }}
            >
              Hapus Filter
            </Button>
            <Button type="primary" onClick={handleSubmitFilter}>
              Cari
            </Button>
          </Space>
        </footer>
      </Space>
    </div>
  );

  const tableColumns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: 80,
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Nama Murid',
      dataIndex: 'student',
      key: 'student',
      render: (data, record) => {
        return (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() =>
              window.open(
                `/daftar-murid/detail/${data._id}?unit=${selectedUnit?.name}`,
                '_blank'
              )
            }
          >
            {data?.name || '-'}
          </span>
        );
      },
    },
    {
      title: 'Nama Tagihan',
      dataIndex: 'name',
      key: 'name',
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Tgl Bayar',
      dataIndex: 'date',
      key: 'date',
      render: (data) => <span>{moment(data).format('DD/MM/YYYY')}</span>,
    },
    {
      title: 'Kelas',
      dataIndex: 'student',
      key: 'student_grade',
      width: 100,
      render: (data) => <span>{data?.grade?.name || 'Murid Tanpa Kelas'}</span>,
    },
    {
      title: 'Nominal',
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (data) => <span>Rp {(data || 0).toLocaleString()}</span>,
    },
    {
      title: 'Sumber Dana',
      dataIndex: 'payment_origin',
      key: 'payment_origin',
      render: (data) => {
        return <span>{data ? data : '-'}</span>;
      },
    },
    {
      title: '',
      dataIndex: 'bill_type',
      key: 'bill_type',
      align: 'center',
      fixed: 'right',
      width: 250,
      render: (type, record) => {
        return (
          <Space>
            <Button
              icon={<EyeOutlined />}
              onClick={() =>
                setOpenDrawer({
                  type,
                  id: record._id,
                })
              }
            >
              Lihat
            </Button>
            <Button
              icon={<RetweetOutlined />}
              onClick={() =>
                setRefundDrawer({
                  type,
                  id: record._id,
                  state: true,
                })
              }
            >
              Refund
            </Button>
          </Space>
        );
      },
    },
  ];

  // download pembayaran

  const parsedData = (data = []) => {
    const parsed = [
      [
        'No',
        'Tanggal',
        'Nama',
        'NIS',
        'Kelas',
        'Nama Tagihan',
        'Total Nominal',
        'Item Tagihan',
      ],
    ];

    data.forEach((dt, idx) => {
      const list = [
        idx + 1,
        moment(dt.date).format('DD/MM/YYYY'),
        dt.studentName,
        dt.studentNik,
        dt.gradeName,
        dt.billName,
        dt.totalAmount.toLocaleString(),
        dt.itemList,
      ];

      parsed.push(list);
    });

    return parsed;
  };

  const downloadLaporan = async () => {
    try {
      setLoadingDownload(true);

      let query = `limit=${limit}&page=${page - 1}`;

      if (name) query += `&student_name=${name}`;
      if (end_date) query += `&end_date=${end_date}`;
      if (start_date) query += `&start_date=${start_date}`;
      if (grade) query += `&grade=${grade}`;
      if (nis) query += `&student_nik=${nis}`;

      const { payments } = await downloadDataPembayaran(unitId, query);

      const newParsedData = parsedData(payments);

      exportExcel(newParsedData, 'Laporan Pembayaran');
      setLoadingDownload(false);
    } catch (err) {
      setLoadingDownload(false);
      console.log(err);
      message.error(err?.data?.m || 'Gagal download excel');
    }
  };

  return (
    <>
      <PageHeader>
        <Space>
          <Button
            type="default"
            icon={<DownloadOutlined />}
            onClick={() => downloadLaporan()}
            loading={loadingDownload}
          >
            Download Laporan
          </Button>
          <CustomButton.IconTextButton
            type="primary"
            icon={IC_PLUSR}
            onClick={() =>
              history.push({
                pathname: '/pembayaran/tambah-pembayaran',
                search: queryString.stringify({
                  unit: selectedUnit.name,
                }),
              })
            }
          >
            Tambah Pembayaran
          </CustomButton.IconTextButton>
        </Space>
      </PageHeader>
      <section style={{ width: 'auto', margin: '2rem 0' }}>
        <Popover
          content={filterMenu}
          visible={filterPopover}
          placement="bottomLeft"
          style={{ width: '30rem' }}
        >
          <Button
            onClick={() => setFilterPopover(!filterPopover)}
            icon={<FilterOutlined />}
          >
            Cari Berdasarkan
          </Button>
        </Popover>
      </section>

      <section style={{ width: '100%', margin: '2rem 0' }}>
        <Table
          dataSource={data}
          columns={tableColumns}
          pagination={{
            position: ['bottomLeft'],
            total: totalData && totalData,
            onChange: (page) => {
              setCurrentPage(page);
            },
            current: currentPage,
            pageSize: limit,
            showSizeChanger: true,
            pageSizeOptions: ['10', '30', '50'],
            onShowSizeChange: (curr, pageSize) => {
              goToSearchQuery('limit', pageSize, filterQuery, history);
            },
          }}
          scroll={{ x: 1500 }}
          loading={loading}
        />
      </section>

      <Drawer.DetailPembayaranSPP
        visible={openDrawer.type === 'SPP'}
        onCancel={() => {
          setOpenDrawer({
            type: '',
            id: '',
          });
        }}
        value={{ paymentId: openDrawer.id, unitId: unitId }}
        refetchData={fetchData}
      />

      <Drawer.DetailPembayaranPPDB
        visible={
          openDrawer.type === 'PPDB' || openDrawer.type === 'PPDB Instalment'
        }
        onCancel={() => {
          setOpenDrawer({
            type: '',
            id: '',
          });
        }}
        value={{ paymentId: openDrawer.id, unitId: unitId }}
        refetchData={fetchData}
      />

      <Drawer.DetailPembayaranLainnya
        visible={openDrawer.type === 'General'}
        onCancel={() => {
          setOpenDrawer({
            type: '',
            id: '',
          });
        }}
        value={{ paymentId: openDrawer.id, unitId: unitId }}
        refetchData={fetchData}
      />

      <Drawer.RefundPembayaran
        visible={refundDrawer.state}
        onCancel={() => {
          setRefundDrawer({
            type: '',
            id: '',
            state: false,
          });
        }}
        value={{
          paymentId: refundDrawer.id,
          unitId: unitId,
          type: refundDrawer.type,
        }}
        refetchData={fetchData}
      />
    </>
  );
};

export { default as TambahPembayaran } from './tambah';
