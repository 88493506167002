import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Form,
  Button,
  Space,
  message,
  Input,
  Select,
  List,
  Checkbox,
  Steps,
  Card,
  DatePicker,
  InputNumber,
  Tooltip,
} from 'antd';
import {
  StyledContentWrapper,
  StyledPageContainer,
  StyledFixedFooter,
} from '../style';
import IC_BACK from 'assets/icons/chevron-left.svg';
import { useHistory } from 'react-router-dom';
import { InputWrapper, Modal } from 'components/shared';
import { useStore } from 'store';
import {
  getListKelasId,
  getMasterBillItem,
  getMuridByGrade,
  submitStudentGraduate,
  tambahTagihanLainnya,
} from 'utils/fetch';
import { CardBase, Loader } from 'components/atoms';
import { InfoCard } from 'components/fragments/card';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';

const TambahTagihanLainnya = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const {
    state: { listTahunAjaran, selectedUnit, activeSchoolYear },
  } = useStore();
  const [current, setCurrent] = useState(0);
  const [listMurid, setListMurid] = useState([]);
  const [listKelas, setListKelas] = useState([]);
  const [filterKelas, setFilterKelas] = useState([]);
  const [billItems, setBillItems] = useState([]);
  const [studentByGrade, setStudentByGrade] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState([]);
  const [dataTagihan, setDataTagihan] = useState(null);
  const [tahunAjaran, setTahunAjaran] = useState('');
  const [listTagihan, setListTagihan] = useState([]);
  const [namaTagihan, setNamaTagihan] = useState('');
  const [loading, setLoading] = useState(false);
  const [nameSearch, setNameSearch] = useState('');
  const [filteredStudent, setFilteredStudent] = useState(listMurid);
  const [hasNoGrade, setHasNoGrade] = useState([]);
  const [notActive, setNotActive] = useState([]);
  const unitId = selectedUnit?._id;

  useEffect(() => {
    if (nameSearch) {
      const filtered = listMurid.filter((murid) =>
        murid?.name.toLowerCase().includes(nameSearch.toLowerCase())
      );

      setFilteredStudent(filtered);
    } else {
      setFilteredStudent(listMurid);
    }
  }, [nameSearch]);

  useEffect(() => {
    if (activeSchoolYear?._id && unitId) {
      console.log('hello this is active');
      setTahunAjaran(activeSchoolYear?._id);
      fetchData();
      fetchItemTagihan();
    }
  }, [activeSchoolYear, unitId]);

  useEffect(() => {
    if (filterKelas.length && studentByGrade) {
      const murid = studentByGrade.map((el) => {
        const inFilter = filterKelas.some((fil) => fil === el._id);
        if (inFilter) {
          return el.students;
        } else {
          return;
        }
      });

      if (filterKelas.some((fil) => fil === 'Murid Tanpa Kelas')) {
        murid.push(hasNoGrade);
      }

      if (filterKelas.some((fil) => fil === 'graduated')) {
        murid.push(notActive);
      }

      const flattened = murid.flatMap((x) => x).filter((y) => y !== undefined);

      setListMurid(flattened);
      setFilteredStudent(flattened);
    } else {
      setListMurid([]);
      setFilteredStudent([]);
    }
  }, [filterKelas, studentByGrade]);

  const handleSubmit = (values) => {
    setLoading(true);
    tambahTagihanLainnya(unitId, values)
      .then((res) => {
        console.log(res);
        message.success('Berhasil menambah tagihan lainnya.');
        setLoading(false);
        history.push(`/tagihan/other?unit=${selectedUnit.name}`);
      })
      .catch((err) => {
        message.error(
          err?.response?.data?.m || 'Gagal menambahkan tagihan lainnya.'
        );
        console.log(err.response);
      });
  };

  const fetchItemTagihan = () => {
    getMasterBillItem(unitId)
      .then((res) => {
        console.log(res, 'master bill item');
        setBillItems(res.masterBillItem);
      })
      .catch((err) => {
        console.log(err, 'error master bill item');
      });
  };

  const fetchData = () => {
    getMuridByGrade(unitId, activeSchoolYear?._id)
      .then((res) => {
        setStudentByGrade(res.grades);
        setHasNoGrade(res.studentsNoGrades);
        setNotActive(res.studentsNotActive);
      })
      .catch((err) => {
        console.log(err.response, 'this is error fetch');
      });
  };

  useEffect(() => {
    if (listKelas || listKelas.length) {
      setFilterKelas([listKelas[0]?._id]);
    }
  }, [listKelas]);

  const checkTagihanList = listTagihan.reduce((acc, curr) => {
    if (!curr?.master_bill_item || !curr?.total_amount) {
      acc = false;
    }
    return acc;
  }, true);

  const totalTagihan = listTagihan.reduce((acc, curr) => {
    if (curr?.total_amount) {
      acc += curr?.total_amount;
    }
    return acc;
  }, 0);

  return (
    <StyledPageContainer>
      <StyledContentWrapper>
        <div
          onClick={() => history.goBack()}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '3rem',
            cursor: 'pointer',
          }}
        >
          <img
            src={IC_BACK}
            alt="back"
            style={{ marginRight: '0.75rem' }}
            width="18"
            height="18"
          />
          <span>Kembali</span>
        </div>
        <h2>Buat Tagihan Lainnya</h2>
        <section style={{ marginTop: '2rem', marginBottom: '6rem' }}>
          <Steps current={current}>
            <Steps.Step title="Detail Tagihan" />
            <Steps.Step title="Pilih Murid" />
          </Steps>
        </section>
        <Form
          form={form}
          layout="vertical"
          onFinish={(v) =>
            setDataTagihan({
              ...v,
              total_amount: v.items.reduce(
                (curr, acc) => curr + Number(acc.total_amount),
                0
              ),
            })
          }
          onValuesChange={(changedValue, value) => {
            console.log(value.items);
            console.log(changedValue);
            // console.log(value)
            if (changedValue?.items) {
              setListTagihan(value.items);
            }

            if (changedValue?.name) {
              setNamaTagihan(value.name);
            }
          }}
        >
          {current === 1 ? (
            <>
              <div>
                {studentByGrade ? (
                  <Row gutter={[24, 14]}>
                    <Col span="8">
                      <CardBase
                        style={{
                          overflowY: 'auto',
                        }}
                        title="Daftar Kelas"
                        bodyStyle={{
                          padding: 0,
                          display: 'flex',
                          maxHeight: '32.575rem',
                          flexDirection: 'column',
                        }}
                      >
                        <>
                          <Checkbox
                            onChange={() => {
                              if (
                                filterKelas.length ===
                                studentByGrade.length + 2
                              ) {
                                setFilterKelas([]);
                              } else {
                                const allGradesId = studentByGrade?.map(
                                  (grd) => grd._id
                                );
                                setFilterKelas([
                                  ...allGradesId,
                                  'Murid Tanpa Kelas',
                                  'graduated',
                                ]);
                              }
                            }}
                            checked={
                              filterKelas.length === studentByGrade.length + 2
                            }
                            style={{ margin: '1rem 0' }}
                          >
                            Pilih Semua Kelas
                          </Checkbox>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              margin: '1rem 0',
                            }}
                          >
                            <Checkbox
                              checked={filterKelas?.some(
                                (el) => el === 'graduated'
                              )}
                              onChange={() => {
                                if (
                                  filterKelas?.some((el) => el === 'graduated')
                                ) {
                                  const filtered = filterKelas.filter(
                                    (el) => el !== 'graduated'
                                  );
                                  setFilterKelas(filtered);
                                } else {
                                  setFilterKelas([...filterKelas, 'graduated']);
                                }
                              }}
                            >
                              Lulus
                            </Checkbox>
                            <span>{notActive?.length || 0} Murid</span>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              margin: '1rem 0',
                            }}
                          >
                            <Checkbox
                              checked={filterKelas?.some(
                                (el) => el === 'Murid Tanpa Kelas'
                              )}
                              onChange={() => {
                                if (
                                  filterKelas?.some(
                                    (el) => el === 'Murid Tanpa Kelas'
                                  )
                                ) {
                                  const filtered = filterKelas.filter(
                                    (el) => el !== 'Murid Tanpa Kelas'
                                  );
                                  setFilterKelas(filtered);
                                } else {
                                  setFilterKelas([
                                    ...filterKelas,
                                    'Murid Tanpa Kelas',
                                  ]);
                                }
                              }}
                            >
                              Tanpa Kelas
                            </Checkbox>
                            <span>{hasNoGrade?.length || 0} Murid</span>
                          </div>

                          {studentByGrade?.map((grade) => {
                            const checked = filterKelas?.some(
                              (el) => el === grade._id
                            );
                            return (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  margin: '1rem 0',
                                }}
                              >
                                <Checkbox
                                  checked={checked}
                                  onChange={() => {
                                    if (checked) {
                                      const filtered = filterKelas.filter(
                                        (el) => el !== grade._id
                                      );
                                      setFilterKelas(filtered);
                                    } else {
                                      setFilterKelas([
                                        ...filterKelas,
                                        grade._id,
                                      ]);
                                    }
                                  }}
                                >
                                  {grade.name}
                                </Checkbox>
                                <span>{grade.students?.length} Murid</span>
                              </div>
                            );
                          })}
                        </>
                      </CardBase>
                    </Col>
                    <Col span="16">
                      <CardBase
                        style={{
                          overflowY: 'auto',
                        }}
                        bodyStyle={{
                          padding: '16px 0',
                          display: 'flex',
                          maxHeight: '38.375rem',
                          flexDirection: 'column',
                        }}
                      >
                        <Input.Search
                          placeholder="Cari nama murid"
                          value={nameSearch}
                          onChange={(e) => setNameSearch(e.target.value)}
                        />
                        <h4 style={{ marginTop: '10px' }}>
                          Murid Terpilih: {selectedStudent.length}
                        </h4>
                        <Tooltip title="Anda akan memilih semua murid dalam kelas terpilih.">
                          <Checkbox
                            onChange={() => {
                              if (selectedStudent.length === listMurid.length) {
                                setSelectedStudent([]);
                              } else {
                                const students = listMurid.map(
                                  (murid) => murid._id
                                );
                                setSelectedStudent(students);
                              }
                            }}
                            checked={
                              selectedStudent.length === listMurid.length
                            }
                            style={{ marginTop: '1.5rem' }}
                          >
                            Pilih Semua Murid
                          </Checkbox>
                        </Tooltip>
                        {listMurid.length ? (
                          <section style={{ margin: '1rem 0' }}>
                            <List
                              dataSource={listMurid.filter((item) => {
                                return (
                                  item.name
                                    .toLowerCase()
                                    .indexOf(nameSearch.toLowerCase()) > -1
                                );
                              })}
                              renderItem={(item) => {
                                const checked = selectedStudent.some(
                                  (el) => el === item._id
                                );

                                return (
                                  <List.Item
                                    onClick={() => {
                                      if (checked) {
                                        const filtered = selectedStudent.filter(
                                          (el) => el !== item._id
                                        );
                                        setSelectedStudent(filtered);
                                      } else {
                                        setSelectedStudent([
                                          ...selectedStudent,
                                          item._id,
                                        ]);
                                      }
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '.8rem',
                                        width: '100%',
                                      }}
                                    >
                                      <Checkbox
                                        checked={checked}
                                        onChange={() => {
                                          if (checked) {
                                            const filtered =
                                              selectedStudent.filter(
                                                (el) => el !== item._id
                                              );
                                            setSelectedStudent(filtered);
                                          } else {
                                            setSelectedStudent([
                                              ...selectedStudent,
                                              item._id,
                                            ]);
                                          }
                                        }}
                                      />
                                      {item.name}
                                    </div>
                                  </List.Item>
                                );
                              }}
                            />
                          </section>
                        ) : (
                          <div style={{ marginTop: '1rem' }}>
                            Tidak ada murid di kelas ini
                          </div>
                        )}
                      </CardBase>
                    </Col>
                  </Row>
                ) : (
                  <Loader text="Fetch data murid..." />
                )}
              </div>
            </>
          ) : (
            <>
              <Row gutter={[18, 8]}>
                <Col span="12">
                  <InputWrapper
                    type="text"
                    name="name"
                    label="Nama Tagihan"
                    placeholder="Ketik nama tagihan di sini"
                  />
                </Col>
                <Col span="12">
                  <Form.Item label="Tahun Ajaran">
                    <Select
                      placeholder="Pilih tahun ajaran"
                      style={{ width: '100%' }}
                      defaultValue={activeSchoolYear?._id}
                      onChange={(e) => setTahunAjaran(e)}
                    >
                      {listTahunAjaran?.map((year) => (
                        <Select.Option value={year._id}>
                          {year.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span="12">
                  <Form.Item label="Tanggal Jatuh Tempo" name={'date'}>
                    <DatePicker
                      // defaultValue={moment()}
                      format="DD/MM/YYYY"
                      style={{ width: '100%' }}
                      onChange={(_, dateString) => {
                        console.log(dateString);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <section style={{ margin: '4rem 0', width: '100%' }}>
                <h3>Item Tagihan</h3>
                <InfoCard
                  sideColor="blue"
                  title="Total"
                  content={`Rp${(totalTagihan || 0).toLocaleString()}`}
                />
                {/* <Form.List> */}
                <section style={{ marginTop: '2rem' }}>
                  <Form.List name="items">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          {fields.map((field, idx) => {
                            return (
                              <div
                                key={field.key}
                                required={false}
                                style={{ margin: 0 }}
                              >
                                <Row gutter={[12, 0]}>
                                  <Col span="9">
                                    <Form.Item
                                      {...field}
                                      name={[field.name, 'master_bill_item']}
                                    >
                                      <Select
                                        placeholder="Pilih Item"
                                        style={{ width: '100%' }}
                                      >
                                        {billItems?.map((item) => (
                                          <Select.Option value={item._id}>
                                            {item.name}
                                          </Select.Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col span="9">
                                    <InputWrapper
                                      formItemProps={{
                                        name: [field.name, 'total_amount'],
                                      }}
                                      type="number"
                                      // name={[field.name, 'total_amount']}
                                      prefix="Rp"
                                      // onBlur={(e) => {
                                      //   const array = [...totalAmount];
                                      //   array[field.key] = Number(
                                      //     e.target.value.replace(/\Rp\s?|(,*)/g, '')
                                      //   );
                                      //   setTotalAmount(array);
                                      // }}
                                      placeholder="Rp 500.000"
                                      formatter={(value) =>
                                        `Rp ${value}`.replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ','
                                        )
                                      }
                                      parser={(value) =>
                                        value.replace(/\Rp\s?|(,*)/g, '')
                                      }
                                    />
                                  </Col>
                                  {/* <Col span="7">
                                    <InputWrapper
                                      formItemProps={{
                                        name: [field.name, 'prioritas'],
                                      }}
                                      type="number"
                                      // name={[field.name, 'total_amount']}

                                      placeholder="Prioritas"
                                    />
                                  </Col> */}
                                  <Col span="3">
                                    <Form.Item
                                      // name={[field.name, 'priority']}
                                      initialValue={idx + 1}
                                    >
                                      <InputNumber
                                        value={idx + 1}
                                        placeholder="Prioritas"
                                        min={1}
                                        style={{ width: '100%' }}
                                        disabled
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span="3">
                                    <Button
                                      type="text"
                                      onClick={() => {
                                        remove(field.name);
                                      }}
                                      icon={
                                        <DeleteOutlined
                                          style={{ fontSize: '20px' }}
                                        />
                                      }
                                    />
                                  </Col>
                                </Row>
                              </div>
                            );
                          })}

                          <Button
                            icon={<PlusCircleOutlined />}
                            onClick={() => add()}
                          >
                            Tambah Item
                          </Button>
                        </>
                      );
                    }}
                  </Form.List>
                </section>
              </section>
            </>
          )}
        </Form>

        <StyledFixedFooter>
          <Space>
            <Button
              onClick={() => {
                if (current === 0) {
                  history.goBack();
                } else {
                  setCurrent(current - 1);
                }
              }}
            >
              Kembali
            </Button>
            {current === 0 ? (
              <Button
                type="primary"
                disabled={
                  !checkTagihanList || listTagihan?.length == 0 || !namaTagihan
                }
                onClick={() => {
                  setCurrent(current + 1);
                  form.submit();
                }}
              >
                Berikutnya
              </Button>
            ) : (
              <Button
                loading={loading}
                type="primary"
                disabled={!selectedStudent.length}
                onClick={() => {
                  const payload = {
                    ...dataTagihan,
                    studentsId: selectedStudent,
                    school_year: tahunAjaran,
                    date: moment(dataTagihan?.date).format('MM/DD/YYYY'),
                  };
                  // console.log(payload);
                  handleSubmit(payload);
                }}
              >
                Simpan
              </Button>
            )}
          </Space>
        </StyledFixedFooter>
      </StyledContentWrapper>
    </StyledPageContainer>
  );
};

export default TambahTagihanLainnya;
