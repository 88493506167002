import React, { useEffect, useState } from 'react';
import {
  Drawer,
  Form,
  DatePicker,
  Alert,
  Divider,
  Input,
  Button,
  Select,
  Radio,
  Col,
  Row,
} from 'antd';
import { Footer } from './shared';

const SistemPembayaran = (props) => {
  const [form] = Form.useForm();
  const [variableStatus, setVariableStatus] = useState(false);
  const [fixedStatus, setFixedStatus] = useState(false);
  const {
    visible,
    onCancel,
    value,
    isEdit,
    handleSubmit,
    loadingState,
    ...rest
  } = props;
  const ArrayTanggal = Array.from({ length: 31 }, (_, i) => i + 1);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    } else {
      form.setFieldsValue({
        fixed_payment_status: value.fixed_payment_status === true ? 1 : 0 || '',
        variable_payment_status:
          value.variable_payment_status === true ? 1 : 0 || '',
        fixed_payment_start: value.fixed_payment_start || '',
        variable_payment_start: value.variable_payment_start || '',
        fixed_payment_end: value.fixed_payment_end || '',
        variable_payment_end: value.variable_payment_end || '',
      });
    }
    setVariableStatus(value.variable_payment_status);
    setFixedStatus(value.fixed_payment_status);
  }, [visible]);

  return (
    <Drawer
      title={`${isEdit ? 'Edit' : 'Aktifkan'} Sistem Pembayaran`}
      width="38rem"
      visible={visible}
      footer={
        <Footer
          onCancel={onCancel}
          onSubmit={() => handleSubmit(form.getFieldsValue())}
          loadingState={loadingState}
        />
      }
      onClose={onCancel}
      {...rest}
    >
      {/* {yearSelected && (
        <Alert
          showIcon
          type="info"
          message={`Tahun yang terbuat Juni ${yearSelected} - Juli ${
            Number(yearSelected) + 1
          }`}
        />
      )} */}
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          fixed_status: 0,
          flexible_status: 0,
        }}
        onFinish={(val) => handleSubmit(val)}
      >
        <section>
          <h3 style={{ marginBottom: '2rem' }}>Fixed Payment</h3>
          <Form.Item name="fixed_payment_status" label="Status">
            <Radio.Group onChange={(e) => setFixedStatus(e.target.value)}>
              <Radio value={1}>Aktif</Radio>
              <Radio value={0}>Tidak Aktif</Radio>
            </Radio.Group>
          </Form.Item>
          {fixedStatus ? (
            <Row gutter={[12, 0]}>
              <Col span="12">
                <Form.Item name="fixed_payment_start" label="Start Date">
                  <Select>
                    {ArrayTanggal.map((tgl) => (
                      <Select.Option
                        value={tgl}
                        // disabled={
                        //   tgl <= form.getFieldValue('variable_payment_start')
                        // }
                      >
                        {tgl}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item name="fixed_payment_end" label="End Date">
                  <Select>
                    {ArrayTanggal.map((tgl) => (
                      <Select.Option
                        value={tgl}
                        // disabled={
                        //   tgl <= form.getFieldValue('variable_payment_end')
                        // }
                      >
                        {tgl}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          ) : null}
        </section>
        <Divider />
        <section>
          <h3 style={{ marginBottom: '2rem' }}>Flexible Payment</h3>
          <Form.Item name="variable_payment_status" label="Status">
            <Radio.Group onChange={(e) => setVariableStatus(e.target.value)}>
              <Radio value={1}>Aktif</Radio>
              <Radio value={0}>Tidak Aktif</Radio>
            </Radio.Group>
          </Form.Item>
          {variableStatus ? (
            <Row gutter={[12, 0]}>
              <Col span="12">
                <Form.Item name="variable_payment_start" label="Start Date">
                  <Select>
                    {ArrayTanggal.map((tgl) => (
                      <Select.Option
                        value={tgl}
                        disabled={
                          tgl <= form.getFieldValue('fixed_payment_end')
                        }
                      >
                        {tgl}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item name="variable_payment_end" label="End Date">
                  <Select>
                    {ArrayTanggal.map((tgl) => (
                      <Select.Option
                        value={tgl}
                        disabled={
                          tgl <= form.getFieldValue('fixed_payment_end')
                        }
                      >
                        {tgl}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          ) : null}
        </section>
      </Form>
    </Drawer>
  );
};

export default SistemPembayaran;
