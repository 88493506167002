import React, { useEffect, useState } from 'react';
import {
  Drawer,
  Form,
  Row,
  Col,
  Space,
  Button,
  Card,
  Divider,
  Tag,
  Spin,
  Modal,
  message,
  Table,
  Menu,
  Dropdown,
  DatePicker,
  Input,
} from 'antd';
import { Footer } from './shared';
import { InputWrapper, PageHeader } from '..';
import { InfoCard, StudentCard } from 'components/fragments/card';
import { CustomButton, Loader } from 'components/atoms';
import IC_EDIT from 'assets/icons/Edit.svg';
import moment from 'moment';
import {
  CalendarOutlined,
  DeleteOutlined,
  EditOutlined,
  FileDoneOutlined,
  FileOutlined,
  MenuOutlined,
  PoweroffOutlined,
  StarFilled,
} from '@ant-design/icons';
import YellowCard from 'components/atoms/card-base/yellow-card';
import {
  getDetailTagihan,
  editNominal,
  nonAktifkanSPP,
  getDetailPembayaran,
  aktifkanSPP,
  BASE_URL,
  createApresiasi,
  deleteApresiasi,
  updateApresiasi,
} from 'utils/fetch';
import { statusMurid, tipeMurid } from 'utils/helpers/common';
import { themeColor } from 'lib/theme';

const DetailTagihanSPP = (props) => {
  const [form] = Form.useForm();
  const { visible, onCancel, value, refetchData, content, ...rest } = props;
  const [isEdit, setIsEdit] = useState(false);

  const [apresiasi, setApresiasi] = useState(false);
  const [dateApresiasi, setDateApresiasi] = useState('');
  const [listApresiasi, setListApresiasi] = useState([]);
  const [editApresiasi, setEditApresiasi] = useState({
    state: false,
    data: null,
  });

  const [detail, setDetail] = useState(null);
  const [sppItems, setSppItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [payment, setPayment] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const { id = '', unitId = '', data } = value;
  const bulan = moment(detail?.month).format('MMMM YYYY') || '-';

  useEffect(() => {
    if (visible) {
      // fetchDataPembayaran();
      fetchData();
    } else {
      form.resetFields();
      setIsEdit(false);
      setEditApresiasi({
        state: false,
        data: null,
      });
    }
  }, [visible]);

  useEffect(() => {
    if (content === 'Apresiasi') {
      setEditApresiasi({
        state: true,
        data: data,
      });
      setApresiasi(true);
    }
  }, [content]);

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({
        sudah: detail?.paid_amount,
      });
    } else {
      form.resetFields();
    }
  }, [isEdit]);

  useEffect(() => {
    if (editApresiasi.state && editApresiasi.data) {
      form.setFieldsValue({
        total_amount: editApresiasi.data?.total_amount,
        notes: editApresiasi.data?.notes,
        date: moment(editApresiasi.data?.date),
      });
      setDateApresiasi(editApresiasi?.data?.date);
    } else {
      form.resetFields();
    }
  }, [editApresiasi]);

  const fetchData = () => {
    setLoading(true);
    getDetailTagihan(unitId, id, 'spp')
      .then((value) => {
        console.log(value, 'detail spp');
        setLoading(false);
        setDetail(value.sppBill);
        setPayment(value.payments);
        setSppItems(value.items);
        setListApresiasi(value.appreciations);
        form.setFieldsValue({
          items: value.items.map((item) => ({
            spp_bill_item_id: item._id,
            total_amount: item.total_amount,
            paid_amount: item.paid_amount,
            new_total_amount: item.total_amount,
          })),
        });
        setTotalAmount(
          value.items.reduce((acc, curr) => acc + (curr.total_amount || 0), 0)
        );
      })
      .catch((err) => {
        console.log(err, 'this is error');
        setLoading(false);
      });
  };

  const fetchDataPembayaran = () => {
    getDetailPembayaran(unitId, 'spp', id)
      .then((res) => {
        console.log(res, 'detail pembayaran');
        setPayment(res.payment);
      })
      .catch((err) => {
        message.error(
          err?.response?.data?.m || 'Gagal memuat detail pembayaran'
        );
        console.log(err.response.data);
      });
  };

  const submitEditNominal = (payload) => {
    console.log(payload);
    setLoadingSubmit(true);
    editNominal(unitId, 'spp', detail._id, payload)
      .then((res) => {
        setLoadingSubmit(false);
        setIsEdit(false);
        refetchData();
        fetchData();
        onCancel();
        console.log(res);
        message.success('Berhasil memperbaharui data tagihan SPP');
      })
      .catch((err) => {
        setLoadingSubmit(false);
        console.log(err);
        message.error(err.response?.data?.m);
      });
  };

  const submitNonAktif = (status) => {
    setLoadingSubmit(true);
    if (status === 'nonaktif') {
      nonAktifkanSPP(unitId, detail._id)
        .then((res) => {
          setLoadingSubmit(false);
          console.log(res);
          message.success('Berhasil menonaktifkan tagihan SPP');
          onCancel();
          refetchData();
          fetchData();
        })
        .catch((err) => {
          setLoadingSubmit(false);
          console.log(err);
          message.error(err.response.data.m);
        });
    } else if (status === 'aktif') {
      aktifkanSPP(unitId, detail._id)
        .then((res) => {
          setLoadingSubmit(false);
          console.log(res);
          message.success('Berhasil mengaktifkan tagihan SPP');
          onCancel();
          refetchData();
          fetchData();
        })
        .catch((err) => {
          setLoadingSubmit(false);
          console.log(err);
          message.error(err.response.data.m);
        });
    } else {
      return;
    }
  };

  const handleClickNonAktif = () => {
    Modal.confirm({
      title: `Apakah Anda yakin ingin non-aktifkan tagihan SPP ${detail?.student?.name} di bulan ${bulan}?`,
      content: (
        <span>Maka tagihan otomatis tidak akan termasuk dalam sistem.</span>
      ),
      onOk: () => submitNonAktif('nonaktif'),
      okText: 'Non-Aktifkan',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      cancelText: 'Batal',
    });
  };

  const handleClickAktif = () => {
    Modal.confirm({
      title: `Apakah Anda yakin ingin aktifkan tagihan SPP ${detail?.student?.name} di bulan ${bulan}?`,
      content: <span>Maka tagihan otomatis akan termasuk dalam sistem.</span>,
      onOk: () => submitNonAktif('aktif'),
      okText: 'Aktifkan',
      okButtonProps: {
        type: 'primary',
      },
      cancelText: 'Batal',
    });
  };

  const tableColumnsItemTagihan = [
    {
      title: 'Item',
      dataIndex: 'master_bill_item',
      key: 'spp_item',
      render: (data) => <span>{data?.name}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'paid_amount',
      key: 'paid_amount',
      render: (data, record) => {
        const total = record.total_amount - (data || 0);
        const isLunas = total === 0;

        if (isLunas && data !== 0) {
          return <Tag color="green">LUNAS</Tag>;
        } else {
          return (
            <Tag color="red">-Rp{Number(total || 0).toLocaleString()}</Tag>
          );
        }
      },
    },
    {
      title: 'Nominal Tagihan',
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (data) => <span>Rp{Number(data || 0).toLocaleString()}</span>,
    },
  ];

  const tablePembayaran = [
    {
      title: 'Kode Pembayaran',
      dataIndex: 'name',
      key: 'name',
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Tanggal Pembayaran',
      dataIndex: 'date',
      key: 'date',
      render: (data) => (
        <span>{data ? new Date(data).toLocaleDateString('ID') : '-'}</span>
      ),
    },
    {
      title: 'Sumber',
      dataIndex: 'payment_origin',
      key: 'payment_origin',
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Nominal',
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (data) => <span>Rp{Number(data).toLocaleString()}</span>,
    },
  ];

  const tableApresiasi = [
    {
      title: 'Tanggal Pemberian',
      dataIndex: 'date',
      key: 'date',
      render: (data) => (
        <span>{data ? new Date(data).toLocaleDateString('ID') : '-'}</span>
      ),
    },
    {
      title: 'Nominal',
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (data) => <span>Rp{Number(data).toLocaleString()}</span>,
    },
    {
      title: 'Catatan',
      dataIndex: 'notes',
      key: 'notes',
      render: (data) => <span>{data || '-'}</span>,
    },
    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      render: (data, record) => (
        <Space align="center" size="small">
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setApresiasi(true);
              setEditApresiasi({ state: true, data: record });
            }}
          />
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => _handleDeleteApresiasi(data)}
          />
        </Space>
      ),
    },
  ];

  const MenuLainnya = (
    <Menu>
      <Menu.Item onClick={() => setIsEdit(true)}>Edit</Menu.Item>
      <Menu.Item onClick={() => setApresiasi(true)}>Tambah Apresiasi</Menu.Item>
      {detail?.active_status ? (
        <Menu.Item
          danger
          disabled={detail?.paid_amount > 0 || detail?.paid_status || false}
          loading={loadingSubmit}
          onClick={handleClickNonAktif}
        >
          Non-Aktifkan
        </Menu.Item>
      ) : (
        <Menu.Item
          disabled={detail?.paid_amount > 0 || false}
          loading={loadingSubmit}
          onClick={handleClickAktif}
        >
          Aktifkan
        </Menu.Item>
      )}
    </Menu>
  );

  const Detail = () => {
    const outstandingAmount = detail?.total_amount - detail?.paid_amount;
    return (
      <>
        <section style={{ marginBottom: '2rem' }}>
          <header
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '0.75rem',
            }}
          >
            <Space>
              <h2 style={{ margin: 0 }}>SPP {bulan}</h2>
            </Space>
            <Space>
              <Button
                icon={<FileDoneOutlined />}
                onClick={() => {
                  window.open(
                    BASE_URL(
                      `bill/${unitId}/spp/${detail?._id}/download-receipt`
                    ),
                    '_blank'
                  );
                }}
              >
                Receipt
              </Button>

              <Dropdown overlay={MenuLainnya}>
                <Button
                  icon={<MenuOutlined />}
                  // onClick={handleBackToFund}
                  // disabled={detail?.payment.payment_origin === 'Direct VA'}
                >
                  Menu Lainnya
                </Button>
              </Dropdown>
            </Space>
          </header>
          <Space>
            <span>
              <CalendarOutlined style={{ color: '#1890FF' }} />{' '}
              {new Date(detail?.month || '').toLocaleDateString('ID')}
            </span>
          </Space>
        </section>

        <StudentCard student={detail?.student} />

        <Row gutter={[18, 0]} style={{ marginTop: '2rem' }}>
          <Col span="12">
            <InfoCard
              title="Nominal Tagihan"
              content={`Rp${(detail?.total_amount || 0).toLocaleString()}`}
              sideColor="blue"
            />
          </Col>
          <Col span="12">
            <InfoCard
              title="Status Pembayaran"
              content={
                detail?.paid_status
                  ? 'LUNAS'
                  : detail?.paid_amount
                  ? `-Rp${(outstandingAmount || 0).toLocaleString()}`
                  : 'BELUM BAYAR'
              }
              sideColor={
                detail?.paid_status
                  ? 'green'
                  : detail?.total_amount
                  ? 'red'
                  : ''
              }
              fontColor={
                detail?.paid_status
                  ? 'green'
                  : detail?.total_amount
                  ? 'red'
                  : ''
              }
            />
          </Col>
        </Row>

        <section style={{ marginTop: '2rem' }}>
          <h3>Item Tagihan</h3>
          <Table
            columns={tableColumnsItemTagihan}
            dataSource={sppItems}
            pagination={false}
          />
        </section>

        {payment?.length ? (
          <section style={{ marginTop: '2rem' }}>
            <h3>Detail Pembayaran</h3>
            <Table
              columns={tablePembayaran}
              dataSource={payment}
              pagination={false}
            />
          </section>
        ) : null}

        <section style={{ marginTop: '2rem' }}>
          <h3>Apresiasi</h3>
          <Table
            columns={tableApresiasi}
            dataSource={listApresiasi}
            pagination={false}
          />
        </section>
      </>
    );
  };

  const _handleAddApresiasi = async (payload) => {
    try {
      const response = await createApresiasi(unitId, payload);
      if (response) {
        console.log(response);
        fetchData();
        setApresiasi(false);
        form.resetFields();
      }
    } catch (err) {
      console.log(err, 'error add');
    }
  };

  const _handleEditApresiasi = async (id, payload) => {
    try {
      const response = await updateApresiasi(unitId, id, payload);
      if (response) {
        console.log(response);
        fetchData();
        setApresiasi(false);
        setEditApresiasi({ state: false, data: null });
        form.resetFields();
      }
    } catch (err) {
      console.log(err, 'error add');
    }
  };

  const _handleDeleteApresiasi = async (id) => {
    try {
      const response = await deleteApresiasi(unitId, id);
      if (response) {
        fetchData();
      }
    } catch (err) {
      console.log(err, 'error delete');
    }
  };

  return (
    <Drawer
      title={
        isEdit
          ? `Edit Tagihan Murid`
          : apresiasi
          ? `Tambah Apresiasi Murid`
          : `Detail Tagihan Murid`
      }
      width={isEdit ? '50vw' : apresiasi ? '40vw' : '50vw'}
      visible={visible}
      footer={
        (isEdit || apresiasi) && (
          <Footer
            onCancel={() => {
              setIsEdit(false);
              setApresiasi(false);
            }}
            onSubmit={form.submit}
            loadingState={loadingSubmit}
          />
        )
      }
      onClose={onCancel}
      {...rest}
    >
      {isEdit ? (
        <>
          <YellowCard>
            <span>Nama Murid</span>
            <h2 style={{ color: themeColor.primary, margin: 0 }}>
              {detail?.student.name}
            </h2>
          </YellowCard>

          <section style={{ margin: '2rem 0' }}>
            <InfoCard
              sideColor="blue"
              title="Total"
              content={`Rp${(totalAmount || 0).toLocaleString()}`}
            />
          </section>

          <Form
            form={form}
            layout="vertical"
            onFinish={(val) => {
              submitEditNominal({
                items: val.items.map((item) => ({
                  spp_bill_item_id: item.spp_bill_item_id,
                  new_total_amount: item.new_total_amount || 0,
                  // paid_amount: item.paid_amount,
                })),
                total_amount: val.items.reduce(
                  (acc, curr) => acc + (curr.new_total_amount || 0),
                  0
                ),
              });
              console.log(val);
            }}
            onValuesChange={(current, values) => {
              console.log(current, values, 'THIS IS CHANGED VALUE');
              if (current.items) {
                setTotalAmount(
                  values.items.reduce(
                    (acc, curr) => acc + (curr.new_total_amount || 0),
                    0
                  )
                );
              }
            }}
          >
            {' '}
            <Form.List name="items">
              {(fields, { add, remove }) => {
                return fields.map((field, idx) => {
                  return (
                    <section>
                      <h3>{sppItems[idx].master_bill_item.name}</h3>
                      <Row gutter={[18, 8]}>
                        <Col span="8">
                          <InputWrapper
                            type="number"
                            prefix="Rp"
                            name="nominal"
                            label="Nominal Tagihan"
                            disabled
                            formItemProps={{
                              name: [field.name, 'total_amount'],
                            }}
                            formatter={(value) =>
                              `Rp ${value}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ','
                              )
                            }
                            parser={(value) =>
                              value.replace(/\Rp\s?|(,*)/g, '')
                            }
                          />
                        </Col>
                        <Col span="8">
                          <InputWrapper
                            type="number"
                            prefix="Rp"
                            formItemProps={{
                              name: [field.name, 'paid_amount'],
                            }}
                            label="Sudah Bayar"
                            disabled
                            formatter={(value) =>
                              `Rp ${value}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ','
                              )
                            }
                            parser={(value) =>
                              value.replace(/\Rp\s?|(,*)/g, '')
                            }
                          />
                        </Col>
                        <Col span="8">
                          <InputWrapper
                            type="number"
                            placeholder="Rp 500.000"
                            formatter={(value) =>
                              `Rp ${value}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ','
                              )
                            }
                            parser={(value) =>
                              value.replace(/\Rp\s?|(,*)/g, '')
                            }
                            formItemProps={{
                              name: [field.name, 'new_total_amount'],
                            }}
                            label="Ubah Nominal"
                          />
                        </Col>
                      </Row>
                    </section>
                  );
                });
              }}
            </Form.List>
          </Form>
        </>
      ) : !apresiasi ? (
        <Detail />
      ) : apresiasi ? (
        <>
          <YellowCard>
            <span>Nama Murid</span>
            <h2 style={{ color: themeColor.primary, margin: 0 }}>
              {detail?.student.name}
            </h2>
          </YellowCard>

          <section style={{ margin: '2rem 0' }}>
            <InfoCard
              sideColor="blue"
              title="Total"
              content={`Rp${(totalAmount || 0).toLocaleString()}`}
            />
          </section>
          <Form
            form={form}
            layout="vertical"
            onFinish={(v) => {
              const payload = {
                ...v,
                date: dateApresiasi,
              };
              console.log(payload, 'value payload');
              console.log(detail, 'detail');
              if (editApresiasi.state) {
                _handleEditApresiasi(editApresiasi?.data?._id, payload);
              } else {
                _handleAddApresiasi({
                  ...payload,
                  student: detail?.student?._id,
                  bill_type: 'SPP',
                  bill_id: detail?._id,
                  unit: unitId,
                });
              }
            }}
          >
            <Row gutter={[18, 8]}>
              <Col span="12">
                <InputWrapper
                  type="number"
                  prefix="Rp"
                  name="total_amount"
                  label="Nominal"
                  placeholder="Rp 500.000"
                  formatter={(value) =>
                    `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
                />
              </Col>
              <Col span="12">
                <Form.Item label="Tanggal Diberikan" name="date">
                  <DatePicker
                    style={{ width: '100%' }}
                    onChange={(_, dateString) => {
                      setDateApresiasi(dateString);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span="24">
                <Form.Item name="notes" label="Notes">
                  <Input.TextArea
                    rows={4}
                    placeholder="Masukan catatan"
                    onChange={(e) => {
                      const value = e.target.value;
                      console.log(value);
                      form.setFieldsValue({ notes: value });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      ) : (
        <Loader text="Fetching details ..." />
      )}
    </Drawer>
  );
};

export default DetailTagihanSPP;
