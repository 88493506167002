import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  message,
  Modal,
  Select,
  Space,
  Table,
  Upload,
} from 'antd';
import { useStore } from 'store';
import { DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import { parsingVABCA, toText } from 'utils/helpers/parse-va';
import moment from 'moment';
import { uploadVA } from 'utils/fetch';
import exportExcel from 'utils/helpers/xlsx';

export const UploadBox = ({ handleChange, value }) => {
  return (
    <label
      htmlFor="input-va"
      style={{
        border: '1px dashed #bfbfbf',
        width: '100%',
        height: '132px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f5f5f5',
        cursor: 'pointer',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <InboxOutlined
          style={{ color: '#40A9FF', fontSize: '5rem' }}
          value={value}
        />

        <p style={{ fontWeight: 'bold' }}>
          Klik disini dan pilih file .txt data virtual account
        </p>
        <Input
          id="input-va"
          type="file"
          style={{ display: 'none' }}
          onChange={handleChange}
        />
      </div>
    </label>
  );
};

const ImportVA = ({ visible, onClose, refetchData, unitId, ...rest }) => {
  const [parsedData, setParsedData] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [selectedBank, setSelectedBank] = useState('BCA');
  const [failedData, setFailedData] = useState([]);
  const [date, setDate] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [error, setError] = useState({
    fileFormat: false,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!visible) {
      setParsedData([]);
      setFileData(null);
    }
  }, [visible]);

  const handleChangeFile = async (e) => {
    const file = e.target.files[0];
    const result = await toText(file);

    const parsed = parsingVABCA(result);

    setFileData(file);
    setParsedData(parsed.newParsing);
    setDate(parsed.tanggal);

    setTotalAmount(
      parsed.newParsing.reduce((current, acc) => current + acc.amount, 0)
    );

    // console.log(file, type, '==== THIS IS FILE ====');
    console.log(parsed, '==== THIS IS READER RESULT ====');
  };

  const parsedResult = (data = []) => {
    const parsed = [
      [
        'No',
        'Nama Murid',
        'Nomor VA',
        'Tanggal Pembayaran',
        'Nominal Pembayaran',
        'Catatan VA',
        'Hasil Integrasi',
      ],
    ];

    data.forEach((dt, idx) => {
      const array = [
        idx + 1,
        dt.student,
        dt.vaCode,
        moment(dt.date).format('DD/MM/YYYY'),
        `Rp${dt.amount.toLocaleString()}`,
        dt.notes,
        dt.result,
      ];

      parsed.push(array);
    });

    return parsed;
  };

  const handleSubmitVA = (data) => {
    setLoading(true);
    const formData = new FormData();

    formData.append('file', fileData);

    // Object.keys(data).forEach((key) => {
    //   formData.append(key, JSON.parse(data[key]));
    // });
    formData.append('totalAmount', Number(data.totalAmount));
    formData.append('date', date);
    formData.append('newIncomingFunds', JSON.stringify(data.newIncomingFunds));

    uploadVA(unitId, formData, { 'Content-Type': 'multipart/form-data' })
      .then((res) => {
        console.log(res, 'success');
        if (res?.reportStatus?.length) {
          setFailedData(res.reportStatus);
          const parsedExcel = parsedResult(res.reportStatus);
          console.log(parsedExcel, 'this is for excel');
          exportExcel(parsedExcel, `va-${moment().format('DD-MM-YYYY hh.mm')}`);
        }

        onClose();
        refetchData();
      })
      .catch((err) => {
        console.log(err, 'error export excel');
        console.log(err.response, 'error submit va');
        message.error(
          err?.response.data.m ||
            'Gagal import VA, silakan cek format file anda.'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const tableColumns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      render: (data, _, idx) => <span>{idx + 1}</span>,
    },
    {
      title: 'Nomor VA',
      dataIndex: 'vaCode',
      key: 'vaCode',
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Nama Murid',
      dataIndex: 'studentName',
      key: 'studentName',
      width: 200,
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Tanggal',
      dataIndex: 'date',
      key: 'date',
      render: (data) => (
        <span>{data ? moment(data).format('DD/MM/YYYY') : '-'}</span>
      ),
    },
    {
      title: 'Nominal',
      dataIndex: 'amount',
      key: 'amount',
      render: (data) => <span>{(data || 0)?.toLocaleString() || '-'}</span>,
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      render: (data) => <span>{data}</span>,
    },
  ];

  return (
    <Modal
      title="Import File VA"
      visible={visible}
      onCancel={onClose}
      {...rest}
      centered
      // okText="Import File VA"
      // cancelText="Batal"
      width="60vw"
      // okButtonProps={{
      //   loading: loading,
      //   disabled: !fileData && !parsedData.length,
      // }}
      // cancelButtonProps={{ loading: loading }}
      // onOk={() => handleSubmitVA({ newIncomingFunds: parsedData })}
      footer={
        <section
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <span>
            Total: Rp
            {totalAmount.toLocaleString()}
          </span>
          <div>
            <Button onClick={onClose}>Batal</Button>
            <Button
              disabled={!fileData && !parsedData.length}
              onClick={() =>
                handleSubmitVA({
                  newIncomingFunds: parsedData,
                  totalAmount,
                  date,
                })
              }
              type="primary"
              loading={loading}
            >
              Import Vile VA
            </Button>
          </div>
        </section>
      }
    >
      <p style={{ margin: 0, lineHeight: 2 }}>Format Bank</p>
      <Select
        placeholder="Pilih tahun ajaran"
        style={{ width: '50%' }}
        value={selectedBank}
        onChange={(val) => setSelectedBank(val)}
      >
        <Select.Option value="BCA">BCA</Select.Option>
      </Select>
      <section style={{ marginTop: '2rem' }}>
        {!fileData && !parsedData.length ? (
          <UploadBox handleChange={handleChangeFile} value={fileData} />
        ) : (
          <>
            <div
              style={{
                border: error?.fileFormat
                  ? '1px solid #FF4D4F'
                  : '1px dashed #bfbfbf',
                width: '80%',
                padding: '1.5rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Space size="middle">
                <div
                  style={{
                    width: '35px',
                    height: '35px',
                    display: 'grid',
                    placeContent: 'center',
                    backgroundColor: '#26C6DA',
                    color: '#ffffff',
                  }}
                >
                  TXT
                </div>
                <span>{fileData?.name}</span>
              </Space>
              <DeleteOutlined
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setFileData(null);
                  setParsedData([]);
                  setTotalAmount(0);
                }}
              />
            </div>
            {error?.fileFormat ? (
              <span style={{ color: '#FF4D4F', marginTop: '1rem' }}>
                Format .txt tidak sesuai.
              </span>
            ) : (
              <section
                style={{
                  marginTop: '2rem',
                  maxHeight: '30vh',
                  overflowY: 'auto',
                }}
              >
                <Table
                  columns={tableColumns}
                  dataSource={parsedData}
                  pagination={{
                    total: parsedData.length,
                    position: ['bottomLeft'],
                    simple: true,
                  }}
                  size="middle"
                />
              </section>
            )}
          </>
        )}
      </section>
    </Modal>
  );
};

export default ImportVA;
