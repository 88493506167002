import styled from 'styled-components';

export const ProgressContainer = styled.div`
  margin: 1rem 0;
`;

export const ProgressTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
