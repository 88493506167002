import React, { useEffect, useState } from 'react';
import {
  Drawer,
  Form,
  Row,
  Col,
  Space,
  Select,
  Alert,
  DatePicker,
  message,
} from 'antd';
import { Footer } from './shared';
import { InputWrapper } from '..';
import { InfoCard, StudentCard } from 'components/fragments/card';
import { CalendarOutlined, FileOutlined } from '@ant-design/icons';
import { getDetailPembayaran, refundPembayaran } from 'utils/fetch';
import { Loader } from 'components/atoms';

const RefundPembayaran = (props) => {
  const [form] = Form.useForm();
  const { visible, onCancel, value, refetchData, ...rest } = props;
  const [detail, setDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [notes, setNotes] = useState('');
  const [date, setDate] = useState('');
  const { unitId = '', paymentId = '', type = '' } = value;
  const modifiedType = type === 'PPDB Instalment' ? 'ppdb' : type.toLowerCase();

  const ArrayTanggal = Array.from({ length: 31 }, (_, i) => i + 1);

  useEffect(() => {
    if (visible && paymentId) {
      fetchData();
    } else {
      setDetail(null);
      form.resetFields();
    }
  }, [visible, paymentId]);

  useEffect(() => {
    if (detail) {
      form.setFieldsValue({ paid_amount: detail?.payment?.total_amount });
    }
  }, [detail]);

  const fetchData = () => {
    setLoading(true);
    getDetailPembayaran(unitId, 'spp', paymentId)
      .then((res) => {
        console.log(res);
        setDetail(res);
      })
      .catch((err) => console.log(err.response, 'error fetch detail'))
      .finally(() => setLoading(false));
  };

  const handleSubmit = (payload) => {
    setLoadingSubmit(true);
    refundPembayaran(unitId, paymentId, payload)
      .then((res) => {
        console.log(res);
        onCancel();
        // window.location.reload();
        refetchData();
        message.success('Berhasil melakukan refund pembayaran');
      })
      .catch((err) => {
        console.log(err.response, 'error refund pembayaran');
        message.error('Gagal melakukan refund pembayaran');
      })
      .finally(() => setLoadingSubmit(false));
  };

  const drawerTitle = 'Refund Pembayaran';

  return (
    <Drawer
      title={drawerTitle}
      width={`50vw`}
      visible={visible}
      footer={
        <Footer
          onCancel={() => onCancel()}
          onSubmit={form.submit}
          loadingState={loadingSubmit}
          submitDisabled={!!date && !!notes ? false : true}
        />
      }
      onClose={onCancel}
      {...rest}
    >
      {detail ? (
        <>
          <section style={{ marginBottom: '2rem' }}>
            <header
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '0.75rem',
              }}
            >
              <Space>
                <h2 style={{ margin: 0 }}>{detail?.payment?.name}</h2>
              </Space>
            </header>
            <Space>
              <span>
                <CalendarOutlined style={{ color: '#1890FF' }} />{' '}
                {new Date(detail?.payment?.date).toLocaleDateString('id')}
              </span>
              {/* <span>
               <FileOutlined style={{ color: '#1890FF' }} /> SPP2021FK2
             </span> */}
            </Space>
          </section>

          <StudentCard student={detail?.payment.student} />

          <Row gutter={[18, 0]} style={{ marginTop: '2rem' }}>
            <Col span="12">
              {/* <InfoCard
               title="Sudah Bayar"
               content={`Rp${(detail?.payment?.total_amount).toLocaleString(
                 'ID'
               )}`}
               sideColor="green"
             /> */}
            </Col>
            {detail[type]?.refund > 0 && (
              <Col span="12">
                <InfoCard
                  title="Refund"
                  content={`Rp${(
                    detail[modifiedType]?.refund || 0
                  ).toLocaleString('ID')}`}
                  sideColor="red"
                />
              </Col>
            )}
          </Row>
          <Form
            form={form}
            layout="vertical"
            onFinish={(val) => {
              const payload = {
                date: val.date,
                total_amount: val.total_amount,
                notes: val.notes,
                refund_origin: 'Payment',
              };
              console.log(payload);
              handleSubmit(payload);
            }}
          >
            <section style={{ marginTop: '2rem' }}>
              <h3>{detail?.payment.name}</h3>
              <Row gutter={[18, 8]}>
                <Col span="12">
                  <InputWrapper
                    type="number"
                    prefix="Rp"
                    name="paid_amount"
                    label="Sudah Bayar"
                    disabled
                    formatter={(value) =>
                      `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
                  />
                </Col>
                <Col span="12">
                  <InputWrapper
                    type="number"
                    prefix="Rp"
                    name="total_amount"
                    label="Refund"
                    placeholder="Rp 500.000"
                    formatter={(value) =>
                      `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
                  />
                </Col>
                <Col span="12">
                  <Form.Item label="Tanggal Refund" name="date">
                    <DatePicker
                      style={{ width: '100%' }}
                      onChange={(_, dateString) => {
                        setDate(dateString);
                      }}
                    />
                    {/* <Select style={{ width: '100%' }} placeholder="Pilih Tanggal">
                     {ArrayTanggal.map((tgl) => (
                       <Select.Option
                         value={tgl}
                         // disabled={tgl <= form.getFieldValue('fixed_payment_end')}
                       >
                         {tgl}
                       </Select.Option>
                     ))}
                   </Select> */}
                  </Form.Item>
                </Col>
                <Col span="12">
                  <InputWrapper
                    type="text"
                    name="notes"
                    placeholder="Ketik alasan singkat di sini .."
                    label="Alasan Cancel"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (!value) {
                        form.setFieldsValue({ notes: '' });
                      }
                      setNotes(value);
                    }}
                  />
                </Col>
              </Row>
            </section>
          </Form>
        </>
      ) : (
        <Loader text="Fetching details ..." />
      )}
    </Drawer>
  );
};

export default RefundPembayaran;
